import { Handle, Position } from 'reactflow';
import { ReactComponent as PlusIcon } from 'assets/icons/plusIcon.svg';
import { ReactComponent as MinusIcon } from 'assets/icons/minusSign.svg';
import { ReactComponent as RedMinusIcon } from 'assets/icons/minus-red.svg';

import './customNode.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    OPEN_ADD_NODE_DRAWER,
    OPEN_DELETE_NODE_MODAL,
    OPEN_EDIT_NODE_DETAILS,
} from 'redux/types/assetModellingTypes';
import { getNodeDetailsById } from 'redux/actions/AssetModellingActions/assetModellingAction';
import { message } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EquipmentModule, modelType } from 'types/enums';

const CustomNode: React.FC = ({ isConnectable, data }: any) => {
    const { t } = useTranslation('translation');
    const horizontalView = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.horizontalView
    );
    const nodeDetailsById = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.nodeDetailsById
    );
    const modelDetails = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.modelDetails
    );
    const [addIconClicked, setAddIconClicked] = useState(false);
    const dispatch = useDispatch();
    const openEditDrawer = (): any => {
        dispatch(
            getNodeDetailsById({
                nodeId: data?.id,
            })
        );
        dispatch({ type: OPEN_EDIT_NODE_DETAILS });
    };
    const openDeleteModal = (): any => {
        dispatch(
            getNodeDetailsById({
                nodeId: data?.id,
            })
        );
        dispatch({ type: OPEN_DELETE_NODE_MODAL });
    };
    const openAddNodeDrawer = (): any => {
        setAddIconClicked(true);
        dispatch(
            getNodeDetailsById({
                nodeId: data?.id,
            })
        );
    };
    useEffect(() => {
        if (addIconClicked) {
            if (
                modelDetails?.modelType === modelType?.ISA &&
                nodeDetailsById?.node?.nodeType?.level === parseInt(EquipmentModule?.level)
            ) {
                message.error(
                    t('assetModeller.modelOverview.nodeLevelExceeds')
                );
            } else {
                dispatch({ type: OPEN_ADD_NODE_DRAWER });
            }
        }
        setAddIconClicked(false);
    }, [nodeDetailsById]);

    return (
        <>
            <div className="nodeDesign">
                <div
                    className={
                        data?.parentId === 0
                            ? 'parentNode'
                            : 'text-updater-node'
                    }
                    onClick={() => {
                        openEditDrawer();
                    }}
                >
                    <Handle
                        type="target"
                        position={horizontalView ? Position.Left : Position.Top}
                        isConnectable={isConnectable}
                        className={horizontalView ? 'leftRight' : 'topBottom'}
                    />

                    <div className="label">{data?.label}</div>

                    <Handle
                        type="source"
                        position={
                            horizontalView ? Position.Right : Position.Bottom
                        }
                        id="b"
                        isConnectable={isConnectable}
                        className={horizontalView ? 'leftRight' : 'topBottom'}
                    />
                </div>
                <div className="icons">
                    <PlusIcon
                        onClick={() => {
                            openAddNodeDrawer();
                        }}
                        className="icon"
                    />
                    {data?.parentId === 0 ? (
                        <MinusIcon
                            onClick={() => {
                                openDeleteModal();
                            }}
                            className="icon"
                        />
                    ) : (
                        <RedMinusIcon
                            onClick={() => {
                                openDeleteModal();
                            }}
                            className="icon"
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default CustomNode;
