import { Card, Col, Divider, Row } from 'antd';
import './index.scss';
// import ScreenNameHeading from 'components/common/ScreenNameHeading';
import CommunicationChannel from './CommunicationChannel';
import ProviderListing from './ProviderListing';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ProviderDetailsForm from './ProviderDetailsForm';
// import { NOTIFICATIONTYPE } from 'types/enums';
import { useTranslation } from 'react-i18next';
import ScreenNameHeading from 'components/common/ScreenNameHeading';

const NotificationEngine: React.FC = () => {
    const [selectedChannel, setSelectedChannel] = useState<string>('Email');

    const { t } = useTranslation('translation');
    const notificationState = useSelector(
        (state: any) => state?.notificationConfiguration?.notificationState
    );

    const providerFormDetail =
        // notificationState === NOTIFICATIONTYPE.create
        //     ?
        useSelector(
            (state: any) =>
                state?.notificationConfiguration?.providerConfigCreateFields
        );

    return (
        <>
            <div className="notification">
                <Row className="notification__headerWrapper">
                    <Col span={16}>
                        <ScreenNameHeading
                            heading={t(
                                'NotificationConfigurator.screenHeading'
                            )}
                            subHeading={t(
                                'NotificationConfigurator.subHeading'
                            )}
                        />
                    </Col>
                </Row>
                <div className="notification__content">
                    <Card bordered={false} className="notification__card">
                        <Row className="notification__data">
                            <Col span={4} className="notification__dataModel">
                                <CommunicationChannel
                                    selectedChannel={selectedChannel}
                                    setSelectedChannel={setSelectedChannel}
                                />
                            </Col>
                            <Col
                                span={20}
                                className="notification__tableWrapper"
                            >
                                {notificationState === 'view' && (
                                    <>
                                        <div className="notification__tableWrapperPadding ">
                                            <span className="fw-500 fs-16 notification__text">
                                                {t(
                                                    'NotificationConfigurator.selectProvider'
                                                )}
                                            </span>
                                        </div>
                                        <Divider />
                                    </>
                                )}

                                {notificationState === 'view' ? (
                                    <ProviderListing
                                        selectedChannel={selectedChannel}
                                    />
                                ) : (
                                    <>
                                        {providerFormDetail &&
                                            Object?.keys(providerFormDetail)
                                                ?.length > 0 && (
                                                <ProviderDetailsForm
                                                    selectedChannel={
                                                        selectedChannel
                                                    }
                                                />
                                            )}
                                    </>
                                )}
                            </Col>
                        </Row>
                    </Card>
                </div>
            </div>
        </>
    );
};
export default NotificationEngine;
