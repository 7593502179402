import React, { useEffect, useState } from 'react';
import './index.scss';
import { Table, Spin, Tag } from 'antd';
import dayjs from 'dayjs';
import { dateFormat, sortingOrder } from 'types/enums';
import CustomPagination from 'components/common/CustomPagination';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import { useDispatch } from 'react-redux';
import { getDataTableList } from 'redux/actions/DataExplorer/DataVisualizationActions';
import { type ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';

const DataTable: React.FC<any> = ({
    dataSource,
    selectedTagRows,
    selectedKpiRows,
    page,
    setPage,
    setPageSize,
    pageSize,
    loading,
    startTime,
    endTime,
    tagUuidList,
    tagOriginId,
    timezone,
    frequencyState,
}) => {
    const { t } = useTranslation('translation');

    const [data, setData] = useState<any>([]);
    const dispatch = useDispatch();

    // const dataSources = dataSource?.streams;

    const dataMapper = (): [] => {
        const temp: any = [];

        dataSource?.tableResponse?.forEach((entry: any) => {
            const rowData: any = {
                dateTime: dayjs(entry.timestamp).format(dateFormat.format),
            };

            // Map selectedTagRows
            entry?.values?.forEach((value: any) => {
                const matchingTag = selectedTagRows.find(
                    (tag: any) => tag.uuid === value?.id
                );

                if (matchingTag) {
                    rowData[matchingTag.uuid] = value.value.toFixed(3);
                }
            });

            // Map selectedKpiRowsValues
            entry?.values?.forEach((value: any) => {
                const matchingKpi = selectedKpiRows?.find(
                    (kpi: any) => kpi?.ruleId === value?.id
                );
                if (matchingKpi) {
                    rowData[matchingKpi.ruleId] = value.value.toFixed(3);
                }
            });
            temp.push(rowData);
        });

        return temp;
    };

    useEffect(() => {
        setData(dataMapper());
    }, [dataSource, page, pageSize, selectedTagRows, selectedKpiRows]);

    const staticColumns: ColumnsType<any> = [
        {
            title: (
                <>
                    <div className="userTableWrapper__columnTitle">
                        <div>{t('dataVisualization.dataTable.dateTime')}</div>
                        <div className="sortArrows">
                            <UpArrow
                                onClick={() => {
                                    dispatch(
                                        getDataTableList({
                                            requestStartTime: startTime,
                                            requestEndTime: endTime,
                                            tagUuidList: tagUuidList,
                                            tagOriginId: tagOriginId,
                                            timezone: timezone,
                                            pageSize: pageSize,
                                            pageNumber: page,
                                            sortOrder: sortingOrder.ascending,
                                            frequency: frequencyState ?? 0,
                                        })
                                    );
                                }}
                            />
                            <DownArrow
                                fill="white"
                                onClick={() => {
                                    dispatch(
                                        getDataTableList({
                                            requestStartTime: startTime,
                                            requestEndTime: endTime,
                                            tagUuidList: tagUuidList,
                                            tagOriginId: tagOriginId,
                                            timezone: timezone,
                                            pageSize: pageSize,
                                            pageNumber: page,
                                            sortOrder: sortingOrder.descending,
                                            frequency: frequencyState ?? 0,
                                        })
                                    );
                                }}
                            />
                        </div>
                    </div>
                </>
            ),
            dataIndex: 'dateTime',
            key: 'dateTime',
            width: '20%',
        },
    ];

    const tagDynamicColumns = selectedTagRows?.map((item: any, index: any) => ({
        title: (
            <>
                <Tag color="blue">Tag</Tag> {item?.parentNode?.name} -
                {item?.tagName} ({item?.dataType?.unit})
            </>
        ),
        dataIndex: item?.uuid, // Using a unique dataIndex for each column
        key: item?.uuid,
        width: '30%',
    }));

    const kpiDynamicColumns = selectedKpiRows?.map((item: any, index: any) => ({
        title: (
            <>
                <Tag color="blue">KPI</Tag> {item?.assetName} - {item?.name} (
                {item?.unit?.name})
            </>
        ),
        dataIndex: item?.ruleId, // Using a unique dataIndex for each column
        key: item?.ruleId,
        width: '30%',
    }));

    // Combine static and dynamic columns
    const columns: ColumnsType<any> = [
        ...staticColumns,
        ...tagDynamicColumns,
        ...kpiDynamicColumns,
    ];

    return (
        <>
            <div>
                {loading ? (
                    <div className="view__loader">
                        <Spin />
                    </div>
                ) : (
                    <Table
                        className="datVisualizationDataTable"
                        dataSource={data}
                        columns={columns}
                        pagination={false}
                        showSorterTooltip={false}
                        locale={{
                            emptyText: t('dataVisualization.noResultText'),
                        }}
                        scroll={{ x: 1500, y: 522 }}
                    />
                )}
                {dataSource?.totalRecord > pageSize ? (
                    <div>
                        <CustomPagination
                            customClassName="dataTable__pagination"
                            totalRecords={dataSource?.totalRecord}
                            page={page}
                            setPage={setPage}
                            setPageSize={setPageSize}
                            pageSize={pageSize}
                            defaultPageSize={pageSize}
                            isPositionFixed={false}
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>
        </>
    );
};

export default DataTable;
