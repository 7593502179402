import React from 'react';
import { Col, Row } from 'antd';
import './index.scss';
import {
    type ProgressProps,
    type CreateRoleCountProps,
} from 'types/interfaces/PropsInterfaces';
import { screenName } from 'types/enums';
import { useTranslation } from 'react-i18next';

const RoleProgress: React.FC<CreateRoleCountProps> = ({
    count,
    screen,
    totalCount,
}) => {
    const { t } = useTranslation('translation');

    const ItemProgress: React.FC<ProgressProps> = ({ state }) => {
        return (
            <>
                {state === 'active' ? (
                    <div className="itemProgress--active"></div>
                ) : state === 'completed' ? (
                    <div className="itemProgress--completed"></div>
                ) : (
                    <div className="itemProgress"></div>
                )}
            </>
        );
    };
    const getStepperText = (): string => {
        if (screen === screenName.deviceManagementDevices) {
            if (count === 1) {
                return t('progress.selectBla');
            } else if (count === 2) {
                return t('progress.selectNewDevice');
            } else if (count === 3) {
                return t('progress.addTags');
            }
        }
        if (screen === screenName.userManagementRoles) {
            if (count === 1) {
                return t('progress.definePermissions');
            } else if (count === 2) {
                return t('progress.addUsers');
            } else if (count === 3) {
                return t('progress.addRoleName');
            }
        }
        if (screen === screenName.businessIntegratorMapping) {
            if (count === 1) {
                return t('progress.enterDetails');
            } else if (count === 2) {
                return t('progress.configureMapping');
            }
        }
        return '';
    };
    return (
        <>
            <Row className="progressBar text-center">
                <Col span={6} className="progressBar__stepCount">
                    <span className="fs-14">{t('home.step')}</span>
                    <div className="progressBar__stepCountValue">
                        <span className="fs-14">
                            {count} {`${t('progress.of')} ${totalCount || 3}`}
                        </span>
                    </div>
                </Col>
                <Col span={12} className="progressBar__stepper">
                    <span className="fs-12">{getStepperText()}</span>
                    <div className="progressBar__stepCountValue">
                        <div className="progressBar__progressWrapper">
                            <ItemProgress
                                state={count === 1 ? 'active' : 'completed'}
                            />
                            <ItemProgress
                                state={
                                    count === 2
                                        ? 'active'
                                        : count === 3
                                        ? 'completed'
                                        : ''
                                }
                            />
                            {totalCount === undefined && (
                                <ItemProgress
                                    state={count === 3 ? 'active' : ''}
                                />
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};

export default RoleProgress;
