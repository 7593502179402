export enum ColumnLength {
  name = 20,
  description = 40,
}

export enum TabName {
  connections = "connections",
  mapping = "mapping",
  operations = "operations",
  monitoring = "monitoring",
}

export enum ConnectionsState {
  tableView = "tableView",
  addConnections = "add",
  viewConnections = "view",
  editConnection = "edit",
  copyConnections = "copy",
}

export enum MappingState {
  tableView = "tableView",
  addConnections = "add",
  viewConnections = "view",
  editConnection = "edit",
  copyConnections = "copy",
}

export enum CreateConnectionScreen {
  adapterScreen = 0,
  adapterForm = 1,
}

export enum ConnectionsStatus {
  warning = "WARNING",
  error = "ERROR",
  healthy = "HEALTHY",
}

export enum ConnectionTableColumnSort {
  connectionName = 1,
  adapterType = 2,
  description = 3,
  direction = 4,
  streamingSupported = 5,
  createdOn = 6,
  createdBy = 7,
}

export enum MappingTableColumnSort {
  mappingName = 1,
  status = 2,
  description = 3,
  connectionName = 4,
  fileType = 5,
  direction = 6,
  tableName = 7,
  createdBy = 8,
  updatedBy = 9,
  createdOn = 10,
  updatedOn = 11,
}

export enum MappingFormFields {
  mappingName = "mappingName",
  braboTableName = "braboTableName",
  connectionName = "connectionName",
  sourceUrl = "sourceUrl",
  mappingFields = "mappingFields",
  description = "description",
  label = "label",
}

export enum MonitoringTableColumnSort {
  monitoringType = 6,
  adapterType = 2,
  direction = 3,
  timestamp = 1,
  operationId = 8,
  mappingId = 7,
  createdBy = 5,
  updatedBy = 9,
  createdOn = 10,
  updatedOn = 11,
}

export enum MappingColLength {
  name = 12,
  description = 20,
}

export enum MoreContentEnum {
  view = "View",
  edit = "Edit",
  copy = "Copy",
}

export enum OperationState {
    tableView = "tableView",
    addOperation = "add",
    viewOperation = "view",
    editOperation = "edit",
    copyOperation = "copy",
}
export enum MonitoringState {
  tableView = "table",
  viewMonitoring = "view",
}

export enum OperationFormFields {
    operationName = "operationName",
    mappingId = "mappingId",
    connectionName = "connectionName",
    operationMethod = "operationMethod",
    function = "function",
    description = "description",
    label = "label",
    startTime = "startTime",
    endTime = "endTime",
    frequencyUnit = "frequencyUnit",
    frequencyValue = "frequencyValue",
    dayValue = "dayValue",
    lastAfter = "lastAfter",
    occurrences = "occurrences",
  }

export enum OperationFrequencyUnit {
    minute = 1,
    hour = 2,
    day = 3,
    week = 4,
    month = 5,
}

export enum OperationTableColumnSort {
    operationName = 1,
    description = 2,
    mappingName = 3,
    connectionName = 4,
    direction = 5,
    adapterType = 6,
    status = 7, 
    createdBy = 8,
    updatedBy = 9,
    createdOn = 10,
    updatedOn = 11,
}
