import { all, call, put, takeLatest } from "redux-saga/effects";
import * as types from "redux/types/businessIntegratorTypes";
import {
  connectionStreamingServices,
  createConnectionsServices,
  getAdapterListServices,
  getAllAuthMethodListServices,
  getAllDirectionListServices,
  getAllFileFormatListServices,
  getConnectionByIdServices,
  getConnectionsListServices,
  updateConnectionServices,
} from "redux/services/BusinessIntegratorServices/connectionsServices";

export function* getAdapterListSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const adapterList = yield call(getAdapterListServices);
    yield put({
      type: types.GET_ADAPTER_LIST_SUCCESS,
      response: adapterList,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_ADAPTER_LIST_FAILURE,
    });
  }
}

export function* getAllFileFormatSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const allFileFormatList = yield call(getAllFileFormatListServices);
    yield put({
      type: types.GET_ALL_FILE_FORMATS_SUCCESS,
      response: allFileFormatList,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_ALL_FILE_FORMATS_FAILURE,
    });
  }
}

export function* getAllDirectionListSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const getAllDirectionList = yield call(getAllDirectionListServices);
    yield put({
      type: types.GET_ALL_DIRECTIONS_LIST_SUCCESS,
      response: getAllDirectionList,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_ALL_DIRECTIONS_LIST_FAILURE,
    });
  }
}

export function* getAllAuthMethodListSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const getAllAuthMethodList = yield call(getAllAuthMethodListServices);
    yield put({
      type: types.GET_ALL_AUTH_METHOD_LIST_SUCCESS,
      response: getAllAuthMethodList,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_ALL_AUTH_METHOD_LIST_FAILURE,
    });
  }
}

export function* createConnectionsSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const isConnectionsCreated = yield call(createConnectionsServices, payload);
    yield put({
      type: types.CREATE_CONNECTIONS_SUCCESS,
      response: isConnectionsCreated,
    });
  } catch (error: any) {
    yield put({
      type: types.CREATE_CONNECTIONS_FAILURE,
    });
  }
}

export function* getConnectionsListSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const getConnectionsList = yield call(getConnectionsListServices, payload);
    yield put({
      type: types.GET_CONNECTIONS_LIST_SUCCESS,
      response: getConnectionsList,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_CONNECTIONS_LIST_FAILURE,
    });
  }
}

export function* getConnectionsByIdSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const getConnectionById = yield call(getConnectionByIdServices, payload);
    yield put({
      type: types.GET_CONNECTION_BY_ID_SUCCESS,
      response: getConnectionById,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_CONNECTION_BY_ID_FAILURE,
    });
  }
}

export function* updateConnectionSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const isUpdated = yield call(updateConnectionServices, payload);
    yield put({
      type: types.UPDATE_CONNECTION_SUCCESS,
      response: isUpdated,
    });
  } catch (error: any) {
    yield put({
      type: types.UPDATE_CONNECTION_FAILURE,
      response: error,
    });
  }
}

export function* isConnectionStreamingSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  const paginatedPayload = {
    page: 1,
    pageSize: 50,
  };
  try {
    const isConnectionStreaming = yield call(
      connectionStreamingServices,
      payload
    );
    if (payload.switchStream) {
      yield put({
        type: types.CONNECTION_STREAMING_SUCCESS,
        response: isConnectionStreaming,
      });
    } else {
      yield put({
        type: types.CONNECTION_STREAMING_DEACTIVATE_SUCCESS,
        response: isConnectionStreaming,
      });
    }

    if (isConnectionStreaming) {
      const getConnectionsList = yield call(
        getConnectionsListServices,
        paginatedPayload
      );
      yield put({
        type: types.GET_CONNECTIONS_LIST_SUCCESS,
        response: getConnectionsList,
      });
    }
  } catch (error: any) {
    yield put({
      type: types.CONNECTION_STREAMING_FAILURE,
      response: error,
    });
  }
}

export function* connectionsSaga(): any {
  yield all([takeLatest(types.GET_ADAPTER_LIST, getAdapterListSaga)]);
  yield all([takeLatest(types.GET_ALL_FILE_FORMATS, getAllFileFormatSaga)]);
  yield all([
    takeLatest(types.GET_ALL_DIRECTIONS_LIST, getAllDirectionListSaga),
  ]);
  yield all([
    takeLatest(types.GET_ALL_AUTH_METHOD_LIST, getAllAuthMethodListSaga),
  ]);
  yield all([takeLatest(types.CREATE_CONNECTIONS, createConnectionsSaga)]);
  yield all([takeLatest(types.GET_CONNECTIONS_LIST, getConnectionsListSaga)]);
  yield all([takeLatest(types.GET_CONNECTION_BY_ID, getConnectionsByIdSaga)]);
  yield all([takeLatest(types.UPDATE_CONNECTION, updateConnectionSaga)]);
  yield all([
    takeLatest(types.CONNECTION_STREAMING, isConnectionStreamingSaga),
  ]);
}
