import { useCallback, useEffect } from 'react';
import ReactFlow, {
    Background,
    MiniMap,
    Controls,
    Panel,
    useEdgesState,
    useNodesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import dagre from 'dagre';
import './index.scss';
import CustomNode from './CustomNode';
import { ReactComponent as VerticalView } from 'assets/icons/verticalView.svg';
import { ReactComponent as HorizontalView } from 'assets/icons/horizontalView.svg';
import { ReactComponent as HorizontalActiveView } from 'assets/icons/horizontalActiveIcon.svg';
import { ReactComponent as VerticalActiveView } from 'assets/icons/verticalActive.svg';
import { useSelector, useDispatch } from 'react-redux';
import NewNodeFormDrawer from './NewNodeFormDrawer';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteNew.svg';
import FlexibleModelFormDrawer from './FlexibleModelFormDrawer';
import {
    CLOSE_DELETE_NODEL_MODAL,
    DISPLAY_HORIZONTALLY,
    DISPLAY_VERTICALLY,
} from 'redux/types/assetModellingTypes';
import { deleteNode } from 'redux/actions/AssetModellingActions/assetModellingAction';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

const nodeTypes = { customNode: CustomNode };

const Modelling: React.FC<any> = ({ treeNode, treeEdge }) => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();

    const treeLoader = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.displayTreeLoader
    );
    const openForm = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.openAddNodeDrawer
    );

    const modelDetails = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.modelDetails
    );
    const horizontalView = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.horizontalView
    );
    const verticalView = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.verticalView
    );

    const nodeDetailsById = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.nodeDetailsById
    );
    const openDeleteNodeModal = useSelector(
        (state: any) =>
            state?.assetModelling?.modelOverview?.openDeleteNodeModal
    );
    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));
    const nodeWidth = 250;
    const nodeHeight = 36;

    const getLayoutedElements = (
        nodes: any,
        edges: any,
        direction = 'TB'
    ): any => {
        const isHorizontal = direction === 'LR';
        dagreGraph.setGraph({ rankdir: direction });
        nodes.forEach((node: any) => {
            dagreGraph.setNode(node?.id, {
                width: nodeWidth,
                height: nodeHeight,
            });
        });

        edges.forEach((edge: any) => {
            dagreGraph.setEdge(edge.source, edge.target);
        });

        dagre.layout(dagreGraph);

        nodes.forEach((node: any) => {
            const nodeWithPosition = dagreGraph.node(node.id);
            node.targetPosition = isHorizontal ? 'left' : 'top';
            node.sourcePosition = isHorizontal ? 'right' : 'bottom';
            node.position = {
                x: nodeWithPosition.x - nodeWidth / 2,
                y: nodeWithPosition.y - nodeHeight / 2,
            };
            node.type = 'customNode';
            return node;
        });
        return { nodes, edges };
    };
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
        treeNode,
        treeEdge
    );
    const [nodes, setNodes, onNodesChange] = useNodesState(layoutedNodes);
    const [edges, setEdges, onEdgesChange] = useEdgesState(layoutedEdges);
    const onLayout = useCallback(
        (direction: any) => {
            const { nodes: layoutedNodes, edges: layoutedEdges } =
                getLayoutedElements(nodes, edges, direction);

            setNodes([...layoutedNodes]);
            setEdges([...layoutedEdges]);
        },
        [nodes, edges]
    );
    useEffect(() => {
        const { nodes, edges } = getLayoutedElements(treeNode, treeEdge);

        setNodes([...nodes]);
        setEdges([...edges]);
    }, [treeNode, treeEdge]);
    const editNodeDetails = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.editNodeDetails
    );
    return (
        <div style={{ height: 'calc(100vh - 245px)', width: '100%' }}>
            {treeLoader ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                <>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        nodeTypes={nodeTypes}
                        fitView
                    >
                        <MiniMap />
                        <Controls />
                        <Background />
                        <Panel position="top-left">
                            {verticalView ? (
                                <VerticalActiveView className="verticalActiveOrientation" />
                            ) : (
                                <VerticalView
                                    className="verticalOrientation"
                                    onClick={() => {
                                        onLayout('TB');
                                        dispatch({ type: DISPLAY_VERTICALLY });
                                    }}
                                />
                            )}

                            {horizontalView ? (
                                <HorizontalActiveView className="horizontalActiveOrientation" />
                            ) : (
                                <HorizontalView
                                    className="horizontalOrientation"
                                    onClick={() => {
                                        onLayout('LR');
                                        dispatch({
                                            type: DISPLAY_HORIZONTALLY,
                                        });
                                    }}
                                />
                            )}
                        </Panel>
                    </ReactFlow>
                </>
            )}
            {modelDetails?.modelType === 'ISA88_95' &&
                openForm &&
                nodeDetailsById && (
                    <NewNodeFormDrawer nodeDetailsById={nodeDetailsById} />
                )}
            {modelDetails?.modelType === 'FLEXIBLE' &&
                openForm &&
                Object.keys(nodeDetailsById)?.length && (
                    <FlexibleModelFormDrawer
                        nodeDetailsById={nodeDetailsById}
                    />
                )}
            {modelDetails?.modelType === 'FLEXIBLE' &&
                editNodeDetails &&
                Object.keys(nodeDetailsById)?.length && (
                    <FlexibleModelFormDrawer
                        nodeDetailsById={nodeDetailsById}
                    />
                )}
            {modelDetails?.modelType === 'ISA88_95' &&
                editNodeDetails &&
                Object.keys(nodeDetailsById)?.length && (
                    <NewNodeFormDrawer nodeDetailsById={nodeDetailsById} />
                )}
            {openDeleteNodeModal && (
                <ConfirmationModal
                    icon={<DeleteIcon />}
                    open={openDeleteNodeModal}
                    onOk={() => {
                        dispatch(
                            deleteNode({
                                nodeId: nodeDetailsById?.node?.id,
                                modelId: modelDetails?.id,
                            })
                        );
                        dispatch({ type: CLOSE_DELETE_NODEL_MODAL });
                    }}
                    onCancel={() => {
                        dispatch({ type: CLOSE_DELETE_NODEL_MODAL });
                    }}
                    text={t('assetModeller.modelOverview.deleteNodeModalText')}
                    customClassName="confirmationModal modelDeletion"
                />
            )}
        </div>
    );
};

export default Modelling;
