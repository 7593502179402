import {
    Button,
    Checkbox,
    DatePicker,
    Select,
    Switch,
    Tooltip,
    notification,
} from 'antd';
import './index.scss';
import { ReactComponent as GreenTick } from 'assets/icons/tick-circle-green.svg';
import { ReactComponent as RedTick } from 'assets/icons/warning-red.svg';

import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { type RangePickerProps } from 'antd/es/date-picker';
import '../../../../styles/_colorVariable.scss';
import {
    getKpiDetail,
    setValidate,
} from 'redux/actions/KpisActions/kpiImplementationActions';
import { dateFormat } from 'types/enums';
import { parseJwt } from 'utils/jwtTokenFunction';
import { assetStatus } from 'types/enums/kpiEnum';

const KpiViewGridPage: React.FC<any> = ({
    data,
    assetList,
    setAssetList,
    kpiId,
    setSelectValidate,
    reloadKey,
    setReloadKey,
}) => {
    const key = data?.id;
    const dispatch = useDispatch();

    const kpiCalculationCycleList = useSelector(
        (state: any) => state.kpi?.kpiImplementation?.kpiCalculationCycleList
    );
    const handleValidate = (e: any): any => {
        if (data?.calculationCycleId !== 0 && data?.validFrom !== '') {
            dispatch(
                setValidate({
                    kpiId: kpiId,
                    validateAssetReqList: [
                        {
                            nodeId: data.id,
                            calculationCycleId: data?.calculationCycleId,
                            validFrom: data?.validFrom?.$d?.valueOf() / 1000,
                            isActive: data?.isActive,
                            requestedBy: parseJwt()?.username,
                        },
                    ],
                })
            );
            dispatch(getKpiDetail(kpiId));
        } else {
            notification.error({
                message: 'Fields are not selected',
                duration: 3,
                description:
                    'Calculation Cycle & Valid From are not selected, Kindly select these field and try again.',
                placement: 'top',
                style: {
                    backgroundColor: '#fff1f0',
                    border: '1px solid #FFCCC7',
                },
            });
        }
        setSelectValidate(data?.id);
        setReloadKey(reloadKey + 1);
    };

    const handleCopyToSelected = (rowIndex: any): any => {
        const valueObj = assetList?.filter((item: any) => item?.id === key);
        const updatedSelectedRows = assetList?.map((item: any) => {
            if (item?.checked) {
                return {
                    ...item,
                    calculationCycleId: valueObj[0]?.calculationCycleId,
                    validFrom: valueObj[0]?.validFrom,
                    isActive: valueObj[0]?.isActive,
                };
            } else {
                return {
                    ...item,
                };
            }
        });
        setAssetList(updatedSelectedRows);
    };
    const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
        // Can not select days before today
        return current && dayjs(current).isBefore(dayjs(), 'day');
    };
    const handleCheckboxChange = (checked: boolean, id: any): any => {
        const checkSelectArray: any[] = [];
        assetList?.map((item: any) => {
            if (item?.id === id) {
                checkSelectArray.push({
                    ...item,
                    checked: checked,
                });
            } else {
                checkSelectArray.push({
                    ...item,
                });
            }
        });
        setAssetList(checkSelectArray);
    };

    const handleSelectCycleChange = (value: number): any => {
        const checkSelectArray: any[] = [];
        assetList?.map((item: any) => {
            if (item?.id === key) {
                checkSelectArray.push({
                    ...item,
                    calculationCycleId: value,
                });
            } else {
                checkSelectArray.push({
                    ...item,
                });
            }
        });
        setAssetList(checkSelectArray);
    };
    const handleToggleChange = (checked: boolean): any => {
        const checkSelectArray: any[] = [];
        assetList?.map((item: any) => {
            if (item?.id === key) {
                checkSelectArray.push({
                    ...item,
                    isActive: checked,
                });
            } else {
                checkSelectArray.push({
                    ...item,
                });
            }
        });
        setAssetList(checkSelectArray);
    };

    const handleDateChange = (date: any, dateString: any): any => {
        const checkSelectArray: any[] = [];
        assetList?.map((item: any) => {
            if (item?.id === key) {
                checkSelectArray.push({
                    ...item,
                    validFrom:
                        dateString !== ''
                            ? dayjs(dateString, dateFormat.formatWithoutTime)
                            : '',
                });
            } else {
                checkSelectArray.push({
                    ...item,
                });
            }
        });
        setAssetList(checkSelectArray);
    };
    const disabledHandler = (data: any): boolean => {
        let result = true;
        const defaultTrue = true;
        const attributeObjectLength: number = Object.keys(
            data?.attributeMap
        ).length;
        const kpiObjectLength: number = Object.keys(data?.kpiMap).length;
        const atrributeHasMap: boolean = Object.values(data?.attributeMap).some(
            (value) => value === false
        );
        const KpiHasMap: boolean = Object.values(data?.kpiMap).some(
            (value) => value === false
        );

        if (attributeObjectLength) {
            if (atrributeHasMap) {
                result = false;
            }
        }
        if (kpiObjectLength && result === defaultTrue) {
            if (KpiHasMap) {
                result = false;
            }
        }
        if (data.status === assetStatus.notReady) {
            result = false;
        }
        return !result;
    };
    return (
        <div
            className="kpiGridViewWrapper"
            style={{
                backgroundColor: data?.isValidated ? 'var(--green-1)' : '',
            }}
        >
            <div className="kpiGridViewWrapper__cardGridContainer">
                <div className="kpiGridViewWrapper__cardGrid">
                    {data.status === assetStatus.validated ||
                    data.status === assetStatus.pending ? (
                        <span className="kpiGridViewWrapper__checkbox">
                            <Checkbox
                                key={key}
                                checked={data?.checked}
                                onChange={(e) => {
                                    handleCheckboxChange(
                                        e.target.checked,
                                        data.id
                                    );
                                }}
                            />
                        </span>
                    ) : null}
                    <Tooltip title={data?.name}>
                        <span className="kpiGridViewWrapper__heading">
                            {data?.name}
                        </span>
                    </Tooltip>
                    <span
                        className="kpiGridViewWrapper__state"
                        style={{
                            backgroundColor: data.isValidated
                                ? 'var(--green-2)'
                                : data.status === assetStatus.notReady
                                ? 'var(--disable)'
                                : '',
                            color: data.isValidated
                                ? 'var(--green-3)'
                                : data.status === assetStatus.notReady
                                ? 'black'
                                : '',
                        }}
                    >
                        {data?.isValidated
                            ? 'Validated'
                            : data.status === assetStatus.pending
                            ? 'Pending'
                            : 'Not Ready'}
                    </span>
                </div>
                <div className="kpiGridViewWrapper__kpi">
                    <span className="kpiGridViewWrapper__headingKpi">
                        KPIs :
                    </span>
                    {Object.entries(data.kpiMap).map(([key, value]: any) => (
                        <div
                            key={key}
                            className="kpiGridViewWrapper__cardGridKpi"
                        >
                            <span className="kpiGridViewWrapper__icon">
                                {value ? <GreenTick /> : <RedTick />} {key}
                            </span>
                            {/* To DO: will use this later on */}
                            {/* <span className="kpiGridViewWrapper__viewMore">
                         View More
                     </span> */}
                        </div>
                    ))}
                </div>
                <div className="kpiGridViewWrapper__attribute">
                    <span className="kpiGridViewWrapper__headingAttribute">
                        Attribute :
                    </span>
                    {Object.entries(data.attributeMap).map(
                        ([key, value]: any) => (
                            <div
                                key={key}
                                className="kpiGridViewWrapper__cardGridKpi"
                            >
                                <span className="kpiGridViewWrapper__icon">
                                    {value ? <GreenTick /> : <RedTick />} {key}
                                </span>
                            </div>
                        )
                    )}
                    {/* To DO: will use this later */}

                    {/* <span className="kpiGridViewWrapper__viewMore">
                        View More
                    </span> */}
                </div>
            </div>
            <div className="kpiGridViewWrapper__dropdownWrapper">
                <div className="kpiGridViewWrapper__dropdown">
                    <Select
                        key={key}
                        style={{ width: '205px' }}
                        disabled={data.status === assetStatus.notReady}
                        value={
                            data?.calculationCycleId > 0
                                ? data?.calculationCycleId
                                : 'Select Calculation Cycle'
                        }
                        onChange={(value) => {
                            handleSelectCycleChange(value);
                        }}
                        options={kpiCalculationCycleList?.map(
                            (item: { id: string; name: string }) => ({
                                value: item?.id,
                                label: item?.name,
                                key: item?.id,
                            })
                        )}
                    ></Select>
                </div>
                <div className="kpiGridViewWrapper__datePicker">
                    <DatePicker
                        key={key}
                        defaultValue={data?.validFrom ? data?.validFrom : ''}
                        format={dateFormat?.formatWithoutTime}
                        value={data?.validFrom}
                        disabled={data.status === assetStatus.notReady}
                        style={{ width: '195px' }}
                        placeholder="Pick Date"
                        onChange={(date, dateString) =>
                            handleDateChange(date, dateString)
                        }
                        disabledDate={disabledDate}
                    />
                </div>
                <div className={'kpiGridViewWrapper__toggle'}>
                    <div
                        className={
                            data?.isActive
                                ? 'kpiGridViewWrapper__inactiveToggle'
                                : 'kpiGridViewWrapper__activeToggle'
                        }
                    >
                        Inactive{' '}
                    </div>
                    <Switch
                        key={key}
                        checked={data?.isActive}
                        onChange={handleToggleChange}
                        size="small"
                        disabled={data.status === assetStatus.notReady}
                    />
                    <div
                        className={
                            data?.isActive
                                ? 'kpiGridViewWrapper__activeToggle'
                                : 'kpiGridViewWrapper__inactiveToggle'
                        }
                    >
                        Active
                    </div>
                </div>
            </div>
            <div className="kpiGridViewWrapper__copyWrapper">
                <div className="kpiGridViewWrapper__copyToClipBoard">
                    <Button
                        key={key}
                        onClick={() => handleCopyToSelected(key)}
                        disabled={data.status === assetStatus.notReady}
                    >
                        Copy to Selected
                    </Button>
                </div>

                <div className="kpiGridViewWrapper__validateButton">
                    <Button
                        className="viewPageKpiImplementationWrapper__validateSelectedButton"
                        type="primary"
                        disabled={disabledHandler(data)}
                        onClick={(e: any) => handleValidate(e)}
                    >
                        Validate
                    </Button>
                </div>
            </div>
        </div>
    );
};
export default KpiViewGridPage;
