import * as types from "redux/types/businessIntegratorTypes";

export const setOperationState = (payload: string): any => ({
  type: types.SET_OPERATION_STATE,
  payload: payload,
})

export const getOperationDetailsById = (payload: any): any => ({
  type: types.GET_OPERATION_DETAILS_BY_ID,
  payload: payload,
});

export const deleteOperation = (payload: any): any => ({
  type: types.DELETE_OPERATION,
  payload: payload,
})

export const activateDeactivateOperation=(payload:any):any=>({
  type: types.ACTIVATE_DEACTIVATE_OPERATION,
  payload: payload
})


export const createOperation = (payload: any): any => ({
    type: types.CREATE_OPERATION,
    payload: payload,
});

export const updateOperation = (payload: any): any => ({
  type: types.UPDATE_OPERATION,
  payload: payload,
});
export const getOperationList = (payload: any): any => ({
  type: types.GET_OPERATION_LIST,
  payload: payload,
});

export const getAllOperation = (payload: any): any => ({
  type: types.GET_ALL_OPERATION,
  payload: payload,
});

export const getDaysList = (): any => ({
  type: types.GET_DAYS_LIST,
});

export const testConnectionValidation = (payload: any): any => ({
  type: types.CONNECTION_VALIDATE,
  payload: payload,
});