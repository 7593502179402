import { baseUrlSetter } from "utils/commonFunction";
import Api from "..";
import { message } from "antd";

export const getAdapterListServices = async (): Promise<any> => {
  baseUrlSetter("deviceManagement");
  try {
    const response = await Promise.resolve(
      Api.get(`/itIntegration/getAllAdapters`)
    );
    return response;
  } catch (error: any) {
    Api.interceptors.response.use(error);
  }
};

export const getAllFileFormatListServices = async (): Promise<any> => {
  baseUrlSetter("deviceManagement");
  try {
    const response = await Promise.resolve(
      Api.get(`/itIntegration/getAllFileFormats`)
    );
    return response;
  } catch (error: any) {
    Api.interceptors.response.use(error);
  }
};

export const getAllDirectionListServices = async (): Promise<any> => {
  baseUrlSetter("deviceManagement");
  try {
    const response = await Promise.resolve(
      Api.get(`/itIntegration/getAllDirections`)
    );
    return response;
  } catch (error: any) {
    Api.interceptors.response.use(error);
  }
};

export const getAllAuthMethodListServices = async (): Promise<any> => {
  baseUrlSetter("deviceManagement");
  try {
    const response = await Promise.resolve(
      Api.get(`/itIntegration/getAllAuthMethods`)
    );
    return response;
  } catch (error: any) {
    Api.interceptors.response.use(error);
  }
};

export const createConnectionsServices = async (payload: any): Promise<any> => {
  baseUrlSetter("deviceManagement");
  const params = payload;
  try {
    const response = Api.post(`/itIntegration/addConnection`, params);
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const getConnectionsListServices = async (
  payload: any
): Promise<any> => {
  baseUrlSetter("deviceManagement");
  const params = payload;
  try {
    const response = Api.get(`/itIntegration/getConnectionsList`, {
      params: params,
    });
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const getConnectionByIdServices = async (payload: any): Promise<any> => {
  baseUrlSetter("deviceManagement");
  const params = payload;
  try {
    const response = Api.get(`/itIntegration/getConnectionById/${params}`);
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const updateConnectionServices = async (payload: any): Promise<any> => {
  baseUrlSetter("deviceManagement");
  const params = payload;
  try {
    const response = Api.put(
      `/itIntegration/updateConnection?activateConnection=${params.activateConnection}`,
      params
    );
    return await Promise.resolve(response);
  } catch (error: any) {
    error.response.data.code !== 100 &&
      message.error(error?.response?.data?.message);
    throw error.response;
  }
};

export const connectionStreamingServices = async (
  payload: any
): Promise<any> => {
  baseUrlSetter("deviceManagement");
  const params = payload;
  try {
    const response = Api.put(
      `itIntegration/switchConnectionStreaming?connectionId=${params.connectionId}&switchStream=${params.switchStream}`,
      params
    );
    return await Promise.resolve(response);
  } catch (error: any) {
    error.response.data.code !== 100 &&
      message.error(error?.response?.data?.message);
    throw error.response;
  }
};
