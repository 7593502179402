// Attribute

export const SHOW_LOADER_SUCCESS = "SHOW_LOADER_SUCCESS";
export const SET_ATTRIBUTE_STATE = "SET_ATTRIBUTE_STATE";
export const GET_ATTRIBUTE_LIST = "GET_ATTRIBUTE_LIST";
export const GET_ATTRIBUTE_LIST_SUCCESS = "GET_ATTRIBUTE_LIST_SUCCESS";
export const GET_ATTRIBUTE_LIST_FAILURE = "GET_ATTRIBUTE_LIST_FAILURE";
export const GET_ATTRIBUTE_DETAILS = "GET_ATTRIBUTE_DETAILS";
export const GET_ATTRIBUTE_DETAIL_SUCCESS = "GET_ATTRIBUTE_DETAIL_SUCCESS";
export const GET_ATTRIBUTE_DETAIL_FAILURE = "GET_ATTRIBUTE_DETAIL_FAILURE";
export const GET_INDUSTRY_LIST = "GET_INDUSTRY_LIST";
export const GET_INDUSTRY_LIST_SUCCESS = "GET_INDUSTRY_LIST_SUCCESS";
export const GET_CATEGORY_LIST = "GET_CATEGORY_LIST";
export const GET_CATEGORY_LIST_SUCCESS = "GET_CATEGORY_LIST_SUCCESS";
export const GET_VALUE_TYPE_LIST = "GET_VALUE_TYPE_LIST";
export const GET_VALUE_TYPE_LIST_SUCCESS = "GET_VALUE_TYPE_LIST_SUCCESS";
export const GET_PROPERTY_LIST = "GET_PROPERTY_LIST";
export const GET_PROPERTY_LIST_SUCCESS = "GET_PROPERTY_LIST_SUCCESS";
export const GET_DATA_REFERENCE_LIST = "GET_DATA_REFERENCE_LIST";
export const GET_DATA_REFERENCE_LIST_SUCCESS =
  "GET_DATA_REFERENCE_LIST_SUCCESS";
export const GET_UOM_LIST = "GET_UOM_LIST";
export const GET_UOM_LIST_SUCCESS = "GET_UOM_LIST_SUCCESS";
export const GET_UOM_CLASS_LIST = "GET_UOM_CLASS_LIST";
export const GET_UOM_CLASS_LIST_SUCCESS = "GET_UOM_CLASS_LIST_SUCCESS";
export const GET_UOM_METRIC_LIST = "GET_UOM_METRIC_LIST";
export const GET_UOM_METRIC_LIST_SUCCESS = "GET_UOM_METRIC_LIST_SUCCESS";
export const CREATE_UOM_CLASS = "CREATE_UOM_CLASS";
export const CREATE_UOM_CLASS_SUCCESS = "CREATE_UOM_CLASS_SUCCESS";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_INDUSTRY = "CREATE_INDUSTRY";
export const CREATE_INDUSTRY_SUCCESS = "CREATE_INDUSTRY_SUCCESS";
export const CREATE_UOM = "CREATE_UOM";
export const CREATE_UOM_SUCCESS = "CREATE_UOM_SUCCESS";
export const CREATE_UOM_FAILURE = "CREATE_UOM_FAILURE";
export const CREATE_ATTRIBUTE = "CREATE_ATTRIBUTE";
export const CREATE_ATTRIBUTE_SUCCESS = "CREATE_ATTRIBUTE_SUCCESS";
export const UPDATE_ATTRIBUTE = "UPDATE_ATTRIBUTE";
export const UPDATE_ATTRIBUTE_SUCCESS = "UPDATE_ATTRIBUTE_SUCCESS";
export const STATUS_UPDATE_ATTRIBUTE = "STATUS_UPDATE_ATTRIBUTE";
export const STATUS_UPDATE_ATTRIBUTE_SUCCESS =
  "STATUS_UPDATE_ATTRIBUTE_SUCCESS";
export const ATTRUBUTE_FIELDS_VALUES = "ATTRUBUTE_FIELDS_VALUES";
export const GET_UOM_TABLE_LIST = "GET_UOM_TABLE_LIST";
export const GET_UOM_TABLE_LIST_SUCCESS = "GET_UOM_TABLE_LIST_SUCCESS";
export const STATUS_UPDATE_UOM = "STATUS_UPDATE_UOM";
export const STATUS_UPDATE_UOM_SUCCESS = "STATUS_UPDATE_UOM_SUCCESS";
export const GET_UOM_DETAILS_BY_ID = "GET_UOM_DETAILS_BY_ID";
export const GET_UOM_DETAILS_BY_ID_SUCCESS = "GET_UOM_DETAILS_BY_ID_SUCCESS";
export const GET_UOM_DETAILS_BY_ID_FAILURE = "GET_UOM_DETAILS_BY_ID_FAILURE";
export const EDIT_UOM = "EDIT_UOM";
export const EDIT_UOM_SUCCESS = "EDIT_UOM_SUCCESS";
export const CREATE_UOM_CLASS_THROUGH_UOM = "CREATE_UOM_CLASS_THROUGH_UOM";

// Table
export const SET_TABLE_STATE = "SET_TABLE_STATE";
export const GET_TABLE_LIST = "GET_TABLE_LIST";
export const GET_TABLE_LIST_SUCCESS = "GET_TABLE_LIST_SUCCESS";
export const GET_TABLE_LIST_FAILURE = "GET_TABLE_LIST_FAILURE";
export const CREATE_TABLE = "CREATE_TABLE";
export const CREATE_TABLE_SUCCESS = "CREATE_TABLE_SUCCESS";
export const CHANGE_SUCCESS_STATUS_OF_TABLE = "CHANGE_SUCCESS_STATUS_OF_TABLE";
export const GET_TABLE_DETAILS = "GET_TABLE_DETAILS";
export const GET_TABLE_DETAIL_SUCCESS = "GET_TABLE_DETAIL_SUCCESS";
export const GET_TABLE_DETAIL_FAILURE = "GET_TABLE_DETAIL_FAILURE";
export const UPDATE_TABLE_DATA_FOR_LOCAL = `UPDATE_TABLE_DATA_FOR_LOCAL`;
export const STATUS_UPDATE_TABLE = "STATUS_UPDATE_TABLE";
export const STATUS_UPDATE_TABLE_SUCCESS = "STATUS_UPDATE_TABLE_SUCCESS";
export const UPDATE_TABLE = "UPDATE_TABLE";
export const UPDATE_TABLE_SUCCESS = "UPDATE_TABLE_SUCCESS";
export const DELETE_TABLE_ROW = "DELETE_TABLE_ROW ";
export const DELETE_TABLE_ROW_SUCCESS = "DELETE_TABLE_ROW_SUCCESS";
export const DELETE_TABLE_ROW_FAILURE = "DELETE_TABLE_ROW_FAILURE";

// TimeCapsule
export const SET_TIME_CAPSULE_STATE = "SET_TIME_CAPSULE_STATE";
export const GET_TIME_CAPSULE_LIST = "GET_TIME_CAPSULE_LIST";
export const GET_TIME_CAPSULE_LIST_SUCCESS = "GET_TIME_CAPSULE_LIST_SUCCESS";
export const GET_TIME_CAPSULE_LIST_FAILURE = "GET_TIME_CAPSULE_LIST_FAILURE";
export const CREATE_TIME_CAPSULE = "CREATE_TIME_CAPSULE";
export const CREATE_TIME_CAPSULE_SUCCESS = "CREATE_TIME_CAPSULE_SUCCESS";
export const CHANGE_SUCCESS_STATUS_OF_TIMECAPSULE =
  "CHANGE_SUCCESS_STATUS_OF_TIMECAPSULE";
export const GET_TIME_CAPSULE_DETAILS = "GET_TIME_CAPSULE_DETAILS";
export const GET_TIME_CAPSULE_DETAIL_SUCCESS =
  "GET_TIME_CAPSULE_DETAIL_SUCCESS";
export const GET_TIME_CAPSULE_DETAIL_FAILURE =
  "GET_TIME_CAPSULE_DETAIL_FAILURE";
export const UPDATE_TIMECAPSULE_DATA_FOR_LOCAL = `UPDATE_TIMECAPSULE_DATA_FOR_LOCAL`;
export const STATUS_UPDATE_TIME_CAPSULE = "STATUS_UPDATE_TIME_CAPSULE";
export const STATUS_UPDATE_TIME_CAPSULE_SUCCESS =
  "STATUS_UPDATE_TIME_CAPSULE_SUCCESS";
export const UPDATE_TIME_CAPSULE = "UPDATE_TIME_CAPSULE";
export const UPDATE_TIME_CAPSULE_SUCCESS = "UPDATE_TIME_CAPSULE_SUCCESS";
export const ASSIGN_TIME_CAPSULE = "ASSIGN_TIME_CAPSULE";
export const ASSIGN_TIME_CAPSULE_SUCCESS = "ASSIGN_TIME_CAPSULE_SUCCESS";

// KPIs
export const SET_KPI_STATE = "SET_KPI_STATE";
export const KPI_FIELDS_VALUES = "KPI_FIELDS_VALUES";
export const GET_KPI_TYPE_LIST = "GET_KPI_TYPE_LIST";
export const GET_KPI_TYPE_LIST_SUCCESS = "GET_KPI_TYPE_LIST_SUCCESS";
export const GET_TARGET_TYPE_LIST = "GET_TARGET_TYPE_LIST";
export const GET_TARGET_TYPE_LIST_SUCCESS = "GET_TARGET_TYPE_LIST_SUCCESS";
export const GET_AGGREGATION_TYPE_LIST = "GET_AGGREGATION_TYPE_LIST";
export const GET_AGGREGATION_TYPE_LIST_SUCCESS =
  "GET_AGGREGATION_TYPE_LIST_SUCCESS";
export const GET_NODE_LEVEL_LIST = "GET_NODE_LEVEL_LIST";
export const GET_NODE_LEVEL_LIST_SUCCESS = "GET_NODE_LEVEL_LIST_SUCCESS";
export const CREATE_KPI = "CREATE_KPI";
export const CREATE_KPI_SUCCESS = "CREATE_KPI_SUCCESS";
export const GET_KPI_LIST = "GET_KPI_LIST";
export const GET_KPI_LIST_SUCCESS = "GET_KPI_LIST_SUCCESS";
export const GET_KPI_LIST_FAILURE = "GET_KPI_LIST_FAILURE";
export const GET_KPI_DETAILS = "GET_KPI_DETAILS";
export const GET_KPI_DETAIL_SUCCESS = "GET_KPI_DETAIL_SUCCESS";
export const GET_KPI_DETAIL_FAILURE = "GET_KPI_DETAIL_FAILURE";
export const STATUS_UPDATE_KPI = "STATUS_UPDATE_KPI";
export const STATUS_UPDATE_KPI_SUCCESS = "STATUS_UPDATE_KPI_SUCCESS";
export const UPDATE_KPI = "UPDATE_KPI";
export const UPDATE_KPI_SUCCESS = "UPDATE_KPI_SUCCESS";
export const SET_UOM_STATE = "SET_UOM_STATE";
