import {
  ACTIVATE_DEACTIVATE_PROVIDER_CONFIG,
  CREATE_PROVIDER_CONFIG,
  GET_ALL_CHANNEL,
  GET_ALL_PROVIDER,
  GET_ALL_PROVIDER_CONFIG,
  GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID,
  GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID,
  SET_NOTIFICATION_STATE,
  TEST_PROVIDER_CONFIG,
  UPDATE_PROVIDER_CONFIG,
} from "redux/types/notificationConfigurationTypes";

export const notificationStateAction = (payload: any): any => ({
  type: SET_NOTIFICATION_STATE,
  payload: payload,
});

export const getAllChannel = (payload?: any): any => ({
  type: GET_ALL_CHANNEL,
  payload: payload,
});

export const getProviderByChannelId = (payload?: any): any => ({
  type: GET_ALL_PROVIDER,
  payload: payload,
});

export const getProviderConfigCreateFeildsById = (payload?: any): any => ({
  type: GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID,
  payload: payload,
});

export const getProviderConfigUpdateFeildsById = (payload?: any): any => ({
  type: GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID,
  payload: payload,
});

export const createProviderConfiguration = (payload: any): any => ({
  type: CREATE_PROVIDER_CONFIG,
  payload: payload,
});

export const updateProviderConfiguration = (payload: any): any => ({
  type: UPDATE_PROVIDER_CONFIG,
  payload: payload,
});
export const testProviderConfiguration = (payload: any): any => ({
  type: TEST_PROVIDER_CONFIG,
  payload: payload,
});

export const getAllProviderConfig = (payload: any): any => ({
  type: GET_ALL_PROVIDER_CONFIG ,
  payload: payload, 
});

export const activateDeactivateConfig = (payload: any): any => ({
  type: ACTIVATE_DEACTIVATE_PROVIDER_CONFIG ,
  payload: payload, 
});