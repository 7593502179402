export enum operatorTypeEnum {
    Assignment = 'Assignment',
    Parenthesis = 'Parenthesis',
    Arithmetic = 'Arithmetic',
    Comparison = 'Comparison',
    Logical = 'Logical',
    Comma='Comma'
}
export enum formulaCreatorScreenType{
    KPI = 'KPI',
    ATRRIBUTE='ATRRIBUTE'
}
export enum frequencyIntervals{
    'Real Time' = 1,
    '1 Minute' = 60000,
    '5 Minutes' = 300000,
    '10 Minutes' = 600000,
    '30 Minutes' = 1800000,
    '1 hour' = 3600000
    
}
export enum ruleCategoryEnum{
    TEXT= 0,
    TAG= 1,
    ATTRIBUTE= 2,
    KPI = 3,
    RULE= 4,
    CONSTANT= 5
}
export enum ruleCRUDEnum{
    CREATE = 'CREATE',
    UPDATE = 'UPDATE',
    DELETE ='DELETE'
}
export enum FormulaCreatorOperandType{
    Functions = 'Functions',
    Tags = 'Tags',
    Attributes = 'Attributes',
    KPIs='KPIs'
    
}
