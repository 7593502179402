import { baseUrlSetter } from 'utils/commonFunction';
import Api from '..';
import { message } from 'antd';

export const getOperationDetailsByIdService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('deviceManagement');
    try {
        const response = Api.get(`operation/getOperationById`, {
            params: payload,
        });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const deleteOperationService = async (payload: any): Promise<any> => {
    baseUrlSetter("deviceManagement");
    try {
        const response = Api.put(
            `operation/delete`,
            payload
        );
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const activateDeactivateOperationServices = async (
    payload: any
): Promise<any> => {
    baseUrlSetter("deviceManagement");
    try {
        const response = Api.put(
            `/operation/change/status`,
            payload
        );
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const createOperationServices = async (payload: any): Promise<any> => {
    baseUrlSetter('deviceManagement');
    const params = payload;
    try {
        const response = Api.post(`/operation/add`, params);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const updateOperationService = async (payload: any): Promise<any> => {
    baseUrlSetter('deviceManagement');
    try {
        const response = Api.put(`/operation/update`, payload);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};
export const getOperationListServices = async (payload: any): Promise<any> => {
    baseUrlSetter('deviceManagement');
    const params = payload;
    try {
        const response = Api.get(`/operation/list/paginated`, {
            params: params,
        });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const getDaysListServices = async (): Promise<any> => {
    baseUrlSetter('deviceManagement');
    try {
        const response = Api.get(`/week/list`);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const connectionValidateService = async (
  payload: any
): Promise<any> => {
  baseUrlSetter('deviceManagement');
  try {
      const response = Api.get(`/itIntegration/validateConnection`, {
          params: payload,
      });
      return await Promise.resolve(response);
  } catch (error: any) {
      message.error(error?.response?.data?.message);
      throw new Error(error);
  }
};