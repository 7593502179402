// import CustomerLogo from 'assets/images/customerLogo.png';
import NocilLogo from 'assets/icons/nocilLogo.svg';
import diageoLogo from 'assets/icons/diageoLogo.svg';
import braboLogoNew from 'assets/icons/braboLogoNew.svg';
import uplLogo from 'assets/icons/uplLogo.svg';

export const serviceNameLogoData = {
    nocil: NocilLogo,
    dev: braboLogoNew,
    diageo: diageoLogo,
    upl: uplLogo,
};
