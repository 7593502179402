import { Popover } from 'antd';
import Table, { type ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { type DataType } from 'types/interfaces/PropsInterfaces';
import { MoreOutlined } from '@ant-design/icons';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import './index.scss';
import NodeMoreContent from './MoreContent';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { DATE_ONLY_FORMAT, PAGE } from 'utils/constants';
import { getNodeTypeId } from 'redux/actions/AssetModellingActions/assetModellingAction';
import {
    assetModelling,
    deviceManagement,
    nodeTypeSortColumns,
    sortingOrder,
} from 'types/enums';
import { useTranslation } from 'react-i18next';

const NodeTable: React.FC<any> = ({
    setOpenNodeInfo,
    setEditNode,
    setOpenDrawer,
    payload,
    search,
    setSearchState,
    setPage,
    setPageSize,
    setSortColumn,
    setSortOrder,
}) => {
    const { t } = useTranslation('translation');
    const [popoverVisible, setPopoverVisible] = useState<
        Record<string, boolean>
    >({});
    const handlePopoverVisibleChange = (
        visible: boolean,
        record: any
    ): void => {
        setPopoverVisible((prevState) => ({
            ...prevState,
            [record.key]: visible,
        }));
    };

    const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
    const [tableData, setTableData] = useState<any>([]);
    const dispatch = useDispatch();
    const nodeTypeDetails = useSelector(
        (state: any) => state.assetModelling?.nodeType?.nodeTypeListDetails
    );

    const tableDataMapper = (): [] => {
        const temp: any = [];
        nodeTypeDetails?.records?.map((item: any, index: number) => {
            temp.push({
                ...item,
                key: item.id,
            });
        });
        return temp;
    };
    useEffect(() => {
        setTableData(tableDataMapper());
    }, [nodeTypeDetails]);

    const rowSelection: any = {
        onSelect: (record: any, selected: boolean, selectedRows: any) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );

            if (selected) {
                setSelectedRowIds([...selectedRowIds, record?.id]);
            } else {
                setSelectedRowIds(
                    selectedRowIds?.filter((id: number) => id !== record?.id)
                );
            }
        },
        onSelectAll: (selected: boolean, selectedRows: DataType[]) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );

            if (selected) {
                const newSelectedIds = selectedRows?.map(
                    (record) => record?.id
                );
                setSelectedRowIds(newSelectedIds);
            } else {
                setSelectedRowIds([]);
            }
        },
    };
    const [showPopover, setShowPopover] = useState(false);
    useEffect(() => {
        setShowPopover(selectedRowIds.length <= 1);
    }, [selectedRowIds]);
    const handlePopoverItemClick = (): void => {
        setPopoverVisible({ actions: false });
    };

    const [isPopoverVisibles, setIsPopoverVisibles] = useState<
        Record<string, boolean>
    >({});
    const handlePopoverVisibleChanges = (visible: any): any => {
        setIsPopoverVisibles(visible);
    };
    const columns: ColumnsType<any> = [
        {
            title: (
                <div>
                    <MoreOutlined />
                </div>
            ),
            key: 'more',
            width: 56,
            render: (_: any, modelData: any) => {
                return (
                    <>
                        <div>
                            <Popover
                                overlayClassName="nodePopover"
                                visible={popoverVisible[modelData?.key]}
                                onVisibleChange={(visible) => {
                                    handlePopoverVisibleChange(
                                        visible,
                                        modelData
                                    );
                                }}
                                placement="bottomLeft"
                                trigger={showPopover ? 'click' : []}
                                content={
                                    modelData?.id ? (
                                        <NodeMoreContent
                                            setEditNode={setEditNode}
                                            setOpenDrawer={setOpenDrawer}
                                            nodeId={modelData?.id}
                                            onItemClick={handlePopoverItemClick}
                                            setSearchState={setSearchState}
                                            setPage={setPage}
                                            setPageSize={setPageSize}
                                        />
                                    ) : null
                                }
                                overlayStyle={{ width: '162px' }}
                            >
                                <MoreOutlined />
                            </Popover>
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.configuration.nodeType')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(nodeTypeSortColumns?.NAME);
                                setSortOrder(sortingOrder?.ascending);
                                setPage(PAGE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(nodeTypeSortColumns?.NAME);
                                setSortOrder(sortingOrder?.descending);
                                setPage(PAGE);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            className: 'column__name',
            width: '223px',
            render: (_: any, nodeData: any) => (
                <>
                    <div
                        className="attributeTableWrapper__nameData"
                        onClick={() => {
                            dispatch(getNodeTypeId(nodeData?.id));
                        }}
                    >
                        <div className="blaTableWrapper__status">
                            <span className="fs-14 fw-500 name">
                                {nodeData?.name?.length <
                                deviceManagement.blaNameLength ? (
                                    nodeData?.name
                                ) : (
                                    <Popover
                                        overlayClassName="customOverlay"
                                        content={
                                            <div className="blaName">
                                                {nodeData?.name}
                                            </div>
                                        }
                                        visible={
                                            isPopoverVisibles[nodeData?.key]
                                        }
                                        onVisibleChange={
                                            handlePopoverVisibleChanges
                                        }
                                        placement="topLeft"
                                    >
                                        {nodeData?.name?.length >
                                        deviceManagement.blaNameLength
                                            ? `${nodeData?.name?.slice(
                                                  0,
                                                  deviceManagement.blaNameLength
                                              )}...`
                                            : nodeData?.name}
                                    </Popover>
                                )}
                            </span>
                        </div>
                    </div>
                </>
            ),
        },

        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.configuration.description')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(nodeTypeSortColumns?.DESCRIPTION);
                                setSortOrder(sortingOrder?.ascending);
                                setPage(PAGE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(nodeTypeSortColumns?.DESCRIPTION);
                                setSortOrder(sortingOrder?.descending);
                                setPage(PAGE);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'description',
            key: 'description',
            className: 'column__description',
            width: '422px',
            render: (_: any, nodeData: any) => (
                <>
                    <div className="attributeTableWrapper__subItems">
                        <span className="fs-14 fw-500 name">
                            {nodeData?.description?.length <
                             assetModelling.description ? (
                                nodeData?.description
                            ) : (
                                <Popover
                                    overlayClassName="customOverlay"
                                    content={
                                        <div className="blaName">
                                            {nodeData?.description}
                                        </div>
                                    }
                                    visible={isPopoverVisibles[nodeData?.key]}
                                    onVisibleChange={
                                        handlePopoverVisibleChanges
                                    }
                                    placement="topLeft"
                                >
                                    {nodeData?.description?.length >
                                    assetModelling.description
                                        ? `${nodeData?.description?.slice(
                                              0,
                                              assetModelling.description
                                          )}...`
                                        : nodeData?.description}
                                </Popover>
                            )}
                        </span>
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.configuration.lastModified')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(nodeTypeSortColumns?.UPDATED_ON);
                                setSortOrder(sortingOrder?.ascending);
                                setPage(PAGE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(nodeTypeSortColumns?.UPDATED_ON);
                                setSortOrder(sortingOrder?.descending);
                                setPage(PAGE);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'lastModified',
            key: 'lastModified',
            width: '200px',
            render: (_: any, nodeData: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {moment(nodeData?.updatedOn).format(DATE_ONLY_FORMAT)}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.configuration.createdOn')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(nodeTypeSortColumns?.CREATED_ON);
                                setSortOrder(sortingOrder?.ascending);
                                setPage(PAGE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortColumn(nodeTypeSortColumns?.CREATED_ON);
                                setSortOrder(sortingOrder?.descending);
                                setPage(PAGE);
                            }}
                        />
                    </div>
                </div>
            ),
            key: 'createdOn',
            dataIndex: 'createdOn',
            width: '200px',
            render: (_: any, nodeData: any) => (
                <>{moment(nodeData?.createdOn).format(DATE_ONLY_FORMAT)}</>
            ),
        },
    ];

    return (
        <>
            <Table
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                    selectedRowKeys: selectedRowIds,
                }}
                columns={columns}
                dataSource={tableData}
                pagination={false}
                scroll={{ y: 'calc(100vh - 390px)' }}
            />
        </>
    );
};
export default NodeTable;
