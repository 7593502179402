import { baseUrlSetter } from 'utils/commonFunction';
import Api from '..';
import { message } from 'antd';

export const createUpdateFormulaService = async (payload: any): Promise<any> => {
    baseUrlSetter('configure');
    const body = payload;
    try {
        const response = Api.post(`/rule/add`, body);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error.response.data.message ? error.response.data.message : "Internal Server Error");
        throw new Error(error);
    }
};
export const fetchFormulaService = async (payload: any): Promise<any> => {
    baseUrlSetter('configure');
    const body = payload;
    try {
        const response = Api.post(`/rule/fetch`, body);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error.response.data.message ? error.response.data.message : "Internal Server Error");
        throw new Error(error);
    }
};
export const updateFormulaService = async (payload: any): Promise<any> => {
    
    baseUrlSetter('configure');
    const body = payload;
    try {
        const response = Api.put(`/rule/update`, body);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error.response.data.message ? error.response.data.message : "Internal Server Error");
        throw new Error(error);
    }
};
