import { Drawer, Row, Col, Button, Divider, Form, Input, Spin } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './index.scss';
import CustomButton from 'components/common/CustomButton';
import { EMPTY, EquipmentState } from 'types/enums';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import {
    setEquipmentClass,
    setEquipmentState,
} from 'redux/actions/AssetModellingActions/assetModellingAction';
import { useTranslation } from 'react-i18next';
import { PAGE, PAGE_SIZE } from 'utils/constants';

const CreateNewClassForm: React.FC<any> = ({
    open,
    paginatedPayload,
    setSearchState,
    setPage,
    setPageSize,
}): any => {
    const { t } = useTranslation('translation');
    const [eqClassName, setEqClassName] = useState('');
    const [description, setDescription] = useState('');

    const dispatch = useDispatch();
    const equipmentClassId = useSelector(
        (state: any) => state.assetModelling?.equipment?.equipmentClassById
    );
    const loader = useSelector(
        (state: any) => state.assetModelling?.equipment?.loader
    );

    const [form] = Form.useForm();

    const initialValues = open
        ? { eqClassName: '', description: '' }
        : {
              eqClassName: equipmentClassId?.name || '',
              description: equipmentClassId?.description || '',
          };
    return (
        <>
            {loader ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                <Drawer
                    className="addClassDrawer"
                    placement="right"
                    size="default"
                    getContainer={false}
                    closable={false}
                    open={open}
                >
                    <>
                        <div>
                            <Row>
                                <Col
                                    span={20}
                                    className="addClassDrawer__heading fw-500 fs-16"
                                >
                                    <span>
                                        {t(
                                            'assetModeller.configuration.createNewEquipmentClass'
                                        )}
                                    </span>
                                </Col>
                                <Col span={4}>
                                    <Button
                                        className="addClassDrawer__icon"
                                        type="text"
                                        onClick={() => {
                                            dispatch(
                                                setEquipmentState(
                                                    EquipmentState?.display
                                                )
                                            );
                                        }}
                                        icon={<CloseOutlined />}
                                    ></Button>
                                </Col>
                            </Row>
                            <Divider className="addClassDrawer__divider" />
                            <Form
                                layout="vertical"
                                onFinish={CreateNewClassForm}
                                form={form}
                                initialValues={initialValues}
                            >
                                <Form.Item
                                    name="Equipment Class Name"
                                    label={t(
                                        'assetModeller.configuration.equipmentClassName'
                                    )}
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Equipment Class Name is required',
                                        },
                                        {
                                            validator: async (_, value) => {
                                                if (
                                                    value &&
                                                    value.trimStart() !== value
                                                ) {
                                                    throw new Error(
                                                        'Equipment Class Name cannot start with a space'
                                                    );
                                                }
                                            },
                                        },
                                    ]}
                                >
                                    <Input
                                        className="addClassDrawer__inputField"
                                        value={eqClassName}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            if (
                                                value !== null &&
                                                value !== undefined
                                            ) {
                                                setEqClassName(value);
                                            }
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="description"
                                    label={t(
                                        'assetModeller.configuration.description'
                                    )}
                                >
                                    <Input
                                        className="addClassDrawer__inputField"
                                        value={description}
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            if (
                                                value !== null &&
                                                value !== undefined
                                            ) {
                                                setDescription(value);
                                            }
                                        }}
                                    />
                                </Form.Item>
                                <div className="addClassDrawer__button">
                                    <Divider />
                                    <Row className="addClassDrawer__footerButtons">
                                        <Col
                                            span={8}
                                            className="addClassDrawer__cancelButton"
                                        >
                                            <CustomButton
                                                type={t('commonStr.cancel')}
                                                disabled={false}
                                                handleClick={() => {
                                                    dispatch(
                                                        setEquipmentState(
                                                            EquipmentState?.display
                                                        )
                                                    );
                                                }}
                                            />
                                        </Col>
                                        <Col
                                            span={8}
                                            className="addClassDrawer__createClassButton"
                                        >
                                            <CustomButton
                                                disabled={!eqClassName.trim()}
                                                type={t(
                                                    'commonStr.CreateClass'
                                                )}
                                                typeOfButton={'submit'}
                                                handleClick={(e: {
                                                    preventDefault: () => void;
                                                }) => {
                                                    dispatch(
                                                        setEquipmentClass({
                                                            name: eqClassName,
                                                            description,
                                                            categories: [],
                                                            paginatedPayload,
                                                        })
                                                    );
                                                    setSearchState(
                                                        EMPTY.string
                                                    );
                                                    dispatch(
                                                        setEquipmentState(
                                                            EquipmentState?.display
                                                        )
                                                    );
                                                    setPage(PAGE);
                                                    setPageSize(PAGE_SIZE);
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Form>
                        </div>
                    </>
                </Drawer>
            )}
        </>
    );
};
export default CreateNewClassForm;
