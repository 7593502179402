import { useDispatch, useSelector } from 'react-redux';
import { Card, Form, Radio, Spin } from 'antd';
import { ReactComponent as SuccessIcon } from 'assets/icons/success.svg';
import { useEffect } from 'react';
import { getAdapterList } from 'redux/actions/BusinessIntegratorActions/connectionsActions';
import { ConnectionsState } from 'types/enums/businessIntegratorEnum';
import { EMPTY } from 'types/enums';

const AdapterListing: React.FC<any> = ({ form, paramAdapterId }: any): any => {
    const dispatch = useDispatch();
    const adapterSelected = Form.useWatch('adapterType', form);
    const connectionsLoader = useSelector(
        (state: any) => state.businessIntegrator.connections.connectionsLoader
    );
    const adapterList = useSelector(
        (state: any) => state.businessIntegrator.connections.adapterList
    );
    const connectionsState = useSelector(
        (state: any) => state.businessIntegrator.connections.connectionsState
    );

    useEffect(() => {
        dispatch(getAdapterList());
    }, []);
    useEffect(() => {
        if (connectionsState === ConnectionsState.addConnections) {
            if (paramAdapterId) {
                form.setFieldsValue({ adapterType: paramAdapterId });
            }
        }
    }, [paramAdapterId, form, connectionsState]);
    return (
        <>
            <div className="adapter__container">
                {connectionsLoader ? (
                    <div className="view__loader">
                        <Spin />
                    </div>
                ) : (
                    <Form.Item name="adapterType">
                        <Radio.Group className="adapter__group">
                            {adapterList?.map((item: any) => {
                                return (
                                    <>
                                        <label htmlFor={item.id}>
                                            <Card
                                                className={`adapter__card ${
                                                    !item.isSupported
                                                        ? 'disabled'
                                                        : EMPTY.string
                                                }`}
                                                hoverable
                                            >
                                                <div
                                                    className={`adpater__cardContent ${
                                                        adapterSelected ===
                                                        item.id
                                                            ? 'selected'
                                                            : EMPTY.string
                                                    }`}
                                                >
                                                    <div className="adapter__cardDetails">
                                                        <div className="circle normal-circle">
                                                            <img
                                                                src={`data:image/svg+xml;base64,${item?.image}`}
                                                                alt="adapter-image"
                                                            />
                                                            <div
                                                                className={`fw-400 fs-16 tile${
                                                                    adapterSelected ===
                                                                    item.id
                                                                        ? 'nameSelected'
                                                                        : EMPTY.string
                                                                }`}
                                                            >
                                                                {item?.name}
                                                            </div>
                                                        </div>
                                                        <div className="fw-400 fs-14 desc">
                                                            {item?.description}
                                                        </div>
                                                    </div>
                                                    <div className="adapter__cardSelection">
                                                        {adapterSelected ===
                                                        item.id ? (
                                                            <SuccessIcon />
                                                        ) : (
                                                            <Radio
                                                                disabled={
                                                                    !item.isSupported
                                                                }
                                                                id={item.id}
                                                                value={item.id}
                                                            ></Radio>
                                                        )}
                                                    </div>
                                                </div>
                                            </Card>
                                        </label>
                                    </>
                                );
                            })}
                        </Radio.Group>
                    </Form.Item>
                )}
            </div>
        </>
    );
};

export default AdapterListing;
