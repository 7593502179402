export const SET_NOTIFICATION_STATE = 'SET_NOTIFICATION_STATE';

export const GET_ALL_CHANNEL = 'GET_ALL_CHANNEL';
export const GET_ALL_CHANNEL_SUCCESS = 'GET_ALL_CHANNEL_SUCCESS';
export const GET_ALL_CHANNEL_FAIL = 'GET_ALL_CHANNEL_FAIL';

export const GET_ALL_PROVIDER = 'GET_ALL_PROVIDER';
export const GET_ALL_PROVIDER_SUCCESS = 'GET_ALL_PROVIDER_SUCCESS';
export const GET_ALL_PROVIDER_FAIL = 'GET_ALL_PROVIDER_FAIL';

export const UPDATE_PROVIDER_CONFIG = 'UPDATE_PROVIDER_CONFIG ';
export const UPDATE_PROVIDER_CONFIG_SUCCESS = 'UPDATE_PROVIDER_CONFIG_SUCCESS';
export const UPDATE_PROVIDER_CONFIG_FAIL = 'UPDATE_PROVIDER_CONFIG_FAIL';

export const CREATE_PROVIDER_CONFIG = 'CREATE_PROVIDER_CONFIG';
export const CREATE_PROVIDER_CONFIG_SUCCESS = 'CREATE_PROVIDER_CONFIG_SUCCESS';
export const CREATE_PROVIDER_CONFIG_FAIL = 'CREATE_PROVIDER_CONFIG_FAIL';

export const TEST_PROVIDER_CONFIG = 'TEST_PROVIDER_CONFIG';
export const TEST_PROVIDER_CONFIG_SUCCESS = 'TEST_PROVIDER_CONFIG_SUCCESS';
export const TEST_PROVIDER_CONFIG_FAIL = 'TEST_PROVIDER_CONFIG_FAIL';

export const GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID =
    'GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID';
export const GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID_SUCCESS =
    'GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID_SUCCESS';
export const GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID_FAIL =
    'GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID_FAIL';

export const GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID =
    'GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID';
export const GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID_SUCCESS =
    'GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID_SUCCESS';
export const GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID_FAIL =
    'GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID_FAIL';

export const GET_ALL_PROVIDER_CONFIG = 'GET_ALL_PROVIDER_CONFIG';
export const GET_ALL_PROVIDER_CONFIG_SUCCESS =
    'GET_ALL_PROVIDER_CONFIG_SUCCESS';
export const GET_ALL_PROVIDER_CONFIG_FAIL = 'GET_ALL_PROVIDER_CONFIG_FAIL';


export const ACTIVATE_DEACTIVATE_PROVIDER_CONFIG = 'ACTIVATE_DEACTIVATE_PROVIDER_CONFIG';
export const ACTIVATE_DEACTIVATE_PROVIDER_CONFIG_SUCCESS =
    'ACTIVATE_DEACTIVATE_PROVIDER_CONFIG_SUCCESS';
export const ACTIVATE_DEACTIVATE_PROVIDER_CONFIG_FAIL = 'ACTIVATE_DEACTIVATE_PROVIDER_CONFIG_FAIL';