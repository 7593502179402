import { useCurrentDateTime } from 'hooks/useCurrentDateTime';
import './index.scss';
import React from 'react';

const CurrentDateTimeComponent: React.FC<any> = ({ homePage, showSecond }) => {
    const { formattedDate, formattedTime } = useCurrentDateTime(showSecond);

    return (
        <div className="currentDateTimeComponent">
            <span className="currentDateTimeComponent__date">
                {formattedDate}
            </span>
            {homePage ? <span> - </span> : ''}

            <span className="currentDateTimeComponent__time">
                {formattedTime}
            </span>
        </div>
    );
};

export default CurrentDateTimeComponent;
