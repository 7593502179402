import { type ReactNode, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { screenName } from 'types/enums';
import {
    ConnectionsState,
    MappingFormFields,
    MappingState,
} from 'types/enums/businessIntegratorEnum';
import { ReactComponent as BackIcon } from 'assets/icons/backIcon.svg';
import CustomButton from 'components/common/CustomButton';
import RoleProgress from 'components/common/RoleProgress';
import {
    getConnectionById,
    getConnectionsList,
} from 'redux/actions/BusinessIntegratorActions/connectionsActions';
import AddMappingStep1 from './AddMappingSteps/AddMappingStep1';
import AddMappingStep2 from './AddMappingSteps/AddMappingStep2';
import TestMapping from './AddMappingSteps/TestMapping';
import './index.scss';
import {
    createMapping,
    getBraboTableList,
    getColumnDetailsByTableId,
    getMappingById,
    getMappingJson,
    setMappingState,
    updateMapping,
} from 'redux/actions/BusinessIntegratorActions/mappingActions';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { cancelHandle, modalShow } from 'utils/modalFunction';
import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmationIcon.svg';
import SuccessfulModal from 'components/common/Modals/SuccessfulModal';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AddMapping = (): any => {
    const { paramAdapterId } = useParams();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const { t } = useTranslation('translation');
    const mappingState = useSelector(
        (state: any) => state.businessIntegrator.mapping.mappingState
    );
    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );
    const isMappingCreated = useSelector(
        (state: any) => state.businessIntegrator.mapping.isMappingCreated
    );
    const isTestExecutuedSuccess = useSelector(
        (state: any) => state.businessIntegrator.mapping.isTestExecutuedSuccess
    );
    const mappingById = useSelector(
        (state: any) => state.businessIntegrator.mapping.mappingById
    );

    const connectionById = useSelector(
        (state: any) => state.businessIntegrator.connections.connectionById
    );
    const isMappingUpdated = useSelector(
        (state: any) => state.businessIntegrator.mapping.isMappingUpdated
    );
    const [createMappingProgressCount, setCreateMappingProgressCount] =
        useState(1);
    const [isTestMapping, setIsTestMapping] = useState<any>(false);
    const [isNextDisable, setIsNextDisable] = useState(true);
    const [isSaveMappingBtnDisabled, setIsSaveMappingBtnDisabled] =
        useState(true);
    const [isMappingSaveModalOpen, setIsMappingSaveModalOpen] = useState(false);
    const [
        onMappingCreateSuccessfullModalOpen,
        setOnMappingCreateSuccessfullModalOpen,
    ] = useState(false);
    const [
        onMappingUpdatedSuccessfullModalOpen,
        setOnMappingUpdatedSuccessfullModalOpen,
    ] = useState(false);
    const [
        isMappingSaveAndActivateModalOpen,
        setIsMappingSaveAndActivateModalOpen,
    ] = useState(false);

    const mappingName = Form.useWatch(MappingFormFields.mappingName, form);
    const braboTableName = Form.useWatch(
        MappingFormFields.braboTableName,
        form
    );
    const connectionName = Form.useWatch(
        MappingFormFields.connectionName,
        form
    );
    const sourceUrl = Form.useWatch(MappingFormFields.sourceUrl, form);
    const mappingFields = Form.useWatch(MappingFormFields.mappingFields, form);
    const description = Form.useWatch(MappingFormFields.description, form);
    const labels = Form.useWatch(MappingFormFields.label, form);

    useEffect(() => {
        if (connectionName && mappingName && braboTableName && sourceUrl) {
            if (isNextDisable) {
                setIsNextDisable(false);
            }
        } else {
            if (!isNextDisable) {
                setIsNextDisable(true);
            }
        }
    }, [connectionName, mappingName, braboTableName, sourceUrl]);

    const onBackClickHandler = (): any => {
        if (createMappingProgressCount === 2) {
            setCreateMappingProgressCount(1);
        } else {
            dispatch(setMappingState(MappingState.tableView));
        }
    };

    useEffect(() => {
        dispatch(
            getConnectionsList({
                pageNum: -1,
                isStreamingOn: 1,
            })
        );
        dispatch(dispatch(getBraboTableList({ pageNumber: -1 })));
    }, []);

    const getTitle = (): any => {
        if (mappingState === MappingState.viewConnections) {
            return t('BusinessIntegrator.mapping.mappingDetails.viewMapping');
        } else if (mappingState === MappingState.editConnection) {
            return t('BusinessIntegrator.mapping.mappingDetails.editMapping');
        } else {
            return t('BusinessIntegrator.mapping.mappingDetails.createMapping');
        }
    };

    let component: ReactNode = <AddMappingStep1 form={form} />;

    switch (createMappingProgressCount) {
        case 2:
            component = (
                <AddMappingStep2
                    form={form}
                    setCreateMappingProgressCount={
                        setCreateMappingProgressCount
                    }
                    setIsTestMapping={setIsTestMapping}
                />
            );
            break;
        default:
            component = <AddMappingStep1 form={form} />;
            break;
    }

    const onNextClickhandler = (): any => {
        setCreateMappingProgressCount(createMappingProgressCount + 1);
        dispatch(
            getMappingJson({
                sourceUrl: connectionById.url + sourceUrl,
                connectionId: connectionName,
            })
        );
    };

    useEffect(() => {
        const allFieldsMapped = mappingFields?.some(
            (item: any) => item.destinationField.id !== ''
        );
        setIsSaveMappingBtnDisabled(!allFieldsMapped);
    }, [mappingFields]);

    const onSaveMappingHandler = (): any => {
        const jsonColumnValueMapping = mappingFields?.map((item: any) => {
            return {
                jsonField: item.jsonField,
                columnId: item.destinationField.id,
            };
        });
        if (mappingState === ConnectionsState.editConnection) {
            dispatch(
                updateMapping({
                    updatedBy:
                        loggedInUserDetails.firstName +
                        loggedInUserDetails.lastName,
                    tableId: braboTableName,
                    columnMapping: jsonColumnValueMapping,
                    connectionId: connectionName,
                    sourceUrl: sourceUrl,
                    description: description,
                    labels: labels,
                    isTested:
                        isTestExecutuedSuccess === null
                            ? false
                            : isTestExecutuedSuccess,
                    isScheduled: false,
                    id: paramAdapterId,
                    name: mappingName,
                })
            );
        } else {
            dispatch(
                createMapping({
                    requestedBy:
                        loggedInUserDetails.firstName +
                        loggedInUserDetails.lastName,
                    tableId: braboTableName,
                    columnMapping: jsonColumnValueMapping,
                    connectionId: connectionName,
                    sourceUrl: sourceUrl,
                    description: description,
                    labels: labels,
                    isTested:
                        isTestExecutuedSuccess === null
                            ? false
                            : isTestExecutuedSuccess,
                    isScheduled: false,
                    name: mappingName,
                })
            );
        }
        cancelHandle(isMappingSaveModalOpen, setIsMappingSaveModalOpen);
    };
    const onConnectionSaveAndActivateHandler = (): any => {
        const jsonColumnValueMapping = mappingFields?.map((item: any) => {
            return {
                jsonField: item.jsonField,
                columnId: item.destinationField.id,
            };
        });
        if (mappingState === ConnectionsState.editConnection) {
            dispatch(
                updateMapping({
                    updatedBy:
                        loggedInUserDetails.firstName +
                        loggedInUserDetails.lastName,
                    tableId: braboTableName,
                    columnMapping: jsonColumnValueMapping,
                    connectionId: connectionName,
                    sourceUrl: sourceUrl,
                    description: description,
                    labels: labels,
                    isTested:
                        isTestExecutuedSuccess === null
                            ? false
                            : isTestExecutuedSuccess,
                    isScheduled: true,
                    id: paramAdapterId,
                    name: mappingName,
                })
            );
        } else {
            dispatch(
                createMapping({
                    requestedBy:
                        loggedInUserDetails.firstName +
                        loggedInUserDetails.lastName,
                    tableId: braboTableName,
                    columnMapping: jsonColumnValueMapping,
                    connectionId: connectionName,
                    sourceUrl: sourceUrl,
                    description: description,
                    labels: labels,
                    isTested:
                        isTestExecutuedSuccess === null
                            ? false
                            : isTestExecutuedSuccess,
                    isScheduled: true,
                    name: mappingName,
                })
            );
        }

        cancelHandle(
            isMappingSaveAndActivateModalOpen,
            setIsMappingSaveAndActivateModalOpen
        );
    };

    useEffect(() => {
        isMappingCreated &&
            modalShow(
                onMappingCreateSuccessfullModalOpen,
                setOnMappingCreateSuccessfullModalOpen
            );
    }, [isMappingCreated]);
    useEffect(() => {
        isMappingUpdated &&
            modalShow(
                onMappingUpdatedSuccessfullModalOpen,
                setOnMappingUpdatedSuccessfullModalOpen
            );
    }, [isMappingUpdated]);

    const onSuccesfulModalCancelHanlder = (): any => {
        if (mappingState === MappingState.editConnection) {
            cancelHandle(
                onMappingUpdatedSuccessfullModalOpen,
                setOnMappingUpdatedSuccessfullModalOpen
            );
        } else {
            cancelHandle(
                onMappingCreateSuccessfullModalOpen,
                setOnMappingCreateSuccessfullModalOpen
            );
        }
        dispatch(setMappingState(MappingState.tableView));
    };

    useEffect(() => {
        mappingState !== MappingState.addConnections &&
            dispatch(
                getMappingById({
                    mappingId: paramAdapterId,
                })
            );
    }, [mappingState, paramAdapterId]);

    useEffect(() => {
        if (mappingById) {
            form.setFieldsValue({
                mappingName: mappingById.name,
                braboTableName: mappingById?.dataTableResponse?.id,
                connectionName: mappingById?.connectionResponse?.id,
                description: mappingById?.description,
                sourceUrl: mappingById?.sourceUrl,
            });
        }
    }, [mappingById, form]);

    useEffect(() => {
        connectionName && dispatch(getConnectionById(connectionName));
    }, [connectionName]);

    useEffect(() => {
        if (braboTableName || mappingById?.dataTableResponse?.id) {
            dispatch(
                getColumnDetailsByTableId({
                    tableId:
                        braboTableName || mappingById?.dataTableResponse?.id,
                })
            );
        }
    }, [braboTableName, mappingById?.dataTableResponse?.id]);

    return (
        <>
            <div className="attributeWrapper adapterList">
                <Card bordered={false}>
                    {isTestMapping ? (
                        <TestMapping
                            form={form}
                            setCreateMappingProgressCount={
                                setCreateMappingProgressCount
                            }
                            setIsTestMapping={setIsTestMapping}
                        />
                    ) : (
                        <>
                            <Row className="adapterList__header">
                                <Col
                                    className="adapterList__backIcon"
                                    span={0.5}
                                >
                                    <BackIcon
                                        onClick={() => {
                                            onBackClickHandler();
                                        }}
                                    />
                                </Col>
                                <Col
                                    className="adapterList__title fw-400 fs-16"
                                    span={18}
                                >
                                    {getTitle()}
                                </Col>
                                {mappingState ===
                                    MappingState.viewConnections &&
                                    !mappingById?.scheduled && (
                                        <Col span={5} className="viewEdit">
                                            <Button
                                                type="primary"
                                                ghost
                                                className="addNewButton"
                                                onClick={() => {
                                                    dispatch(
                                                        setMappingState(
                                                            MappingState.editConnection
                                                        )
                                                    );
                                                    setCreateMappingProgressCount(
                                                        createMappingProgressCount -
                                                            1
                                                    );
                                                }}
                                            >
                                                Edit
                                            </Button>
                                        </Col>
                                    )}
                                {mappingState ===
                                    ConnectionsState.addConnections && (
                                    <Col span={5}>
                                        <RoleProgress
                                            count={createMappingProgressCount}
                                            screen={
                                                screenName.businessIntegratorMapping
                                            }
                                            totalCount={2}
                                        />
                                    </Col>
                                )}
                            </Row>
                            <Form
                                form={form}
                                layout="vertical"
                                disabled={
                                    mappingState ===
                                    MappingState.viewConnections
                                }
                                onFinish={onSaveMappingHandler}
                            >
                                <div
                                    className={
                                        'adapterList__content addMapping'
                                    }
                                >
                                    {component}
                                </div>
                                <div className="adapterList__footer addMapping">
                                    <div className="adapterList__buttons">
                                        <CustomButton
                                            type={'Cancel'}
                                            disabled={false}
                                            handleClick={() => {
                                                dispatch(
                                                    setMappingState(
                                                        MappingState.tableView
                                                    )
                                                );
                                            }}
                                        />
                                        {createMappingProgressCount === 2 ? (
                                            <>
                                                <Button
                                                    type="primary"
                                                    ghost
                                                    className="addNewButton"
                                                    disabled={
                                                        mappingState ===
                                                        ConnectionsState.viewConnections
                                                            ? true
                                                            : isSaveMappingBtnDisabled
                                                    }
                                                    onClick={() => {
                                                        modalShow(
                                                            isMappingSaveModalOpen,
                                                            setIsMappingSaveModalOpen
                                                        );
                                                    }}
                                                >
                                                    {t('BusinessIntegrator.mapping.mappingDetails.saveMapping')}
                                                </Button>
                                                <CustomButton
                                                    type= {t('BusinessIntegrator.mapping.mappingDetails.saveAndActivate')}
                                                    disabled={
                                                        !isTestExecutuedSuccess
                                                    }
                                                    handleClick={() => {
                                                        modalShow(
                                                            isMappingSaveAndActivateModalOpen,
                                                            setIsMappingSaveAndActivateModalOpen
                                                        );
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <CustomButton
                                                type={'Next'}
                                                disabled={
                                                    mappingState ===
                                                    MappingState.viewConnections
                                                        ? false
                                                        : isNextDisable
                                                }
                                                handleClick={() => {
                                                    onNextClickhandler();
                                                }}
                                            />
                                        )}
                                    </div>
                                </div>
                            </Form>
                        </>
                    )}
                </Card>
            </div>
            <ConfirmationModal
                open={isMappingSaveModalOpen}
                onCancel={() => {
                    cancelHandle(
                        isMappingSaveModalOpen,
                        setIsMappingSaveModalOpen
                    );
                }}
                onOk={() => {
                    form.submit();
                }}
                icon={<ConfirmationIcon />}
                text={
                    mappingState !== MappingState.editConnection
                        ? t('BusinessIntegrator.mapping.saveConfirmMapping')
                        : t('BusinessIntegrator.mapping.saveConfirmChanges')
                }
            />
            <SuccessfulModal
                open={onMappingCreateSuccessfullModalOpen}
                onOk={() => {}}
                onCancel={() => {
                    onSuccesfulModalCancelHanlder();
                }}
                text={t('BusinessIntegrator.mapping.createdSucessful')}
            />
            <ConfirmationModal
                open={isMappingSaveAndActivateModalOpen}
                onCancel={() => {
                    cancelHandle(
                        isMappingSaveAndActivateModalOpen,
                        setIsMappingSaveAndActivateModalOpen
                    );
                }}
                onOk={() => {
                    onConnectionSaveAndActivateHandler();
                }}
                icon={<ConfirmationIcon />}
                text={
                    mappingState !== MappingState.editConnection
                        ? 
                        t('BusinessIntegrator.mapping.saveAndActivateMapping')
                        : t('BusinessIntegrator.mapping.saveAndActivateChanges')
                }
            />
            <SuccessfulModal
                open={onMappingUpdatedSuccessfullModalOpen}
                onOk={() => {}}
                onCancel={() => {
                    onSuccesfulModalCancelHanlder();
                }}
                text={t('BusinessIntegrator.mapping.updatedSucessful')}
            />
        </>
    );
};

export default AddMapping;
