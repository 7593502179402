import { NOTIFICATIONTYPE } from 'types/enums';

const notificationConfigurationInitialState: any = {
    notificationConfiguration: {
        providerListId: [],
        channelList: [],
        allProviderConfig:{},
        notificationState: NOTIFICATIONTYPE.view,
        providerConfigCreateFields: {},
        isProviderConfigureCreated:false,
        providerConfigUpdateFields: {},
        isChannel: false,
        isProviderCardShow: false,
        testVerification: false,
        isUpdated:false,
        createProviderConfig:{},
        updateProviderConfig:{},
        activateDeactivateConfig:false
    },
};

export default notificationConfigurationInitialState;
