export const data=[
    {
        tagTd:'1',
        tagName:'one',
        deviceName:'two',
        uom:'1234',
        assigned:'1'
    },
    {
        tagTd:'2',
        tagName:'one',
        deviceName:'two',
        uom:'1234',
        assigned:'1'
    },
    {
        tagTd:'3',
        tagName:'one',
        deviceName:'two',
        uom:'1234',
        assigned:'1'
    },
    {
        tagTd:'4',
        tagName:'one',
        deviceName:'two',
        uom:'1234',
        assigned:'1'
    },
    {
        tagTd:'5',
        tagName:'one',
        deviceName:'two',
        uom:'1234',
        assigned:'1'
    },
    {
        tagTd:'6',
        tagName:'one',
        deviceName:'two',
        uom:'1234',
        assigned:'1'
    },
    {
        tagTd:'7',
        tagName:'one',
        deviceName:'two',
        uom:'1234',
        assigned:'1'
    },
    {
        tagTd:'8',
        tagName:'one',
        deviceName:'two',
        uom:'1234',
        assigned:'1'
    },
    {
        tagTd:'9',
        tagName:'one',
        deviceName:'two',
        uom:'1234',
        assigned:'1'
    },
    {
        tagTd:'10',
        tagName:'one',
        deviceName:'two',
        uom:'1234',
        assigned:'1'
    },
    {
        tagTd:'11',
        tagName:'one',
        deviceName:'two',
        uom:'1234',
        assigned:'1'
    },
    {
        tagTd:'12',
        tagName:'one',
        deviceName:'two',
        uom:'1234',
        assigned:'1'
    },
    {
        tagTd:'13',
        tagName:'one',
        deviceName:'two',
        uom:'1234',
        assigned:'1'
    }
]