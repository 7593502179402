import { baseUrlSetter } from 'utils/commonFunction';
import Api from '../index';
import { message } from 'antd';
import { EMPTY } from 'types/enums';

export const getAttributeImplementationListsService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('configure');
    const params = {
        ...(payload?.pageNumber !== EMPTY?.string
            ? { pageNumber: payload?.page }
            : {}),
        ...(payload?.pageSize !== EMPTY?.string
            ? { pageSize: payload?.pageSize }
            : {}),
        ...(payload?.sortColumn !== EMPTY?.string
            ? { sortColumn: payload?.sortColumn }
            : {}),
        ...(payload?.sortOrder !== EMPTY?.string
            ? { sortOrder: payload?.sortOrder }
            : {}),
        ...(payload?.assignStatus !== EMPTY?.string
            ? { assignStatus: payload?.assignStatus }
            : {}),
        ...(payload?.search !== EMPTY?.string
            ? { search: payload?.search }
            : {}),
        assetId: payload?.assetId,
        status: payload?.status ? payload.status : 0,
        isValidated:payload?.isValidated?payload?.isValidated:false
    };
    try {
        const response = Api.get(`/impl/attribute`, {
            params: params,
        });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error?.response?.data?.message
                ? error?.response?.data?.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const getAttributeListsWithoutAssestService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('configure');
    const params = {
        pageNumber: payload?.page ? payload.page : 0,
        status:payload?.status?payload.status:0
    };
    try {
        const response = Api.get(`/attribute`, {
            params: params,
        });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error?.response?.data?.message
                ? error?.response?.data?.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const AssignAttributeService = async (payload: any): Promise<any> => {
    baseUrlSetter('configure');
    const params = {
        ...payload,
    };
    try {
        const response = await Promise.resolve(
            Api.put(`/impl/attribute/assign`, params)
        );
        return response;
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const validateStaticValueAttributeService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('configure');
    const requestBody = {
        assetId: payload?.assetId,
        requestedBy: payload?.requestedBy,
        attributeId: payload?.attributeId,
        value: payload?.value,
    };
    try {
        const response = await Promise.resolve(
            Api.post(`/impl/attribute/value`, requestBody)
        );
        return response;
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const editAttributeValueService = async (payload: any): Promise<any> => {
    baseUrlSetter('configure');
    try {
        const response = await Promise.resolve(
            Api.put(`/impl/attribute/value`, payload)
        );
        message.success(response?.data?.message);
        return response;
    } catch (error: any) {
        throw new Error(error);
    }
};
export const getTagList = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = {
        ...(payload?.tagName !== EMPTY?.string
            ? { tagName: payload?.tagName }
            : {}),
        ...(payload?.pageNumber !== EMPTY?.string
            ? { pageNumber: payload?.page }
            : {}),
        ...(payload?.pageSize !== EMPTY?.string
            ? { pageSize: payload?.pageSize }
            : {}),
        ...(payload?.sortColumn !== EMPTY?.string
            ? { sortColumn: payload?.sortColumn }
            : {}),
        ...(payload?.sortOrder !== EMPTY?.string
            ? { sortOrder: payload?.sortOrder }
            : {}),
        ...(payload?.assignStatus !== EMPTY?.string
            ? { assignStatus: payload?.assignStatus }
            : {}),
        ...(payload?.search !== EMPTY?.string
            ? { search: payload?.search }
            : {}),
        ...(payload?.includeChildNode !== EMPTY?.string
            ? { includeChildNode: payload?.includeChildNode }
            : {}),
        assetId: payload?.assetId,
    };
    try {
        const response = Api.get(`/stream/paginated/list/${payload?.id}`, {
            params: params,
        });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error?.response?.data?.message
                ? error?.response?.data?.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const validateTagValueAttributeService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('configure');
    const requestBody = {
        assetId: payload?.assetId,
        requestedBy: payload?.requestedBy,
        attributeId: payload?.attributeId,
        value: payload?.value,
    };
    try {
        const response = await Promise.resolve(
            Api.post(`/impl/attribute/value`, requestBody)
        );
        return response;
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const validateTagValueAttributeEditService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('configure');
    const requestBody = {
        assetId: payload?.assetId,
        requestedBy: payload?.requestedBy,
        attributeId: payload?.attributeId,
        value: payload?.value,
    };
    try {
        const response = await Promise.resolve(
            Api.put(`/impl/attribute/value`, requestBody)
        );
        return response;
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};
