import * as types from "redux/types/businessIntegratorTypes";

export const setMappingState = (payload: string): any => ({
  type: types.SET_MAPPING_STATE,
  payload: payload,
});

export const getMappingJson = (payload: any): any => ({
  type: types.GET_MAPPING_JSON,
  payload: payload,
});

export const getBraboTableList = (payload: any): any => ({
  type: types.GET_BRABOTABLE_LIST,
  payload: payload,
});

export const getColumnDetailsByTableId = (payload: any): any => ({
  type: types.GET_COLUMN_DETAILS_BY_TABLE_ID,
  payload: payload,
});

export const setMappingFields = (payload: any): any => ({
  type: types.SET_MAPPING_FIELDS,
  payload: payload,
});

export const executeMapping = (payload: any): any => ({
  type: types.EXECUTE_MAPPING,
  payload: payload,
});

export const setExecutedSuccess = (payload: any): any => ({
  type: types.SET_EXECUTED_SUCCESS,
  payload: payload,
});

export const createMapping = (payload: any): any => ({
  type: types.CREATE_MAPPING,
  payload: payload,
});

export const getMappingById = (payload: any): any => ({
  type: types.GET_MAPPING_BY_ID,
  payload: payload,
});

export const getMappingList = (payload: any): any => ({
  type: types.GET_MAPPING_LIST,
  payload: payload,
});

export const getAllMapping = (payload: any): any => ({
  type: types.GET_ALL_MAPPING,
  payload: payload,
});

export const getLabelsList = (payload: any): any => ({
  type: types.GET_LABELS_DATA,
  payload: payload,
});

export const deleteMapping = (payload: any): any => ({
  type: types.MAPPING_DELETED,
  payload: payload,
});

export const activateMapping = (payload: any): any => ({
  type: types.MAPPING_ACTIVATED,
  payload: payload,
});

export const updateMapping = (payload: any): any => ({
  type: types.UPDATE_MAPPING,
  payload: payload,
});
