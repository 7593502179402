import { baseUrlSetter } from "utils/commonFunction";
import Api from "..";
import { message } from "antd";

export const getMappingJsonServices = async (payload: any): Promise<any> => {
  baseUrlSetter("deviceManagement");
  const params = payload;
  try {
    const response = Api.get(`/mapping/jsonValue`, { params: params });
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const getColumnDetailsByTableIdServices = async (
  payload: any
): Promise<any> => {
  baseUrlSetter("configure");
  const params = payload;
  try {
    const response = Api.get(`/table/${params.tableId}`);
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const getBraboTableListServices = async (payload: any): Promise<any> => {
  baseUrlSetter("configure");
  const params = payload;
  try {
    const response = Api.get(`/table/paginated/list`, { params: params });
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const executeMappingServices = async (payload: any): Promise<any> => {
  baseUrlSetter("deviceManagement");
  const params = payload;
  try {
    const response = Api.post(`/mapping/test`, params);
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const createMappingServices = async (payload: any): Promise<any> => {
  baseUrlSetter("deviceManagement");
  const params = payload;
  try {
    const response = Api.post(`/mapping/add`, params);
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const getMappingByIdServices = async (payload: any): Promise<any> => {
  baseUrlSetter("deviceManagement");
  const params = payload;
  try {
    const response = Api.get(`/mapping/getMappingById`, { params: params });
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const getMappingListServices = async (payload: any): Promise<any> => {
  baseUrlSetter("deviceManagement");
  const params = payload;
  try {
    const response = Api.get(`/mapping/list/paginated`, { params: params });
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const getLabelsListServices = async (payload: any): Promise<any> => {
  baseUrlSetter("deviceManagement");
  const params = payload;
  try {
    const response = Api.get(`/itIntegration/getLabelsList`, {
      params: params,
    });
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const deleteMappingServices = async (payload: any): Promise<any> => {
  baseUrlSetter("deviceManagement");
  try {
    const response = Api.put(`/mapping/delete`, payload);
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(
      error.response.data.message
        ? error.response.data.message
        : "Internal Server Error"
    );
    throw new Error(error);
  }
};

export const activateMappingServices = async (payload: any): Promise<any> => {
  baseUrlSetter("deviceManagement");
  try {
    const response = Api.put(`/mapping/change/status`, payload);
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(
      error.response.data.message
        ? error.response.data.message
        : "Internal Server Error"
    );
    throw new Error(error);
  }
};

export const updateMappingServices = async (payload: any): Promise<any> => {
  baseUrlSetter("deviceManagement");
  const params = payload;
  try {
    const response = Api.put(`/mapping/update`, params);
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};
