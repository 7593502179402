import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../../types/implementationTypes';
import {
    addEditTimeCapsuleValidationService,
    assignTimeCapsuleToAssetService,
    getAssignedTimeCapsuleListService,
    getAttributesListService,
    getConditionsService,
    getTagsListService,
    getUnAssignedTimeCapsuleListService,
    getValidatedTimeCapsuleDetailsService,
    removeTimeCapsuleFromAssetService,
} from 'redux/services/ImplementationServices/timeCapsuleServices';
import { message } from 'antd';
export function* getAssignedTimeCapsuleListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getAssignedTimeCapsuleListData = yield call(
            getAssignedTimeCapsuleListService,
            payload
        );
        yield put({
            type: types.GET_ASSIGNED_TIME_CAPSULE_LIST_SUCCESS,
            response: getAssignedTimeCapsuleListData,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_ASSIGNED_TIME_CAPSULE_LIST_FAILURE });
    }
}

export function* getUnassignedTimeCapsuleListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getUnassignedTimeCapsuleListData = yield call(
            getUnAssignedTimeCapsuleListService,
            payload
        );
        yield put({
            type: types.GET_UNASSIGNED_TIME_CAPSULE_LIST_SUCCESS,
            response: getUnassignedTimeCapsuleListData,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_UNASSIGNED_TIME_CAPSULE_LIST_FAILURE });
    }
}

export function* assignTimeCapsuleToAssetSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const assignTimeCapsuleToAssetData = yield call(
            assignTimeCapsuleToAssetService,
            payload
        );
        yield put({
            type: types.ASSIGN_TIME_CAPSULE_TO_ASSET_SUCCESS,
            response: assignTimeCapsuleToAssetData,
        });
        message.success('Time Capsule are assigned successfully');
    } catch (error: any) {}
}

export function* removeTimeCapsuleFromAssetSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const removeTimeCapsuleFromAssetData = yield call(
            removeTimeCapsuleFromAssetService,
            payload
        );
        yield put({
            type: types.REMOVE_TIME_CAPSULE_FROM_ASSET_SUCCESS,
            response: removeTimeCapsuleFromAssetData,
        });
        message.success('Removed successfully ');
    } catch (error: any) {}
}

export function* getTagsListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getTagsList = yield call(getTagsListService, payload);
        yield put({
            type: types.GET_TAGS_LIST_SUCCESS,
            response: getTagsList,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_TAGS_LIST_FAILURE });
    }
}

export function* getAttributesListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getAttributesList = yield call(getAttributesListService, payload);
        yield put({
            type: types.GET_ATTRIBUTES_LIST_SUCCESS,
            response: getAttributesList,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_ATTRIBUTES_LIST_FAILURE });
    }
}

export function* addEditTimeCapsuleValidationSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const addEditTimeCapsuleValidation:any = yield call(
            addEditTimeCapsuleValidationService,
            payload
        );
        yield put({
            type: types.ADD_EDIT_TIME_CAPSULE_VALIDATION_SUCCESS,
            response: addEditTimeCapsuleValidation,
        });

        message.success(addEditTimeCapsuleValidation?.data?.message);

        if (addEditTimeCapsuleValidation) {
            const getAssignedTimeCapsuleListData = yield call(
                getAssignedTimeCapsuleListService,
                payload
            );
            yield put({
                type: types.GET_ASSIGNED_TIME_CAPSULE_LIST_SUCCESS,
                response: getAssignedTimeCapsuleListData,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.ADD_EDIT_TIME_CAPSULE_VALIDATION_FAILURE });
    }
}

export function* getValidatedTimeCapsuleDetailsSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getValidatedTimeCapsuleData = yield call(
            getValidatedTimeCapsuleDetailsService,
            payload
        );
        yield put({
            type: types.GET_VALIDATED_TIME_CAPSULE_DETAILS_SUCCESS,
            response: getValidatedTimeCapsuleData,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_VALIDATED_TIME_CAPSULE_DETAILS_FAILURE });
    }
}

export function* getConditionsSaga({ type }: { type: string }): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getConditionsData = yield call(getConditionsService);
        yield put({
            type: types.GET_CONDITIONS_SUCCESS,
            response: getConditionsData,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_CONDITIONS_FAILURE });
    }
}
export function* timeCapsuleImplementationSaga(): any {
    yield all([
        takeLatest(
            types.GET_ASSIGNED_TIME_CAPSULE_LIST,
            getAssignedTimeCapsuleListSaga
        ),
    ]);
    yield all([
        takeLatest(
            types.GET_UNASSIGNED_TIME_CAPSULE_LIST,
            getUnassignedTimeCapsuleListSaga
        ),
    ]);
    yield all([
        takeLatest(
            types.ASSIGN_TIME_CAPSULE_TO_ASSET,
            assignTimeCapsuleToAssetSaga
        ),
    ]);
    yield all([
        takeLatest(
            types.REMOVE_TIME_CAPSULE_FROM_ASSET,
            removeTimeCapsuleFromAssetSaga
        ),
    ]);
    yield all([takeLatest(types.GET_TAGS_LIST, getTagsListSaga)]);
    yield all([takeLatest(types.GET_ATTRIBUTES_LIST, getAttributesListSaga)]);
    yield all([
        takeLatest(
            types.ADD_EDIT_TIME_CAPSULE_VALIDATION,
            addEditTimeCapsuleValidationSaga
        ),
    ]);
    yield all([
        takeLatest(
            types.GET_VALIDATED_TIME_CAPSULE_DETAILS,
            getValidatedTimeCapsuleDetailsSaga
        ),
    ]);
    yield all([takeLatest(types.GET_CONDITIONS, getConditionsSaga)]);
}
