import React, { useEffect, useState } from 'react';
import './index.scss';
import LeftPanelNavigation from 'components/common/Modals/FormulaCreatorModal/LeftPanelNavigation';
import { formulaCreatorScreenType } from 'types/enums/FormulaCreatorEnum';
import ChipsInput from 'components/common/Modals/FormulaCreatorModal/ChipsInput';
import { Popover } from 'antd';
import { EMPTY } from 'types/enums';
import { useSelector } from 'react-redux';
import NumberCustomPopover from 'components/common/Modals/FormulaCreatorModal/NumberCustomPopover';
import OperatorsLists from 'components/common/Modals/FormulaCreatorModal/OperatorsLists';
import ClearAllCustomPopover from 'components/common/Modals/FormulaCreatorModal/ClearAllCustomPopover';

const FormulaCreation: React.FC = () => {
    const expressionArray = useSelector(
        (state: any) => state?.formulaCreator?.expressionArray
    );
    const formulaData = useSelector(
        (state: any) => state?.formulaCreator?.formulaData
    );
    const [previewData,setPreviewData] = useState<string[]>(expressionArray);
    const [hideNumberPopover, setHideNumberPopover] = useState(false);
    const [hideClearAllPopover, setHideClearAllPopover] = useState(false);


    const hideNumberInput = (): void => {
        setHideNumberPopover(false);
    };
   
    const hideclearAll = (): void => {
        setHideClearAllPopover(false);
    };

    const handleNumberPopoverChange = (newOpen: boolean): void => {
        setHideNumberPopover(newOpen);
    };
    
    const handleHideClearAllPopoverChange = (newOpen: boolean): void => {
        setHideClearAllPopover(newOpen);
    };
    useEffect(() => {
        
        setPreviewData(expressionArray?.filter((item: any) => item !== null))
    }, [JSON.stringify(expressionArray),expressionArray.length,expressionArray])

    return (
        <div className="formulaCreation">
            <div className="formulaCreation__leftPanel">
                <LeftPanelNavigation screen={formulaCreatorScreenType.KPI} />
            </div>
            <div className="formulaCreation__rightPanel">
                <div className="formulaCreation__rightPanel-rightInput">
                    <ChipsInput />
                </div>
                <div className="formulaCreation__rightPanel-rightPreview">
                    <div className="rightPreviewContent">
                        <h2>Formula Preview</h2>
                        <span>{previewData.join(EMPTY.string)}</span>
                    </div>
                </div>
                <div className="formulaCreation__rightPanel-rightOperators">
                    <Popover
                        placement="topRight"
                        overlayClassName="formulaCreatorPopOver"
                        title={EMPTY.string}
                        content={
                            <NumberCustomPopover hideInput={hideNumberInput} />
                        }
                        open={hideNumberPopover}
                        onOpenChange={handleNumberPopoverChange}
                    >
                        <div className="operatorContainer__type">
                            <button>Number</button>
                        </div>
                    </Popover>
                    
                    <OperatorsLists />
                    {formulaData?.ruleData?.length ? (
                        <div className="clearAllContainer">
                            <Popover
                                placement="topLeft"
                                overlayClassName="clearAllOverlay"
                                title={EMPTY.string}
                                content={
                                    <ClearAllCustomPopover
                                        hideInput={hideclearAll}
                                    />
                                }
                                open={hideClearAllPopover}
                                onOpenChange={handleHideClearAllPopoverChange}
                            >
                                <button className="clear-all">
                                    <span className="clear-all__text">
                                        Clear all
                                    </span>
                                </button>
                            </Popover>
                        </div>
                    ):null}
                </div>
            </div>
        </div>
    );
};

export default FormulaCreation;
