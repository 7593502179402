import { Input, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/common/CustomButton';
import './index.scss';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { BUTTONTYPE, EMPTY } from 'types/enums';
import { useTranslation } from 'react-i18next';

const CategoryUOMClassContent: React.FC<any> = ({ setUomClassCreate }) => {
    const { t } = useTranslation('translation');

    const [uomClassName, setuomClassName] = useState<any>(EMPTY.string);

    const uomClassList = useSelector(
        (state: any) => state?.configure?.attributes?.uomClassList
    );

    return (
        <>
            <div className="createUOMClassContent">
                <div className="createUOMClassContent__heading">
                    <div className="createUOMClassContent__heading__name">
                        {t('UomConfigurator.addUomPopUpTitle')}
                    </div>
                    <span>
                        <CloseOutlined
                            onClick={() => {
                                setUomClassCreate(false);
                                setuomClassName(EMPTY.string);
                            }}
                        />
                    </span>
                </div>
                <div className="createUOMClassContent__bodyWrapper">
                    <div className="createUOMClassContent__body">
                        <div className="createUOMClassContent__body__label fw-400 fs-14">
                            <span className="mandatoryClass fw-400 fs-14">
                                *
                            </span>
                            {t('UomConfigurator.className')}
                        </div>
                        <Input
                            maxLength={100}
                            placeholder={t('UomConfigurator.classPlaceholder')}
                            value={uomClassName}
                            onChange={(e) => {
                                setuomClassName(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="createUOMClassContent__footer">
                    <div className="createUOMClassContent__footer__buttonDiv">
                        <CustomButton
                            type={BUTTONTYPE.cancel}
                            disabled={false}
                            handleClick={() => {
                                setUomClassCreate(false);
                                setuomClassName(EMPTY.string);
                            }}
                        />
                        <CustomButton
                            type={BUTTONTYPE.save}
                            typeOfButton="submit"
                            disabled={uomClassName === EMPTY.string}
                            handleClick={() => {
                                const classExist = uomClassList.find(
                                    (item: any) => item.name === uomClassName
                                );
                                if (classExist) {
                                    message.error(
                                        t('UomConfigurator.classExist')
                                    );
                                    return;
                                } else {
                                    localStorage.setItem(
                                        'recentUomClass',
                                        uomClassName
                                    );
                                    message.success(
                                        t(
                                            'UomConfigurator.classAddedSuccessfully'
                                        )
                                    );
                                }
                                if (uomClassName.trim() === EMPTY.string) {
                                    message.error(
                                        t('UomConfigurator.classEmpty')
                                    );
                                    return;
                                }
                                setUomClassCreate(false);
                                setuomClassName(EMPTY.string);
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default CategoryUOMClassContent;
