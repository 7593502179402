import { Col, Input, Row, Spin } from 'antd';
import './index.scss';
import { SearchOutlined } from '@ant-design/icons';
import CustomButton from 'components/common/CustomButton';
import AlertTable from './AlertTable';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import CustomPagination from 'components/common/CustomPagination';
import { useEffect, useState } from 'react';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAlertList,
    setAlertState,
} from 'redux/actions/AlertActions/alertActions';
import { ALERTTYPE } from 'types/enums';
import { useTranslation } from 'react-i18next';

const AlertTableComponnet = ({ selectedAssetkey }: any): any => {
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [sortColumn, setSortColumn] = useState<number | null>(null);
    const [sortOrder, setSortOrder] = useState<number | null>(null);
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    const [alertSearchTimeout, setAlertSearchTimeout] =
        useState<any>(undefined);
    const [searchState, setSearchState] = useState('');

    const handlePage = (page: any): any => {
        setPage(page);
    };

    const handlePageSize = (current: any): any => {
        setPageSize(current);
    };

    const alertData = useSelector((state: any) => state.alert?.alertList);

    const alertListLoading = useSelector(
        (state: any) => state.alert?.alertListLosding
    );

    useEffect(() => {
        dispatch(
            getAlertList({
                page,
                pageSize,
                search: searchState,
                sortColumn,
                sortOrder,
                assetId: selectedAssetkey,
            })
        );
    }, [page, pageSize, selectedAssetkey]);

    const handleSearchChange = (e: any): any => {
        setSearchState(e.target.value);
        if (alertSearchTimeout) {
            clearTimeout(alertSearchTimeout);
        }
        // Debouncing for search implemented
        setAlertSearchTimeout(
            setTimeout(() => {
                dispatch(
                    getAlertList({
                        page: PAGE,
                        pageSize: PAGE_SIZE,
                        search: e.target.value,
                        assetId: selectedAssetkey,
                    })
                );
            }, 1000)
        );
        setPage(PAGE);
        setPageSize(PAGE_SIZE);
    };

    return (
        <>
            {alertData?.totalActiveAlert + alertData?.totalInActiveAlert > 0 ? (
                <>
                    <div className="alertWrapper__rowHeader">
                        <Input
                            allowClear
                            className="alertWrapper__search"
                            placeholder={t('alerts.searchAlert')}
                            prefix={<SearchOutlined />}
                            value={searchState}
                            onChange={handleSearchChange}
                        />
                        <div className="alertWrapper__button">
                            <CustomButton
                                type={'Create Alerts'}
                                disabled={false}
                                handleClick={() => {
                                    dispatch(setAlertState(ALERTTYPE.create));
                                }}
                            />
                        </div>
                    </div>
                    {alertListLoading ? (
                        <div className="view__loader">
                            <Spin />
                        </div>
                    ) : alertData?.pageResponse?.totalRecords > 0 ? (
                        <Row
                            className={
                                alertData?.pageResponse?.totalRecords > 50
                                    ? 'attributeWrapper__attributeListPagination'
                                    : 'attributeWrapper__attributeList'
                            }
                        >
                            <Col span={24}>
                                <AlertTable
                                    data={alertData?.pageResponse?.records}
                                    payload={{
                                        page,
                                        pageSize,
                                        assetId: selectedAssetkey,
                                    }}
                                    search={searchState}
                                    setSortColumn={setSortColumn}
                                    setSortOrder={setSortOrder}
                                    setPage={setPage}
                                />
                            </Col>
                        </Row>
                    ) : (
                        <div className="attributeWrapper__noData">
                            <EmptyDataComponent
                                textValue={t('alerts.emptySearchText')}
                                loading={alertListLoading}
                            />
                        </div>
                    )}
                </>
            ) : (
                <div className="attributeWrapper__noData">
                    <EmptyDataComponent
                        textValue={t('alerts.noAlertCreated')}
                        buttonType={{
                            name: 'Create Alerts',
                            disable: false,
                        }}
                        loading={alertListLoading}
                        buttonClickHandler={() => {
                            dispatch(setAlertState(ALERTTYPE.create));
                        }}
                    />
                </div>
            )}

            {alertData?.pageResponse?.totalRecords > 50 && (
                <CustomPagination
                    totalRecords={alertData?.pageResponse?.totalRecords}
                    setPage={handlePage}
                    page={page}
                    setPageSize={handlePageSize}
                    pageSize={pageSize}
                />
            )}
        </>
    );
};
export default AlertTableComponnet;
