import {
    Divider,
    Modal,
    Row,
    Col,
    Form,
    Select,
    Card,
    Input,
    Button,
    Spin,
} from 'antd';
import CustomButton from 'components/common/CustomButton';
import './index.scss';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    addEditTimeCapsuleValidation,
    getAttributesList,
    getConditions,
    getTagsList,
} from 'redux/actions/ImplementationActions/timeCapsuleActions';
import { parseJwt } from 'utils/jwtTokenFunction';
import { CLEAR_TIME_CAPSULE_DATA } from 'redux/types/implementationTypes';
import { EMPTY, timeCapsuleCondition, variableType } from 'types/enums';
const ValidateTimeCapsule: React.FC<any> = ({
    open,
    selectedTimeCapsule,
    cancel,
    viewDetails,
    editDetails,
    setEditOptionSelected,
    setViewDetails,
    setOpenValidateForm,
}) => {
    const details = parseJwt();
    const [form] = Form.useForm();

    const selectedAsset = useSelector(
        (state: any) => state?.implementation?.attribute?.selectedAsset
    );
    const tagsList = useSelector(
        (state: any) => state?.implementation?.timeCapsule?.tagsList?.content
    );
    const conditions = useSelector(
        (state: any) =>
            state?.implementation?.timeCapsule?.conditions?.conditionList
    );
    const attributesList = useSelector(
        (state: any) =>
            state?.implementation?.timeCapsule?.attributesList?.pageResponse
                ?.records
    );
    const validatedInfo = useSelector(
        (state: any) =>
            state?.implementation?.timeCapsule?.validatedTimeCapsuleDetails
    );
    const viewDetailsLoader = useSelector(
        (state: any) => state?.implementation?.timeCapsule?.viewDetailsLoader
    );

    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    const options = [
        {
            id: variableType?.TAGS,
            value: 'TAGS',
            label: 'Tags',
        },
        {
            id: variableType?.ATTRIBUTES,
            value: 'ATTRIBUTES',
            label: 'Attributes',
        },
    ];

    const { Option } = Select;
    const [showInputField, setShowInputField] = useState(false);
    const [showEndTriggerInputField, setShowEndTriggerInputField] =
        useState(false);

    const handleEndSelectChange = (value: any): any => {
        if (value !== 1) {
            setShowEndTriggerInputField(true);
        } else {
            setShowEndTriggerInputField(false);
        }
    };

    const startTriggerType = Form.useWatch('startTriggerType', form);
    const startTriggerVariable = Form.useWatch('startTriggerVariable', form);
    const startTriggerCondition = Form.useWatch('startTriggerCondition', form);
    const startInputValue = Form.useWatch('startInputValue', form);
    const endTriggerType = Form.useWatch('endTriggerType', form);
    const endTriggerVariable = Form.useWatch('endTriggerVariable', form);
    const endTriggerCondition = Form.useWatch('endTriggerCondition', form);
    const endInputValue = Form.useWatch('endInputValue', form);
    useEffect(() => {
        if (
            startTriggerCondition &&
            (startTriggerCondition === timeCapsuleCondition?.FIRST_CONDITION ||
                startTriggerCondition ===
                    timeCapsuleCondition?.SEVENTH_CONDITION ||
                startTriggerCondition ===
                    timeCapsuleCondition?.EIGHTH_CONDITION)
        ) {
            setShowInputField(false);
        } else if (startTriggerCondition) {
            setShowInputField(true);
        }
    }, [startTriggerCondition]);

    useEffect(() => {
        if (
            endTriggerCondition &&
            (endTriggerCondition === timeCapsuleCondition?.FIRST_CONDITION ||
                endTriggerCondition ===
                    timeCapsuleCondition?.SEVENTH_CONDITION ||
                endTriggerCondition === timeCapsuleCondition?.EIGHTH_CONDITION)
        ) {
            setShowEndTriggerInputField(false);
        } else if (endTriggerCondition) {
            setShowEndTriggerInputField(true);
        }
    }, [endTriggerCondition]);
    const isSaveDisabled = !(
        startTriggerType &&
        startTriggerVariable &&
        startTriggerCondition &&
        (!showInputField || startInputValue) &&
        endTriggerType &&
        endTriggerVariable &&
        endTriggerCondition &&
        (!showEndTriggerInputField || endInputValue)
    );
    useEffect(() => {
        dispatch(getConditions());
        dispatch(getTagsList({ assetId: selectedAsset?.key }));
        dispatch(
            getAttributesList({
                assetId: selectedAsset?.key,
            })
        );
    }, []);

    const initialValues = {
        startTriggerType: validatedInfo
            ? validatedInfo?.startTrigger?.variableTypeId
            : EMPTY?.string,
        startTriggerVariable: validatedInfo
            ? validatedInfo?.startTrigger?.variableId
            : EMPTY?.string,
        startTriggerCondition: validatedInfo
            ? validatedInfo?.startTrigger?.conditionId
            : EMPTY?.string,
        endTriggerType: validatedInfo
            ? validatedInfo?.endTrigger?.variableTypeId
            : EMPTY?.string,
        endTriggerVariable: validatedInfo
            ? validatedInfo?.endTrigger?.variableId
            : EMPTY?.string,
        endTriggerCondition: validatedInfo
            ? validatedInfo?.endTrigger?.conditionId
            : EMPTY?.string,
        startInputValue: validatedInfo
            ? validatedInfo?.startTrigger?.value
            : EMPTY?.string,
        endInputValue: validatedInfo
            ? validatedInfo?.endTrigger?.value
            : EMPTY?.string,
    };

    const validateDetails = (values: any): any => {
        dispatch(
            addEditTimeCapsuleValidation({
                assetId: selectedAsset?.key,
                timeCapsuleId: selectedTimeCapsule?.id,
                startTrigger: {
                    variableTypeId: parseInt(values?.startTriggerType),
                    variableId: values?.startTriggerVariable,
                    conditionId: parseInt(values?.startTriggerCondition),
                    value: values?.startInputValue,
                },
                endTrigger: {
                    variableTypeId: parseInt(values?.endTriggerType),
                    variableId: values?.endTriggerVariable,
                    conditionId: parseInt(values?.endTriggerCondition),
                    value: values?.endInputValue,
                },
                requestedBy: details.username,
            })
        );
        setOpenValidateForm(false);
        dispatch({ type: CLEAR_TIME_CAPSULE_DATA });
    };

    return (
        <>
            <Modal
                className="validateModal timeCapsule"
                open={open}
                title={
                    <div className="validateModal__title">
                        <span>
                            {viewDetails
                                ? t('implementation.timeCapsule.view')
                                : editDetails
                                ? t('implementation.timeCapsule.edit')
                                : t('implementation.timeCapsule.validate')}
                            {' - '}
                            {selectedTimeCapsule?.name}
                        </span>
                        {viewDetails && (
                            <Button
                                type="primary"
                                ghost
                                onClick={() => {
                                    setEditOptionSelected(true);
                                    setViewDetails(false);
                                }}
                                className="validateModal__editBtn"
                            >
                                {t('implementation.timeCapsule.edit')}
                            </Button>
                        )}
                    </div>
                }
                onCancel={() => cancel()}
                footer={null}
            >
                <div>
                    <Divider />
                    <div
                        className={
                            viewDetails ? 'viewModal' : 'validationDetails'
                        }
                    >
                        {viewDetailsLoader &&
                        Object.keys(validatedInfo)?.length === 0 ? (
                            <div className="view__loader">
                                <Spin />
                            </div>
                        ) : (
                            <>
                                <Form
                                    layout="horizontal"
                                    initialValues={initialValues}
                                    onFinish={validateDetails}
                                    form={form}
                                >
                                    <div className="upperRow">
                                        <div className="validateModal__triggerHeading">
                                            {t(
                                                'implementation.timeCapsule.startTrigger'
                                            )}
                                        </div>
                                        <Card className="validateModal__innerCard">
                                            <Row className="startTrigger">
                                                <Col span={6}>
                                                    <Form.Item name="startTriggerType">
                                                        <Select
                                                            placeholder="Select Variable Type"
                                                            className="validateModal__selectDropdown"
                                                            disabled={
                                                                viewDetails
                                                            }
                                                            onChange={(
                                                                value: any
                                                            ) => {
                                                                form.setFieldsValue(
                                                                    {
                                                                        startTriggerVariable:
                                                                            null,
                                                                        startTriggerCondition:
                                                                            null,
                                                                        startInputValue:
                                                                            null,
                                                                    }
                                                                );
                                                                setShowInputField(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            {options?.map(
                                                                (item: any) => (
                                                                    <Option
                                                                        key={
                                                                            item?.id
                                                                        }
                                                                        value={
                                                                            item?.id
                                                                        }
                                                                    >
                                                                        {
                                                                            item?.label
                                                                        }
                                                                    </Option>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item name="startTriggerVariable">
                                                        <Select
                                                            showSearch
                                                            placeholder="Select Variable"
                                                            className="validateModal__selectDropdown"
                                                            disabled={
                                                                viewDetails
                                                            }
                                                            allowClear
                                                            options={
                                                                startTriggerType ===
                                                                variableType?.TAGS
                                                                    ? tagsList?.map(
                                                                          (
                                                                              tag: any
                                                                          ) => {
                                                                              return {
                                                                                  value: tag?.uuid,
                                                                                  label: tag?.tagName,
                                                                              };
                                                                          }
                                                                      )
                                                                    : startTriggerType ===
                                                                      variableType?.ATTRIBUTES
                                                                    ? attributesList?.map(
                                                                          (
                                                                              attribute: any
                                                                          ) => {
                                                                              return {
                                                                                  value: attribute?.id?.toString(),
                                                                                  label: attribute?.name,
                                                                              };
                                                                          }
                                                                      )
                                                                    : ''
                                                            }
                                                            filterOption={(
                                                                inputValue: any,
                                                                option: any
                                                            ) => {
                                                                return option?.label
                                                                    ?.toLowerCase()
                                                                    ?.includes(
                                                                        inputValue?.toLowerCase()
                                                                    );
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item name="startTriggerCondition">
                                                        <Select
                                                            placeholder="Select Condition"
                                                            className="validateModal__selectDropdown"
                                                            disabled={
                                                                viewDetails
                                                            }
                                                        >
                                                            {conditions?.map(
                                                                (item: any) => (
                                                                    <Option
                                                                        key={
                                                                            item?.id
                                                                        }
                                                                        value={
                                                                            item?.id
                                                                        }
                                                                    >
                                                                        {
                                                                            item?.name
                                                                        }
                                                                    </Option>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                {showInputField && (
                                                    <Col span={6}>
                                                        <Form.Item name="startInputValue">
                                                            <Input
                                                                className="validateModal__inputBox"
                                                                disabled={
                                                                    viewDetails
                                                                }
                                                                placeholder={t(
                                                                    'implementation.timeCapsule.enterValuePlaceholder'
                                                                )}
                                                                onKeyPress={(
                                                                    e: any
                                                                ) => {
                                                                    const currentValue =
                                                                        e
                                                                            .currentTarget
                                                                            .value;
                                                                    const cursorPosition =
                                                                        e
                                                                            .currentTarget
                                                                            .selectionStart;
                                                                    const forbiddenCharactersRegex =
                                                                        /^[a-zA-Z!@#$%^&*()_=\\[\]{};':"|,<>?+/`~]*$/;
                                                                    if (
                                                                        forbiddenCharactersRegex.test(
                                                                            e.key
                                                                        )
                                                                    ) {
                                                                        e.preventDefault();
                                                                    }

                                                                    if (
                                                                        e.key ===
                                                                        '-'
                                                                    ) {
                                                                        if (
                                                                            cursorPosition !==
                                                                                0 ||
                                                                            currentValue.includes(
                                                                                '-'
                                                                            )
                                                                        ) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }

                                                                    if (
                                                                        e.key ===
                                                                        '.'
                                                                    ) {
                                                                        if (
                                                                            currentValue.includes(
                                                                                '.'
                                                                            )
                                                                        ) {
                                                                            e.preventDefault();
                                                                        }
                                                                    }
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Card>
                                    </div>
                                    <div className="upperRow">
                                        <div className="validateModal__triggerHeading">
                                            {t(
                                                'implementation.timeCapsule.endTrigger'
                                            )}
                                        </div>
                                        <Card className="validateModal__innerCard">
                                            <Row className="startTrigger">
                                                <Col span={6}>
                                                    <Form.Item name="endTriggerType">
                                                        <Select
                                                            placeholder="Select Variable Type"
                                                            className="validateModal__selectDropdown"
                                                            disabled={
                                                                viewDetails
                                                            }
                                                            onChange={(
                                                                value: any
                                                            ) => {
                                                                form.setFieldsValue(
                                                                    {
                                                                        endTriggerVariable:
                                                                            null,
                                                                        endTriggerCondition:
                                                                            null,
                                                                        endInputValue:
                                                                            null,
                                                                    }
                                                                );
                                                                setShowEndTriggerInputField(
                                                                    false
                                                                );
                                                            }}
                                                        >
                                                            {options?.map(
                                                                (item: any) => (
                                                                    <Option
                                                                        key={
                                                                            item?.id
                                                                        }
                                                                        value={
                                                                            item?.id
                                                                        }
                                                                    >
                                                                        {
                                                                            item?.label
                                                                        }
                                                                    </Option>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item name="endTriggerVariable">
                                                        <Select
                                                            allowClear
                                                            showSearch
                                                            placeholder="Select Variable"
                                                            className="validateModal__selectDropdown"
                                                            disabled={
                                                                viewDetails
                                                            }
                                                            options={
                                                                endTriggerType ===
                                                                variableType?.TAGS
                                                                    ? tagsList?.map(
                                                                          (
                                                                              tag: any
                                                                          ) => {
                                                                              return {
                                                                                  value: tag?.uuid,
                                                                                  label: tag?.tagName,
                                                                              };
                                                                          }
                                                                      )
                                                                    : endTriggerType ===
                                                                      variableType?.ATTRIBUTES
                                                                    ? attributesList?.map(
                                                                          (
                                                                              attribute: any
                                                                          ) => {
                                                                              return {
                                                                                  value: attribute?.id?.toString(),
                                                                                  label: attribute?.name,
                                                                              };
                                                                          }
                                                                      )
                                                                    : ''
                                                            }
                                                            filterOption={(
                                                                inputValue: any,
                                                                option: any
                                                            ) => {
                                                                return option?.label
                                                                    ?.toLowerCase()
                                                                    ?.includes(
                                                                        inputValue?.toLowerCase()
                                                                    );
                                                            }}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={6}>
                                                    <Form.Item name="endTriggerCondition">
                                                        <Select
                                                            placeholder="Select Condition"
                                                            className="validateModal__selectDropdown"
                                                            onChange={
                                                                handleEndSelectChange
                                                            }
                                                            disabled={
                                                                viewDetails
                                                            }
                                                        >
                                                            {conditions?.map(
                                                                (item: any) => (
                                                                    <Option
                                                                        key={
                                                                            item?.id
                                                                        }
                                                                        value={
                                                                            item?.id
                                                                        }
                                                                    >
                                                                        {
                                                                            item?.name
                                                                        }
                                                                    </Option>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                {showEndTriggerInputField && (
                                                    <Col span={6}>
                                                        <Form.Item name="endInputValue">
                                                            <Input
                                                                className="validateModal__inputBox"
                                                                disabled={
                                                                    viewDetails
                                                                }
                                                                placeholder={t(
                                                                    'implementation.timeCapsule.enterValuePlaceholder'
                                                                )}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                )}
                                            </Row>
                                        </Card>
                                    </div>
                                    {!viewDetails && (
                                        <div className="validateModal__footer">
                                            <Divider />
                                            <Row className="validateModal__footerBtns">
                                                <Col
                                                    span={3}
                                                    className="validateModal__cancelBtn"
                                                >
                                                    <CustomButton
                                                        type={'Cancel'}
                                                        disabled={false}
                                                        handleClick={() => {
                                                            cancel();
                                                        }}
                                                    />
                                                </Col>
                                                <Col
                                                    span={3}
                                                    className="validateModal__saveBtn"
                                                >
                                                    <CustomButton
                                                        type={'Save'}
                                                        disabled={
                                                            isSaveDisabled
                                                        }
                                                        typeOfButton={'submit'}
                                                    />
                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                </Form>
                            </>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};
export default ValidateTimeCapsule;
