import React, { useState, useEffect } from 'react';
import { Button, message, Modal, Popover, Select } from 'antd';
import './index.scss';
import ChipsInput from './ChipsInput';
import OperatorsLists from './OperatorsLists';
import LeftPanelNavigation from './LeftPanelNavigation';
import { ReactComponent as ConfirmationIcon } from 'assets/icons/formulaValidate.svg';

import { useDispatch, useSelector } from 'react-redux';
import {
    
    createFormulaAction,
    updateFrequencyAction,
    updateRuleFormulaAction,
    viewFormulaScreenCloseAction,
} from 'redux/actions/FormulaCreatorActions/formulaCreatorActions';
import { EMPTY } from 'types/enums';
import { formulaCreatorScreenType, frequencyIntervals } from 'types/enums/FormulaCreatorEnum';
import { parseJwt } from 'utils/jwtTokenFunction';
import NumberCustomPopover from './NumberCustomPopover';
import ClearAllCustomPopover from './ClearAllCustomPopover';
import ConfirmationModal from '../ConfirmationModal';
interface Interval {
    label: string;
    value: number;
}
const FormulaCreatorModal: React.FC = () => {
    const viewModelRedux = useSelector(
        (state: any) => state?.formulaCreator?.viewMeta?.view
    );
    const viewModelDataRedux = useSelector(
        (state: any) => state?.formulaCreator?.viewMeta
    );
    const formulaData = useSelector(
        (state: any) => state?.formulaCreator?.formulaData
    );
    const titleModelRedux = useSelector(
        (state: any) => state?.formulaCreator?.viewMeta?.title
    );
    const [title, setTitle] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(viewModelRedux);
    const [, setFrequencyType] = useState<string>(EMPTY.string);
    const [frequencyVal, setFrequencyVal] = useState({ label: '', value: '' });
    const expressionArray = useSelector(
        (state: any) => state?.formulaCreator?.expressionArray
    );

    const focusedIndexRedux = useSelector(
        (state: any) => state?.formulaCreator?.formulaData?.focusedIndex
    );
    const formulaFrequencyRedux = useSelector(
        (state: any) => state?.formulaCreator?.formulaData?.frequencyInMillis
    );
    const [isValidateOpen, setIsValidateOpen] = useState(false);
    const [isDraftOpen, setIsDraftOpen] = useState(false);
    const [previewData, setPreviewData] = useState<string[]>(expressionArray);
    const [hideNumberPopover, setHideNumberPopover] = useState(false);
    const [hideClearAllPopover, setHideClearAllPopover] = useState(false);
    // TODO : will use this once we have scope of string functions from backend
    // const [hideStringPopover, setHideStringPopover] = useState(false);

    const hideNumberInput = (): void => {
        setHideNumberPopover(false);
    };
        // TODO : will use this once we have scope of string functions from backend

    // const hideStringInput = (): void => {
    //     setHideStringPopover(false);
    // };
    const hideclearAll = (): void => {
        setHideClearAllPopover(false);
    };

    const handleNumberPopoverChange = (newOpen: boolean): void => {
        setHideNumberPopover(newOpen);
    };
        // TODO : will use this once we have scope of string functions from backend

    // const handleStringPopoverChange = (newOpen: boolean): void => {
    //     setHideStringPopover(newOpen);
    // };
    const handleHideClearAllPopoverChange = (newOpen: boolean): void => {
        setHideClearAllPopover(newOpen);
    };
    const frequencyHandleChange = (value: {
        value: string;
        label: string;
    }): void => {
        setFrequencyType(value.label);
        dispatch(updateFrequencyAction(value.value));
    };
    function findInterval(value: number): Interval | null {
        const label = Object.keys(frequencyIntervals).find(
            (key) =>
                frequencyIntervals[key as keyof typeof frequencyIntervals] ===
                value
        );

        if (label) {
            return { label, value };
        }

        return null; // Value not found in the enum
    }

    const dispatch = useDispatch();

    useEffect(() => {
        const modifiedExpression = expressionArray?.filter((item: any) => item !== null);
        setPreviewData(modifiedExpression);
    }, [JSON.stringify(expressionArray),expressionArray[focusedIndexRedux], expressionArray,expressionArray.length]);
    useEffect(() => {
        setTitle(titleModelRedux);
    }, [titleModelRedux]);

    useEffect(() => {
        setIsModalOpen(viewModelRedux);
    }, [viewModelRedux]);
    useEffect(() => {
        if (!viewModelRedux) {
            dispatch(updateFrequencyAction(1));
        }
    }, [viewModelRedux])
    
    useEffect(() => {
        if (isModalOpen && formulaFrequencyRedux) {
            const result: any = findInterval(formulaFrequencyRedux);
            setFrequencyVal(result);
        }
    }, [formulaFrequencyRedux, isModalOpen,viewModelRedux]);
    const onSaveHandler = () :void=> {
        if (formulaData.ruleId?.length) {
            const payload = {
                ...{...formulaData,ruleData:formulaData?.ruleData?.filter((item: any) => item !== null)},
                updatedBy: parseJwt()?.username,
                isDraft: false,
                ruleCategory:viewModelDataRedux?.ruleCategory,
                ruleCategoryId: viewModelDataRedux?.ruleCategoryId,
                assetId:viewModelDataRedux?.assetId

            };
            dispatch(updateRuleFormulaAction(payload));
        } else {
            const payload = {
                ...{...formulaData,ruleData:formulaData?.ruleData?.filter((item: any) => item !== null)},
                createdBy: parseJwt()?.username,
                updatedBy: parseJwt()?.username,
                isDraft: false,
                ruleCategory:viewModelDataRedux?.ruleCategory,
                ruleCategoryId: viewModelDataRedux?.ruleCategoryId,
                assetId:viewModelDataRedux?.assetId

            };
             dispatch(createFormulaAction(payload));
        }
        setIsValidateOpen(false)
         dispatch(viewFormulaScreenCloseAction());
    }
    const onDraftHandler = ():void => {
        if (formulaData.ruleId?.length) {
            const payload = {
                ...{...formulaData,ruleData:formulaData?.ruleData?.filter((item: any) => item !== null)},
                updatedBy: parseJwt()?.username,
                isDraft: true,
                ruleCategory:viewModelDataRedux?.ruleCategory,
                ruleCategoryId: viewModelDataRedux?.ruleCategoryId,
                assetId:viewModelDataRedux?.assetId
            };

            dispatch(updateRuleFormulaAction(payload));
        } else {
            const payload = {
                ...{...formulaData,ruleData:formulaData?.ruleData?.filter((item: any) => item !== null)},
                createdBy: parseJwt()?.username,
                updatedBy: parseJwt()?.username,
                isDraft: true,
                ruleCategory:viewModelDataRedux?.ruleCategory,
                ruleCategoryId: viewModelDataRedux?.ruleCategoryId,
                assetId:viewModelDataRedux?.assetId
            };
            dispatch(createFormulaAction(payload));
        }

        setIsDraftOpen(false)
         dispatch(viewFormulaScreenCloseAction());
    }

    const validateAndSaveHandler = (): void => {
        if (formulaData?.ruleData.length) {
            const formulaasEmptyString = expressionArray?.some((str: any) => str === "");
            if (formulaasEmptyString) {
                message.error('Please input value in ruleBox')
            } else {
                setIsValidateOpen(true)
            }
        } else {
            message.error('Expression length cannot be zero.')
        }
    };
    const draftAndSaveHandler = (): void => {
        if (formulaData?.ruleData.length) {
            const formulaasEmptyString = expressionArray?.some((str: any) => str === "");
            if (formulaasEmptyString) {
                message.error('Please input value in ruleBox')
            } else {
                setIsDraftOpen(true)
            }
        } else {
            message.error('Expression length cannot be zero.')
        }
       
    };
   
    

    return (
        <>
            <Modal
                
                className="formulaCreatorModal"
                title={<span>Validate Formula - {title}</span>}
                open={isModalOpen}
                onOk={() => dispatch(viewFormulaScreenCloseAction())}
                width={'100vw'}
                key={viewModelDataRedux?.ruleCategoryId}
                style={{ top: 0, height: '100vh' }}
                onCancel={() => dispatch(viewFormulaScreenCloseAction())}
                footer={
                    <div className="formulaCreatorModal__footer">
                        <Button
                            key="back"
                            onClick={() =>
                                dispatch(viewFormulaScreenCloseAction())
                            }
                        >
                            Cancel
                        </Button>
                        <Button
                            key="validate"
                            type="primary"
                            disabled={!formulaData?.ruleData?.length}
                            onClick={validateAndSaveHandler}
                        >
                            Validate & Save
                        </Button>
                        <Button
                            key="save"
                            type="primary"
                            className='draftSave'
                            disabled={!formulaData?.ruleData?.length}

                            onClick={draftAndSaveHandler}
                        >
                            Save as Draft
                        </Button>
                    </div>
                }
            >
                <div className="formulaCreatorModal__content">
                    <div className="formulaCreatorModal__content-primary">
                        <div className="formulaCreatorModal__content-primaryMain">
                            <LeftPanelNavigation screen={formulaCreatorScreenType.ATRRIBUTE} />
                            <div className="formulaCreatorModal__content-right">
                                <div className="formulaCreatorModal__content-rightInput">
                                    <ChipsInput />
                                </div>
                                <div className="formulaCreatorModal__content-rightPreview">
                                    <div className="rightPreviewContent">
                                        <h2>Formula Preview</h2>
                                        <span>
                                            {previewData.join(EMPTY.string)}
                                        </span>
                                    </div>
                                </div>
                                <div className="formulaCreatorModal__content-rightOperators">
                                    {viewModelDataRedux ?
                                        <Popover
                                            placement="topRight"
                                            overlayClassName={viewModelRedux ? "formulaCreatorPopOver" : 'hideFormulaCreatorPopOver'}
                                            title={''}
                                            content={
                                                <NumberCustomPopover
                                                    hideInput={hideNumberInput}
                                                />
                                            }
                                            open={hideNumberPopover}
                                            onOpenChange={handleNumberPopoverChange}
                                        >
                                            <div className="operatorContainer__type">
                                                <button>Number</button>
                                            </div>
                                        </Popover>:null}
                                    {/* TO DO : will work on this when we have a support for String functions */}
                                    {/* <Popover
                                        placement="topRight"
                                        overlayClassName="formulaCreatorPopOver"
                                        title={''}
                                        content={
                                            <StringCustomPopover
                                                hideInput={hideStringInput}
                                            />
                                        }
                                        open={hideStringPopover}
                                        onOpenChange={handleStringPopoverChange}
                                    >
                                        <div className="operatorContainer__type">
                                            <button>String</button>
                                        </div>
                                    </Popover> */}
                                    <OperatorsLists />
                                    {formulaData?.ruleData?.length ?
                                        <div className='clearAllContainer'>
                                            <Popover
                                                placement="topLeft"
                                                overlayClassName="clearAllOverlay"
                                                title={''}
                                                content={
                                                    <ClearAllCustomPopover
                                                        hideInput={hideclearAll}
                                                    />
                                                }
                                                open={hideClearAllPopover}
                                                onOpenChange={
                                                    handleHideClearAllPopoverChange
                                                }
                                            >
                                                <button className="clear-all">
                                                    <span className="clear-all__text">
                                                        Clear all
                                                    </span>
                                                </button>
                                            </Popover>
                                        </div>:null}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="formulaCreatorModal__content-secondary">
                        <div className="formulaCreatorModal__content-secondaryMain">
                            <span className="formulaCreatorModal__content-frequencyTitle">
                                Frequency of Calculation :
                            </span>
                            <Select
                                labelInValue
                                placeholder="Select Frequency"
                                value={frequencyVal}
                                onChange={frequencyHandleChange}
                                options={[
                                    {
                                        value: frequencyIntervals['Real Time'],
                                        label: 'Real Time',
                                    },
                                    {
                                        value: frequencyIntervals['1 Minute'],
                                        label: '1 Minute',
                                    },
                                    {
                                        value: frequencyIntervals['5 Minutes'],
                                        label: '5 Minutes',
                                    },
                                    {
                                        value: frequencyIntervals['10 Minutes'],
                                        label: '10 Minutes',
                                    },
                                    {
                                        value: frequencyIntervals['30 Minutes'],
                                        label: '30 Minutes',
                                    },
                                    {
                                        value: frequencyIntervals['1 hour'],
                                        label: '1 hour',
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            </Modal>
            {isValidateOpen &&  <ConfirmationModal
                open={isValidateOpen}
                icon={<ConfirmationIcon/>}
                    onOk={() => {
                        onSaveHandler();
                    }}
                    onCancel={() => {
                        setIsValidateOpen(false);
                    }}
                    text="Would you like to Validate & Save?"
                    
            />}
            
            {
                isDraftOpen &&<ConfirmationModal
                open={isDraftOpen}
                icon={<ConfirmationIcon/>}
                    onOk={() => {
                        onDraftHandler();
                    }}
                    onCancel={() => {
                        setIsDraftOpen(false);
                    }}
                    text="Would you like to Save as Draft?"
                    
            />}
            
        </>
    );
};

export default FormulaCreatorModal;