import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import {
    getAllChannel,
    notificationStateAction,
} from 'redux/actions/NotificationConfiguration/notificationConfiguration';
import { useEffect } from 'react';
import { NOTIFICATIONTYPE } from 'types/enums';
import { Spin } from 'antd';
import { getUrlForNotificationService } from 'utils/commonFunction';
interface CommunicationChannelProps {
    setSelectedChannel: Function;
    selectedChannel: string;
}
const CommunicationChannel: React.FC<CommunicationChannelProps> = ({
    setSelectedChannel,
    selectedChannel,
}) => {
    const dispatch = useDispatch();
    const channelList = useSelector(
        (state: any) => state?.notificationConfiguration?.channelList
    );
    const isChannel = useSelector(
        (state: any) => state?.notificationConfiguration?.isChannel
    );
    useEffect(() => {
        dispatch(getAllChannel());
    }, []);

    return (
        <>
            <div className="communicationChannel">
                {isChannel ? (
                    <Spin />
                ) : (
                    <>
                        {channelList?.map((channel: any, index: any) => (
                            <div
                                key={channel?._id}
                                className={`communicationChannel__card ${
                                    selectedChannel === channel?.name &&
                                    'active'
                                }`}
                                onClick={() => {
                                    setSelectedChannel(channel?.name);
                                    dispatch(
                                        notificationStateAction(
                                            NOTIFICATIONTYPE.view
                                        )
                                    );
                                }}
                            >
                                <div className="communicationChannel__content">
                                    <img
                                        src={
                                            getUrlForNotificationService() +
                                            channel?.logo?.url
                                        }
                                    />
                                    <div className="communicationChannel__label">
                                        {channel?.name}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                )}
            </div>
        </>
    );
};
export default CommunicationChannel;
