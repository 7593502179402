import * as types from "redux/types/businessIntegratorTypes";

export const setConnectionsState = (payload: string): any => ({
  type: types.SET_CONNECTIONS_STATE,
  payload: payload,
});

export const getAdapterList = (): any => ({
  type: types.GET_ADAPTER_LIST,
});

export const getAllFileFormats = (): any => ({
  type: types.GET_ALL_FILE_FORMATS,
});

export const getAllDirectionsList = (): any => ({
  type: types.GET_ALL_DIRECTIONS_LIST,
});

export const getAllAuthMethodList = (): any => ({
  type: types.GET_ALL_AUTH_METHOD_LIST,
});

export const createConnections = (payload: any): any => ({
  type: types.CREATE_CONNECTIONS,
  payload: payload,
});

export const getConnectionsList = (payload: any): any => ({
  type: types.GET_CONNECTIONS_LIST,
  payload: payload,
});

export const getConnectionById = (payload: any): any => ({
  type: types.GET_CONNECTION_BY_ID,
  payload: payload,
});

export const updateConnection = (payload: any): any => ({
  type: types.UPDATE_CONNECTION,
  payload: payload,
});

export const isConnectionStreaming = (payload: any): any => ({
  type: types.CONNECTION_STREAMING,
  payload: payload,
});

export const clrearIsConnectionStreaming = (): any => ({
  type: types.CLEAR_CONNECTION_STREAMING,
});
