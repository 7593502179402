import { Card, Col, Row } from 'antd';
import ScreenNameHeading from 'components/common/ScreenNameHeading';
import './index.scss';
import UOMConfiguratorTable from './UomTable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getUOMTableList,
    setUomState,
} from 'redux/actions/ConfigureActions/attributeActions';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import CountAnalytics from 'components/common/CountAnalytics';
import { ReactComponent as ActiveUserIcon } from 'assets/icons/activeUserCountIcon.svg';
import { ReactComponent as DeactivateUserIcon } from 'assets/icons/deacticeUserCountIcon.svg';
import { EMPTY, UOMTYPE } from 'types/enums';
import CreateUom from './CreateUom';
import ViewUom from './ViewUom';
import { useTranslation } from 'react-i18next';

const UOMConfigurator: React.FC = () => {
    const dispatch = useDispatch();
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [sortOrder, setSortOrder] = useState<any>();
    const [sortColumn, setSortColumn] = useState<any>();
    const [searchState, setSearchState] = useState<any>(EMPTY.string);

    const { t } = useTranslation('translation');

    const [payload, setPayload] = useState({
        page,
        pageSize,
        status: 0,
    });

    useEffect(() => {
        setPayload({ ...payload, page, pageSize });
    }, [page, pageSize]);

    const isUomUpdateSuccess = useSelector(
        (state: any) => state?.configure?.attributes?.isUomUpdated
    );
    const tableData = useSelector(
        (state: any) => state.configure?.attributes?.uomTableList
    );

    const uomState = useSelector(
        (state: any) => state.configure?.attributes?.uomState
    );

    useEffect(() => {
        if (searchState === EMPTY.string)
            dispatch(
                getUOMTableList({
                    ...payload,
                    sortOrder: sortOrder,
                    sortColumn: sortColumn,
                })
            );
    }, [payload, isUomUpdateSuccess, sortOrder, sortColumn, searchState]);

    const getCountAnalyticsDetails = [
        {
            title: t('UomConfigurator.countAnalytics.ActiveUom'),
            count: tableData?.totalActiveUom
                ? `${tableData?.totalActiveUom}`
                : '0',
            icon: <ActiveUserIcon />,
        },
        {
            title: t('UomConfigurator.countAnalytics.InactiveUom'),
            count: tableData?.totalInActiveUom
                ? `${tableData?.totalInActiveUom}`
                : '0',
            icon: <DeactivateUserIcon />,
        },
    ];
    useEffect(() => {
        dispatch(setUomState(EMPTY.string));
    }, []);
    const getComponent = (): any => {
        switch (uomState) {
            case UOMTYPE.create:
                return (
                    <CreateUom
                        setSortOrder={setSortOrder}
                        setSortColumn={setSortColumn}
                        setSearchState={setSearchState}
                    />
                );
            case UOMTYPE.view:
                return (
                    <ViewUom
                        setSortOrder={setSortOrder}
                        setSortColumn={setSortColumn}
                        setSearchState={setSearchState}
                    />
                );
            case UOMTYPE.edit:
                return (
                    <ViewUom
                        setSortOrder={setSortOrder}
                        setSortColumn={setSortColumn}
                        setSearchState={setSearchState}
                    />
                );
            default:
                return (
                    <>
                        <div className="uomConfigurator__content">
                            <Card
                                bordered={false}
                                className="uomConfigurator__card"
                            >
                                <div className="uomConfigurator__data">
                                    <UOMConfiguratorTable
                                        data={tableData}
                                        payload={payload}
                                        setPage={setPage}
                                        page={page}
                                        setPageSize={setPageSize}
                                        pageSize={pageSize}
                                        setSortColumn={setSortColumn}
                                        setSortOrder={setSortOrder}
                                        setSearchState={setSearchState}
                                        searchState={searchState}
                                    />
                                </div>
                            </Card>
                        </div>
                    </>
                );
        }
    };

    return (
        <>
            <div className="uomConfigurator">
                <Row className="uomConfigurator__headerWrapper">
                    <Col span={16}>
                        <ScreenNameHeading
                            heading={t('UomConfigurator.screenHeading')}
                            subHeading={t('UomConfigurator.screenSubHeading')}
                        />
                    </Col>
                    <Col span={8}>
                        <CountAnalytics
                            countDetails={getCountAnalyticsDetails}
                        />
                    </Col>
                </Row>
                {getComponent()}
            </div>
        </>
    );
};
export default UOMConfigurator;
