import { TagMappingTablePopover } from 'types/enums';
import { useDispatch, useSelector } from 'react-redux';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteNew.svg';
import { useState } from 'react';
import { unMapTagNodes } from 'redux/actions/AssetModellingActions/assetModellingAction';
import { useTranslation } from 'react-i18next';

const TagMappingMoreContent: React.FC<any> = ({
    selectedRowIds,
    setPopoverVisible,
    onItemClick,
    setSelectedRowIds,
    record,
}) => {
    const [openRemoveMapping, setOpenRemoveMapping] = useState(false);
    const { t } = useTranslation('translation');
    const selectedAsset = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.selectedAsset
    );
    const dispatch = useDispatch();
    const values = [
        {
            title: TagMappingTablePopover?.removeMapping,
        },
    ];
    return (
        <>
            <div className="more-container">
                <div className={`more-content`}>
                    <ul>
                        {values &&
                            values.length > 0 &&
                            values.map((item: any) => (
                                <li
                                    key={item.title}
                                    className="moreContent__items"
                                >
                                    <span
                                        className="moreContent__option"
                                        onClick={() => {
                                            if (selectedRowIds?.length > 1) {
                                                onItemClick();
                                                setOpenRemoveMapping(true);
                                            } else {
                                                setPopoverVisible(false);
                                                setOpenRemoveMapping(true);
                                            }
                                        }}
                                    >
                                        {item.title}
                                    </span>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
            {openRemoveMapping && (
                <ConfirmationModal
                    icon={<DeleteIcon />}
                    open={openRemoveMapping}
                    onOk={() => {
                        dispatch(
                            unMapTagNodes({
                                tagIdList: selectedRowIds?.length
                                    ? selectedRowIds
                                    : [record],
                                nodeId: selectedAsset?.key,
                            })
                        );
                        setSelectedRowIds([]);
                        setOpenRemoveMapping(false);
                    }}
                    onCancel={() => {
                        setOpenRemoveMapping(false);
                    }}
                    text={t('assetModeller.modelOverview.removeTagsModalText')}
                    customClassName="confirmationModal modelDeletion"
                />
            )}
        </>
    );
};
export default TagMappingMoreContent;
