import { Card, Row, Col, Form, Input, Divider, Spin } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { ReactComponent as BackIcon } from 'assets/icons/backIcon.svg';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import CustomButton from 'components/common/CustomButton';
import { BUTTONTYPE, EMPTY } from 'types/enums';
import { type ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    CLOSE_SUB_MODEL_TABS,
    REMOVE_TOTAL_TAGS_COUNT,
} from 'redux/types/assetModellingTypes';
import {
    editModelDetails,
    getModelDetailsById,
} from 'redux/actions/AssetModellingActions/assetModellingAction';
import { parseJwt } from 'utils/jwtTokenFunction';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ModelInfo: React.FC<any> = ({
    editModel,
    setOpenModelInfo,
    setEditModel,
    recordDetails,
    setPage,
}) => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const details = parseJwt();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch({ type: REMOVE_TOTAL_TAGS_COUNT });
    }, []);
    const [uploadedProfileImage, setUploadedProfileImage] = useState<any>();
    useEffect(() => {
        recordDetails?.id && dispatch(getModelDetailsById(recordDetails?.id));
    }, []);
    const modelDetails = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.modelDetails
    );
    const modelDetailsLoading = useSelector(
        (state: any) =>
            state?.assetModelling?.modelOverview?.modelDetailsLoading
    );
    const [payload, setPayload] = useState({
        name: '',
        id: '',
        description: '',
        updatedBy: details?.username,
        createdBy: details?.username,
        isPrimary: false,
        umiIndex: 0,
        modelType: '',
    });
    const { name, description, modelType } = payload;
    const initialValues = {
        modelName: modelDetails?.name || EMPTY?.string,
        description: modelDetails?.description || EMPTY?.string,
        modelType:
            modelDetails?.modelType === 'ISA88_95'
                ? 'S88/95 Asset Hierarchy'
                : 'Flexible Asset Model',
    };
    useEffect(() => {
        setPayload({
            ...payload,
            name: modelDetails?.name,
            id: modelDetails?.id,
            description: modelDetails?.description,
            modelType: modelDetails?.modelType,
        });
    }, [modelDetails]);
    const editDetails = (): any => {
        dispatch(
            editModelDetails({
                ...payload,
                description: description,
                page: PAGE,
                pageSize: PAGE_SIZE,
                isPrimary: modelDetails?.isPrimary,
            })
        );
        setOpenModelInfo(false);
        setPage(PAGE);
        setEditModel(false);
        dispatch({
            type: CLOSE_SUB_MODEL_TABS,
        });
    };
    const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
    const handleDescriptionChange = (
        e: ChangeEvent<HTMLTextAreaElement>
    ): any => {
        const { value } = e.target;
        const isDisabled = value?.trimStart() !== value;
        setSaveButtonDisabled(isDisabled);
        setPayload((prevPayload) => ({
            ...prevPayload,
            description: value,
        }));
    };
    return (
        <>
            <div className="modelInfoWrapper">
                <Card bordered={false}>
                    <>
                        {modelDetailsLoading ? (
                            <div className="view__loader">
                                <Spin />
                            </div>
                        ) : (
                            <>
                                <Row>
                                    <Col
                                        span={24}
                                        onClick={() => {
                                            dispatch({
                                                type: CLOSE_SUB_MODEL_TABS,
                                            });
                                            setPage(PAGE);
                                            setOpenModelInfo(false);
                                            setEditModel(false);
                                            navigate(
                                                `/transform/contextualization/modelOverview`,
                                                {
                                                    replace: true,
                                                }
                                            );
                                        }}
                                        className="modelInfoWrapper__backIcon"
                                    >
                                        <BackIcon />
                                        <span className="modelInfoWrapper__backText">
                                            {t(
                                                'assetModeller.modelOverview.back'
                                            )}
                                        </span>
                                    </Col>
                                </Row>
                                <div
                                    className={
                                        editModel
                                            ? 'modelInfoWrapper__editDetails'
                                            : 'modelInfoWrapper__viewDetails'
                                    }
                                >
                                    <Form
                                        layout="vertical"
                                        className="modelInfoWrapper__form"
                                        initialValues={initialValues}
                                        onFinish={editDetails}
                                    >
                                        <Form.Item
                                            className="modelInfoWrapper__imageUploadContainer"
                                            valuePropName="fileList"
                                            name={'upload'}
                                        >
                                            <label
                                                className={
                                                    uploadedProfileImage
                                                        ? 'display-none'
                                                        : 'custom-file-upload'
                                                }
                                            >
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    disabled={!editModel}
                                                />
                                                <div className="upload-inner">
                                                    <PlusOutlined />
                                                    <span>
                                                        {t(
                                                            'assetModeller.modelOverview.uploadImage'
                                                        )}
                                                    </span>
                                                </div>
                                            </label>
                                            {uploadedProfileImage && (
                                                <>
                                                    <img
                                                        src={
                                                            uploadedProfileImage
                                                        }
                                                        alt="avatar"
                                                        className="image__container"
                                                    />
                                                    <div
                                                        className="remove__container"
                                                        onClick={() => {
                                                            setUploadedProfileImage(
                                                                ''
                                                            );
                                                        }}
                                                    >
                                                        <div className="remove">
                                                            <DeleteOutlined />
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </Form.Item>
                                        <Form.Item
                                            name="modelName"
                                            label={t(
                                                'assetModeller.modelOverview.modelName'
                                            )}
                                            required={true}
                                        >
                                            <Input
                                                className="modelInfoWrapper__input"
                                                disabled={true}
                                                value={name}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="description"
                                            label={t(
                                                'assetModeller.modelOverview.description'
                                            )}
                                            rules={[
                                                {
                                                    validator: async (
                                                        _,
                                                        value
                                                    ) => {
                                                        if (
                                                            value &&
                                                            value.trimStart() !==
                                                                value
                                                        ) {
                                                            throw new Error(
                                                                'Description cannot start with a space'
                                                            );
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            <TextArea
                                                value={description}
                                                disabled={!editModel}
                                                onChange={
                                                    handleDescriptionChange
                                                }
                                                onClick={() => {
                                                    setSaveButtonDisabled(
                                                        false
                                                    );
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="modelType"
                                            label={t(
                                                'assetModeller.modelOverview.modelType'
                                            )}
                                            required={true}
                                        >
                                            <Input
                                                className="modelInfoWrapper__input"
                                                disabled={true}
                                                value={modelType}
                                            />
                                        </Form.Item>
                                        {editModel && (
                                            <div className="modelInfoWrapper__button">
                                                <Divider />
                                                <div className="modelInfoWrapper__footerButtons">
                                                    <div className="modelInfoWrapper__cancelBtn">
                                                        <CustomButton
                                                            type={
                                                                BUTTONTYPE.cancel
                                                            }
                                                            disabled={false}
                                                            handleClick={() => {
                                                                dispatch({
                                                                    type: CLOSE_SUB_MODEL_TABS,
                                                                });
                                                                setOpenModelInfo(
                                                                    false
                                                                );
                                                                setEditModel(
                                                                    false
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="modelInfoWrapper__saveBtn">
                                                        <CustomButton
                                                            disabled={
                                                                saveButtonDisabled
                                                            }
                                                            type={
                                                                BUTTONTYPE.save
                                                            }
                                                            typeOfButton={
                                                                'submit'
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </Form>
                                </div>
                            </>
                        )}
                    </>
                </Card>
            </div>
        </>
    );
};
export default ModelInfo;
