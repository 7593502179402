import { Checkbox, Col, Divider, Form, Input, Row } from 'antd';
import './index.scss';
// import { ReactComponent as SendGridLogo } from 'assets/icons/sendGridFormLogo.svg';
import CustomButton from 'components/common/CustomButton';
import { BUTTONTYPE, INPUTTYPE, NOTIFICATIONTYPE } from 'types/enums';
import { useDispatch, useSelector } from 'react-redux';
import {
    createProviderConfiguration,
    getAllProviderConfig,
    getProviderConfigCreateFeildsById,
    notificationStateAction,
    testProviderConfiguration,
    updateProviderConfiguration,
} from 'redux/actions/NotificationConfiguration/notificationConfiguration';
import { useEffect, useState } from 'react';
import { ReactComponent as BackIcon } from 'assets/icons/LightArrowBack.svg';
import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmationIcon.svg';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import SuccessfulModal from 'components/common/Modals/SuccessfulModal';
import { cancelHandle, modalShow, okHandle } from 'utils/modalFunction';
import { useTranslation } from 'react-i18next';
import { getUrlForNotificationService } from 'utils/commonFunction';

const ProviderDetailsForm: React.FC<any> = (): any => {
    const [form] = Form.useForm();
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const [isFormFilled, setIsFormFilled] = useState(false);
    const [editConfigModalOpen, setEditConfigModalOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [onCancelClicked, setOnCancelClicked] = useState(false);
    const [requiredFields, setRequiredFields] = useState<any>({});

    const notificationState = useSelector(
        (state: any) => state?.notificationConfiguration?.notificationState
    );
    const testVerification = useSelector(
        (state: any) => state?.notificationConfiguration?.testVerification
    );
    const isProviderConfigureCreated = useSelector(
        (state: any) =>
            state?.notificationConfiguration?.isProviderConfigureCreated
    );

    const allProviderConfig = useSelector(
        (state: any) => state?.notificationConfiguration?.allProviderConfig
    );

    const providerFormDetail = useSelector(
        (state: any) =>
            state?.notificationConfiguration?.providerConfigCreateFields
    );
  
    const createProviderConfig = useSelector(
        (state: any) => state?.notificationConfiguration?.createProviderConfig
    );



    // Note : will use later
    // const updateProviderConfig = useSelector(
    //     (state: any) => state?.notificationConfiguration?.updateProviderConfig
    // );

    const isUpdated = useSelector(
        (state: any) => state?.notificationConfiguration?.isUpdated
    );

    useEffect(() => {
        if (providerFormDetail && Object.keys(providerFormDetail).length) {
            const varReq = Object.keys(providerFormDetail.configSchema).filter(
                (key) => providerFormDetail.configSchema[key].required
            );
            setRequiredFields(varReq);
        }
    }, [providerFormDetail, notificationState]);

    useEffect(() => {
        if (isProviderConfigureCreated && notificationState === NOTIFICATIONTYPE.create) {
            dispatch(notificationStateAction(NOTIFICATIONTYPE.edit));
            // dispatch(getProviderConfigUpdateFeildsById(provider?._id));
            dispatch(getProviderConfigCreateFeildsById(providerFormDetail?._id));
            dispatch(
                getAllProviderConfig({
                    providerName: providerFormDetail?.slug,
                    channelName: providerFormDetail.type,
                })
            );
        }
    }, [isProviderConfigureCreated]);

    useEffect(() => {
        if (providerFormDetail && allProviderConfig.length) {
            form.setFieldsValue(allProviderConfig[0]?.credentials || {});
        }
    }, [providerFormDetail, allProviderConfig, form]);

    const handleValuesChange = (): any => {
        const formValues = form.getFieldsValue();
        const allRequiredFieldsFilled = requiredFields?.every((key: any) => {
            const value = formValues[key];
            if (typeof value === 'boolean') {
                return true; // Checkbox is considered filled if it exists, regardless of true/false
            }
            if (typeof value === 'number') {
                return !isNaN(value); // Number is considered filled if it's not NaN
            }
            if (typeof value === 'string') {
                return value.trim() !== ''; // String is considered filled if it's non-empty
            }
            return value !== undefined && value !== null; // Generic check for any other types
        });
        setIsFormFilled(allRequiredFieldsFilled);
    };

    const mapSchemaToFormFields = (configSchema: any): any => {
        return Object?.keys(configSchema)?.map((key: any) => {
            const field = configSchema[key];
            const rules: any = [
                {
                    required: field.required,
                    message: `Please enter ${field?.description?.toLowerCase()}`,
                },
            ];
            // Add pattern rule if field type is 'number'
            if (field.type === INPUTTYPE.NUMBER) {
                rules.push({
                    pattern: /^\d+$/,
                    message: 'Only numbers are allowed',
                });
            }
            if (field.type === INPUTTYPE.EMAIL) {
                rules.push({
                    type: INPUTTYPE.EMAIL,
                    message: `${field.label} is not a valid email`
                });
            }
            if (field.type === 'password') {
                rules.push({
                    type: INPUTTYPE.PASSWORD,
                });
            }

            return {
                type:
                    field.type === INPUTTYPE.BOOLEAN
                        ? 'checkbox'
                        : field.type === INPUTTYPE.PASSWORD
                        ? 'password'
                        : 'input',
                label: field.label,
                name: key,
                placeholder: `Enter ${field.description}`,
                rules: rules,
                valuePropName: field.type === 'boolean' ? 'checked' : 'value',
            };
        });
    };

    const handleSubmit = (): any => {
        form.validateFields().then((formValues) => {
            const parsedValues = { ...formValues };
            Object.keys(providerFormDetail?.configSchema).forEach((key) => {
                if (
                    providerFormDetail?.configSchema[key]?.type ===
                    INPUTTYPE.NUMBER
                ) {
                    parsedValues[key] = Number(parsedValues[key]);
                }
            });

            if (notificationState === NOTIFICATIONTYPE.edit) {
                setIsModalOpen(true);
            } else {
                dispatch(notificationStateAction(NOTIFICATIONTYPE.create));

                dispatch(
                    createProviderConfiguration({
                        status: 'active',
                        channel: providerFormDetail?.type,
                        check: true,
                        credentials: parsedValues,
                        verificationTemplateMessage: '',
                        verificationTemplateId:
                            '2c4ab9dc-3e74-45ed-a0e5-f672ccf33b39',
                        name: providerFormDetail?.name,
                        slug: providerFormDetail?.slug,
                        organizationId: '66ba8c464ed22b0ea623099c',
                        providerId: providerFormDetail?._id,
                    })
                );
            }
        });
        setEditConfigModalOpen(false);
    };

    const onOkHandler = (): any => {
        if (notificationState === NOTIFICATIONTYPE.view) {
            setEditConfigModalOpen(false);
        } else {
            dispatch(notificationStateAction(NOTIFICATIONTYPE.edit));
            form.validateFields().then((formValues) => {
                const parsedValues = { ...formValues };
                Object.keys(providerFormDetail.configSchema).forEach((key) => {
                    if (
                        providerFormDetail.configSchema[key].type === 'number'
                    ) {
                        parsedValues[key] = Number(parsedValues[key]);
                    }
                });
                dispatch(
                    updateProviderConfiguration({
                        status: allProviderConfig[0]?.status,
                        _id: allProviderConfig[0]?._id,
                        active: true,
                        channel: allProviderConfig[0]?.channel,
                        credentials: parsedValues,
                        name: allProviderConfig[0]?.name,
                        organization: allProviderConfig[0]?.organization,
                        provider: allProviderConfig[0]?.provider,
                        createdAt: allProviderConfig[0]?.createdAt,
                        updatedAt: allProviderConfig[0]?.updatedAt,
                        __v: 0,
                        isVerified: allProviderConfig[0]?.isVerified,
                    })
                );
                dispatch(
                    getAllProviderConfig({
                        providerName: providerFormDetail?.slug,
                        channelName: providerFormDetail?.type,
                    })
                );
            });
            setIsModalOpen(false); // Close the ConfirmationModal
            // setEditConfigModalOpen(true); // Open the SuccessfulModal
        }
    };

    const onTestHandlerClick = (): any => {
        let providerConfigId;

        if (notificationState === NOTIFICATIONTYPE.create) {
            providerConfigId = createProviderConfig?._id;
        } else if (notificationState === NOTIFICATIONTYPE.edit) {
            providerConfigId = allProviderConfig[0]?._id;
        }
        dispatch(
            testProviderConfiguration({
                providerConfigId: providerConfigId,
                recipient:allProviderConfig[0]?.credentials?.recipient
            })
        );
    };
    useEffect(() => {
        if (onCancelClicked) {
            modalShow(isModalOpen, setIsModalOpen);
        }
    }, [onCancelClicked]);
    useEffect(() => {
        if (testVerification === true) {
            dispatch(notificationStateAction(NOTIFICATIONTYPE.view));
        }
    }, [testVerification]);

    useEffect(() => {
        // need this later
        // if () {
        //     modalShow(editConfigModalOpen, setEditConfigModalOpen);
        // }
        okHandle(isModalOpen, setIsModalOpen);
    }, []);
    useEffect(() => {
        isUpdated && modalShow(editConfigModalOpen, setEditConfigModalOpen);
    }, [isUpdated]);

    return (
        <>
            <div className="notification__tableWrapperPadding ">
                <div className="d-flex">
                    <BackIcon
                        className="attribute__backIcon"
                        onClick={() => {
                            dispatch(
                                notificationStateAction(NOTIFICATIONTYPE.view)
                            );
                        }}
                    />
                    <span className="fw-500 fs-16 attribute__assignText">
                        {t('NotificationConfigurator.back')}
                    </span>
                </div>
                <Col span={4}>
                    <div className="notification__TestBtn">
                        <CustomButton
                            // type={BUTTONTYPE.testEmail}
                            type={`Test ${
                                providerFormDetail.type
                                    .charAt(0)
                                    .toUpperCase() +
                                providerFormDetail.type.slice(1)
                            }`}
                            disabled={
                                notificationState === 'edit'
                                    ? false
                                    : !isProviderConfigureCreated
                            }
                            handleClick={() => {
                                onTestHandlerClick();
                            }}
                        />
                    </div>
                </Col>
            </div>
            <Divider />
            <div className="providerForm">
                <Row gutter={10} className="providerForm__card">
                    <Col span={12} className="providerForm__container">
                        <div className="providerForm__logo">
                            <img
                                src={
                                    getUrlForNotificationService() +
                                    providerFormDetail?.logo?.url
                                }
                            />
                        </div>

                        <Form
                            form={form}
                            layout="horizontal"
                            onValuesChange={handleValuesChange}
                            initialValues={
                                allProviderConfig[0]?.credentials || {}
                            }
                        >
                            {mapSchemaToFormFields(
                                providerFormDetail?.configSchema
                            )?.map((item: any, index: any) => {
                                switch (item.type) {
                                    case 'input':
                                        return (
                                            <Form.Item
                                                key={index}
                                                label={item.label}
                                                name={item.name}
                                                rules={item.rules}
                                            >
                                                <Input
                                                    placeholder={
                                                        item.placeholder
                                                    }
                                                />
                                            </Form.Item>
                                        );
                                    case 'checkbox':
                                        return (
                                            <>
                                                {/* {item.rules && (
                                                    <span className="error-asterisk">
                                                        *
                                                    </span>
                                                )} */}
                                                <Form.Item
                                                    className="checkbox"
                                                    key={index}
                                                    name={item.name}
                                                    valuePropName={
                                                        item.valuePropName
                                                    }
                                                    rules={item.rules}
                                                >
                                                    <Checkbox>
                                                        {item.label}
                                                    </Checkbox>
                                                </Form.Item>
                                            </>
                                        );
                                    case 'password':
                                        return (
                                            <Form.Item
                                                key={index}
                                                label={item.label}
                                                name={item.name}
                                                rules={item.rules}
                                            >
                                                <Input.Password
                                                    placeholder={
                                                        item.placeholder
                                                    }
                                                />
                                            </Form.Item>
                                        );
                                    default:
                                        return null;
                                }
                            })}
                            <ConfirmationModal
                                open={isModalOpen}
                                icon={<ConfirmationIcon />}
                                onOk={() => onOkHandler()}
                                onCancel={() => {
                                    cancelHandle(isModalOpen, setIsModalOpen);
                                    setOnCancelClicked(false);
                                }}
                                text={t(
                                    'NotificationConfigurator.confirmationMessage'
                                )}
                            />
                            <SuccessfulModal
                                open={editConfigModalOpen}
                                onOk={() => onOkHandler()}
                                onCancel={() => {
                                    // dispatch();
                                    cancelHandle(
                                        editConfigModalOpen,
                                        setEditConfigModalOpen
                                    );
                                }}
                                text={t(
                                    'NotificationConfigurator.successMessage'
                                )}
                            />
                        </Form>
                    </Col>
                </Row>
                <Divider />
                <div className="providerForm__footer">
                    <Row className="providerForm__btn">
                        <Col span={4} className="cancelBtn">
                            <CustomButton
                                type={BUTTONTYPE.cancel}
                                disabled={false}
                                handleClick={() => {
                                    dispatch(
                                        notificationStateAction(
                                            NOTIFICATIONTYPE.view
                                        )
                                    );
                                }}
                            />
                        </Col>
                        <Col span={4} className="saveBtn">
                            <CustomButton
                                type={BUTTONTYPE.saveConfiguration}
                                disabled={!isFormFilled}
                                handleClick={() => handleSubmit()}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    );
};

export default ProviderDetailsForm;
