// Formula Creator
export const SET_FOCUSED_INDEX = 'SET_FOCUSED_INDEX';
export const SET_SELECTED_ASSET = 'SET_SELECTED_ASSET';

export const GET_FOCUSED_INDEX = 'GET_FOCUSED_INDEX';
export const UPDATE_FORMULA = 'UPDATE_FORMULA';
export const UPDATE_FORMULA_SUCCESS = 'UPDATE_FORMULA_SUCCESS';
export const UPDATE_FREQUENCY='UPDATE_FREQUENCY'

export const CLEAR_FORMULA = 'CLEAR_FORMULA';
export const CLEAR_FORMULA_SUCCESS = 'CLEAR_FORMULA_SUCCESS';


export const VIEW_FORMULA_OPEN = 'VIEW_FORMULA_OPEN';
export const VIEW_FORMULA_CLOSE = 'VIEW_FORMULA_CLOSE';
export const LIST_ATTRIBUTES='LIST_ATTRIBUTES'
export const LIST_ATTRIBUTES_SUCCESS='LIST_ATTRIBUTES_SUCCESS'
export const LIST_ATTRIBUTES_FAILURE = 'LIST_ATTRIBUTES_FAILURE'
export const SHOW_LOADER_SUCCESS = 'SHOW_LOADER_SUCCESS';
export const LIST_TAGS = 'LIST_TAGS';
export const LIST_TAGS_SUCCESS = 'LIST_TAGS_SUCCESS'
export const LIST_TAGS_FAILURE = 'LIST_TAGS_FAILURE'
export const LIST_KPIS = 'LIST_KPIS';
export const LIST_KPIS_SUCCESS = 'LIST_KPIS_SUCCESS'
export const LIST_KPIS_FAILURE = 'LIST_KPIS_FAILURE'
export const LIST_ATTRIBUTES_WITHOUT_ASSET='LIST_ATTRIBUTES_WITHOUT_ASSET'
export const LIST_ATTRIBUTES_WITHOUT_ASSET_SUCCESS='LIST_ATTRIBUTES_WITHOUT_ASSET_SUCCESS'
export const LIST_ATTRIBUTES_WITHOUT_ASSET_FAILURE = 'LIST_ATTRIBUTES_WITHOUT_ASSET_FAILURE'

export const CREATE_FORMULA = 'CREATE_FORMULA';
export const CREATE_FORMULA_SUCCESS = 'CREATE_FORMULA_SUCCESS'
export const CREATE_FORMULA_FAILURE = 'CREATE_FORMULA_FAILURE'
export const FETCH_FORMULA = 'FETCH_FORMULA';
export const FETCH_FORMULA_SUCCESS = 'FETCH_FORMULA_SUCCESS'
export const FETCH_FORMULA_FAILURE = 'FETCH_FORMULA_FAILURE'

export const UPDATE_RULE_FORMULA = 'UPDATE_RULE_FORMULA'
export const UPDATE_RULE_FORMULA_SUCCESS = 'UPDATE_RULE_FORMULA_SUCCESS'
export const UPDATE_RULE_FORMULA_FAILURE = 'UPDATE_RULE_FORMULA_FAILURE'
export const UPDATE_FORMULA_FOR_KPI='UPDATE_FORMULA_FOR_KPI'