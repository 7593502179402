import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Cascader, Checkbox, Col, DatePicker, message, Row, Select } from 'antd';
import './index.scss';
import dayjs from 'dayjs';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import { ReactComponent as ResetIcon } from 'assets/icons/resetIconOrange.svg';
import {
    getAdapterList,
    getAllDirectionsList,
} from 'redux/actions/BusinessIntegratorActions/connectionsActions';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import MonitoringTable from './monitoringTable';
import { dateFormat } from 'types/enums';
import CustomButton from 'components/common/CustomButton';
import { getAllMonitoringList, getCreatedByList, getLabelsList, getMonitoringList } from 'redux/actions/BusinessIntegratorActions/monitoringActions';
import { useParams } from 'react-router-dom';
import { TabName } from 'types/enums/businessIntegratorEnum';
import { getAllMapping } from 'redux/actions/BusinessIntegratorActions/mappingActions';
import { getAllOperation } from 'redux/actions/BusinessIntegratorActions/operationActions';
import { getAllUsers } from 'redux/actions/UserManagementActions/usersAction';
import { useTranslation } from 'react-i18next';

const Monitoring: React.FC = (): any => {
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    const { currentTab } = useParams();
    const { RangePicker } = DatePicker;
    const { SHOW_CHILD } = Cascader;
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [sortOrder, setSortOrder] = useState<any>();
    const [sortColumn, setSortColumn] = useState<any>();
    const [startTime, setStartTime] = useState<any>(null);
    const [endTime, setEndTime] = useState<any>(null);
    const [startCreatedOn, setStartCreatedOn] = useState<any>(null);
    const [endCreatedOn, setEndCreatedOn] = useState<any>(null);
    const [direction, setDirection] = useState<any>();
    const [adapterType, setAdapterType] = useState<any>();
    const [reset, setReset] = useState(false);
    const [monitoringType, setMonitoringType] = useState<any>();
    const [labelIds, setLabelIds] = useState([]);
    const [operationIds, setOperationIds] = useState<any>([]);
    const [mappingIds, setMappingIds] = useState<any>([]);
    const [searchIds, setSearchIds] = useState<any>([]);
    const [userIds, setUserIds] = useState<any>([]);
    const [adapterDropdownList, setAdapterDropdownList] = useState([]);
    const [directionDropdownList, setDirectionDropdownList] = useState([]);

    const monitoringTableLoader = useSelector(
        (state: any) =>
            state.businessIntegrator.monitoring.monitoringTableLoader
    );
    const allMonitoringList = useSelector(
        (state: any) =>
            state.businessIntegrator.monitoring.allMonitoringList
    );
    const createdByList = useSelector(
        (state: any) =>
            state.businessIntegrator.monitoring.createdByList
    );
    const labelsList = useSelector(
        (state: any) => state.businessIntegrator.monitoring.labelsList?.records
    );
    const adapterList = useSelector(
        (state: any) => state.businessIntegrator.connections.adapterList
    );
    const allDirectionsList = useSelector(
        (state: any) => state.businessIntegrator.connections.allDirectionsList
    );
    const monitoringTableList = useSelector(
        (state: any) => state.businessIntegrator.monitoring.monitoringListData
    );
    const mappingDropdownList = useSelector(
        (state: any) => state.businessIntegrator.mapping.allMappingList
    );
    const operationDropdownList = useSelector(
        (state: any) => state.businessIntegrator.operation.allOperationList
    );
    useEffect(() => {
        dispatch(getAllMapping({ pageNum: -1 }));
        dispatch(getAllOperation({ pageNum: -1 }));
        dispatch(getAdapterList());
        dispatch(getAllDirectionsList());
        dispatch(getAllUsers());
        dispatch(getLabelsList({pageNum: -1, labelId: 4}));
        dispatch(getCreatedByList());
        dispatch(getAllMonitoringList());
    }, []);
    useEffect(() => {
        const adapterArr: any = adapterList?.map(
            (item: any) => {
                return {
                    value: item?.id,
                    label: item?.name,
                };
            })
        // adapterArr.unshift({
        //     value: "",
        //     label: "All"
        // })
        setAdapterDropdownList(adapterArr)
    }, [adapterList]);
    useEffect(() => {
        const directionArr: any = allDirectionsList?.map(
            (item: any) => {
                return {
                    value: item?.id,
                    label: item?.name,
                };
            })
        // directionArr.unshift({
        //     value: "",
        //     label: "All"
        // })
        setDirectionDropdownList(directionArr)
    }, [allDirectionsList]);
    useEffect(() => { 
        if(currentTab !== TabName.monitoring) {
            setPage(PAGE);
            setPageSize(PAGE_SIZE);
            setSortOrder(null);
            setSortColumn(null);
        }
    }, [currentTab]);

    useEffect(() => {
        dispatch(
            getMonitoringList({
                monitoringTypeId: monitoringType,
                startTime: Date.parse(startTime) || null,
                endTime: Date.parse(endTime) || null,
                startCreatedOn: Date.parse(startCreatedOn) || null,
                endCreatedOn: Date.parse(endCreatedOn) || null,
                directionId: direction,
                adapterId: adapterType,
                operationIds: operationIds?.toString(),
                mappingIds: mappingIds?.toString(),
                labelIds: labelIds?.toString(),
                searchName: searchIds?.toString(),
                userIds: userIds?.toString(),
                pageNum: page,
                pageSize: pageSize,
                sortOrder: sortOrder,
                sortColumn: sortColumn,
            })
        ) 
    }, [pageSize, page, sortOrder, sortColumn]);

    const onApply = (): void => {
        if((startTime && !endTime) || (endTime && !startTime) || (startCreatedOn && !endCreatedOn) || (endCreatedOn && !startCreatedOn)) {
           message.error("Please select both Start and End time")
        }
        else {
            dispatch(
                getMonitoringList({
                    monitoringTypeId: monitoringType,
                    startTime: Date.parse(startTime) || null,
                    endTime: Date.parse(endTime) || null,
                    startCreatedOn: Date.parse(startCreatedOn) || null,
                    endCreatedOn: Date.parse(endCreatedOn) || null,
                    directionId: direction,
                    adapterId: adapterType,
                    operationIds: operationIds?.toString(),
                    mappingIds: mappingIds?.toString(),
                    labelIds: labelIds?.toString(),
                    searchName: searchIds?.toString(),
                    userIds: userIds?.toString(),
                    pageNum: PAGE,
                    pageSize: PAGE_SIZE,
                })
            );
            setPage(PAGE);
            setPageSize(PAGE_SIZE);
            setSortOrder(null);
            setSortColumn(null);
        }
    };
    const dropdownRender: any = (menus: React.ReactNode, idList: any, setFunction: any, selectedList: any) => (
        <div>
            <Checkbox
                checked={selectedList?.length === idList?.length}
                indeterminate={selectedList?.length && selectedList?.length < idList?.length}
                onChange={(e) => {
                    e.target.checked && setFunction(idList);
                    !e.target.checked && setFunction([]);
                }} 
            >
                Select All
            </Checkbox>
            {menus}
        </div>
      );
    const resetFilter = (): void => {
        if (monitoringType ||
            adapterType || 
            operationIds?.length || 
            mappingIds?.length ||
            labelIds?.length || 
            searchIds?.length ||
            userIds?.length ||
            direction || 
            startTime || 
            endTime || 
            startCreatedOn ||
            endCreatedOn) {
                dispatch(
                    getMonitoringList({
                        pageNum: PAGE,
                        pageSize: PAGE_SIZE,
                    })
                )
            }
        setMonitoringType(null);
        setStartTime(null);
        setEndTime(null);
        setStartCreatedOn(null);
        setEndCreatedOn(null);
        setOperationIds([]);
        setMappingIds([]);
        setLabelIds([]);
        setSearchIds([]);
        setUserIds([]);
        setAdapterType(null);
        setDirection(null);
        setReset(!reset);
        setPage(PAGE);
        setPageSize(pageSize);
        setSortOrder(null);
        setSortColumn(null);
    };
    const filter = (inputValue: any, path: any): any =>
        path?.some((option: any): any =>
            (option?.label as string)
                ?.toLowerCase()
                ?.includes(inputValue?.toLowerCase())
        );
    const onOk = (value: any): void => {
        value[0] && setStartTime(dayjs(value[0], dateFormat.format));
        value[1] && setEndTime(dayjs(value[1], dateFormat.format));
    };
    const onCreatedOnOk = (value: any): void => {
        value[0] && setStartCreatedOn(dayjs(value[0], dateFormat.format));
        value[1] && setEndCreatedOn(dayjs(value[1], dateFormat.format));
    };
    return (
        <>
            <div className="attributeWrapper monitoringWrapper">
                <Card bordered={false}>
                    {monitoringTableList?.errorConnections +
                        monitoringTableList?.healthyConnections +
                        monitoringTableList?.healthyConnections +
                        monitoringTableList?.warningConnections >
                        0 || monitoringTableLoader ? (
                        <>
                            {
                                <>
                                    <Row className="attributeWrapper__filterHeader">
                                        <Col span={3}>
                                            <Select
                                                placeholder={"Monitoring Type"}
                                                options={[
                                                    {
                                                        value: 3,
                                                        label: "All"
                                                    },
                                                    {
                                                        value: 1,
                                                        label: "Connection"
                                                    },
                                                    {
                                                        value: 2,
                                                        label: "Message"
                                                    },
                                                ]}
                                                value={monitoringType}
                                                onChange={(value) => {
                                                    setMonitoringType(value);
                                                }}
                                            />
                                        </Col>
                                        <Col span={3}>
                                            <Select
                                                placeholder={"Adapter Type"}
                                                options={adapterDropdownList}
                                                value={adapterType}
                                                onChange={(value) => {
                                                    setAdapterType(value);
                                                }}
                                            />
                                        </Col>
                                        <Col span={3}>
                                            <Select
                                                placeholder={"Select Direction"}
                                                options={directionDropdownList}
                                                value={direction}
                                                onChange={(value) => {
                                                    setDirection(value);
                                                }}
                                            />
                                        </Col>
                                        <Col span={6}>
                                            <RangePicker
                                                allowClear={false}
                                                showTime
                                                onOk={onOk}
                                                value={[startTime, endTime]}
                                                placeholder={['Start Timestamp', 'End Timestamp']}
                                            />
                                        </Col>
                                        <Col span={3}>
                                            <Cascader
                                                popupClassName='selectAllCheckbox'
                                                key={`${reset}`}
                                                multiple
                                                maxTagCount="responsive"
                                                options={operationDropdownList?.map(
                                                    (item: any) => {
                                                        return {
                                                            value: item?.id,
                                                            label: item?.operationName,
                                                        };
                                                    }
                                                )}                                                
                                                onChange={(value: any) => {
                                                    setOperationIds(value?.flat());
                                                }}
                                                placeholder={"Operation Id"}
                                                dropdownRender={(menus) => dropdownRender(menus, operationDropdownList?.map((item:any) => item?.id), setOperationIds, operationIds)}
                                                showSearch={{ filter }}
                                                showCheckedStrategy={SHOW_CHILD}
                                                onSearch={(value) => {}}
                                                value={operationIds?.map((item: any) => {return ([item])})}
                                            />
                                        </Col>
                                        <Col span={3}>
                                            <Cascader
                                                popupClassName='selectAllCheckbox'
                                                key={`${reset}`}
                                                multiple
                                                maxTagCount="responsive"
                                                options={mappingDropdownList?.map(
                                                    (item: any) => {
                                                        return {
                                                            value: item?.id,
                                                            label: item?.mappingName,
                                                        };
                                                    }
                                                )}
                                                onChange={(value: any) => {
                                                    setMappingIds(value?.flat());
                                                }}
                                                placeholder={"Mapping Id"}
                                                dropdownRender={(menus) => dropdownRender(menus, mappingDropdownList?.map((item:any) => item?.id), setMappingIds, mappingIds)}
                                                showSearch={{ filter }}
                                                showCheckedStrategy={SHOW_CHILD}
                                                onSearch={(value) => {}}
                                                value={mappingIds?.map((item: any) => {return ([item])})}
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="attributeWrapper__filterAdvHeader">
                                        <Col span={3}>
                                            <Cascader
                                                popupClassName='selectAllCheckbox'
                                                key={`${reset}`}
                                                multiple
                                                maxTagCount="responsive"
                                                options={labelsList?.map((item: any) => {
                                                    return {
                                                        value: item?.id,
                                                        label: item?.name,
                                                    };
                                                })}
                                                onChange={(value: any) => {
                                                    setLabelIds(value?.flat());
                                                }}
                                                placeholder={"Select Label"}
                                                dropdownRender={(menus) => dropdownRender(menus, labelsList?.map((item:any) => item?.id), setLabelIds, labelIds)}
                                                showSearch={{ filter }}
                                                showCheckedStrategy={SHOW_CHILD}
                                                onSearch={(value) => {}}
                                                value={labelIds?.map((item: any) => {return ([item])})}
                                            />
                                        </Col>
                                        <Col span={3}>
                                            <Cascader
                                                popupClassName='selectAllCheckbox'
                                                key={`${reset}`}
                                                multiple
                                                maxTagCount="responsive"
                                                options={allMonitoringList?.map((item: any) => {
                                                    return {
                                                        value: item,
                                                        label: item,
                                                    };
                                                })}
                                                onChange={(value: any) => {
                                                    setSearchIds(value?.flat());
                                                }}
                                                placeholder={"Search Id"}
                                                dropdownRender={(menus) => dropdownRender(menus, allMonitoringList?.map((item:any) => item), setSearchIds, searchIds)}
                                                showSearch={{ filter }}
                                                showCheckedStrategy={SHOW_CHILD}
                                                onSearch={(value) => {}}
                                                value={searchIds?.map((item: any) => {return ([item])})}
                                            />
                                        </Col>
                                        <Col span={3}>
                                            <Cascader
                                                popupClassName='selectAllCheckbox'
                                                key={`${reset}`}
                                                multiple
                                                maxTagCount="responsive"
                                                options={createdByList?.map(
                                                    (item: any) => {
                                                        return {
                                                            value: item,
                                                            label: item,
                                                        };
                                                    }
                                                )}
                                                onChange={(value: any) => {
                                                    setUserIds(value?.flat());
                                                }}
                                                placeholder={"Created By"}
                                                dropdownRender={(menus) => dropdownRender(menus, createdByList?.map((item:any) => item), setUserIds, userIds)}
                                                showSearch={{ filter }}
                                                showCheckedStrategy={SHOW_CHILD}
                                                onSearch={(value) => {}}
                                                value={userIds?.map((item: any) => {return ([item])})}
                                            />
                                        </Col>
                                        <Col span={6}>
                                            <RangePicker
                                                allowClear={false}
                                                showTime
                                                onOk={onCreatedOnOk}
                                                value={[startCreatedOn, endCreatedOn]}
                                                placeholder={['Start Created On', 'End Created On']}
                                            />
                                        </Col>
                                        <Col span={2}>
                                            <CustomButton
                                                type="Apply"
                                                disabled={
                                                    !(adapterType || 
                                                    operationIds?.length || 
                                                    mappingIds?.length ||
                                                    searchIds?.length ||
                                                    labelIds?.length || 
                                                    userIds?.length ||
                                                    startCreatedOn ||
                                                    endCreatedOn ||
                                                    direction || 
                                                    startTime || 
                                                    endTime ||
                                                    monitoringType)
                                                }
                                                handleClick={onApply}
                                            />
                                        </Col>
                                        <Col span={1}>
                                            <span
                                                className="customHeader__child-resetButton"
                                                onClick={resetFilter}
                                            >
                                                <ResetIcon />
                                            </span>
                                        </Col>
                                    </Row>
                                </>
                            }
                            <MonitoringTable
                                setPage={setPage}
                                page={page}
                                setPageSize={setPageSize}
                                pageSize={pageSize}
                                setSortOrder={setSortOrder}
                                setSortColumn={setSortColumn}
                            />
                        </>
                    ) : (
                        <>
                            <div className="monitoringWrapper__noData">
                                <EmptyDataComponent
                                    textValue={t(
                                        'BusinessIntegrator.mapping.NoDataMonitoring'
                                    )}
                                    loading={monitoringTableLoader}
                                />
                            </div>
                        </>
                    )}
                </Card>
            </div>
        </>
    );
};

export default Monitoring;
