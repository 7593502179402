import { Card, Row, Col, Input, Spin } from 'antd';
import CustomButton from 'components/common/CustomButton';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import { EMPTY } from 'types/enums';
import NodeTable from './NodeTable';
import './index.scss';
import CustomPagination from 'components/common/CustomPagination';
import { useEffect, useState } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import AddNode from './AddNode';
import { useDispatch, useSelector } from 'react-redux';
import { getNodeTypeDetails } from 'redux/actions/AssetModellingActions/assetModellingAction';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import { useTranslation } from 'react-i18next';

const NodeType: React.FC = () => {
    const { t } = useTranslation('translation');
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
    const [addNodeForm, setAddNodeForm] = useState(false);
    const [editNode, setEditNode] = useState(false);
    const [openNodeInfo, setOpenNodeInfo] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [searchState, setSearchState] = useState<string>(EMPTY.string);
    const [tableSearchTimeout, setTableSearchTimeout] =
        useState<any>(undefined);

    const dispatch = useDispatch();
    const [payload, setPayload] = useState<any>({
        page: PAGE,
        pageSize: PAGE_SIZE,
    });

    useEffect(() => {
        setPayload((prevPayload: typeof payload) => ({
            ...prevPayload,
            page,
            pageSize,
        }));
    }, [page, pageSize]);

    const nodeData = useSelector(
        (state: any) => state.assetModelling?.nodeType?.nodeTypeListDetails
    );
    const nodeDataLoading = useSelector(
        (state: any) => state?.assetModelling?.nodeType?.nodeDataLoading
    );

    const handleSearchChange = (e: any): any => {
        const searchValue = e.target.value;
        setSearchState(searchValue);
        if (tableSearchTimeout) {
            clearTimeout(tableSearchTimeout);
        }

        if (searchValue === '') {
            setPage(PAGE);
            setPageSize(PAGE_SIZE);

            dispatch(
                getNodeTypeDetails({
                    ...payload,
                    name: '',
                    page: PAGE,
                    pageSize: PAGE_SIZE,
                })
            );
        } else {
            // Debouncing for search implemented
            setTableSearchTimeout(
                setTimeout(() => {
                    dispatch(
                        getNodeTypeDetails({
                            ...payload,
                            name: searchValue,
                            page: PAGE,
                        })
                    );
                }, 1000)
            );
        }
    };
    const [sortOrder, setSortOrder] = useState<number | null>(null);
    const [sortColumn, setSortColumn] = useState<number | null>(null);

    useEffect(() => {
        dispatch(
            getNodeTypeDetails({
                sortColumn: sortColumn,
                sortOrder: sortOrder,
                page: page,
                pageSize: pageSize,
                name: searchState ?? EMPTY?.string,
            })
        );
    }, [page, pageSize, sortOrder, sortColumn]);
    return (
        <>
            <div className="nodeOverviewWrapper">
                <Card bordered={false}>
                    <>
                        <Row>
                            <Col span={12}>
                                <Input
                                    allowClear
                                    className="createRolesWrapper__search"
                                    placeholder={t(
                                        'assetModeller.configuration.search'
                                    )}
                                    prefix={<SearchOutlined />}
                                    value={searchState}
                                    onChange={handleSearchChange}
                                />
                            </Col>
                            <Col span={12}>
                                <div className="nodeOverviewWrapper__addModelBtn">
                                    {nodeData?.records?.length === 0 ? null : (
                                        <CustomButton
                                            type={t(
                                                'assetModeller.modelOverview.addNode'
                                            )}
                                            disabled={false}
                                            handleClick={() => {
                                                setAddNodeForm(true);
                                                setOpenDrawer(true);
                                            }}
                                        />
                                    )}
                                </div>
                            </Col>
                        </Row>
                        {nodeData?.records?.length > 0 ? (
                            <>
                                {nodeDataLoading ? (
                                    <div className="view__loader">
                                        <Spin />
                                    </div>
                                ) : (
                                    <Row>
                                        <Col span={24}>
                                            <div className="nodeOverviewTable">
                                                <NodeTable
                                                    setEditNode={setEditNode}
                                                    setOpenNodeInfo={
                                                        setOpenNodeInfo
                                                    }
                                                    setOpenDrawer={
                                                        setOpenDrawer
                                                    }
                                                    data={
                                                        nodeData
                                                            ?.paginatedResponse
                                                            ?.records
                                                    }
                                                    payload={{
                                                        page,
                                                        pageSize,
                                                    }}
                                                    setSearchState={
                                                        setSearchState
                                                    }
                                                    search={searchState}
                                                    setPage={setPage}
                                                    setPageSize={setPageSize}
                                                    setSortColumn={
                                                        setSortColumn
                                                    }
                                                    setSortOrder={setSortOrder}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </>
                        ) : (
                            <div className="nodeOverviewWrapper__tableEmpty">
                                {searchState !== EMPTY.string ? (
                                    <EmptyDataComponent textValue="No Results, Try a different search item" />
                                ) : (
                                    <EmptyDataComponent
                                        textValue={t(
                                            'assetModeller.configuration.noNodeTypeText'
                                        )}
                                        buttonType={{
                                            name: 'New Node',
                                            disable: false,
                                        }}
                                        buttonClickHandler={() => {
                                            setAddNodeForm(true);
                                            setOpenDrawer(true);
                                        }}
                                    />
                                )}
                            </div>
                        )}
                    </>
                    {openDrawer && (
                        <AddNode
                            open={openDrawer}
                            setAddNodeForm={setAddNodeForm}
                            editNode={editNode}
                            openNodeInfo={openNodeInfo}
                            addNodeForm={addNodeForm}
                            setOpenDrawer={setOpenDrawer}
                            setOpenNodeInfo={setOpenNodeInfo}
                            setEditNode={setEditNode}
                            paginatedPayload={payload}
                            setSearchState={setSearchState}
                            setPage={setPage}
                            setPageSize={setPageSize}
                        />
                    )}
                </Card>
            </div>
            {nodeData?.totalRecords > PAGE_SIZE && (
                <CustomPagination
                    totalRecords={nodeData?.totalRecords}
                    setPage={setPage}
                    page={page}
                    setPageSize={setPageSize}
                    pageSize={pageSize}
                />
            )}
        </>
    );
};
export default NodeType;
