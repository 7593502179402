import * as types from 'redux/types/implementationTypes';

export const getAssignedTimeCapsuleList = (payload: any): any => ({
    type: types.GET_ASSIGNED_TIME_CAPSULE_LIST,
    payload: payload,
});

export const getUnassignedTimeCapsuleList = (payload: any): any => ({
    type: types.GET_UNASSIGNED_TIME_CAPSULE_LIST,
    payload: payload,
});

export const assignTimeCapsuleToAsset = (payload: any): any => ({
    type: types.ASSIGN_TIME_CAPSULE_TO_ASSET,
    payload: payload,
});

export const removeTimeCapsuleFromAsset = (payload: any): any => ({
    type: types.REMOVE_TIME_CAPSULE_FROM_ASSET,
    payload: payload,
});

export const getTagsList=(payload:any):any=>({
    type: types.GET_TAGS_LIST,
    payload: payload
})

export const getAttributesList=(payload:any):any=>({
    type: types.GET_ATTRIBUTES_LIST,
    payload: payload
})

export const addEditTimeCapsuleValidation=(payload:any):any=>({
    type: types.ADD_EDIT_TIME_CAPSULE_VALIDATION,
    payload: payload
})

export const getValidatedTimeCapsuleDetails=(payload:any):any=>({
    type: types.GET_VALIDATED_TIME_CAPSULE_DETAILS,
    payload: payload
})

export const getConditions=():any=>({
    type: types.GET_CONDITIONS
})