import EmptyDataComponent from 'components/common/EmptyDataComponent';
import { useEffect, useState, type FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAttributesForAsset,
    getAttributesWithoutAssest,
    updateFormulaAction,
} from 'redux/actions/FormulaCreatorActions/formulaCreatorActions';
import './index.scss';
import { Spin } from 'antd';
import { implementationAssignStatus } from 'types/enums';
import {
    formulaCreatorScreenType,
    ruleCRUDEnum,
    ruleCategoryEnum,
} from 'types/enums/FormulaCreatorEnum';

const AttributesListing: FC<any> = ({ attributeSearch, screen }) => {
    const attributesListsRedux = useSelector(
        (state: any) => state?.formulaCreator?.attributesList?.data
    );
    const attributesLoaderRedux = useSelector(
        (state: any) => state?.formulaCreator?.attributesList?.loader
    );
    const expressionArray = useSelector(
        (state: any) => state?.formulaCreator?.expressionArray
    );
    const [loader, setLoader] = useState<any>(attributesLoaderRedux);

    const assetRedux = useSelector(
        (state: any) => state?.formulaCreator?.viewMeta?.assetId
    );
    const selectedAssetRedux = useSelector(
        (state: any) => state?.formulaCreator?.selectedAsset
    );
    const focusedIndexRedux = useSelector(
        (state: any) => state?.formulaCreator?.focusedIndex
    );

    const dispatch = useDispatch();
    const [filteredListingData, setFilteredListingData] =
        useState<any>(attributesListsRedux);
    const operandHandler = (item: any): void => {
        const payload = {
            index: expressionArray.length?focusedIndexRedux:0,
            type: ruleCRUDEnum.UPDATE,
            details: {
                ruleCategoryId: ruleCategoryEnum.ATTRIBUTE,
                detail: {
                    assetId: selectedAssetRedux,
                    id: item?.id,
                    name: item?.name,
                },
            },
        };
        dispatch(updateFormulaAction(payload));
    };
    useEffect(() => {
        const attributesRequestPayload = {
            page: 0,
            assetId: selectedAssetRedux,
            assignStatus: implementationAssignStatus.ASSIGN,
            status: 1,
            isValidated:true
        };
        formulaCreatorScreenType.ATRRIBUTE === screen
            ? dispatch(getAttributesForAsset(attributesRequestPayload))
            : dispatch(getAttributesWithoutAssest({status:1}));
    }, [screen, assetRedux,selectedAssetRedux]);

    useEffect(() => {
        if (attributeSearch?.length) {
            const attributeList = attributesListsRedux;
            setFilteredListingData(
                attributeList.filter((ele: any) =>
                    ele?.name
                        .toLowerCase()
                        .includes(attributeSearch.toLowerCase())
                )
            );
        } else {
            setFilteredListingData(attributesListsRedux);
        }
    }, [attributeSearch, attributesListsRedux]);

    useEffect(() => {
        setLoader(attributesLoaderRedux);
    }, [attributesLoaderRedux]);

    useEffect(() => {
        setFilteredListingData(attributesListsRedux);
    }, []);

    return (
        <div className="attributesListing">
            <div
                className="attributesListing__container"
                style={{
                    justifyContent: !attributesListsRedux?.length
                        ? 'center'
                        : 'flex-start',
                    alignItems: !attributesListsRedux?.length
                        ? 'center'
                        : 'flex-start',
                }}
            >
                {!loader ? (
                    filteredListingData?.length ? (
                        filteredListingData?.map((item: any) => (
                            <span
                                key={item.id}
                                onClick={() => {
                                    operandHandler(item);
                                }}
                                className="attributesListing__container__dataItem"
                            >
                                {item.name}
                            </span>
                        ))
                    ) : (
                        <div className="noResultOnSearch">
                            <EmptyDataComponent textValue="Attributes not found" />
                        </div>
                    )
                ) : (
                    <Spin />
                )}
            </div>
        </div>
    );
};

export default AttributesListing;
