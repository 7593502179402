import * as types from 'redux/types/formulaCreationTypes';
export const getFocusedIndexAction = (): any => ({
    type: types.GET_FOCUSED_INDEX,
});
export const setFocusedIndexAction = (payload?: any): any => ({
    type: types.SET_FOCUSED_INDEX,
    payload: payload,
});
export const updateFormulaAction = (payload?: any): any => ({
    type: types.UPDATE_FORMULA,
     payload: payload,
})
export const clearFormulaAction = (payload?: any): any => ({
    type: types.CLEAR_FORMULA,
     payload: payload,
})
export const viewFormulaScreenOpenAction = (payload?: any): any => ({
    type: types.VIEW_FORMULA_OPEN,
    payload: payload,
})
export const viewFormulaScreenCloseAction = (): any => ({
    type: types.VIEW_FORMULA_CLOSE,
})

export const getAttributesForAsset = (payload?: any): any => ({
    type: types.LIST_ATTRIBUTES,
    payload: payload,
});

export const getAttributesWithoutAssest = (payload?: any): any => ({
    type: types.LIST_ATTRIBUTES_WITHOUT_ASSET,
    payload: payload,
});
export const getTagsForAsset = (payload: any): any => ({
    type: types.LIST_TAGS,
    payload: payload,
});
export const getKpisForAsset = (payload?: any): any => ({
    type: types.LIST_KPIS,
    payload: payload,
});

export const updateFrequencyAction = (payload?: any): any => ({
    type: types.UPDATE_FREQUENCY,
    payload: payload,
});

export const setSelectedAssetAction = (payload: any): any => ({
    type: types.SET_SELECTED_ASSET,
    payload:payload
})
export const createFormulaAction = (payload: any): any => ({
    type: types.CREATE_FORMULA,
    payload:payload
})
export const fetchFormulaAction = (payload: any): any => ({
    type: types.FETCH_FORMULA,
    payload:payload
})
export const updateRuleFormulaAction = (payload: any): any => ({
    type: types.UPDATE_RULE_FORMULA,
    payload:payload
})
export const updateFormulaForKPI = (payload: any): any => ({
    type: types.UPDATE_FORMULA_FOR_KPI,
    payload:payload
})
