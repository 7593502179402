import {
    Button,
    Col,
    Divider,
    Modal,
    Popover,
    Row,
    Spin,
    Switch,
    Table,
    Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { type ColumnsType } from 'antd/es/table';
import { MoreOutlined } from '@ant-design/icons';
import { attribute, EMPTY, sortOrder } from 'types/enums';
import { DATE_FORMAT, PAGE } from 'utils/constants';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import { ReactComponent as ActiveDotIcon } from 'assets/icons/activeDot.svg';
import { ReactComponent as WarningDotIcon } from 'assets/icons/warningDot.svg';
import { ReactComponent as InActiveDotIcon } from 'assets/icons/connectionInActiveDot.svg';
import { ReactComponent as ErrorDotIcon } from 'assets/icons/inactiveDot.svg';
import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmationIcon.svg';
import { ReactComponent as ErrorIcon } from 'assets/icons/errorIcon.svg';
import { ReactComponent as SuccessIcon } from 'assets/icons/successIcon.svg';
import CustomPagination from 'components/common/CustomPagination';
import { cancelHandle, modalShow } from 'utils/modalFunction';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import MoreContent from './MoreContent';
import {
    ColumnLength,
    ConnectionsState,
    ConnectionsStatus,
    ConnectionTableColumnSort,
    CreateConnectionScreen,
} from 'types/enums/businessIntegratorEnum';
import {
    clrearIsConnectionStreaming,
    isConnectionStreaming,
    setConnectionsState,
} from 'redux/actions/BusinessIntegratorActions/connectionsActions';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import SuccessfulModal from 'components/common/Modals/SuccessfulModal';

const ConnectionTable: React.FC<any> = ({
    setPage,
    page,
    setPageSize,
    pageSize,
    setSortOrder,
    setSortColumn,
    setSearchState,
}): any => {
    const { t } = useTranslation('translation');

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<any>([]);
    const [popoverVisible, setPopoverVisible] = useState<
        Record<string, boolean>
    >({});
    const [isValidatedModalOpen, setIsValidatedModalOpen] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState<any>({});
    const [isStreamingErrorModalOpen, setIsStreamingErrorModalOpen] =
        useState(false);
    const [isDeactivateSuccessModalOpen, setIsDeactivateSuccessModalOpen] =
        useState(false);
    const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
    const [deactivateSuccessModalOpen, setDeactivateSuccessModalOpen] =
        useState(false);

    const connectionsTableList = useSelector(
        (state: any) => state.businessIntegrator.connections.connectionsList
    );
    const connectionsTableLoader = useSelector(
        (state: any) =>
            state.businessIntegrator.connections.connectionTableLoader
    );
    const isConnectionStreamingOn = useSelector(
        (state: any) =>
            state.businessIntegrator.connections.isConnectionStreamingOn
    );
    const isConnectionStreamingDeactivate = useSelector(
        (state: any) =>
            state.businessIntegrator.connections.isConnectionStreamingDeactivate
    );
    const isConnectionStreamingCode = useSelector(
        (state: any) =>
            state.businessIntegrator.connections.isConnectionStreamingCode
    );
    const isConnectionStreamingMessage = useSelector(
        (state: any) =>
            state.businessIntegrator.connections.isConnectionStreamingMessage
    );

    const tableDataMapper = (): [] => {
        const temp: any = [];
        connectionsTableList?.paginatedResponse?.records?.map(
            (item: any, index: number) => {
                temp.push({
                    ...item,
                    key: item.id,
                });
            }
        );
        return temp;
    };
    useEffect(() => {
        setTableData(tableDataMapper());
    }, [connectionsTableList]);

    const onActivateSwitchHandlerClicked = (
        checked: boolean,
        data: any
    ): void => {
        if (!data.streamingOn) {
            setSelectedRowData(data);
            modalShow(isActivateModalOpen, setIsActivateModalOpen);
        } else {
            setSelectedRowData(data);
            modalShow(
                isDeactivateSuccessModalOpen,
                setIsDeactivateSuccessModalOpen
            );
        }
    };

    const onActivateClickHandler = (): any => {
        dispatch(
            isConnectionStreaming({
                connectionId: selectedRowData.id,
                switchStream: true,
            })
        );
        setIsValidatedModalOpen(false);
        setSelectedRowData({});
        cancelHandle(isActivateModalOpen, setIsActivateModalOpen);
    };

    useEffect(() => {
        if (isConnectionStreamingOn === true) {
            modalShow(isValidatedModalOpen, setIsValidatedModalOpen);
            dispatch(clrearIsConnectionStreaming());
        }
    }, [isConnectionStreamingOn]);

    useEffect(() => {
        if (isConnectionStreamingOn === 100) {
            modalShow(isStreamingErrorModalOpen, setIsStreamingErrorModalOpen);
        }
    }, [isConnectionStreamingOn]);

    useEffect(() => {
        if (isConnectionStreamingDeactivate === true) {
            modalShow(
                deactivateSuccessModalOpen,
                setDeactivateSuccessModalOpen
            );
            dispatch(clrearIsConnectionStreaming());
        }
    }, [isConnectionStreamingDeactivate]);
    const handlePopoverVisibleChange = (
        visible: boolean,
        key: string
    ): void => {
        setPopoverVisible((prevState) => ({
            ...prevState,
            [key]: visible,
        }));
    };

    const SearchNoDataText = (
        <EmptyDataComponent
            textValue={t('BusinessIntegrator.connections.searchNoDataText')}
            loading={connectionsTableLoader}
        />
    );

    const getConnectionStatus = (status: any): any => {
        switch (status) {
            case ConnectionsStatus.warning:
                return <WarningDotIcon />;
            case ConnectionsStatus.healthy:
                return <ActiveDotIcon />;
            case ConnectionsStatus.error:
                return <ErrorDotIcon />;
            default:
                return <InActiveDotIcon />;
        }
    };

    const onSorting = (columnName: any, sortOrder: any): any => {
        setPage(PAGE);
        setSearchState(EMPTY.string);
        setSortColumn(columnName);
        setSortOrder(sortOrder);
    };

    const columns: ColumnsType<any> = [
        {
            title: '',
            key: 'more',
            width: 26,
            render: (_: any, record: any) => {
                return (
                    <>
                        <div>
                            <Popover
                                visible={popoverVisible[record.key]}
                                onVisibleChange={(visible) => {
                                    handlePopoverVisibleChange(
                                        visible,
                                        record.key
                                    );
                                }}
                                placement="bottomLeft"
                                trigger="click"
                                content={
                                    <MoreContent
                                        record={record}
                                        setPopoverVisible={setPopoverVisible}
                                    />
                                }
                                overlayStyle={{ width: '162px' }}
                            >
                                <MoreOutlined
                                    onClick={() => {
                                        handlePopoverVisibleChange(
                                            true,
                                            record.key
                                        );
                                    }}
                                />
                            </Popover>
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.connectionName'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    ConnectionTableColumnSort.connectionName,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    ConnectionTableColumnSort.connectionName,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            className: 'column__name',
            width: '110px',
            render: (_: any, data: any) => {
                const truncatedText =
                    data?.name?.length > ColumnLength.name
                        ? data?.name?.substring(
                              CreateConnectionScreen.adapterScreen,
                              ColumnLength.name
                          ) + '...'
                        : data?.name;
                return (
                    <>
                        <div
                            className="attributeTableWrapper__nameData"
                            onClick={() => {
                                dispatch(
                                    setConnectionsState(
                                        ConnectionsState.viewConnections
                                    )
                                );
                                navigate(
                                    `/connect/business-integrator/connections/view/${data.key}`
                                );
                            }}
                        >
                            {data?.name?.length >
                            attribute.attributeNameLength ? (
                                <div className="blaTableWrapper__status">
                                    {getConnectionStatus(
                                        data?.connectionStatus
                                    )}
                                    <Popover
                                        overlayClassName="popoverWidth"
                                        content={data?.name}
                                        trigger="hover"
                                        placement="topLeft"
                                    >
                                        <span className="fs-14 fw-500 name">
                                            {truncatedText}
                                        </span>
                                    </Popover>
                                </div>
                            ) : (
                                <div className="blaTableWrapper__status">
                                    {getConnectionStatus(
                                        data?.connectionStatus
                                    )}
                                    <span className="fs-14 fw-500 name">
                                        {data.name}
                                    </span>
                                </div>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.adapterType'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    ConnectionTableColumnSort.adapterType,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    ConnectionTableColumnSort.adapterType,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            width: '100px',
            dataIndex: 'adapterType',
            key: 'adapterType',
            className: 'column__properties',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {data?.adapter?.name}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.description'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    ConnectionTableColumnSort.description,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    ConnectionTableColumnSort.description,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'description',
            key: 'description',
            width: '210px',
            className: 'column__description',
            render: (_: any, data: any) => {
                const truncatedText =
                    data?.description?.length > ColumnLength.description
                        ? data?.description?.substring(
                              CreateConnectionScreen.adapterScreen,
                              ColumnLength.description
                          ) + '...'
                        : data?.description;
                return (
                    <>
                        <div className="attributeTableWrapper__subItems">
                            <span className="fs-14 fw-500 name">
                                {data?.description?.length >
                                ColumnLength.description ? (
                                    <Popover
                                        overlayClassName="popoverWidth"
                                        content={data?.description}
                                        trigger="hover"
                                        placement="topLeft"
                                    >
                                        {truncatedText}
                                    </Popover>
                                ) : (
                                    data?.description
                                )}
                            </span>
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.direction'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    ConnectionTableColumnSort.direction,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    ConnectionTableColumnSort.direction,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'direction',
            key: 'direction',
            className: 'column__properties',
            width: '70px',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {data?.direction?.name}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.activate'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    ConnectionTableColumnSort.streamingSupported,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    ConnectionTableColumnSort.streamingSupported,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'activate',
            key: 'activate',
            width: '65px',
            className: 'column__uom',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        <Switch
                            size="small"
                            key={data.key}
                            onClick={(checked: boolean) => {
                                onActivateSwitchHandlerClicked(checked, data);
                            }}
                            value={data.streamingOn}
                        />
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t('BusinessIntegrator.connections.tableColumn.label')}
                    </div>
                    <div className="sortArrows"></div>
                </div>
            ),
            dataIndex: 'label',
            key: 'label',
            width: '67px',
            className: 'column__valueType',
            render: (_: any, data: any) => {
                const array: string[] = [];
                data?.labelResponse?.map((item: any) => {
                    array?.push(item?.name);
                });
                const maxLength = 18;
                const dataLength = 200;
                const joinedArray = array?.join(', ');
                let truncatedData = joinedArray;
                let remainingData: string[] = [];
                if (joinedArray?.length > maxLength) {
                    const ellipsisLength = 3;
                    const remainingLength = maxLength - ellipsisLength;
                    truncatedData =
                        joinedArray.substring(0, remainingLength) + '...';
                    const lastCommaIndex = truncatedData?.lastIndexOf(',');
                    if (
                        lastCommaIndex !== -1 &&
                        lastCommaIndex < remainingLength
                    ) {
                        const remainingString = truncatedData?.substring(
                            lastCommaIndex + 1,
                            remainingLength
                        );
                        remainingData = [
                            remainingString +
                                joinedArray
                                    ?.substring(remainingLength)
                                    ?.split(',')[0],
                            ...joinedArray
                                ?.substring(remainingLength)
                                ?.split(',')
                                ?.slice(1),
                        ];
                    } else {
                        remainingData = joinedArray
                            ?.substring(remainingLength)
                            ?.split(',');
                    }
                }
                return (
                    <div className="timeCapsuleTableWrapper__subItems">
                        <span>{truncatedData}</span>
                        {remainingData?.length > 0 && (
                            <Tooltip
                                overlayClassName={
                                    remainingData?.join(', ')?.length >
                                    dataLength
                                        ? 'monitoringDivToolTip'
                                        : 'monitoringToolTip'
                                }
                                title={joinedArray}
                                color={'white'}
                            >
                                <span className="timeCapsuleTable__subItems">
                                    +{remainingData?.length}
                                </span>
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
        {
            key: 'createdOn',
            className: 'column__addedOn',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.createdOn'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            onClick={() => {
                                onSorting(
                                    ConnectionTableColumnSort.createdOn,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            onClick={() => {
                                onSorting(
                                    ConnectionTableColumnSort.createdOn,
                                    sortOrder.ascending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'createdOn',
            width: '85px',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {moment(record.createdOn).format(DATE_FORMAT)}
                    </div>
                </>
            ),
        },
        {
            key: 'createdBy',
            className: 'column__addedOn',
            width: '82px',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.createdBy'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            onClick={() => {
                                onSorting(
                                    ConnectionTableColumnSort.createdBy,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            onClick={() => {
                                onSorting(
                                    ConnectionTableColumnSort.createdBy,
                                    sortOrder.ascending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'createdBy',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {record.createdBy}
                    </div>
                </>
            ),
        },
        {
            key: 'changedOn',
            width: '82px',
            className: 'column__addedOn',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.changedOn'
                        )}
                    </div>
                    <div className="sortArrows"></div>
                </div>
            ),
            dataIndex: 'changedOn',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {moment(record.updatedOn).format(DATE_FORMAT)}
                    </div>
                </>
            ),
        },
        {
            key: 'changedBy',
            width: '82px',
            className: 'column__addedOn',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.changedBy'
                        )}
                    </div>
                    <div className="sortArrows"></div>
                </div>
            ),
            dataIndex: 'changedBy',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {record.updatedBy}
                    </div>
                </>
            ),
        },
    ];

    return (
        <>
            {connectionsTableLoader ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                <>
                    <Row
                        className={
                            connectionsTableList?.paginatedResponse
                                ?.totalRecords > 50
                                ? 'attributeWrapper__attributeListPagination'
                                : 'attributeWrapper__attributeList'
                        }
                    >
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={tableData}
                                pagination={false}
                                loading={connectionsTableLoader}
                                scroll={{
                                    y:
                                        connectionsTableList?.paginatedResponse
                                            ?.totalRecords > 50
                                            ? 'calc(100vh - 390px)'
                                            : 'calc(100vh - 360px)',
                                    x: '2000px',
                                }}
                                locale={{ emptyText: SearchNoDataText }}
                            />
                        </Col>
                    </Row>
                    {connectionsTableList?.paginatedResponse?.totalRecords >
                    50 ? (
                        <CustomPagination
                            totalRecords={
                                connectionsTableList?.paginatedResponse
                                    ?.totalRecords
                            }
                            setPage={setPage}
                            page={page}
                            setPageSize={setPageSize}
                            pageSize={pageSize}
                        />
                    ) : (
                        EMPTY.string
                    )}
                </>
            )}
            <ConfirmationModal
                open={isActivateModalOpen}
                onCancel={() => {
                    cancelHandle(isActivateModalOpen, setIsActivateModalOpen);
                }}
                onOk={() => {
                    onActivateClickHandler();
                }}
                icon={<ConfirmationIcon />}
                text={'Are you sure you want to activate the Connection?'}
            />
            <Modal
                className="validateTagModal validateModal connections confirmationModal"
                open={isValidatedModalOpen}
                title={'Test Result Area'}
                destroyOnClose={true}
                onOk={() => {}}
                centered
                afterClose={() => {
                    cancelHandle(isValidatedModalOpen, setIsValidatedModalOpen);
                    dispatch(clrearIsConnectionStreaming());
                }}
                onCancel={() => {
                    cancelHandle(isValidatedModalOpen, setIsValidatedModalOpen);
                    dispatch(clrearIsConnectionStreaming());
                    setSortColumn(EMPTY.string);
                    setSortOrder(EMPTY.string);
                }}
                okText="OK"
                footer={[
                    <Button
                        key="ok"
                        type="primary"
                        className="okBtn fw-400 fs-14"
                        onClick={() => {
                            cancelHandle(
                                isValidatedModalOpen,
                                setIsValidatedModalOpen
                            );
                            dispatch(clrearIsConnectionStreaming());
                            setSortColumn(EMPTY.string);
                            setSortOrder(EMPTY.string);
                        }}
                    >
                        Ok
                    </Button>,
                ]}
            >
                <SuccessIcon />
                <div className="success_message">Success Code:200</div>
                <div>
                    <div className="validatedModalText fw-400 fs-16">
                        The Sender adapter has been tested successfully and it
                        is working fine.
                    </div>
                    <Divider className="divider" />
                </div>
            </Modal>
            <Modal
                className="validateTagModal validateModal confirmationModal connections"
                open={isStreamingErrorModalOpen}
                title={'Test Result Area'}
                destroyOnClose={true}
                onOk={() => {}}
                centered
                afterClose={() => {
                    cancelHandle(
                        isStreamingErrorModalOpen,
                        setIsStreamingErrorModalOpen
                    );
                    dispatch(clrearIsConnectionStreaming());
                }}
                onCancel={() => {
                    cancelHandle(
                        isStreamingErrorModalOpen,
                        setIsStreamingErrorModalOpen
                    );
                    dispatch(clrearIsConnectionStreaming());
                }}
                okText="Ok"
                footer={[
                    <Button
                        key="ok"
                        type="primary"
                        className="okBtn fw-400 fs-14"
                        onClick={() => {
                            cancelHandle(
                                isStreamingErrorModalOpen,
                                setIsStreamingErrorModalOpen
                            );
                            dispatch(clrearIsConnectionStreaming());
                        }}
                    >
                        Ok
                    </Button>,
                ]}
            >
                <ErrorIcon />
                <div className="error-message">
                    Error Code:{isConnectionStreamingCode}
                </div>
                <div>
                    <div className="validatedModalErrorText fw-400 fs-16">
                        {isConnectionStreamingMessage}
                    </div>
                    <Divider className="divider" />
                </div>
            </Modal>
            <ConfirmationModal
                open={isDeactivateSuccessModalOpen}
                onCancel={() => {
                    cancelHandle(
                        isDeactivateSuccessModalOpen,
                        setIsDeactivateSuccessModalOpen
                    );
                }}
                onOk={() => {
                    dispatch(
                        isConnectionStreaming({
                            connectionId: selectedRowData.id,
                            switchStream: false,
                        })
                    );
                    cancelHandle(
                        isDeactivateSuccessModalOpen,
                        setIsDeactivateSuccessModalOpen
                    );
                    setSelectedRowData({});
                }}
                icon={<ConfirmationIcon />}
                text={
                    'Connection is not in use by operations.Press yes to deactivate the connection'
                }
            />
            <SuccessfulModal
                open={deactivateSuccessModalOpen}
                onOk={() => {
                    cancelHandle(
                        deactivateSuccessModalOpen,
                        setDeactivateSuccessModalOpen
                    );
                }}
                onCancel={() => {
                    cancelHandle(
                        deactivateSuccessModalOpen,
                        setDeactivateSuccessModalOpen
                    );
                    setSortColumn(EMPTY.string);
                    setSortOrder(EMPTY.string);
                }}
                text="Connection Deactivated Successfully!"
            />
        </>
    );
};

export default ConnectionTable;
