import { Drawer, Row, Col, Button, Divider, Form, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './index.scss';
import CustomButton from 'components/common/CustomButton';
import { BUTTONTYPE, EMPTY, EquipmentState } from 'types/enums';
import {
    putEquipmentClassUpdate,
    setEquipmentState,
} from 'redux/actions/AssetModellingActions/assetModellingAction';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import { useTranslation } from 'react-i18next';

const EditEquipmentClass: React.FC<any> = ({
    open,
    setSearchState,
    setPage,
    setPageSize,
}): any => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState<any>({});
    const [name, setName] = useState<string>(EMPTY.string);
    const [description, setDescription] = useState<string | null>(null);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const { t } = useTranslation('translation');
    const equipmentClassId = useSelector(
        (state: any) => state.assetModelling?.equipment?.equipmentClassById
    );

    useEffect(() => {
        if (equipmentClassId) {
            setInitialValues({
                name: equipmentClassId?.name || EMPTY.string,
                description: equipmentClassId?.description || EMPTY.string,
            });
            setName(equipmentClassId?.name || EMPTY.string);
            setDescription(equipmentClassId?.description || null);
        }
    }, [equipmentClassId]);

    const resetFormValues = useCallback(() => {
        form.setFieldsValue(initialValues);
        setName(initialValues.name);
        setDescription(initialValues.description);
    }, [form, initialValues]);

    useEffect(() => {
        if (open) {
            resetFormValues();
        }
    }, [open, resetFormValues]);

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>): any => {
        setName(e.target.value);
        checkChanges({ name: e.target.value, description });
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>): any => {
        const value = e.target.value.trim();
        setDescription(value === EMPTY.string ? null : value);

        checkChanges({ name, description: value === EMPTY.string ? null : value });
    };

    // Function to check if there are any changes & length of name field
    const checkChanges = (values: {
        name: string;
        description: string | null;
    }): any => {
        setIsSaveEnabled(
            (values?.name !== initialValues?.name ||
                values?.description !== initialValues?.description) &&
                values?.name?.trim()?.length > 0
        );
    };

    return (
        <>
            <Drawer
                className="editClassDrawer"
                placement="right"
                size="default"
                getContainer={false}
                closable={false}
                open={open}
            >
                <>
                    <div>
                        <Row>
                            <Col
                                span={20}
                                className="editClassDrawer__heading fw-500 fs-16"
                            >
                                <span>
                                    {t(
                                        'assetModeller.configuration.editEquipmentClass'
                                    )}
                                </span>
                            </Col>
                            <Col span={4}>
                                <Button
                                    className="editClassDrawer__icon"
                                    type="text"
                                    onClick={() => {
                                        dispatch(
                                            setEquipmentState(
                                                EquipmentState?.display
                                            )
                                        );
                                    }}
                                    icon={<CloseOutlined />}
                                ></Button>
                            </Col>
                        </Row>
                        <Divider className="editClassDrawer__divider" />
                        <Form
                            layout="vertical"
                            form={form}
                            initialValues={initialValues}
                        >
                            <Form.Item
                                name="name"
                                label={t(
                                    'assetModeller.configuration.equipmentClassName'
                                )}
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Equipment Class Name is required',
                                    },
                                    {
                                        validator: async (_, value) => {
                                            if (
                                                value &&
                                                value.trimStart() !== value
                                            ) {
                                                throw new Error(
                                                    'Equipment Class Name cannot start with a space'
                                                );
                                            }
                                        },
                                    },
                                ]}
                            >
                                <Input
                                    className="editClassDrawer__inputField"
                                    disabled={false}
                                    value={name}
                                    onChange={handleNameChange}
                                />
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label={t(
                                    'assetModeller.configuration.description'
                                )}
                                required={false}
                            >
                                <Input
                                    className="editClassDrawer__inputField"
                                    disabled={false}
                                    value={description ?? EMPTY.string}
                                    onChange={handleDescriptionChange}
                                />
                            </Form.Item>
                            <div className="editClassDrawer__button">
                                <Divider />
                                <Row className="editClassDrawer__footerButtons">
                                    <Col
                                        span={8}
                                        className="editClassDrawer__cancelButton"
                                    >
                                        <CustomButton
                                            type={BUTTONTYPE?.cancel}
                                            disabled={false}
                                            handleClick={() => {
                                                dispatch(
                                                    setEquipmentState(
                                                        EquipmentState?.display
                                                    )
                                                );
                                            }}
                                        />
                                    </Col>
                                    <Col
                                        span={8}
                                        className="editClassDrawer__SaveButton"
                                    >
                                        <CustomButton
                                            disabled={!isSaveEnabled}
                                            type={BUTTONTYPE?.save}
                                            typeOfButton={'submit'}
                                            handleClick={() => {
                                                dispatch(
                                                    putEquipmentClassUpdate({
                                                        id: equipmentClassId?.id,
                                                        name: name,
                                                        description:
                                                            description ?? EMPTY.string, 
                                                        categories: [],
                                                        page: PAGE,
                                                        pageSize: PAGE_SIZE,
                                                    })
                                                );
                                                setPage(PAGE);
                                                setPageSize(PAGE_SIZE);
                                                setSearchState(EMPTY.string);
                                                dispatch(
                                                    setEquipmentState(
                                                        EquipmentState?.display
                                                    )
                                                );
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </div>
                </>
            </Drawer>
        </>
    );
};
export default EditEquipmentClass;
