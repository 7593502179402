import './index.scss';
import { OperationState } from 'types/enums/businessIntegratorEnum';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteNew.svg';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { deleteOperation, getOperationDetailsById, setOperationState } from 'redux/actions/BusinessIntegratorActions/operationActions';

const MoreContent: React.FC<{
    record: any;
    setPopoverVisible: Function;
}> = ({ record }) => {
    const dispatch = useDispatch();
    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const values = [
        {
            title: 'View',
            disable: false,
        },
        {
            title: 'Edit',
            disable: false,
        },
        {
            title: 'Copy',
            disable: false,
        },
        {
            title: 'Delete',
            disable: false,
        },
    ];

    // To hide delete functionality from inactive mapping
    if (record?.isActive) {
        values.splice(3, 1);
        values.splice(1, 1);
    }

    const handleClick = (viewType: any): any => {
        if (viewType === 'View') {
            dispatch(setOperationState(OperationState.viewOperation));
            dispatch(getOperationDetailsById({operationId:record?.id}));
        } else if (viewType === 'Copy') {
            dispatch(setOperationState(OperationState.copyOperation));
            dispatch(getOperationDetailsById({operationId:record?.id}));
        } else if (viewType === 'Delete') {
            setDeleteModalOpen(true);
        } else {
            dispatch(setOperationState(OperationState.editOperation));
            dispatch(getOperationDetailsById({operationId:record?.id}));
        }
    };

    return (
        <>
            <div className="more-container">
                <div className={`more-content`}>
                    <ul>
                        {values?.map((item: any) => {
                            return (
                                <>
                                    <li
                                        key={item.title}
                                        className={`moreContent__items  ${
                                            item.disable ? 'display-none' : null
                                        }`}
                                        onClick={() => {
                                            handleClick(item.title);
                                        }}
                                    >
                                        <span className="moreContent__icon">
                                            {item.icon}
                                        </span>
                                        <span className="moreContent__option">
                                            {item.title}
                                        </span>
                                    </li>
                                </>
                            );
                        })}
                    </ul>
                </div>
            </div>
            {deleteModalOpen && (
                <ConfirmationModal
                    icon={<DeleteIcon />}
                    open={deleteModalOpen}
                    onOk={() => {
                        dispatch(
                            deleteOperation({
                                operationIds: [record?.id],
                                requestedBy: loggedInUserDetails?.firstName +
                                    loggedInUserDetails?.lastName,
                            })
                        );
                        setDeleteModalOpen(false);
                    }}
                    onCancel={() => {
                        setDeleteModalOpen(false);
                    }}
                    text={"Would you like to delete the selected operation ?"}
                    customClassName="confirmationModal modelDeletion"
                />
            )}
        </>
    );
};

export default MoreContent;
