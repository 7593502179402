import React, { useEffect } from 'react';
import {
    Card,
    Cascader,
    Col,
    Divider,
    Form,
    Input,
    Row,
    Select,
    Switch,
} from 'antd';
import './index.scss';
import { useSelector } from 'react-redux';
import {
    ALERTTYPE,
    EMPTY,
    alertActionType,
    alertNonAggregated,
} from 'types/enums';
import CustomSelectWithTab from './CustomDropdownWithTabs';
import { handleKeyPressForNumberInput } from 'utils/commonFunction';
import { useTranslation } from 'react-i18next';

const AlertStepThree: React.FC<any> = ({
    index,
    conditions,
    setConditions,
    submittable,
    setSubmittable,
    deleted,
    setDeleted,
}) => {
    const [form] = Form.useForm();
    const { t } = useTranslation('translation');
    const durationList = useSelector((state: any) => state.alert?.durationList);
    const { SHOW_CHILD } = Cascader;
    const { TextArea } = Input;
    const calculationCycleList = useSelector(
        (state: any) => state.alert?.calculationCycleList
    );
    const notificationChannelList = useSelector(
        (state: any) => state.alert?.notificationChannelList
    );

    const alertState = useSelector((state: any) => state?.alert?.alertState);

    const cascaderOptions = notificationChannelList?.map((item: any) => ({
        value: item.id,
        label: item.name.toString(),
    }));
    const values = Form.useWatch([], form);
    useEffect(() => {
        form.resetFields();
    }, [deleted]);

    useEffect(() => {
        const submittableArr = [...submittable];
        form.validateFields()
            .then(() => {
                submittableArr[index] = true;
            })
            .catch(() => {
                submittableArr[index] = false;
            });
        index && submittableArr[index - 1] && setSubmittable(submittableArr);
        !index && setSubmittable(submittableArr);
    }, [form, values, submittable[index - 1], conditions]);

    const filter = (inputValue: any, path: any): any =>
        path?.some((option: any): any =>
            (option?.label as string)
                ?.toLowerCase()
                ?.includes(inputValue?.toLowerCase())
        );

    const aggregationTypeItems = [
        {
            label: 'Aggregated',
            key: alertActionType.Aggregated,
            value: alertActionType.Aggregated,
        },
        {
            label: 'Non-Aggregated',
            key: alertActionType.NonAggregated,
            value: alertActionType.NonAggregated,
        },
    ];
    const initialValues = {
        aggregationType: conditions[index]?.aggregateId,
        nonAggregatedFrequency: conditions[index]?.frequency,
        aggregationTypeValue: conditions[index]?.durationId,
        notificationChannel: cascaderOptions
            ?.filter((option: any) =>
                conditions[index]?.notificationChannelId?.includes(option.value)
            )
            .map((option: any) => [option.value]),
    };
    const handleAggregationChange = (value: any): void => {
        form.resetFields(['aggregationTypeValue']);
        form.resetFields(['nonAggregatedFrequency']);
        const conditionArray = [...conditions];
        conditionArray[index] = {
            ...conditionArray[index],
            aggregateId: value,
            durationId:
                value === alertActionType.NonAggregated
                    ? alertNonAggregated.Seconds
                    : null,
        };
        setConditions(conditionArray);
    };

    const handleNonAgrregatedUnitChange = (value: any): void => {
        // setNonAggregatedUnitType(value);
        const conditionArray = [...conditions];
        conditionArray[index] = {
            ...conditionArray[index],
            durationId: value,
        };
        setConditions(conditionArray);
    };

    const usersList = useSelector(
        (state: any) => state.userManagement.users.allUsersList
    );

    const rolesList = useSelector(
        (state: any) => state.userManagement.roles.allRolesList
    );

    return (
        <>
            <div className="createAlertWrapper__clearTextDiv">
                {index !== 0 && alertState !== ALERTTYPE?.view && (
                    <>
                        <div
                            className="deleteText"
                            onClick={() => {
                                const conditionArray = [...conditions];
                                conditionArray.splice(index, 1);
                                setConditions(conditionArray);
                                const submittableArr = [...submittable];
                                submittableArr.splice(index, 1);
                                setSubmittable(submittableArr);
                                setDeleted(!deleted);
                            }}
                        >
                            {t('alerts.delete')}
                        </div>
                        <Divider type="vertical"></Divider>
                    </>
                )}
                {alertState !== ALERTTYPE?.view && (
                    <div
                        className="clearText"
                        onClick={() => {
                            const conditionArray = [...conditions];
                            conditionArray[index] = {
                                isEnableNotification: false,
                            };
                            setConditions(conditionArray);
                            setDeleted(!deleted);
                            form.resetFields();
                        }}
                    >
                        {t('alerts.clear')}
                    </div>
                )}
            </div>
            <Card className="createAlertWrapper__innerCard">
                <Form form={form} initialValues={initialValues}>
                    <div className="createAlertWrapper__stepThreeWrapper">
                        <Form.Item
                            className="createAlertWrapper__descriptionInput"
                            name="prescriptiveAction"
                        >
                            <TextArea
                                disabled={alertState === ALERTTYPE?.view}
                                placeholder={t('alerts.enterPersAction')}
                                maxLength={5000}
                                onChange={(e: any) => {
                                    const conditionArray = [...conditions];
                                    conditionArray[index] = {
                                        ...conditionArray[index],
                                        prescriptive: e.target.value,
                                    };
                                    setConditions(conditionArray);
                                }}
                                defaultValue={conditions[index]?.prescriptive}
                            ></TextArea>
                        </Form.Item>

                        <Row className="createAlertWrapper__stepThreeInfo">
                            <Col span={5}>
                                <Form.Item
                                    className="createAlertWrapper__selectDropdown"
                                    name="aggregationType"
                                    rules={[
                                        {
                                            required: true,
                                            message: EMPTY.string,
                                        },
                                    ]}
                                >
                                    <Select
                                        disabled={
                                            alertState === ALERTTYPE?.view
                                        }
                                        placeholder={t('alerts.selectAggType')}
                                        options={aggregationTypeItems}
                                        className="createAlertWrapper__selectDropdown"
                                        value={conditions[index]?.aggregateId}
                                        defaultValue={
                                            conditions[index]?.aggregateId
                                        }
                                        onChange={handleAggregationChange}
                                        getPopupContainer={(triggerNode) =>
                                            triggerNode.parentElement
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                span={5}
                                className="createAlertWrapper__aggregatedCol"
                            >
                                {conditions[index]?.aggregateId ===
                                alertActionType.Aggregated ? (
                                    <Form.Item
                                        className="createAlertWrapper__selectDropdown"
                                        name="aggregationTypeValue"
                                        rules={[
                                            {
                                                required: true,
                                                message: EMPTY.string,
                                            },
                                        ]}
                                    >
                                        <Select
                                            disabled={
                                                alertState === ALERTTYPE?.view
                                            }
                                            placeholder={t(
                                                'alerts.selectDuration'
                                            )}
                                            filterOption={(input, option) =>
                                                (option?.label as string)
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    )
                                            }
                                            defaultValue={
                                                conditions[index].durationId
                                            }
                                            options={calculationCycleList?.map(
                                                (item: {
                                                    id: string;
                                                    name: string;
                                                }) => ({
                                                    value: item?.id,
                                                    label: item?.name,
                                                    key: item?.id,
                                                })
                                            )}
                                            onChange={(
                                                value: any,
                                                item: any
                                            ) => {
                                                const conditionArray = [
                                                    ...conditions,
                                                ];
                                                conditionArray[index] = {
                                                    ...conditionArray[index],
                                                    durationId: value,
                                                };
                                                setConditions(conditionArray);
                                            }}
                                            className="createAlertWrapper__selectDropdown"
                                            getPopupContainer={(triggerNode) =>
                                                triggerNode.parentElement
                                            }
                                        />
                                    </Form.Item>
                                ) : (
                                    <>
                                        <Form.Item
                                            name="nonAggregatedFrequency"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: EMPTY.string,
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="createAlertWrapper__aggregatedInput"
                                                onKeyPress={
                                                    handleKeyPressForNumberInput
                                                }
                                                disabled={
                                                    alertState ===
                                                    ALERTTYPE?.view
                                                }
                                                onChange={(e: any) => {
                                                    const conditionArray = [
                                                        ...conditions,
                                                    ];
                                                    conditionArray[index] = {
                                                        ...conditionArray[
                                                            index
                                                        ],
                                                        frequency:
                                                            e.target.value,
                                                    };
                                                    setConditions(
                                                        conditionArray
                                                    );
                                                }}
                                                defaultValue={
                                                    conditions[index].frequency
                                                }
                                                value={
                                                    conditions[index].frequency
                                                }
                                            />
                                        </Form.Item>

                                        <Select
                                            disabled={
                                                alertState === ALERTTYPE?.view
                                            }
                                            placeholder={t('alerts.selectUnit')}
                                            value={conditions[index].durationId}
                                            defaultValue={
                                                alertNonAggregated.Seconds
                                            }
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label as string)
                                                    .toLowerCase()
                                                    .includes(
                                                        input.toLowerCase()
                                                    )
                                            }
                                            options={durationList?.map(
                                                (item: {
                                                    id: string;
                                                    name: string;
                                                }) => ({
                                                    value: item?.id,
                                                    label: item?.name,
                                                    key: item?.id,
                                                })
                                            )}
                                            onChange={
                                                handleNonAgrregatedUnitChange
                                            }
                                            className="createAlertWrapper__selectDropdownAggregated"
                                            getPopupContainer={(triggerNode) =>
                                                triggerNode.parentElement
                                            }
                                        />
                                    </>
                                )}
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    className="createAlertWrapper__selectDropdown"
                                    name="userRoleSelection"
                                >
                                    <CustomSelectWithTab
                                        placeholder={t('alerts.selectUserGrp')}
                                        className="createAlertWrapper__selectDropdown"
                                        conditions={conditions}
                                        setConditions={setConditions}
                                        index={index}
                                        usersList={usersList}
                                        rolesList={rolesList}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item
                                    className="createAlertWrapper__selectDropdown"
                                    name="notificationChannel"
                                    rules={[
                                        {
                                            required: true,
                                            message: EMPTY.string,
                                        },
                                    ]}
                                >
                                    <Cascader
                                        multiple
                                        disabled={
                                            alertState === ALERTTYPE?.view
                                        }
                                        options={cascaderOptions}
                                        showSearch={{ filter }}
                                        placeholder={t('alerts.selectMedium')}
                                        defaultValue={cascaderOptions
                                            .filter((option: any) =>
                                                conditions[
                                                    index
                                                ]?.notificationChannelId?.includes(
                                                    option.value
                                                )
                                            )
                                            .map((option: any) => [
                                                option.value,
                                            ])}
                                        maxTagCount="responsive"
                                        showCheckedStrategy={SHOW_CHILD}
                                        className="createAlertWrapper__selectCascader"
                                        onChange={(value: any, item: any) => {
                                            const conditionArray = [
                                                ...conditions,
                                            ];
                                            conditionArray[index] = {
                                                ...conditionArray[index],
                                                notificationChannelId:
                                                    value.flatMap(
                                                        (innerArray: any) =>
                                                            innerArray
                                                    ),
                                            };
                                            setConditions(conditionArray);
                                        }}
                                        getPopupContainer={(triggerNode) =>
                                            triggerNode.parentElement
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={3} className="notificationWrapper">
                                <Form.Item
                                    className="createAlertWrapper__selectDropdown"
                                    name="notificationBoolean"
                                >
                                    <div className="notificationBoolean">
                                        {t('alerts.notfication')}
                                        <span className="switchSpan">
                                            <Switch
                                                disabled={
                                                    alertState ===
                                                    ALERTTYPE?.view
                                                }
                                                // Will add logic later on
                                                onChange={(
                                                    checked: boolean
                                                ) => {
                                                    const conditionArray = [
                                                        ...conditions,
                                                    ];
                                                    conditionArray[index] = {
                                                        ...conditionArray[
                                                            index
                                                        ],
                                                        isEnableNotification:
                                                            checked,
                                                    };
                                                    setConditions(
                                                        conditionArray
                                                    );
                                                }}
                                                defaultChecked={
                                                    conditions[index]
                                                        ?.isEnableNotification
                                                }
                                            />
                                        </span>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </Card>
            <div className="createAlertWrapper__verticalButtonsSecondDiv" />
        </>
    );
};

export default AlertStepThree;
