import React, { useEffect } from 'react';
import { Tabs } from 'antd';
import { type TabItemProps } from 'types/interfaces/PropsInterfaces';
import './index.scss';
import { useDispatch } from 'react-redux';
import {
    setAddBlaState,
    setBlaId,
    setDeviceId,
} from 'redux/actions/DeviceManagementActions/blasAction';
import { setAddDeviceState } from 'redux/actions/DeviceManagementActions/deviceAction';
import { useNavigate } from 'react-router-dom';
import {
    CLOSE_SUB_MODEL_TABS,
    REMOVE_TOTAL_TAGS_COUNT,
} from 'redux/types/assetModellingTypes';
const TabsComponent: React.FC<TabItemProps> = ({
    tabItem,
    setTabKey,
    setActiveTabKey,
    activeTabKey,
    customClassName,
    onTabClick,
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    useEffect(() => {
        if (setTabKey) {
            setActiveTabKey(setTabKey);
        }
    }, [setTabKey]);

    const onChange = (key: string): void => {
        setActiveTabKey(key);
        dispatch(setAddBlaState('view'));
        dispatch(setBlaId(null));
        dispatch(setDeviceId(null));
        dispatch(setAddDeviceState(false));
    };
    const tabClick = (key: any): any => {
        if (key === 'modelOverview') {
            dispatch({ type: CLOSE_SUB_MODEL_TABS });
            navigate(`/transform/contextualization/${key}`, {
                replace: true,
            });
            dispatch({ type: REMOVE_TOTAL_TAGS_COUNT });
        }
        if (key === 'configuration') {
            dispatch({ type: REMOVE_TOTAL_TAGS_COUNT });
        }
        if (onTabClick) {
            onTabClick();
        }
    };
    return (
        <div
            className={customClassName ? `${customClassName}` : 'tabsComponent'}
        >
            <Tabs
                activeKey={activeTabKey}
                onChange={onChange}
                items={tabItem}
                onTabClick={tabClick}
            />
        </div>
    );
};

export default TabsComponent;
