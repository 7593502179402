import { Button, Col, Form, Input, Row, Table } from 'antd';
import { ReactComponent as BackIcon } from 'assets/icons/backIcon.svg';
import CustomButton from 'components/common/CustomButton';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { executeMapping } from 'redux/actions/BusinessIntegratorActions/mappingActions';
import { EMPTY } from 'types/enums';
import { useTranslation } from 'react-i18next';

const TestMapping: React.FC<any> = ({
    form,
    setCreateMappingProgressCount,
    setIsTestMapping,
}: any): any => {
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');

    const [testInputField, setTestInputField] = useState<any>({});
    const [data, setData] = useState<any>([]);
    const [isExecusteMappingBtnDisabled, setIsExecusteMappingBtnDisabled] =
        useState(true);

    const isMappingExecuted = useSelector(
        (state: any) => state.businessIntegrator.mapping.isMappingExecuted
    );

    const mappingFields = Form.useWatch('mappingFields', form);
    const braboTableName = Form.useWatch('braboTableName', form);

    const tableDataMapper = (): any[] => {
        const temp: any[] = [];
        mappingFields?.forEach((item: any, index: number) => {
            if (item?.destinationField.id !== EMPTY.string) {
                temp.push({
                    ...item,
                    key: index + 1,
                    jsonField: item.jsonField,
                    testInput: '',
                    testOutput: '',
                    mappedOutputField: item?.destinationField?.name,
                });
            }
        });
        return temp;
    };

    const columns = [
        {
            title: t('BusinessIntegrator.mapping.tableColumn.fieldName'),
            dataIndex: 'jsonField',
            key: 'jsonField',
        },
        {
            title: t('BusinessIntegrator.mapping.tableColumn.testInput'),
            dataIndex: 'testInput',
            key: 'testInput',
            render: (_: any, record: any) => (
                <Input
                    className="fieldInput"
                    value={record.testInput}
                    onChange={(e) => {
                        handleFieldChange(e.target.value, record.key);
                    }}
                />
            ),
        },
    ];

    const mappedOutputField = [
        {
            title: t('BusinessIntegrator.mapping.tableColumn.testOutput'),
            dataIndex: 'testOutput',
            key: 'testOutput',
            render: (_: any, record: any) => (
                <Input
                    className="fieldOutput"
                    value={record.testOutput}
                    disabled
                />
            ),
        },
        {
            title: t('BusinessIntegrator.mapping.tableColumn.mappedOutputField'),
            dataIndex: 'mappedOutputField',
            key: 'mappedOutputField',
        },
    ];

    const handleFieldChange = (value: any, key: any): any => {
        const newData = data?.map((item: any) => {
            if (item.key === key) {
                return { ...item, testInput: value };
            }
            return item;
        });
        setData(newData);
        const newSelectedOptions = { ...testInputField, [key]: value };
        setTestInputField(newSelectedOptions);
    };

    useEffect(() => {
        const newData = tableDataMapper();
        setData(newData);
    }, [mappingFields]);

    const handleExecuteTest = (): any => {
        const jsonColumnValueMapping = data?.map((item: any) => {
            return {
                fieldName: item.jsonField,
                columnId: item.destinationField.id,
                value: item.testInput,
            };
        });
        const queryParams: any = {
            tableId: braboTableName,
            jsonColumnValueMapping: jsonColumnValueMapping,
        };

        dispatch(executeMapping(queryParams));
    };

    useEffect(() => {
        let newData: any = [];
        if (isMappingExecuted) {
            newData = data?.map((item: any) => {
                return { ...item, testOutput: item.testInput };
            });
            setData(newData);
        }
    }, [isMappingExecuted]);

    const handleClearAll = (): any => {
        const newData = data?.map((item: any) => {
            return {
                ...item,
                testInput: EMPTY.string,
                testOutput: EMPTY.string,
            };
        });
        setData(newData);
    };

    useEffect(() => {
        const allFieldsMapped = data?.some(
            (item: any) => item.testInput !== EMPTY.string
        );
        setIsExecusteMappingBtnDisabled(!allFieldsMapped);
    }, [data]);

    return (
        <>
            <Row className="adapterList__header">
                <Col className="adapterList__backIcon" span={0.5}>
                    <BackIcon
                        onClick={() => {
                            setIsTestMapping(false);
                            setCreateMappingProgressCount(2);
                        }}
                    />
                </Col>
                <Col className="adapterList__title fw-400 fs-16" span={18}>
                {t('BusinessIntegrator.mapping.mappingDetails.testMappingScreen')}
                </Col>
                <Col span={5} className="clearAll">
                    <Button
                        type="primary"
                        ghost
                        className="addNewButton"
                        onClick={() => {
                            handleClearAll();
                        }}
                    >
                    {t('BusinessIntegrator.mapping.mappingDetails.clearAll')}
                    </Button>
                </Col>
            </Row>
            <div
                className={
                    'adapterList__content addMapping testMappingContainer'
                }
            >
                <Form className="highcharts-credits" form={form}>
                    <Form.Item
                        name="mappingFields"
                        className="highcharts-credits"
                    ></Form.Item>
                    <Form.Item
                        name="braboTableName"
                        className="highcharts-credits"
                    ></Form.Item>
                </Form>
                <Row className="testMapping" gutter={30}>
                    <Col span={10}>
                        <Table
                            columns={columns}
                            bordered
                            dataSource={data}
                            pagination={false}
                        />
                    </Col>
                    <Col span={10}>
                        <Table
                            className="mappedOutputField"
                            columns={mappedOutputField}
                            bordered
                            dataSource={data}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </div>

            <div className="adapterList__footer addMapping">
                <div className="adapterList__buttons">
                    <CustomButton
                        type={'Cancel'}
                        disabled={false}
                        handleClick={() => {
                            setIsTestMapping(false);
                            setCreateMappingProgressCount(2);
                        }}
                    />
                    <CustomButton
                        type={'Execute Test'}
                        disabled={isExecusteMappingBtnDisabled}
                        handleClick={() => {
                            handleExecuteTest();
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default TestMapping;
