import { message } from 'antd';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getListKPIsForAsset } from 'redux/services/ConfigureServices/attributeServices';
import {
    createUpdateFormulaService,
    fetchFormulaService,
    
    updateFormulaService,
} from 'redux/services/FormulaCreatorServices/formulaCreatorServices';
import { getAttributeImplementationListsService, getAttributeListsWithoutAssestService, getTagList } from 'redux/services/ImplementationServices/attributeServices';
import * as types from 'redux/types/formulaCreationTypes';
export function* getAttributesForAssetSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getAssignedAttributeListData = yield call(
            getAttributeImplementationListsService,
            payload
        );
        yield put({
            type: types.LIST_ATTRIBUTES_SUCCESS,
            response: getAssignedAttributeListData,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.LIST_ATTRIBUTES_FAILURE });
    }
}


export function* getAttributesListWithoutAssestSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        // Api call
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getAssignedAttributeListWithoutAssestData = yield call(
            getAttributeListsWithoutAssestService,
            payload
        );
        yield put({
            type: types.LIST_ATTRIBUTES_WITHOUT_ASSET_SUCCESS,
            response: getAssignedAttributeListWithoutAssestData,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.LIST_ATTRIBUTES_WITHOUT_ASSET_FAILURE });
    }
}

export function* getTagsForAssetSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const tagList: any = yield call(getTagList, payload);

        yield put({
            type: types.LIST_TAGS_SUCCESS,
            payload: tagList.data,
        });
    } catch (error: any) {
        yield put({
            type: types.LIST_ATTRIBUTES_FAILURE,
        });
        return message.error('error');
    }
}
export function* getKPIsForAssetSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const KPIList: any = yield call(getListKPIsForAsset, payload);
        yield put({
            type: types.LIST_KPIS_SUCCESS,
            response: KPIList,
        });
    } catch (error: any) {
        yield put({
            type: types.LIST_KPIS_FAILURE,
        });
        return message.error('error');
    }
}

export function* createUpdateFormulaSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
    }): Generator<any> {
        const updatedPayload=!payload.isScheduled?{...payload,frequencyInMillis:0}:payload

    try {
        // Api call
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const formulaResponse = yield call(createUpdateFormulaService, updatedPayload);
        yield put({
            type: types.CREATE_FORMULA_SUCCESS,
            payload: formulaResponse,
        });
    } catch (error: any) {
        yield put({ type: types.CREATE_FORMULA_FAILURE, payload: error });
    }
}
export function* fetchFormulaSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
    }): Generator<any> {
    
    try {
        // Api call
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const formulaResponse:any = yield call(fetchFormulaService, payload);
        const updatedFormulaResponse=!formulaResponse.isScheduled?{...formulaResponse,frequencyInMillis:1}:formulaResponse
        yield put({
            type: types.FETCH_FORMULA_SUCCESS,
            payload: updatedFormulaResponse,
        });
    } catch (error: any) {
        yield put({ type: types.FETCH_FORMULA_FAILURE, payload: error });
    }
}
export function* updateFormulaSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
    }): Generator<any> {
   
    const updatedPayload=!payload.isScheduled?{...payload,frequencyInMillis:0}:payload

  try {
      // Api call
      yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
      const formulaResponse = yield call(updateFormulaService, updatedPayload);
      yield put({
          type: types.UPDATE_RULE_FORMULA_SUCCESS,
          payload: formulaResponse,
      });
  } catch (error: any) {
      yield put({ type: types.FETCH_FORMULA_FAILURE, payload: error });
  }
}

export function* formulaCreatorListingSaga(): any {
    yield all([takeLatest(types.LIST_ATTRIBUTES, getAttributesForAssetSaga)]);
    yield all([takeLatest(types.LIST_ATTRIBUTES_WITHOUT_ASSET, getAttributesListWithoutAssestSaga)]);
    yield all([takeLatest(types.LIST_TAGS, getTagsForAssetSaga)]);
  yield all([takeLatest(types.CREATE_FORMULA, createUpdateFormulaSaga)]);
  yield all([takeLatest(types.FETCH_FORMULA, fetchFormulaSaga)]);
    yield all([takeLatest(types.UPDATE_RULE_FORMULA, updateFormulaSaga)]);
    yield all([takeLatest(types.LIST_KPIS, getKPIsForAssetSaga)]);



}
