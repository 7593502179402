import  { useState, type ChangeEvent, type FC, useEffect } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/editIcon.svg';
import './index.scss';
import { ReactComponent as RemoveIcon } from 'assets/icons/remove.svg';

import { Button, Input } from 'antd';
import { setFocusedIndexAction, updateFormulaAction } from 'redux/actions/FormulaCreatorActions/formulaCreatorActions';
import { useDispatch, useSelector } from 'react-redux';
import { EMPTY } from 'types/enums';
import { ruleCRUDEnum, ruleCategoryEnum } from 'types/enums/FormulaCreatorEnum';
import { parseJwt } from 'utils/jwtTokenFunction';
const ChipsInput: FC = () => {
    const expressionArray = useSelector(
        (state: any) => state?.formulaCreator?.expressionArray
    );
    const [components, setComponents] = useState<string[]>([expressionArray]);
    const [, setInsertAtIndex] = useState<number | null>(null);
    const focusedIndexRedux = useSelector(
        (state: any) => state?.formulaCreator?.focusedIndex
    );
    const [focusedIndex,seFocusedIndex] = useState<any >(focusedIndexRedux);
    const viewMetaRedux = useSelector(
        (state: any) => state?.formulaCreator?.viewMeta
    );
  
    const formulaDataRedux = useSelector(
        (state: any) => state?.formulaCreator?.formulaData
    );
    const dispatch = useDispatch();
    
    const handlePlusClick = (index: number): void => {
        setInsertAtIndex(index);
        const newComponent = EMPTY.string;
        const newComponents = [...components];
        newComponents.splice(index + 1, 0, newComponent);
        setComponents(newComponents);
        const infoObject = {
            assetId:viewMetaRedux?.assetId,
        
        
            ruleCategory: ruleCategoryEnum?.ATTRIBUTE,
            ruleCategoryId: `${viewMetaRedux?.ruleCategoryId}`,
            
            isScheduled: false,
            frequencyInMillis: 0,
            createdBy: parseJwt().username,
            isDraft: false
          }
        const payload = {
            index: index,
            type: ruleCRUDEnum.CREATE,
            infoObject:infoObject,
            details: {
                ruleCategoryId: ruleCategoryEnum.TEXT,
                detail: {
                    text: EMPTY.string,
                },
            },
        };
        dispatch(updateFormulaAction(payload))

    };
  
    const handleInputChange = (
        index: number,
        event: ChangeEvent<HTMLInputElement>
    ): void => {
        const newComponents = [...components];
        newComponents[index] = event.target.value;
        setComponents(newComponents);
    };


    const inputCancel = (index: number): void => {
        const newComponents = [...components];
        newComponents.splice(index, 1);
        setComponents(newComponents);
        setInsertAtIndex(null);
        
        // Reset insertAtIndex after deletion
        dispatch(updateFormulaAction({index:index,type:ruleCRUDEnum.DELETE}))

    };
    const handleKeyDown = (event: any): void => {
       
        event.preventDefault();
        const allowedKeys = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Enter'];
        if (!allowedKeys.includes(event.key)) {
            return event.key;
        }
    };
    useEffect(() => {
        const resultArray=expressionArray
        setComponents(resultArray)
    }, [expressionArray,formulaDataRedux])
    useEffect(() => {
        dispatch(setFocusedIndexAction(focusedIndex));
    }, [focusedIndex]);
  
    return (
        <>
                <div className="chipsInputContainer">
                    <Button
                        onClick={() :void=> {handlePlusClick(-1)}}
                        className="chipsInputComponent__editButton"
                    >
                        <EditIcon />
                    </Button>

                    {components.map((component, index) => (
                        <div key={index} className="chipsInputComponent">
                            <Input
                                className="chipsInputComponent__input"
                                onSelectCapture={():void => {seFocusedIndex(index)}}
                                value={component}
                                onChange={(event) =>
                                    {handleInputChange(index, event)}
                                }
                                onKeyDown={handleKeyDown}
                                onPaste={(event) => { event.preventDefault(); }}
                            />

                            <Button
                                onClick={():void => {handlePlusClick(index)}}
                                className="chipsInputComponent__editButton"
                            >
                                <EditIcon />
                            </Button>

                            <RemoveIcon
                                className="chipsInputComponent__cancel"
                                onClick={():void => {inputCancel(index)}}
                            />
                        </div>
                    ))}
                </div> 
            {/* <ButtonsPOC setComponents={setComponents} /> */}
        </>
    );
};

export default ChipsInput;
