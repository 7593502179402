/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Dropdown,
    type MenuProps,
    Row,
    message,
    Spin,
    Input,
} from 'antd';
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import CustomButton from 'components/common/CustomButton';
import CustomPagination from 'components/common/CustomPagination';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import CustomModal from 'components/common/Modals/CustomModal';
import { cancelHandle, modalShow, okHandle } from 'utils/modalFunction';
import UserCreation from './AddUser';
import { useDispatch, useSelector } from 'react-redux';
import UserTable from './UserTable';
import './index.scss';
import {
    getAllUsers,
    getUsersList,
} from 'redux/actions/UserManagementActions/usersAction';
import { getTemplate } from 'redux/actions/BulkUploadActions/bulkUploadActions';
import { ReactComponent as CSVIcon } from 'assets/icons/csvIcon.svg';
import { ReactComponent as ExcelIcon } from 'assets/icons/excelIcon.svg';
import Api from 'redux/services';
import { getAllRoles } from 'redux/actions/UserManagementActions/rolesAction';
import { useParams } from 'react-router-dom';
import { baseUrlSetter } from 'utils/commonFunction';
import { getObjectByResourceName, hasPermission } from 'utils/rbacFunction';
import { EMPTY, PERMISSIONS, resourceName } from 'types/enums';
import PermissionComponent from 'components/common/PermissionComponent';
import { useTranslation } from 'react-i18next';
import { PAGE, PAGE_SIZE } from 'utils/constants';

const Users: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
    const [statusValue, setStatusValue] = useState(3);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [userTypeValue, setUserTypeValue] = useState<any>(0);
    const [searchSelectedValues, setSearchSelectedValues] = useState<any>([]);
    const [rolesFilterSelection, setRolesFilterSelection] = useState<any>([]);
    const [typeOfDownload, setTypeOfDownload] = useState<any>();
    const [userPermissionDetails, setUserPermissionDetails] = useState({});
    const [, setadduserTypeValue] = useState(false);
    const [payload, setPayload] = useState({
        page,
        pageSize,
    });
    const countDetails = useSelector(
        (state: any) => state.userManagement?.users?.countDetails
    );
    const [userCreationNextClicked, setUserCreationNextClicked] =
        useState(false);
    // const loaderState = useSelector((state: any) => state.root.showLoader);
    const { currentTab } = useParams();
    useEffect(() => {
        setSearchSelectedValues([]);
        setSearch('');
    }, [currentTab]);

    const userTableDataList = useSelector(
        (state: any) => state.userManagement.users.usersList
    );

    const loggedInUserPermissionData = useSelector(
        (state: any) =>
            state.userManagement.users?.loggedInUserScreenPermissionList
    );

    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );
    const loggedInUserRolePermission = getObjectByResourceName(
        resourceName.roles,
        loggedInUserPermissionData
    );
    const loggedInUserPermission = getObjectByResourceName(
        resourceName.users,
        loggedInUserPermissionData
    );
    const userListLoading = useSelector(
        (state: any) => state.userManagement?.users?.userListLoading
    );
    const userCreated = useSelector(
        (state: any) => state.userManagement?.users?.isUserCreated
    );

    const onAddUsersHandler = (): any => {
        modalShow(isModalOpen, setIsModalOpen);
        if (
            loggedInUserRolePermission?.permission?.length ||
            loggedInUserDetails?.admin
        ) {
            dispatch(getAllRoles());
        }
    };

    // Note: To be moved to sage
    function handleChange(event: any, type: string): void {
        baseUrlSetter('userManagement');
        event.preventDefault();
        let url = '';
        if (type === 'excel') {
            url = '/users/importUsersFromExcel';
        } else if (type === 'csv') {
            url = 'users/importUsersFromCsv';
        }
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        event.target.value = '';
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        Api.post(url, formData, config)
            .then((response) => {
                message.success('File Uploaded Successfully');
            })
            .catch(() => {
                message.error(
                    'Could not upload, please check the contents of file.'
                );
            });
    }

    const items: MenuProps['items'] = [
        {
            label: (
                <>
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        onChange={(e: any) => {
                            handleChange(e, 'csv');
                        }}
                        id="file-upload-users-csv"
                    />
                    <label
                        onClick={() => {
                            document
                                .getElementById('file-upload-users-csv')
                                ?.click();
                        }}
                    >
                        {t('commonStr.uploadCsv')}
                    </label>
                </>
            ),
            key: 'uploadCSV',
            icon: <CSVIcon />,
        },
        {
            label: (
                <>
                    <input
                        style={{ display: 'none' }}
                        type="file"
                        onChange={(e: any) => {
                            handleChange(e, 'excel');
                        }}
                        id="file-upload-users-excel"
                    />
                    <label
                        onClick={() => {
                            document
                                .getElementById('file-upload-users-excel')
                                ?.click();
                        }}
                    >
                        {t('commonStr.uploadExcel')}
                    </label>
                </>
            ),
            key: 'uploadExcel',
            icon: <ExcelIcon />,
        },
        {
            label: t('commonStr.downlaodTemp'),
            key: 'downloadTemplate',
            icon: <ExcelIcon />,
            children: [
                {
                    key: 'csv',
                    label: (
                        <div
                            onClick={() => {
                                setTypeOfDownload('downloadTemplateCSV');
                            }}
                        >
                            {t('commonStr.asCsv')}
                        </div>
                    ),
                },
                {
                    key: 'excel',
                    label: (
                        <div
                            onClick={() => {
                                setTypeOfDownload('downloadTemplateExcel');
                            }}
                        >
                            {t('commonStr.asExcel')}
                        </div>
                    ),
                },
            ],
        },
    ];
    const [userSearchTimeout, setUserSearchTimeout] = useState<any>(undefined);
    const [search, setSearch] = useState('');
    const [sortOrder, setSortOrder] = useState<number | null>(null);
    const [sortColumn, setSortColumn] = useState<number | null>(null);
    useEffect(() => {
        dispatch(getAllUsers());
        // dispatch(getAllRoles());
    }, []);

    useEffect(() => {
        setPayload({ ...payload, page, pageSize });
    }, [page, pageSize]);

    useEffect(() => {
        if (
            loggedInUserPermission?.permission?.length ||
            loggedInUserDetails?.admin
        ) {
            search === EMPTY?.string &&
                dispatch(
                    getUsersList({
                        ...payload,
                        statusValue: statusValue,
                        rolesFilter: rolesFilterSelection,
                        sortOrder: sortOrder,
                        sortColumn: sortColumn,
                    })
                );
        }
    }, [
        payload,
        statusValue,
        searchSelectedValues,
        rolesFilterSelection,
        loggedInUserDetails,
        loggedInUserPermission,
        sortColumn,
        sortOrder,
    ]);

    useEffect(() => {
        if (typeOfDownload)
            dispatch(
                getTemplate({ screenType: 'userManagement', typeOfDownload })
            );
        setTypeOfDownload('');
    }, [typeOfDownload]);

    useEffect(() => {
        setUserPermissionDetails(
            getObjectByResourceName(
                resourceName.users,
                loggedInUserPermissionData
            )
        );
    }, [loggedInUserPermissionData]);

    useEffect(() => {
        if (userCreated) {
            setIsModalOpen(false);
        }
    }, [userCreated]);
    return (
        <>
            <div className="usersWrapper">
                <Card bordered={false}>
                    <PermissionComponent screenName={resourceName.users}>
                        <>
                            {countDetails?.activeCount +
                                countDetails?.inActiveCount >
                            0 ? (
                                <>
                                    <Row>
                                        <Col
                                            span={24}
                                            className="usersWrapper__header"
                                        >
                                            <Input
                                                allowClear
                                                className="usersWrapper__search"
                                                placeholder={t(
                                                    'users.searchNameEmailID'
                                                )}
                                                prefix={<SearchOutlined />}
                                                value={search}
                                                onChange={(e) => {
                                                    setPage(PAGE);
                                                    setSearch(e.target.value);
                                                    if (userSearchTimeout) {
                                                        clearTimeout(
                                                            userSearchTimeout
                                                        );
                                                    }
                                                    setUserSearchTimeout(
                                                        setTimeout(() => {
                                                            dispatch(
                                                                getUsersList({
                                                                    page: PAGE,
                                                                    pageSize:
                                                                        PAGE_SIZE,

                                                                    search: e
                                                                        .target
                                                                        .value,
                                                                    sortColumn:
                                                                        sortColumn,
                                                                    sortOrder:
                                                                        sortOrder,
                                                                })
                                                            );
                                                        }, 1000)
                                                    );
                                                }}
                                            />

                                            <div className="usersWrapper__header-button">
                                                <Dropdown
                                                    menu={{ items }}
                                                    overlayClassName="bluk__upload"
                                                >
                                                    <Button>
                                                        {t(
                                                            'commonStr.bulkUpload'
                                                        )}
                                                        <DownOutlined />
                                                    </Button>
                                                </Dropdown>
                                                <CustomButton
                                                    type={t('users.addUsers')}
                                                    disabled={
                                                        hasPermission(
                                                            userPermissionDetails,
                                                            PERMISSIONS.write
                                                        )
                                                            ? false
                                                            : !loggedInUserDetails.admin
                                                    }
                                                    handleClick={() =>
                                                        onAddUsersHandler()
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    {userListLoading ? (
                                        <div className="view__loader">
                                            <Spin />
                                        </div>
                                    ) : (
                                        <>
                                            <Row
                                                className={
                                                    userTableDataList?.totalRecords >
                                                    50
                                                        ? 'usersWrapper__usersListPagination'
                                                        : 'usersWrapper__usersList'
                                                }
                                            >
                                                <Col span={24}>
                                                    <UserTable
                                                        userTableDataList={
                                                            userTableDataList?.records
                                                        }
                                                        statusValue={
                                                            statusValue
                                                        }
                                                        setStatusValue={
                                                            setStatusValue
                                                        }
                                                        paginationPayload={
                                                            payload
                                                        }
                                                        setRolesFilterSelection={
                                                            setRolesFilterSelection
                                                        }
                                                        setadduserTypeValue={
                                                            setadduserTypeValue
                                                        }
                                                        setSortOrder={
                                                            setSortOrder
                                                        }
                                                        setSortColumn={
                                                            setSortColumn
                                                        }
                                                        setPage={setPage}
                                                    />
                                                </Col>
                                                {userTableDataList?.totalRecords >
                                                PAGE_SIZE ? (
                                                    <CustomPagination
                                                        totalRecords={
                                                            userTableDataList?.totalRecords
                                                        }
                                                        setPage={setPage}
                                                        page={page}
                                                        setPageSize={
                                                            setPageSize
                                                        }
                                                        pageSize={pageSize}
                                                    />
                                                ) : (
                                                    ''
                                                )}
                                            </Row>
                                        </>
                                    )}
                                </>
                            ) : (
                                <div className="usersWrapper__tableEmpty">
                                    <EmptyDataComponent
                                        textValue={''}
                                        buttonType={{
                                            name: 'Add Users',
                                            disable: false,
                                        }}
                                        bulkImport={true}
                                        loading={userListLoading}
                                        buttonClickHandler={() => {
                                            if (
                                                userTableDataList?.length <= 0
                                            ) {
                                                onAddUsersHandler();
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    </PermissionComponent>
                </Card>
            </div>

            <CustomModal
                customClassName={
                    userTypeValue === 2 && userCreationNextClicked
                        ? 'add-user'
                        : 'user-creation'
                }
                open={isModalOpen}
                footer={null}
                onOk={() => {
                    okHandle(isModalOpen, setIsModalOpen);
                    setadduserTypeValue(false);
                }}
                onCancel={() => {
                    setUserTypeValue(0);
                    cancelHandle(isModalOpen, setIsModalOpen);
                    setadduserTypeValue(false);
                }}
                title={t('users.addUsers')}
                userTypeValue={userTypeValue}
            >
                <UserCreation
                    setUserTypeValue={setUserTypeValue}
                    setIsModalOpenUser={setIsModalOpen}
                    setUserCreationNextClicked={setUserCreationNextClicked}
                    userTypeValue={userTypeValue}
                    onCancelhandler={() => {
                        cancelHandle(isModalOpen, setIsModalOpen);
                        setIsModalOpen(false);
                    }}
                    onOk={() => {
                        if (userCreated) {
                            okHandle(isModalOpen, setIsModalOpen);
                            setIsModalOpen(false);
                        } else {
                            setIsModalOpen(true);
                        }
                    }}
                    paginationPayload={payload}
                    setadduserTypeValue={setadduserTypeValue}
                />
            </CustomModal>
        </>
    );
};

export default Users;
