import { message } from "antd";
import { all, call, put, takeLatest } from "redux-saga/effects";
import {
  activateMappingServices,
  createMappingServices,
  deleteMappingServices,
  executeMappingServices,
  getBraboTableListServices,
  getColumnDetailsByTableIdServices,
  getLabelsListServices,
  getMappingByIdServices,
  getMappingJsonServices,
  getMappingListServices,
  updateMappingServices,
} from "redux/services/BusinessIntegratorServices/mappingServices";
import * as types from "redux/types/businessIntegratorTypes";

export function* getMappingJsonSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const mappingJson = yield call(getMappingJsonServices, payload);
    yield put({
      type: types.GET_MAPPING_JSON_SUCCESS,
      response: mappingJson,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_MAPPING_JSON_FAILURE,
    });
  }
}

export function* getBraboTableListSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const braboTableList = yield call(getBraboTableListServices, payload);
    yield put({
      type: types.GET_BRABOTABLE_LIST_SUCCESS,
      response: braboTableList,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_BRABOTABLE_LIST_FAILURE,
    });
  }
}

export function* getColumnDetailsByTableIdSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const columnDetails = yield call(
      getColumnDetailsByTableIdServices,
      payload
    );
    yield put({
      type: types.GET_COLUMN_DETAILS_BY_TABLE_ID_SUCCESS,
      response: columnDetails,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_COLUMN_DETAILS_BY_TABLE_ID_FAILURE,
    });
  }
}

export function* executeMappingSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const isMappingExecuted = yield call(executeMappingServices, payload);
    yield put({
      type: types.EXECUTE_MAPPING_SUCCESS,
      response: isMappingExecuted,
    });
    isMappingExecuted && message.success("Executed Succesfully");
  } catch (error: any) {
    yield put({
      type: types.EXECUTE_MAPPING_FAILURE,
    });
  }
}

export function* createMappingSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const isMappingCreated = yield call(createMappingServices, payload);
    yield put({
      type: types.CREATE_MAPPING_SUCCESS,
      response: isMappingCreated,
    });
  } catch (error: any) {
    yield put({
      type: types.CREATE_MAPPING_FAILURE,
    });
  }
}

export function* getMappingByIdSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const getMappingById = yield call(getMappingByIdServices, payload);
    yield put({
      type: types.GET_MAPPING_BY_ID_SUCCESS,
      response: getMappingById,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_MAPPING_BY_ID_FAILURE,
    });
  }
}

export function* getMappingListSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const mappingList = yield call(getMappingListServices, payload);
    yield put({
      type: types.GET_MAPPING_LIST_SUCCESS,
      response: mappingList,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_MAPPING_LIST_FAILURE,
    });
  }
}

export function* getAllMappingSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const mappingList = yield call(getMappingListServices, payload);
    yield put({
      type: types.GET_ALL_MAPPING_SUCCESS,
      response: mappingList,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_ALL_MAPPING_FAILURE,
    });
  }
}

export function* getLabelsListSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const labelsList = yield call(getLabelsListServices, payload);
    yield put({
      type: types.GET_LABELS_DATA_SUCCESS,
      response: labelsList,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_LABELS_DATA_FAILURE,
    });
  }
}

export function* updateMappingSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const isMappingUpdated = yield call(updateMappingServices, payload);
    yield put({
      type: types.UPDATE_MAPPING_SUCCESS,
      response: isMappingUpdated,
    });
  } catch (error: any) {
    yield put({
      type: types.UPDATE_MAPPING_FAILURE,
    });
  }
}

export function* deleteMappingSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const deletedResponse = yield call(deleteMappingServices, payload);
    yield put({
      type: types.MAPPING_DELETED_SUCCESS,
      response: deletedResponse,
    });
  } catch (error: any) {
    yield put({
      type: types.MAPPING_DELETED_FAILURE,
    });
  }
}

export function* activateMappingSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const activatedResponse = yield call(activateMappingServices, payload);
    yield put({
      type: types.MAPPING_ACTIVATED_SUCCESS,
      response: activatedResponse,
    });
  } catch (error: any) {
    yield put({
      type: types.MAPPING_ACTIVATED_FAILURE,
    });
  }
}

export function* mappingSaga(): any {
  yield all([takeLatest(types.GET_MAPPING_JSON, getMappingJsonSaga)]);
  yield all([takeLatest(types.CREATE_MAPPING, createMappingSaga)]);
  yield all([takeLatest(types.EXECUTE_MAPPING, executeMappingSaga)]);
  yield all([takeLatest(types.GET_BRABOTABLE_LIST, getBraboTableListSaga)]);
  yield all([
    takeLatest(
      types.GET_COLUMN_DETAILS_BY_TABLE_ID,
      getColumnDetailsByTableIdSaga
    ),
  ]);
  yield all([takeLatest(types.GET_MAPPING_BY_ID, getMappingByIdSaga)]);
  yield all([takeLatest(types.GET_MAPPING_LIST, getMappingListSaga)]);
  yield all([takeLatest(types.GET_ALL_MAPPING, getAllMappingSaga)]);
  yield all([takeLatest(types.GET_LABELS_DATA, getLabelsListSaga)]);
  yield all([takeLatest(types.MAPPING_DELETED, deleteMappingSaga)]);
  yield all([takeLatest(types.MAPPING_ACTIVATED, activateMappingSaga)]);
  yield all([takeLatest(types.UPDATE_MAPPING, updateMappingSaga)]);
}
