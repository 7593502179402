import * as types from 'redux/types/alertTypes';
import initialState from '../initialStates';

export default function alertReducer(
    state = initialState.alert,
    action: any
): any {
    switch (action.type) {
        case types.SET_ALERT_STATE:
            state = {
                ...state,
                alertState: action.payload,
            };
            return state;
        case types.GET_ALERT_LIST:
            state = {
                ...state,
                alertListLoading: true,
            };
            return state;
        case types.GET_ALERT_LIST_SUCCESS:
            state = {
                ...state,
                alertListLoading: true,
                alertList: action.response.data,
                operatorsListById: {},
                operatorsList: [],
                alertDetails: {},
                statusUpdateAlert: false,
            };
            return state;
        case types.GET_ALERT_LIST_FAILURE:
            state = {
                ...state,
                alertListLoading: false,
            };
            return state;
        case types.GET_OPERATOR_LIST_SUCCESS:
            state = {
                ...state,
                operatorsList: action.response.data,
            };
            return state;
        case types.GET_OPERATOR_LIST_FAILURE:
            state = {
                ...state,
                operatorsList: [],
            };
            return state;
        case types.GET_OPERATOR_BY_ID_SUCCESS: {
            const newOperatorList = { ...state?.operatorsListById };
            newOperatorList[action?.payload?.index] = action.response.data;
            state = {
                ...state,
                operatorsListById: { ...newOperatorList },
            };
            return state;
        }
        case types.GET_OPERATOR_BY_ID_FAILURE:
            state = {
                ...state,
                operatorsListById: [],
            };
            return state;
        case types.GET_ALERT_DETAILS_SUCCESS:
            state = {
                ...state,
                alertDetails: action.response.data,
            };
            return state;
        case types.GET_ALERT_DETAILS_FAILURE:
            state = {
                ...state,
                alertDetails: {},
            };
            return state;
        case types.GET_KPI_LIST_BY_ASSET_SUCCESS:
            state = {
                ...state,
                kpiListByAsset: action.response.data,
            };
            return state;
        case types.GET_KPI_LIST_BY_ASSET_FAILURE:
            state = {
                ...state,
                kpiListByAsset: [],
            };
            return state;
        case types.GET_DURATION_LIST_SUCCESS:
            state = {
                ...state,
                durationList: action.response.data,
            };
            return state;
        case types.GET_DURATION_LIST_FAILURE:
            state = {
                ...state,
                durationList: [],
            };
            return state;
        case types.GET_CALCULATION_CYCLE_LIST_SUCCESS:
            state = {
                ...state,
                calculationCycleList: action.response.data,
            };
            return state;
        case types.GET_CALCULATION_CYCLE_LIST_FAILURE:
            state = {
                ...state,
                calculationCycleList: [],
            };
            return state;
        case types.GET_NOTIFICATION_CHANNEL_LIST_SUCCESS:
            state = {
                ...state,
                notificationChannelList: action.response.data,
            };
            return state;
        case types.GET_NOTIFICATION_CHANNEL_LIST_FAILURE:
            state = {
                ...state,
                notificationChannelList: [],
            };
            return state;
        case types.GET_CONSEQUENCE_LIST_SUCCESS:
            state = {
                ...state,
                consequenceList: action.response.data,
            };
            return state;
        case types.CREATE_ALERT_SUCCESS:
            state = {
                ...state,
                alertCreated: true,
            };
            return state;
        case types.SET_CREATED_STATE:
            state = {
                ...state,
                alertCreated: false,
                alertUpdated: false,
            };
            return state;
        case types.UPDATE_ALERT_STATUS_SUCCESS:
            state = {
                ...state,
                statusUpdateAlert: true,
            };
            return state;
        case types.UPDATE_ALERT_STATUS_FAILURE:
            state = {
                ...state,
                statusUpdateAlert: false,
            };
            return state;
        case types.UPDATE_ALERT_SUCCESS:
            state = {
                ...state,
                alertUpdated: true,
            };
            return state;
        default:
            return state;
    }
}
