import EmptyDataComponent from 'components/common/EmptyDataComponent';
import { useEffect, useState, type FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getKpisForAsset,
    updateFormulaAction,
} from 'redux/actions/FormulaCreatorActions/formulaCreatorActions';
import './index.scss';
import {
    formulaCreatorScreenType,
    ruleCRUDEnum,
    ruleCategoryEnum,
} from 'types/enums/FormulaCreatorEnum';
const KPIsListing: FC<any> = ({ kpiSearch, screen }) => {
    const KPIsListsRedux = useSelector(
        (state: any) => state?.formulaCreator?.KPIsList?.data
    );
    const kpiDetailId = useSelector(
        (state: any) => state?.configure?.kpi?.kpiDetails?.id
    );

    const assetRedux = useSelector(
        (state: any) => state?.formulaCreator?.viewMeta?.assetId
    );
    const expressionArray = useSelector(
        (state: any) => state?.formulaCreator?.expressionArray
    );
    const selectedAssetRedux = useSelector(
        (state: any) => state?.formulaCreator?.selectedAsset
    );
    const focusedIndexRedux = useSelector(
        (state: any) => state?.formulaCreator?.focusedIndex
    );
    const dispatch = useDispatch();

    const [filteredListingData, setFilteredListingData] =
        useState<any>(KPIsListsRedux);
    const operandHandler = (item: any): void => {
        const payload = {
            index: expressionArray.length?focusedIndexRedux:0,
            type: ruleCRUDEnum.UPDATE,
            details: {
                ruleCategoryId: ruleCategoryEnum.KPI,
                detail: {
                    assetId: selectedAssetRedux,
                    id: item?.id,
                    name: item?.name,
                },
            },
        };
        dispatch(updateFormulaAction(payload));
    };

    useEffect(() => {
        const payload: any = {
            assetId: selectedAssetRedux,
            isValidated: 1,
            activeStatus:1
            // To Do: will change this once we get non-paginated data.
        };
      
        if (screen === formulaCreatorScreenType.KPI) {
            kpiDetailId
                ? dispatch(getKpisForAsset({ excludedKpiList: kpiDetailId,isValidated:1,activeStatus:1 }))
                : dispatch(getKpisForAsset({isValidated:1,activeStatus:1}))
        } else {
          
            dispatch(getKpisForAsset(payload))

        
        }
    }, [assetRedux,selectedAssetRedux]);
    useEffect(() => {
        if (kpiSearch?.length) {
            const tagListingData = KPIsListsRedux;
            setFilteredListingData(
                tagListingData.filter((ele: any) =>
                    ele?.name.toLowerCase().includes(kpiSearch.toLowerCase())
                )
            );
        } else {
            setFilteredListingData(KPIsListsRedux);
        }
    }, [kpiSearch, KPIsListsRedux]);
    useEffect(() => {
        setFilteredListingData(KPIsListsRedux);
    }, []);

    return (
        <div className="kpiListing">
            <div
                className="kpiListing__container"
                style={{
                    justifyContent: !KPIsListsRedux?.length
                        ? 'center'
                        : 'flex-start',
                    alignItems: !KPIsListsRedux?.length
                        ? 'center'
                        : 'flex-start',
                }}
            >
                {filteredListingData?.length ? (
                    filteredListingData?.map((item: any) => (
                        <span
                            key={item.tagId}
                            onClick={() => {
                                operandHandler(item);
                            }}
                            className="kpiListing__container__dataItem"
                        >
                            {item.name}
                        </span>
                    ))
                ) : (
                    <div className="noResultOnSearch">
                    <EmptyDataComponent textValue="KPIs not found" />
                </div>                )}
            </div>
        </div>
    );
};

export default KPIsListing;
