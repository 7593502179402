import {type FC,  useState, useEffect } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Input, Select } from 'antd';
import AssetHierachy from '../AssetHierachy';
import { functionsListData } from 'utils/constants';
import FunctionsListing from './FunctionsListing';
import { EMPTY } from 'types/enums';
import TagsListing from './TagsListing';
import AttributesListing from './AttributesListing';
import { useSelector } from 'react-redux';
import { FormulaCreatorOperandType, formulaCreatorScreenType } from 'types/enums/FormulaCreatorEnum';
import KPIsListing from './KPIsListing';
const LeftPanelNavigation: FC<any> = ({screen}) => {
    const [listingCategory, setListingCategory] = useState('');
    const [functionType, setFunctionType] = useState<string[]>(['all']);
    const [functionSearch, setFunctionSearch] = useState<string>(EMPTY.string)
    const [tagSearch, setTagSearch] = useState<string>(EMPTY.string)
    const [kpiSearch, setKpiSearch] = useState<string>(EMPTY.string)
    const [attributeSearch, setAttributeSearch] = useState<string>(EMPTY.string)
    const handleChange = (value: { value: string; label: React.ReactNode }):any => {
        setListingCategory(value.value);
    };
    const viewMetaRedux = useSelector(
        (state: any) => state?.formulaCreator?.viewMeta?.view
    );

    const functionHandleChange = (value: any):void => {
        const keysToFind=value.map((obj:any) => obj.key)
        setFunctionType(keysToFind)
    };
    
    const handleSearchfunction = (val: any): void => {
        switch (listingCategory) {
            case FormulaCreatorOperandType.Functions:setFunctionSearch(val)
             break;
            case FormulaCreatorOperandType.Tags:setTagSearch(val)
            break;
             case FormulaCreatorOperandType.Attributes:setAttributeSearch(val)
            break;
            case FormulaCreatorOperandType.KPIs:setKpiSearch(val)
            break;
            default:
            break;
        }
        
    }
    const functionDropdownData = [{ key: 'all',
    title: 'All',},...functionsListData]
   useEffect(() => {
       if (functionType.length === 0) {
        setFunctionType(['all'])
     }
   }, [functionType.length === 0])
    useEffect(() => {
        handleChange({
            value: FormulaCreatorOperandType.Functions,
            label: FormulaCreatorOperandType.Functions,
        })
    },[viewMetaRedux,screen])
    
    const viewConditionalListing = (): any => {
        if (listingCategory===FormulaCreatorOperandType.Attributes) {
            return <AttributesListing screen={screen} attributeSearch={attributeSearch} />

        }
        else if (listingCategory === FormulaCreatorOperandType.Tags) {
            return <TagsListing  tagSearch={ tagSearch} />
        }
        else if (listingCategory === FormulaCreatorOperandType.KPIs) {
            return <KPIsListing screen={screen} kpiSearch={kpiSearch} />

        }
        else  {
            return <FunctionsListing screen={screen} functionType={functionType} functionSearch={functionSearch} /> 
        }
      
    }
    
   

    
    return (
        <div className="formulaCreatorModal__content-left">
            <Select
                labelInValue
                value={{ label: listingCategory, value: listingCategory }}
                style={{ width: 120 }}
                onChange={handleChange}
                
                options= {screen ===`${formulaCreatorScreenType.ATRRIBUTE}`? [
                    {
                        value: FormulaCreatorOperandType.Functions,
                        label: FormulaCreatorOperandType.Functions,
                    },
                    {
                        value: FormulaCreatorOperandType.Tags,
                        label: FormulaCreatorOperandType.Tags,
                    },
                    {
                        value: FormulaCreatorOperandType.Attributes,
                        label: FormulaCreatorOperandType.Attributes,
                    },
                    {
                        value: FormulaCreatorOperandType.KPIs,
                        label: FormulaCreatorOperandType.KPIs,
                    },
                ]:[{
                    value: FormulaCreatorOperandType.Functions,
                    label: FormulaCreatorOperandType.Functions,
                },
                
                {
                    value: FormulaCreatorOperandType.Attributes,
                    label: FormulaCreatorOperandType.Attributes,
                },
                {
                    value: FormulaCreatorOperandType.KPIs,
                    label: FormulaCreatorOperandType.KPIs,
                },]}
            />
            {screen===formulaCreatorScreenType.ATRRIBUTE&& listingCategory !== FormulaCreatorOperandType.Functions && (
                <AssetHierachy
                    setSelectedAsset={() => {}}
                    setSelectedAssetChildrenKey={() => { }}
                    
                />
            )}
            {listingCategory === FormulaCreatorOperandType.Functions&& functionDropdownData&& functionDropdownData?.length ?
                <Select
                    labelInValue
                    defaultValue={{
                        value: 'all',
                        label: 'All',
                    }}
                    value={functionType}
                    allowClear
                    mode="multiple"
                    style={{ width: 120 }}
                    onChange={functionHandleChange}
                    options={functionDropdownData.map((item: any) =>  ({ value: item.key, label: item.title }))}
                />
                    
                 : null}
            <Input prefix={<SearchOutlined />} placeholder='Search' value={listingCategory === FormulaCreatorOperandType.Functions ? functionSearch : listingCategory === FormulaCreatorOperandType.Tags ? tagSearch : listingCategory === FormulaCreatorOperandType.Attributes ? attributeSearch : kpiSearch} onChange={(e: any) => { handleSearchfunction(e.target.value) }} />
            {viewConditionalListing()}
           
        </div>
    );
};

export default LeftPanelNavigation;
