import { Col, Row } from 'antd';
import ScreenNameHeading from 'components/common/ScreenNameHeading';
import './index.scss';
import TabsComponent from 'components/common/TabsComponent';
import { useSelector } from 'react-redux';
import { hasTabPermission } from 'utils/commonFunction';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Configuration from './Configuration';
import ModelOverview from './ModelOverview';
import CountAnalytics from 'components/common/CountAnalytics';
import { ReactComponent as ActiveUserIcon } from 'assets/icons/activeUserCountIcon.svg';
import { useTranslation } from 'react-i18next';

const AssetModelling: React.FC<{ activate: any }> = ({ activate }) => {
    const { currentTab = 'modelOverview' } = useParams();
    const [activeTabKey, setActiveTabKey] = useState(currentTab);
    const navigate = useNavigate();
    const { t } = useTranslation('translation');

    useEffect(() => {
        setActiveTabKey(currentTab);
    }, [currentTab]);
    useEffect(() => {
        navigate(`/transform/contextualization/${activeTabKey}`, {
            replace: true,
        });
    }, [activeTabKey]);

    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );
    const displayTagsCount = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.displayTagsCount
    );
    const allowedTabList = useSelector(
        (state: any) => state.root.allowedMenuList
    );
    const mappedTagsList = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.mappedTagsList
    );
    const tabItems = [
        {
            id: 1,
            permissionKey: 'Asset Modeller',
            key: 'modelOverview',
            label: t('assetModeller.modelOverview.modelOverviewTabName'),
            children: <ModelOverview />,
        },
        {
            id: 2,
            permissionKey: 'Asset Modeller',
            key: 'configuration',
            label: t('assetModeller.configuration.configurationTabName'),
            children: <Configuration />,
        },
    ];
    const getTagCountAnalyticsDetails = (): any => {
        return [
            {
                title: t('assetModeller.modelOverview.totalTags'),
                count: mappedTagsList?.totalElements
                    ? mappedTagsList?.totalElements
                    : '--',
                icon: <ActiveUserIcon />,
            },
        ];
    };
    return (
        <>
            <div className="assetModelling">
                <Row className="assetModelling__headerWrapper">
                    <Col span={18}>
                        <ScreenNameHeading
                            heading={t('assetModeller.heading')}
                            subHeading={
                                t('assetModeller.subHeading')
                            }
                        />
                    </Col>
                    {displayTagsCount && (
                        <Col span={6}>
                            <CountAnalytics
                                countDetails={getTagCountAnalyticsDetails()}
                            />
                        </Col>
                    )}
                </Row>
                <Row className="assetModelling__tabswrapper fw-500 fs-16">
                    <Col span={24}>
                        <TabsComponent
                            tabItem={
                                loggedInUserDetails?.admin
                                    ? tabItems
                                    : hasTabPermission(tabItems, allowedTabList)
                            }
                            setTabKey={activate}
                            setActiveTabKey={setActiveTabKey}
                            activeTabKey={activeTabKey}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default AssetModelling;
