import { useEffect, useState } from 'react';
import { Col, Form, Input, Popover, Row, Select } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import './index.scss';
import { ReactComponent as AddIcon } from 'assets/icons/Plus.svg';
// import { ReactComponent as ClassDeleteIcon } from 'assets/icons/classDelete.svg';
import CategoryUOMClassContent from '../CreateUomClass';
import {
    getUomClassList,
    getUomMetricList,
} from 'redux/actions/ConfigureActions/attributeActions';
import { useDispatch, useSelector } from 'react-redux';
import { EMPTY, UOMTYPE } from 'types/enums';
import { useTranslation } from 'react-i18next';

function UomFormComponent({ form }: any): any {
    const [uomClassCreate, setUomClassCreate] = useState(false);
    const { Option } = Select;
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');

    const uomClassList = useSelector(
        (state: any) => state?.configure?.attributes?.uomClassList
    );
    const uomMetricList = useSelector(
        (state: any) => state?.configure?.attributes?.uomMetricList
    );
    const uomState = useSelector(
        (state: any) => state.configure?.attributes?.uomState
    );
    const recentUomClass = localStorage.getItem('recentUomClass');

    useEffect(() => {
        dispatch(getUomClassList());
        dispatch(getUomMetricList());
    }, []);

    const [recentAddedClass, setRecentAddedClass] = useState<any>();
    const [classes, setClasses] = useState<any>();
    useEffect(() => {
        setClasses(recentUomClass);
    }, [recentUomClass]);

    useEffect(() => {
        setRecentAddedClass([...uomClassList, { id: classes, name: classes }]);
    }, [uomClassList, classes, recentUomClass]);

    // to delete recent created class
    // const onRecentAddedClassHandler = (e: any): any => {
    //     setRecentAddedClass(uomClassList);
    //     localStorage.removeItem('recentUomClass');
    // };
    useEffect(() => {
        localStorage.removeItem('recentUomClass');
    }, [uomState]);

    return (
        <div className="attributeFormWrapper">
            <div className="attributeFormWrapper__content__wrapper">
                <div className="attributeFormWrapper__wrapper">
                    <Row gutter={10}>
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'UomConfigurator.uomTableColumnHeaderTitel.nameOfUnit'
                                        )}
                                    </div>
                                }
                                name="nameOfUnit"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'UomConfigurator.errorMessage.enterUnit'
                                        ),
                                    },
                                    {
                                        max: 100,
                                        message: t(
                                            'UomConfigurator.errorMessage.maxlength'
                                        ),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={t(
                                        'UomConfigurator.errorMessage.enterUnit'
                                    )}
                                    maxLength={100}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'UomConfigurator.uomTableColumnHeaderTitel.abbreviation'
                                        )}
                                    </div>
                                }
                                name="abbreviation"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'UomConfigurator.errorMessage.enterAbbreviation'
                                        ),
                                    },
                                    {
                                        pattern:
                                            /^[a-zA-Z0-9\s!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/,
                                        message: t(
                                            'UomConfigurator.errorMessage.allowed'
                                        ),
                                    },
                                    {
                                        max: 50,
                                        message: t(
                                            'UomConfigurator.errorMessage.fiftyLength'
                                        ),
                                    },
                                ]}
                            >
                                <Input
                                    placeholder={t(
                                        'UomConfigurator.errorMessage.enterAbbreviation'
                                    )}
                                    maxLength={50}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col span={24}>
                            <Form.Item
                                className="DescriptionBox"
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'UomConfigurator.uomTableColumnHeaderTitel.description'
                                        )}
                                    </div>
                                }
                                name="description"
                                rules={[
                                    {
                                        max: 200,
                                        message: t(
                                            'UomConfigurator.errorMessage.twoHundredLength'
                                        ),
                                    },
                                ]}
                            >
                                <TextArea
                                    rows={4}
                                    placeholder={t(
                                        'UomConfigurator.errorMessage.description'
                                    )}
                                    maxLength={200}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={10}>
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <Row className="labelRowForAdd fw-400 fs-14">
                                        <Col span={20}>
                                            {t(
                                                'UomConfigurator.uomTableColumnHeaderTitel.uomClass'
                                            )}
                                        </Col>
                                        <Col span={4}>
                                            <Popover
                                                className="createCategoryPopOver"
                                                content={
                                                    <CategoryUOMClassContent
                                                        setUomClassCreate={
                                                            setUomClassCreate
                                                        }
                                                    />
                                                }
                                                trigger="click"
                                                open={uomClassCreate}
                                                placement="bottomLeft"
                                                overlayStyle={{
                                                    width: '411px',
                                                    margin: '5px',
                                                    padding: '0 !important',
                                                }}
                                            />
                                            <Row
                                                className={`${
                                                    uomState === UOMTYPE.view
                                                        ? 'disabled fw-400 fs-14'
                                                        : EMPTY.string
                                                } addItemsButton fw-400 fs-14`}
                                                onClick={() => {
                                                    if (
                                                        !(
                                                            uomState ===
                                                            UOMTYPE.view
                                                        )
                                                    )
                                                        setUomClassCreate(true);
                                                }}
                                            >
                                                <Col span={7}>
                                                    <AddIcon />
                                                </Col>
                                                <Col
                                                    span={17}
                                                    className="fw-400 fs-14"
                                                >
                                                    {t(
                                                        'UomConfigurator.addClassModal'
                                                    )}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                }
                                name="uomClasses"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'UomConfigurator.errorMessage.UOMClass'
                                        ),
                                    },
                                ]}
                            >
                                <Select
                                    placeholder={t(
                                        'UomConfigurator.errorMessage.select'
                                    )}
                                    allowClear
                                >
                                    {recentAddedClass
                                        ?.filter((item: any) => item?.name)
                                        .map((item: any) => (
                                            <Option
                                                key={item?.id}
                                                value={item?.id}
                                            >
                                                {item.name}
                                                {/* {classes &&
                                                    item.name === classes && (
                                                        <div
                                                            className="uomDeleteClass"
                                                            onClick={
                                                                onRecentAddedClassHandler
                                                            }
                                                        >
                                                            <ClassDeleteIcon />
                                                        </div>
                                                    )} */}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div className="fw-400 fs-14">
                                        {t(
                                            'UomConfigurator.uomTableColumnHeaderTitel.metricSystem'
                                        )}
                                    </div>
                                }
                                name="metricSystem"
                                rules={[
                                    {
                                        required: true,
                                        message: t(
                                            'UomConfigurator.errorMessage.metricSystem'
                                        ),
                                    },
                                ]}
                            >
                                <Select
                                    placeholder={t(
                                        'UomConfigurator.errorMessage.select'
                                    )}
                                >
                                    {uomMetricList?.map((item: any) => {
                                        return (
                                            <Option
                                                key={item?.id}
                                                value={item?.id}
                                            >
                                                {item?.name}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </div>
            </div>
        </div>
    );
}

export default UomFormComponent;
