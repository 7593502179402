import EmptyDataComponent from 'components/common/EmptyDataComponent';
import { useEffect, useState, type FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    getTagsForAsset,
    updateFormulaAction,
} from 'redux/actions/FormulaCreatorActions/formulaCreatorActions';
import './index.scss';
import { ruleCRUDEnum, ruleCategoryEnum } from 'types/enums/FormulaCreatorEnum';
const TagsListing: FC<any> = ({ tagSearch }) => {
    const tagsListsRedux = useSelector(
        (state: any) => state?.formulaCreator?.tagsList?.data
    );

    const assetRedux = useSelector(
        (state: any) => state?.formulaCreator?.viewMeta?.assetId
    );
    const selectedAssetRedux = useSelector(
        (state: any) => state?.formulaCreator?.selectedAsset
    );
    const expressionArray = useSelector(
        (state: any) => state?.formulaCreator?.expressionArray
    );
    const focusedIndexRedux = useSelector(
        (state: any) => state?.formulaCreator?.focusedIndex
    );
    const dispatch = useDispatch();

    const [filteredListingData, setFilteredListingData] =
        useState<any>(tagsListsRedux);
    const operandHandler = (item: any): void => {
        const payload = {
            index: expressionArray.length?focusedIndexRedux:0,
            type: ruleCRUDEnum.UPDATE,
            details: {
                ruleCategoryId: ruleCategoryEnum.TAG,
                detail: {
                    assetId: selectedAssetRedux,
                    id: item?.uuid,
                    name: item?.tagName,
                },
            },
        };
        dispatch(updateFormulaAction(payload));
    };

    useEffect(() => {
        const payload: any = {
            id: selectedAssetRedux,
            page: 0,
            includeChildNode: false,
        };
        dispatch(getTagsForAsset(payload));
    }, [assetRedux,selectedAssetRedux]);
    useEffect(() => {
        if (tagSearch?.length) {
            const tagListingData = tagsListsRedux;
            setFilteredListingData(
                tagListingData.filter((ele: any) =>
                    ele?.tagName.toLowerCase().includes(tagSearch.toLowerCase())
                )
            );
        } else {
            setFilteredListingData(tagsListsRedux);
        }
    }, [tagSearch, tagsListsRedux]);
    useEffect(() => {
        setFilteredListingData(tagsListsRedux);
    }, []);

    return (
        <div className="tagListing">
            <div
                className="tagListing__container"
                style={{
                    justifyContent: !tagsListsRedux?.length
                        ? 'center'
                        : 'flex-start',
                    alignItems: !tagsListsRedux?.length
                        ? 'center'
                        : 'flex-start',
                }}
            >
                {filteredListingData?.length ? (
                    filteredListingData?.map((item: any) => (
                        <span
                            key={item.tagId}
                            onClick={() => {
                                operandHandler(item);
                            }}
                            className="attributesListing__container__dataItem"
                        >
                            {item.tagName}
                        </span>
                    ))
                ) : (
                    <div className="noResultOnSearch">
                    <EmptyDataComponent textValue="Tags not found" />
                </div>                )}
            </div>
        </div>
    );
};

export default TagsListing;
