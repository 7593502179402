
import {   Radio, TreeSelect } from 'antd';
import './index.scss';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAttributesForAsset, getKpisForAsset, getTagsForAsset, setSelectedAssetAction } from 'redux/actions/FormulaCreatorActions/formulaCreatorActions';

const AssetHierachy: React.FC<any> = (
) => {
    const assetRedux=useSelector(
        (state: any) => state?.formulaCreator?.viewMeta?.assetId
    );
    const [expandedKeys] = useState<string[]>([]);
    const [selectedTagId, setSelectedTagId] = useState(assetRedux);
    const handleNodeClick = (flattenedNode: any): any => {
        setSelectedTagId(flattenedNode?.id);
        
    };
    const dispatch=useDispatch()
   
    const [selectedAssetId, setSelectedAssetId] = useState(
        assetRedux
    );

    const nodeList = useSelector(
        (state: any) => state?.dataVisualization?.nodeList
    );
    
    const convertToTreeSelectData = (flattenedNode: any): any => {
        const isExpanded = expandedKeys?.includes(flattenedNode?.name);
        const treeSelectNode = {
            title: (
                <Radio
                    value={flattenedNode?.name}
                    style={{ marginRight: 8 }}
                    onClick={() => handleNodeClick(flattenedNode)}
                    checked={selectedTagId === flattenedNode?.id}
                >
                    {flattenedNode?.name}
                </Radio>
            ),
            value: flattenedNode?.id,
            key: flattenedNode?.name,
            children: isExpanded
                ? (flattenedNode?.childNodes || [])?.map(
                      convertToTreeSelectData
                  )
                : '',
            defaultExpandAll: false,
        };

        if (
            flattenedNode?.childNodes &&
            flattenedNode?.childNodes?.length > 0
        ) {
            treeSelectNode.children = flattenedNode?.childNodes?.map(
                convertToTreeSelectData
            );
        }
        return treeSelectNode;
    };

    useEffect(() => {
        dispatch(getAttributesForAsset({
            page: 1,
            pageSize: 50,
            assetId: selectedAssetId,
            assignStatus: 1,
            search: ""
        }))
        const payload :any= {
            id:selectedAssetId,
            page:0,
            includeChildNode:false
        }
        dispatch((getTagsForAsset(payload)))
        const kpiPayload :any= {
            assetId: assetRedux,
            // To Do: will change this once we get non-paginated data.
        }
      dispatch(getKpisForAsset(kpiPayload))
    
}, [])
    const treeSelectData = [nodeList?.node]?.map(convertToTreeSelectData);
    const selectAssetValue = (value: any): any => {
        setSelectedAssetId(value);
        setSelectedTagId(value)
        dispatch(setSelectedAssetAction(value))
    };
    return (
        <>
            <div className="assestHierarchy">
                        <div className="selectAsset">
                            <TreeSelect
                                showSearch
                                className="tree-select"
                                dropdownStyle={{
                                    minWidth: 313,
                                    width: 300,
                                    maxHeight: 400,
                                    overflow: 'auto',
                                }}
                                
                                treeData={treeSelectData}
                                onSelect={selectAssetValue}
                                value={selectedAssetId}
                        
                            />
                        </div>
                        
                    </div>
        </>
    );
};
export default AssetHierachy;
