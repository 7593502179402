import {
    Drawer,
    Row,
    Col,
    Switch,
    Button,
    Divider,
    Form,
    Input,
    Select,
    Spin,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/common/CustomButton';
import { BUTTONTYPE, EquipmentModule } from 'types/enums';
import './index.scss';
import { useEffect, useState } from 'react';
import { parseJwt } from 'utils/jwtTokenFunction';
import { useSelector, useDispatch } from 'react-redux';
import {
    CLOSE_ADD_NODE_DRAWER,
    CLOSE_EDIT_NODE_DETAILS,
    EMPTY_EQUIPMENT_CLASS_DETAILS,
} from 'redux/types/assetModellingTypes';
import {
    addNode,
    getEquipmentClassId,
    getEquipmentClassList,
    getNodeTypeDetails,
    getNodeTypeId,
    editNode,
} from 'redux/actions/AssetModellingActions/assetModellingAction';
import { DEFAULT_PAGE_SIZE, PAGE } from 'utils/constants';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const FlexibleModelFormDrawer: React.FC<any> = ({ nodeDetailsById }) => {
    const { t } = useTranslation('translation');
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const details = parseJwt();
    const modelDetails = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.modelDetails
    );
    const editNodeDetails = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.editNodeDetails
    );
    useEffect(() => {
        dispatch(
            getNodeTypeDetails({ page: PAGE, pageSize: DEFAULT_PAGE_SIZE })
        );
    }, []);
    const nodeType = useSelector(
        (state: any) =>
            state?.assetModelling?.nodeType?.nodeTypeListDetails?.records
    );

    const [enableField, setEnableField] = useState(false);
    const nodeTypeDetails = useSelector(
        (state: any) => state?.assetModelling?.nodeType?.nodeTypeById
    );

    const [payload, setPayload] = useState({
        name: '',
        description: '',
        createdBy: details?.username,
        updatedBy: details?.username,
        model: {
            id: modelDetails?.id,
            name: modelDetails?.name,
            description: modelDetails?.description,
            umiIndex: modelDetails?.umiIndex,
            modelType: modelDetails?.modelType,
        },
        parentId: nodeDetailsById?.node?.id,
        isCloudPushEligible: false,
        assetGroupId: '',
        equipmentAttributeList: [],
        streamList: [],
        nodeAttributeList: [],
        nodeType: {
            id: editNodeDetails
                ? nodeDetailsById?.node?.nodeType?.id
                : nodeTypeDetails?.id,
            name: editNodeDetails
                ? nodeDetailsById?.node?.nodeType?.name
                : nodeTypeDetails?.name,
            level: editNodeDetails
                ? nodeDetailsById?.node?.nodeType?.level
                : nodeTypeDetails?.level,
            description: editNodeDetails
                ? nodeDetailsById?.node?.nodeType?.description
                : nodeTypeDetails?.description,
            createdBy: details?.username,
            updatedBy: details?.username,
            umiIndex: editNodeDetails
                ? nodeDetailsById?.node?.nodeType?.umiIndex
                : nodeTypeDetails?.umiIndex,
            categories: editNodeDetails
                ? nodeDetailsById?.node?.nodeType?.categories
                : nodeTypeDetails?.categories,
        },
    });
    const { name, description, assetGroupId } = payload;
    const initialValues = {
        name: editNodeDetails ? nodeDetailsById?.node?.name : '',
        id: editNodeDetails ? nodeDetailsById?.node?.assetGroupId : '',
        description: editNodeDetails ? nodeDetailsById?.node?.description : '',
        nodeType: editNodeDetails ? nodeDetailsById?.node?.nodeType?.name : '',
        pushToCloud:
            editNodeDetails && nodeDetailsById.node?.isCloudPushEligible,
        equipmentClass: editNodeDetails
            ? nodeDetailsById?.node?.equipmentClass?.name
            : null,
    };

    const nodeAddition = (value: any): any => {
        if (enableField) {
            dispatch(
                addNode({
                    ...payload,
                    parentId: nodeDetailsById?.node?.id,
                    modelId: modelDetails?.id,
                    equipmentClass: {
                        id: equipmentDataById?.id,
                        name: equipmentDataById?.name,
                        description: equipmentDataById?.description,
                        categories: equipmentDataById?.categories,
                        umiIndex: equipmentDataById?.umiIndex,
                    },
                    nodeType: {
                        id: nodeTypeDetails?.id,
                        name: nodeTypeDetails?.name,
                        level: nodeTypeDetails?.level,
                        description: nodeTypeDetails?.description,
                        umiIndex: nodeTypeDetails?.umiIndex,
                        categories: nodeTypeDetails?.categories,
                    },
                    isCloudPushEligible: value.pushToCloud,
                })
            );
        } else {
            dispatch(
                addNode({
                    ...payload,
                    parentId: nodeDetailsById?.node?.id,
                    modelId: modelDetails?.id,
                    nodeType: {
                        id: nodeTypeDetails?.id,
                        name: nodeTypeDetails?.name,
                        level: nodeTypeDetails?.level,
                        description: nodeTypeDetails?.description,
                        umiIndex: nodeTypeDetails?.umiIndex,
                        categories: nodeTypeDetails?.categories,
                    },
                    isCloudPushEligible: value.pushToCloud,
                })
            );
        }
        dispatch({
            type: CLOSE_ADD_NODE_DRAWER,
        });
        dispatch({
            type: EMPTY_EQUIPMENT_CLASS_DETAILS,
        });
    };
    useEffect(() => {
        dispatch(getNodeTypeId(nodeDetailsById?.node?.nodeType?.id));
    }, [editNodeDetails]);
    const editNodeInfo = (values: any): any => {
        dispatch(
            editNode({
                ...payload,
                id: nodeDetailsById?.node?.id,
                parentId: Object.keys(nodeDetailsById?.parentNode)?.length
                    ? nodeDetailsById?.parentNode?.id
                    : 0,
                modelId: modelDetails?.id,
                assetGroupId: values?.id,
                name: values?.name,
                nodeType: {
                    id: nodeTypeDetails?.id,
                    name: nodeTypeDetails?.name,
                    level: nodeTypeDetails?.level,
                    description: nodeTypeDetails?.description,
                    umiIndex: nodeTypeDetails?.umiIndex,
                    categories: nodeTypeDetails?.categories,
                },
                description: values?.description,
                isCloudPushEligible: values.pushToCloud,
                equipmentClass: {
                    id: equipmentDataById?.id,
                    name: equipmentDataById?.name,
                    description: equipmentDataById?.description,
                    categories: equipmentDataById?.categories,
                    umiIndex: equipmentDataById?.umiIndex,
                },
            })
        );
        dispatch({
            type: CLOSE_EDIT_NODE_DETAILS,
        });
        dispatch({
            type: EMPTY_EQUIPMENT_CLASS_DETAILS,
        });
    };
    useEffect(() => {
        if (enableField) {
            dispatch(
                getEquipmentClassList({
                    page: PAGE,
                    pageSize: DEFAULT_PAGE_SIZE,
                })
            );
        }
    }, [enableField]);
    const equipmentList = useSelector(
        (state: any) =>
            state?.assetModelling?.equipment?.equipmentClassTableList
    );
    const equipmentDataById = useSelector(
        (state: any) => state?.assetModelling?.equipment?.equipmentClassById
    );
    const nodeDetailsLoading = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.nodeDetailsLoading
    );
    const closeDrawer = (): any => {
        if (editNodeDetails) {
            dispatch({ type: CLOSE_EDIT_NODE_DETAILS });
            dispatch({
                type: EMPTY_EQUIPMENT_CLASS_DETAILS,
            });
        } else {
            dispatch({ type: CLOSE_ADD_NODE_DRAWER });
            dispatch({
                type: EMPTY_EQUIPMENT_CLASS_DETAILS,
            });
        }
        setEnableField(false);
    };
    useEffect(() => {
        editNodeDetails &&
            nodeDetailsById?.node?.nodeType?.level ===
                parseInt(EquipmentModule?.level) &&
            setEnableField(true);
        editNodeDetails &&
            nodeDetailsById?.node?.nodeType?.level !==
                parseInt(EquipmentModule?.level) &&
            setEnableField(false);
    }, [nodeDetailsById?.node?.nodeType?.level]);

    return (
        <>
            {nodeDetailsLoading ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                <>
                    <Drawer
                        className="modellingDrawer"
                        placement="right"
                        size="default"
                        getContainer={false}
                        closable={false}
                        open={true}
                        onClose={closeDrawer}
                    >
                        <>
                            <div>
                                <Form
                                    form={form}
                                    layout="vertical"
                                    className="modellingDrawer__form"
                                    initialValues={initialValues}
                                    onFinish={
                                        editNodeDetails
                                            ? editNodeInfo
                                            : nodeAddition
                                    }
                                >
                                    <div>
                                        <Row>
                                            <Col span={9}>
                                                <Row>
                                                    <Col
                                                        span={24}
                                                        className="modellingDrawer__heading fw-500 fs-16"
                                                    >
                                                        <span>
                                                            {editNodeDetails
                                                                ? t(
                                                                      'assetModeller.modelOverview.editNode'
                                                                  )
                                                                : t(
                                                                      'assetModeller.modelOverview.addNode'
                                                                  )}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col span={8}>
                                                <div className="pushToCloud">
                                                    {t(
                                                        'assetModeller.modelOverview.pushToCloud'
                                                    )}
                                                </div>
                                            </Col>
                                            <Col span={5}>
                                                <Form.Item
                                                    name="pushToCloud"
                                                    valuePropName="checked"
                                                >
                                                    <Switch />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <Button
                                                    className="modellingDrawer__icon"
                                                    type="text"
                                                    onClick={() => {
                                                        editNodeDetails
                                                            ? dispatch({
                                                                  type: CLOSE_EDIT_NODE_DETAILS,
                                                              }) &&
                                                              dispatch({
                                                                  type: EMPTY_EQUIPMENT_CLASS_DETAILS,
                                                              })
                                                            : dispatch({
                                                                  type: CLOSE_ADD_NODE_DRAWER,
                                                              }) &&
                                                              dispatch({
                                                                  type: EMPTY_EQUIPMENT_CLASS_DETAILS,
                                                              });
                                                    }}
                                                    icon={
                                                        <CloseOutlined
                                                            onClick={() =>
                                                                editNodeDetails
                                                            ? dispatch({
                                                                  type: CLOSE_EDIT_NODE_DETAILS,
                                                              }) &&
                                                              dispatch({
                                                                  type: EMPTY_EQUIPMENT_CLASS_DETAILS,
                                                              })
                                                            : dispatch({
                                                                  type: CLOSE_ADD_NODE_DRAWER,
                                                              }) &&
                                                              dispatch({
                                                                  type: EMPTY_EQUIPMENT_CLASS_DETAILS,
                                                              })
                                                            }
                                                        />
                                                    }
                                                ></Button>
                                            </Col>
                                        </Row>
                                        <Divider className="modellingDrawer__divider" />
                                    </div>
                                    <div className="scrollable-content">
                                        <Form.Item
                                            name="nodeType"
                                            label={t(
                                                'assetModeller.modelOverview.nodeType'
                                            )}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Node Type is required',
                                                },
                                            ]}
                                        >
                                            <Select
                                                onChange={(
                                                    key,
                                                    option: any
                                                ) => {
                                                    dispatch(
                                                        getNodeTypeId(
                                                            option?.id
                                                        )
                                                    );
                                                    if (
                                                        option?.key ===
                                                        EquipmentModule?.level
                                                    ) {
                                                        setEnableField(true);
                                                    }
                                                    if (
                                                        option?.key !==
                                                        EquipmentModule?.level
                                                    ) {
                                                        setEnableField(false);
                                                    }
                                                }}
                                            >
                                                {nodeType?.map((item: any) => (
                                                    <Option
                                                        key={item?.level}
                                                        id={item?.id}
                                                        value={item?.name}
                                                    >
                                                        {item?.value}
                                                    </Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            name="nodeParent"
                                            label={t(
                                                'assetModeller.modelOverview.nodeParent'
                                            )}
                                        >
                                            <Input
                                                className="modellingDrawer__inputField"
                                                disabled={true}
                                                defaultValue={
                                                    editNodeDetails
                                                        ? Object.keys(
                                                              nodeDetailsById?.parentNode
                                                          )?.length
                                                            ? nodeDetailsById
                                                                  ?.parentNode
                                                                  ?.name
                                                            : 'None'
                                                        : nodeDetailsById?.node
                                                              ?.name
                                                }
                                            />
                                        </Form.Item>
                                        <Divider className="modellingDrawer__divider" />

                                        <Form.Item
                                            name="name"
                                            label={t(
                                                'assetModeller.modelOverview.assetName'
                                            )}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Asset/Asset Group Name is required',
                                                },
                                                {
                                                    validator: async (
                                                        _,
                                                        value
                                                    ) => {
                                                        if (
                                                            value &&
                                                            value.trimStart() !==
                                                                value
                                                        ) {
                                                            throw new Error(
                                                                'Asset/Asset Group Name cannot start with a space'
                                                            );
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="modellingDrawer__inputField"
                                                value={name}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    setPayload({
                                                        ...payload,
                                                        name: e?.target?.value,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="id"
                                            label={t(
                                                'assetModeller.modelOverview.assetId'
                                            )}
                                        >
                                            <Input
                                                className="modellingDrawer__inputField"
                                                value={assetGroupId}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    setPayload({
                                                        ...payload,
                                                        assetGroupId:
                                                            e?.target?.value,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="description"
                                            label={t(
                                                'assetModeller.modelOverview.description'
                                            )}
                                        >
                                            <Input
                                                className="modellingDrawer__inputField"
                                                value={description}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) => {
                                                    setPayload({
                                                        ...payload,
                                                        description:
                                                            e?.target?.value,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="equipmentClass"
                                            className={
                                                enableField ? '' : 'hideField'
                                            }
                                        >
                                            <Select
                                                placeholder={t(
                                                    'assetModeller.modelOverview.equipmentclassPlaceholder'
                                                )}
                                                onChange={(
                                                    key,
                                                    option: any
                                                ) => {
                                                    dispatch(
                                                        getEquipmentClassId(
                                                            parseInt(
                                                                option?.key
                                                            )
                                                        )
                                                    );
                                                }}
                                            >
                                                {equipmentList?.records?.map(
                                                    (item: any) => (
                                                        <Option
                                                            key={item?.id}
                                                            value={item?.name}
                                                        >
                                                            {item?.value}
                                                        </Option>
                                                    )
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </div>
                                    <div className="modellingDrawer__button">
                                        <Divider />
                                        <Row className="modellingDrawer__footerButtons">
                                            <Col
                                                span={8}
                                                className="modellingDrawer__cancelButton"
                                            >
                                                <CustomButton
                                                    type={BUTTONTYPE.cancel}
                                                    disabled={false}
                                                    handleClick={() => {
                                                        editNodeDetails
                                                            ? dispatch({
                                                                  type: CLOSE_EDIT_NODE_DETAILS,
                                                              }) &&
                                                              dispatch({
                                                                  type: EMPTY_EQUIPMENT_CLASS_DETAILS,
                                                              })
                                                            : dispatch({
                                                                  type: CLOSE_ADD_NODE_DRAWER,
                                                              }) &&
                                                              dispatch({
                                                                  type: EMPTY_EQUIPMENT_CLASS_DETAILS,
                                                              });
                                                    }}
                                                />
                                            </Col>
                                            <Col
                                                span={8}
                                                className="modellingDrawer__createBtn"
                                            >
                                                <CustomButton
                                                    disabled={false}
                                                    type={
                                                        editNodeDetails
                                                            ? 'Save'
                                                            : BUTTONTYPE.createNode
                                                    }
                                                    typeOfButton={'submit'}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </div>
                        </>
                    </Drawer>
                </>
            )}
        </>
    );
};
export default FlexibleModelFormDrawer;
