import * as types from 'redux/types/assetModellingTypes';

export const setEquipmentState = (payload: string): any => {
    return {
        type: types.SET_EQUIPMENT_STATE,
        payload: payload,
    };
};

export const getModelList = (payload?: any): any => ({
    type: types.GET_TOTAL_MODEL_LIST,
    payload: payload,
});

export const setNodeData = (payload: any): any => ({
    type: types.SET_NODE_DATA,
    payload: payload,
});

export const getNodeTypeDetails = (payload: any): any => {
    return {
        type: types.GET_NODE_TYPE_DETAILS,
        payload: payload,
    };
};

export const setEquipmentClass = (payload: any): any => ({
    type: types.SET_EQUIPMENT_DATA,
    payload: payload,
});

export const getEquipmentClassList = (payload: any): any => {
    return {
        type: types.GET_EQUIPMENT_DETAILS,
        payload: payload,
    };
};

export const putNodeTypeUpdate = (payload: any): any => ({
    type: types.PUT_NODE_TYPE_UPDATE,
    payload: payload,
});

export const addModelDetails = (payload: any): any => ({
    type: types.SET_MODEL_DETAILS,
    payload: payload,
});

export const getModelDetailsById = (payload: any): any => ({
    type: types.GET_MODEL_DETAILS_BY_ID,
    payload: payload,
});

export const deletModel = (payload: any): any => ({
    type: types.DELETE_MODEL,
    payload: payload,
});

export const editModelDetails = (payload: any): any => ({
    type: types.EDIT_MODEL_DETAILS,
    payload: payload,
});

export const getTagListByDevice = (payload: any): any => ({
    type: types.GET_TAG_LIST_BY_DEVICE,
    payload: payload,
});
export const getNodeTypeId = (payload: any): any => {
    return {
        type: types.GET_NODE_TYPE_BY_ID,
        payload: payload,
    };
};
export const putEquipmentClassUpdate = (payload: any): any => ({
    type: types.PUT_EQUIPMENT_CLASS_UPDATE,
    payload: payload,
});
export const cloneEquipmentClass = (payload: any): any => ({
    type: types.CLONE_EQUIPMENT_CLASS_REQUEST,
    payload: payload,
});

export const cloneNodeType = (payload: any): any => ({
    type: types.CLONE_NODE_TYPE_REQUEST,
    payload: payload,
});

export const deleteNodeType = (payload: any): any => ({
    type: types.DELETE_NODE_TYPE,
    payload: payload,
});

export const getMappedTagsList = (payload: any): any => ({
    type: types.GET_MAPPED_TAGS_LIST,
    payload: payload,
});

export const getEquipmentClassId = (payload: any): any => {
    return {
        type: types.GET_EQUIPMENT_CLASS_BY_ID,
        payload: payload,
    };
};

export const deleteEquipmentClass = (payload: any): any => ({
    type: types.DELETE_EQUIPMENT_CLASS,
    payload: payload,
});
export const mapTagNodes = (payload: any): any => ({
    type: types.MAP_TAG_NODES,
    payload: payload,
});

export const getDataTypeList = (payload: any): any => ({
    type: types.GET_DATATYPE_LIST,
    payload: payload,
});

export const getUnitsList = (payload: any): any => ({
    type: types.GET_UNITS_LIST,
    payload: payload,
});

export const updateTagFactor = (payload: any): any => ({
    type: types.UPDATE_TAG_FACTOR,
    payload: payload,
});

export const getTreeByModelId = (payload: any): any => ({
    type: types.GET_TREE_BY_MODEL_ID,
    payload: payload,
});

export const getNodeDetailsById = (payload: any): any => ({
    type: types.GET_NODE_DETAILS_BY_ID,
    payload: payload,
});

export const deleteNode = (payload: any): any => ({
    type: types.DELETE_NODE,
    payload: payload,
});
export const setNodeInfo = (payload: any): any => ({
    type: types.SET_NODE_INFO,
    payload: payload,
});

export const addNode = (payload: any): any => ({
    type: types.ADD_NODE,
    payload: payload,
});

export const unMapTagNodes = (payload: any): any => ({
    type: types.REMOVE_MAPPING,
    payload: payload,
});

export const editNode = (payload: any): any => ({
    type: types.EDIT_NODE_DETAILS,
    payload: payload,
});
export const selectedAsset = (payload: any): any => ({
    type: types.SELECTED_ASSET,
    payload: payload,
});
