import { Drawer, Row, Col, Button, Divider, Form, Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import './index.scss';
import React, { useEffect, useState, useCallback } from 'react';
import { setEquipmentState } from 'redux/actions/AssetModellingActions/assetModellingAction';
import { useDispatch, useSelector } from 'react-redux';
import { EMPTY, EquipmentState } from 'types/enums';
import { useTranslation } from 'react-i18next';

const ViewEquipmentClass: React.FC<any> = ({ open }): any => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const [initialValues, setInitialValues] = useState<any>({});

    const equipmentClassId = useSelector(
        (state: any) => state.assetModelling?.equipment?.equipmentClassById
    );

    useEffect(() => {
        if (equipmentClassId) {
            setInitialValues({
                name: equipmentClassId?.name || EMPTY.string,
                description: equipmentClassId?.description || EMPTY.string,
            });
        }
    }, [equipmentClassId]);

    const resetFormValues = useCallback(() => {
        form.setFieldsValue(initialValues);
    }, [form, initialValues]);

    useEffect(() => {
        if (open) {
            resetFormValues();
        }
    }, [open, resetFormValues]);

    return (
        <>
            <Drawer
                className="viewClassDrawer"
                placement="right"
                size="default"
                getContainer={false}
                closable={false}
                open={open}
            >
                <>
                    <div>
                        <Row>
                            <Col
                                span={17}
                                className="viewClassDrawer__heading fw-500 fs-16"
                            >
                                <span>
                                    {t(
                                        'assetModeller.configuration.viewEquipmentClass'
                                    )}
                                </span>
                            </Col>
                            <Col span={5}>
                                <Button
                                    className="viewClassDrawer__editBtn"
                                    onClick={() => {
                                        dispatch(
                                            setEquipmentState(
                                                EquipmentState?.edit
                                            )
                                        );
                                    }}
                                    disabled={false}
                                >
                                    {t('commonStr.edit')}
                                </Button>
                            </Col>
                            <Col span={2}>
                                <Button
                                    className="viewClassDrawer__icon"
                                    type="text"
                                    onClick={() => {
                                        dispatch(
                                            setEquipmentState(
                                                EquipmentState?.display
                                            )
                                        );
                                    }}
                                    icon={<CloseOutlined />}
                                ></Button>
                            </Col>
                        </Row>
                        <Divider className="viewClassDrawer__divider" />
                        <Form
                            layout="vertical"
                            form={form}
                            initialValues={initialValues}
                        >
                            <Form.Item
                                name="name"
                                label={t(
                                    'assetModeller.configuration.equipmentClassName'
                                )}
                                required={true}
                            >
                                <Input
                                    className="viewClassDrawer__inputField"
                                    disabled={true}
                                />
                            </Form.Item>
                            <Form.Item
                                name="description"
                                label={t(
                                    'assetModeller.configuration.description'
                                )}
                                required={false}
                            >
                                <Input
                                    className="viewClassDrawer__inputField"
                                    disabled={true}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </>
            </Drawer>
        </>
    );
};
export default ViewEquipmentClass;
