import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    cloneNodeType,
    deleteNodeType,
    getNodeTypeId,
} from 'redux/actions/AssetModellingActions/assetModellingAction';
import { EMPTY, nodePopoverItems } from 'types/enums';
import { cancelHandle, modalShow } from 'utils/modalFunction';
import { ReactComponent as DeleteIcon } from 'assets/icons/deleteNew.svg';
import { ReactComponent as Clone } from 'assets/icons/Clone.svg';
import { useTranslation } from 'react-i18next';
import { PAGE, PAGE_SIZE } from 'utils/constants';

const NodeMoreContent: React.FC<any> = ({
    setOpenDrawer,
    nodeId,
    onItemClick,
    setSearchState,
    setPage,
    setPageSize,
    setEditNode
}) => {
    const { t } = useTranslation('translation');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalCloneOpen, setIsModalCloneOpen] = useState(false);

    const dispatch = useDispatch();
    const values = [
        {
            title: nodePopoverItems?.edit,
        },
        {
            title: nodePopoverItems?.delete,
        },
        {
            title: nodePopoverItems?.clone,
        },
    ];

    return (
        <>
            <div className="more-container">
                <div className={`more-content`}>
                    <ul>
                        {values &&
                            values?.length > 0 &&
                            values?.map((item: any) => (
                                <li
                                    key={item?.title}
                                    className="moreContent__items"
                                >
                                    <span
                                        className="moreContent__option"
                                        onClick={() => {
                                            if (
                                                item?.title ===
                                                nodePopoverItems?.edit
                                            ) {
                                                setEditNode(true)
                                                setOpenDrawer(true);
                                                dispatch(getNodeTypeId(nodeId));
                                            } else if (
                                                item?.title ===
                                                nodePopoverItems?.clone
                                            ) {
                                                modalShow(
                                                    isModalCloneOpen,
                                                    setIsModalCloneOpen
                                                );
                                            } else if (
                                                item?.title ===
                                                nodePopoverItems?.delete
                                            ) {
                                                modalShow(
                                                    isModalOpen,
                                                    setIsModalOpen
                                                );
                                            }
                                            onItemClick();
                                        }}
                                    >
                                        {item?.title}
                                    </span>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
            {isModalOpen && (
                <ConfirmationModal
                    open={isModalOpen}
                    icon={<DeleteIcon />}
                    onOk={() => {
                        dispatch(
                            deleteNodeType({
                                nodeTypeId: nodeId,
                            })
                        );
                        setPage(PAGE);
                        setPageSize(PAGE_SIZE);
                        setSearchState(EMPTY.string);
                        setIsModalOpen(false);
                    }}
                    onCancel={() => {
                        cancelHandle(isModalOpen, setIsModalOpen);
                    }}
                    text={t(
                        'assetModeller.configuration.deleteNodeTypeModalText'
                    )}
                    customClassName="confirmationModal modelDeletion"
                />
            )}
            {isModalCloneOpen && (
                <ConfirmationModal
                    open={isModalCloneOpen}
                    icon={<Clone />}
                    onOk={() => {
                        dispatch(
                            cloneNodeType({
                                nodeTypeId: nodeId,
                            })
                        );
                        setPage(PAGE);
                        setPageSize(PAGE_SIZE);
                        setSearchState(EMPTY.string);
                        setIsModalCloneOpen(false);
                    }}
                    onCancel={() => {
                        cancelHandle(isModalCloneOpen, setIsModalCloneOpen);
                    }}
                    text={t(
                        'assetModeller.configuration.cloneNodeTypeModalText'
                    )}
                />
            )}
        </>
    );
};
export default NodeMoreContent;
