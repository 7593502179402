import Table, { type ColumnsType } from 'antd/es/table';
import { useState } from 'react';
import { type DataType } from 'types/interfaces/PropsInterfaces';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import './index.scss';
import { data } from './otData';

const ITTable: React.FC<any> = ({ setOpenModelInfo }) => {
    const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
    const rowSelection: any = {
        onSelect: (record: any, selected: boolean, selectedRows: any) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );

            if (selected) {
                setSelectedRowIds([...selectedRowIds, record?.id]);
            } else {
                setSelectedRowIds(
                    selectedRowIds?.filter((id: number) => id !== record?.id)
                );
            }
        },
        onSelectAll: (selected: boolean, selectedRows: DataType[]) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );

            if (selected) {
                const newSelectedIds = selectedRows?.map(
                    (record) => record?.id
                );
                setSelectedRowIds(newSelectedIds);
            } else {
                setSelectedRowIds([]);
            }
        },
    };

    const columns: ColumnsType<any> = [
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>Tag ID</div>
                    <div className="sortArrows">
                        <UpArrow cursor="pointer" onClick={() => {}} />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {}}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'tagId',
            key: 'tagId',
            width: '150px',
            render: (_: any, data: any) => (
                <>
                    <div
                        className="attributeTableWrapper__nameData"
                        onClick={() => {}}
                    >
                        <div className="blaTableWrapper__status">
                            <span
                                className="fs-14 fw-500 name"
                                onClick={() => {
                                    setOpenModelInfo(true);
                                }}
                            >
                                {data?.tagTd}
                            </span>
                        </div>
                    </div>
                </>
            ),
        },

        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>Tag Name</div>
                    <div className="sortArrows">
                        <UpArrow cursor="pointer" onClick={() => {}} />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {}}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'description',
            key: 'description',
            width: '200px',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__subItems">
                        {data?.tagName}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>Source Name</div>
                    <div className="sortArrows">
                        <UpArrow cursor="pointer" onClick={() => {}} />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {}}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'sourceName',
            key: 'sourceName',
            width: '200px',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {data?.deviceName}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>UOM</div>
                    <div className="sortArrows">
                        <UpArrow cursor="pointer" onClick={() => {}} />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {}}
                        />
                    </div>
                </div>
            ),
            key: 'uom',
            dataIndex: 'uom',
            width: '200px',
            render: (_: any, data: any) => (
                <>
                    <div>{data?.uom}</div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>Assigned/Unassigned</div>
                    <div className="sortArrows">
                        <UpArrow cursor="pointer" onClick={() => {}} />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {}}
                        />
                    </div>
                </div>
            ),
            key: 'createdOn',
            dataIndex: 'createdOn',
            width: '200px',
            render: (_: any, data: any) => (
                <>
                    <div>{data?.assigned}</div>
                </>
            ),
        },
    ];
    return (
        <>
            <div className="tagOverview">
                <Table
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                        selectedRowKeys: selectedRowIds,
                    }}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    scroll={{ y: '180px' }}
                />
            </div>
        </>
    );
};
export default ITTable;
