import * as types from "redux/types/businessIntegratorTypes";

export const getLabelsList = (payload: any): any => ({
  type: types.GET_LABELS_LIST,
  payload: payload,
});

export const getMonitoringList = (payload: any): any => ({
    type: types.GET_MONITORING_LIST,
    payload: payload,
  });

export const setMonitoringState = (payload: any): any => ({
  type: types.SET_MOINITORING_STATE,
  payload: payload,
});

export const getCreatedByList = (): any => ({
  type: types.GET_CREATEDBY_LIST,
});

export const getAllMonitoringList = (): any => ({
    type: types.GET_ALL_MONITORING_LIST,
});

export const getMonitoringById = (payload: any): any => ({
  type: types.GET_MONITORING_BY_ID,
  payload: payload,
});

export const reprocessMonitoring = (payload: any): any => ({
  type: types.REPROCESS_MONITORING,
  payload: payload,
});
