import { Button, Card, Col, Form, Row, Spin, Switch } from 'antd';
import { ReactComponent as RightArrow } from 'assets/icons/rightBlueArrow.svg';
import { type ProviderListingProps } from 'types/interfaces/PropsInterfaces';
import {
    activateDeactivateConfig,
    getAllProviderConfig,
    getProviderByChannelId,
    getProviderConfigCreateFeildsById,
    // getProviderConfigUpdateFeildsById,
    notificationStateAction,
} from 'redux/actions/NotificationConfiguration/notificationConfiguration';

import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { NOTIFICATIONTYPE } from 'types/enums';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getUrlForNotificationService } from 'utils/commonFunction';

const ProviderListing: React.FC<ProviderListingProps> = ({
    selectedChannel,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    const handleConfiguration = (provider: any): any => {
        dispatch(notificationStateAction(NOTIFICATIONTYPE.create));
        dispatch(getProviderConfigCreateFeildsById(provider?._id));
    };

    const manageConfiguration = (provider: any): any => {
        dispatch(notificationStateAction(NOTIFICATIONTYPE.edit));
        // dispatch(getProviderConfigUpdateFeildsById(provider?._id));
        dispatch(getProviderConfigCreateFeildsById(provider?._id));
        dispatch(
            getAllProviderConfig({
                providerName: provider?.slug,
                channelName: provider.type,
            })
        );
    };

    const providerListById = useSelector(
        (state: any) => state?.notificationConfiguration?.providerListId
    );

    const isProviderCardShow = useSelector(
        (state: any) => state?.notificationConfiguration?.isProviderCardShow
    );

    useEffect(() => {
        if (selectedChannel) {
            dispatch(
                getProviderByChannelId({
                    type: selectedChannel?.toLocaleLowerCase(),
                })
            );
        }
    }, [selectedChannel]);

    return (
        <>
            <div className="provider__container">
                {isProviderCardShow ? (
                    <Spin />
                ) : (
                    <>
                        {' '}
                        <Row gutter={[16, 16]}>
                            {providerListById?.map(
                                (provider: any, index: any) => (
                                    <Col span={8} key={index}>
                                        <Form.Item
                                            name={`providerType-${index}`}
                                        >
                                            <Card className="provider__card">
                                                <div className="provider__cardContent">
                                                    <div className="provider__cardDetails">
                                                        <div className="circle normal-circle">
                                                            <img
                                                                src={
                                                                    getUrlForNotificationService() +
                                                                    provider
                                                                        ?.logo
                                                                        ?.url
                                                                }
                                                            />
                                                            <div className="fw-500 fs-16 text">
                                                                {provider?.name
                                                                    .charAt(0)
                                                                    .toUpperCase() +
                                                                    provider?.name?.slice(
                                                                        1
                                                                    )}
                                                            </div>
                                                        </div>
                                                        <div className="fw-400 fs-14 desc">
                                                            {
                                                                provider?.description
                                                            }
                                                        </div>
                                                    </div>
                                                    <div
                                                        className={`${
                                                            provider.hasConfig
                                                                ? 'provider__switch'
                                                                : ''
                                                        }`}
                                                    >
                                                        {/* {provider.hasConfig ? (
                                                            <Switch
                                                                value={
                                                                    provider?.isVerified
                                                                }
                                                            />
                                                        ) : null} */}
                                                        {provider.hasConfig ? (
                                                            <Switch
                                                                key={
                                                                    provider.key
                                                                }
                                                                value={
                                                                    provider?.isActive
                                                                }
                                                                onClick={(
                                                                    checked: boolean
                                                                ) => {
                                                                    dispatch(
                                                                        activateDeactivateConfig(
                                                                            {
                                                                                providerName:
                                                                                    provider?.slug,
                                                                                channelName:
                                                                                    provider.type,
                                                                                selectedChannel:
                                                                                    selectedChannel,
                                                                            }
                                                                        )
                                                                    );
                                                                }}
                                                            />
                                                        ) : null}
                                                        <div className="provider__createConfigBtn fs-400 fs-14">
                                                            {provider.hasConfig ? (
                                                                <Button
                                                                    type="primary"
                                                                    onClick={() =>
                                                                        manageConfiguration(
                                                                            provider
                                                                        )
                                                                    }
                                                                >
                                                                    {t(
                                                                        'NotificationConfigurator.manage'
                                                                    )}
                                                                    <RightArrow />
                                                                </Button>
                                                            ) : (
                                                                <Button
                                                                    className="create-config"
                                                                    type="primary"
                                                                    onClick={() =>
                                                                        handleConfiguration(
                                                                            provider
                                                                        )
                                                                    }
                                                                >
                                                                    {t(
                                                                        'NotificationConfigurator.createConfiguration'
                                                                    )}
                                                                    <RightArrow />
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </Card>
                                        </Form.Item>
                                    </Col>
                                )
                            )}
                        </Row>
                    </>
                )}
            </div>
        </>
    );
};

export default ProviderListing;
