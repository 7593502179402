import { Input } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/common/CustomButton';
import './index.scss';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createIndustry } from 'redux/actions/ConfigureActions/attributeActions';
import { parseJwt } from 'utils/jwtTokenFunction';
import { useTranslation } from 'react-i18next';

const CategoryIndustryContent: React.FC<any> = ({ setIndustryCreate }) => {
    const { t } = useTranslation('translation');
    const [industryNameState, setIndustryNameState] = useState('');
    const details = parseJwt();
    const dispatch = useDispatch();
    return (
        <>
            <div className="createIndustryContent">
                <div className="createIndustryContent__heading">
                    <div className="createIndustryContent__heading__name">
                        {t('commonStr.AddIndustry')}
                    </div>
                    <span>
                        <CloseOutlined
                            onClick={() => {
                                setIndustryCreate(false);
                                setIndustryNameState('');
                            }}
                        />
                    </span>
                </div>
                <div className="createIndustryContent__bodyWrapper">
                    <div className="createIndustryContent__body">
                        <div className="createIndustryContent__body__label">
                            <span className="mandatoryClass">*</span>{' '}
                            {t('attributeDefinition.tableColumn.name')}
                        </div>
                        <Input
                            maxLength={100}
                            placeholder={t('commonStr.EnterIndustryName')}
                            value={industryNameState}
                            onChange={(e) => {
                                setIndustryNameState(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <div className="createIndustryContent__footer">
                    <div className="createIndustryContent__footer__buttonDiv">
                        <CustomButton
                            type={t('commonStr.cancel')}
                            disabled={false}
                            handleClick={() => {
                                setIndustryCreate(false);
                                setIndustryNameState('');
                            }}
                        />
                        <CustomButton
                            type={t('commonStr.save')}
                            typeOfButton="submit"
                            disabled={industryNameState === ''}
                            handleClick={() => {
                                dispatch(
                                    createIndustry({
                                        name: industryNameState,
                                        requestedBy: details?.username,
                                    })
                                );
                                setIndustryCreate(false);
                                setIndustryNameState('');
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default CategoryIndustryContent;
