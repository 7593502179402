import { baseUrlSetter } from 'utils/commonFunction';
import Api from '..';
import { message } from 'antd';
import { EMPTY } from 'types/enums';

export const setNodeDataService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    try {
        const response = Api.post(`/nodeType`, payload);
        return await Promise.resolve(response);
    } catch (error: any) {
        throw new Error(error);
    }
};
export const getNodeTypeDetailsService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = {
        name: payload?.name ?? EMPTY.string,
        pageNumber: payload?.page,
        pageSize: payload?.pageSize,
        sortBy: payload?.sortColumn,
        sortOrder: payload?.sortOrder,
        search: payload?.search,
    };
    try {
        const response = Api.get(`/nodeType`, { params: params });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const getModelListService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = {
        sortBy: payload?.sortColumn,
        sortOrder: payload?.sortOrder,
        pageNumber: payload?.page,
        pageSize: payload?.pageSize,
    };
    try {
        const response = Api.get(`/model/list`, {
            params: params,
        });
        return await Promise.resolve(response);
    } catch (error: any) {
        if(error?.response?.status === 401){
            message?.error('Authorization Failed')
        }
        else{
        message.error(error?.response?.data?.message);
        throw new Error(error);
        }
    }
};
export const NodeTypeUpdateService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = payload;
    try {
        const response = Api.put(`/nodeType/`, params);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};
export const setEquipmentClassService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    try {
        const response = Api.post(`/equipment`, payload);
        return await Promise.resolve(response);
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getEquipmentClassListService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('asset');
    const params = {
        pageNumber: payload?.page,
        pageSize: payload?.pageSize,
        sortBy: payload?.sortColumn,
        sortOrder: payload?.sortOrder,
        name: payload?.name ?? EMPTY.string,
    };
    try {
        const response = Api.get(`/equipment/getList`, { params: params });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const addModelService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = payload;
    try {
        const response = Api.post(`/model`, params);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const getModelDetailsByIdService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('asset');
    const params = payload;
    try {
        const response = Api.get(`/model/${params}`);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const deleteModelService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = payload;
    try {
        const response = Api.delete(`/model`, {
            params: params,
        });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const editModelService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = payload;
    try {
        const response = Api.put(`/model`, params);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};
export const getNodeTypeIdService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = {
        nodeTypeId: payload,
    };
    try {
        const response = Api.get(`/nodeType/getDetails`, {
            params: params,
        });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};
export const getTagListByDeviceService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const deviceUuidList = payload?.deviceUuidList?.join(',');
    const params = {
        deviceUuid: deviceUuidList,
    };
    try {
        const response = Api.get(`/stream/tagListByDevices`, {
            params: params,
        });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};
export const editEquipmentClassService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = payload;
    try {
        const response = Api.put(`/equipment/`, params);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const equipmentClassCloneService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('asset');
    try {
        const response = Api.post(
            `/equipment/duplicate?equipmentId=${payload?.equipmentId}`
        );
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const getMappedTagsListService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = {
        tagName: payload?.tagName,
        source: payload?.source,
        sourceName: payload?.sourceName,
        pageNumber: payload?.page,
        dataType: payload?.dataType,
        unit: payload?.unit,
        sortOrder: payload?.sortOrder,
        sortColumn: payload?.sortColumn,
        pageSize: payload?.pageSize,
        includeChildNode: false,
    };
    try {
        const response = Api.get(`/stream/paginated/list/${payload?.nodeId}`, {
            params: params,
        });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const mapTagNodesService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = {
        nodeId: payload?.nodeId,
        tagIdList: payload?.tagIdList,
    };
    try {
        const response = Api.put(`/stream/mapTagNodes`, params);
        return await Promise.resolve(response);
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getDataTypeListService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    try {
        const response = Api.get(`/stream/dataType/list`, { params: payload });
        return await Promise.resolve(response);
    } catch (error: any) {
        throw new Error(error);
    }
};

export const getUnitsListService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    try {
        const response = Api.get(`/stream/units/list`, { params: payload });
        return await Promise.resolve(response);
    } catch (error: any) {
        throw new Error(error);
    }
};

export const updateTagFactorService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = {
        tagId: payload?.tagId,
        multiplicationFactor: payload?.multiplicationFactor,
        additionFactor: payload?.additionFactor,
        timeOffset: payload?.timeOffset,
    };
    try {
        const response = Api.put(`/stream/tagFactor`, params);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const nodeTypeCloneService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    try {
        const response = Api.post(
            `/nodeType/duplicate?nodeTypeId=${payload?.nodeTypeId}`
        );
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};
export const nodeTypeDeleteService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = payload;
    try {
        const response = Api.delete(`/nodeType`, {
            params: params,
        });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error ? error?.response?.data?.message : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const getTreeByModelIdService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = payload;
    try {
        const response = Api.get(`/node/tree/${params}`);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const getNodeDetailsByIdService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = payload;
    try {
        const response = Api.get(`/node/${params?.nodeId}`);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const deleteNodeService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = payload;
    try {
        const response = Api.delete(`/node?nodeId=${params?.nodeId}`);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const addNodeService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    try {
        const response = Api.post(`/node`, payload);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};
export const equipmentClassDeleteService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('asset');
    const params = payload;
    try {
        const response = Api.delete(`/equipment`, {
            params: params,
        });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error ? error?.response?.data?.message : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const getEquipmentIdService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = {
        equipmentId: payload,
    };
    try {
        const response = Api.get(`/equipment/getDetails`, {
            params: params,
        });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const unMapTagNodesService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = {
        tagIdList: payload?.tagIdList,
    };
    try {
        const response = Api.put(`/stream/unmapTagNodes`, params);
        return await Promise.resolve(response);
    } catch (error: any) {
        throw new Error(error);
    }
};

export const editNodeService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    try {
        const response = Api.put(`/node`, payload);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};
