import React, { useEffect, useState } from 'react';
import { Card, Col, Input, Row, Spin } from 'antd';
import CustomButton from 'components/common/CustomButton';
import CustomPagination from 'components/common/CustomPagination';
import './index.scss';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import RoleTable from './RoleTable';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAllRoles,
    getRolesList,
    setEditRoleState,
} from 'redux/actions/UserManagementActions/rolesAction';
import { EMPTY, PERMISSIONS, resourceName, ROLETYPE } from 'types/enums';
import { useParams } from 'react-router-dom';
import { getObjectByResourceName, hasPermission } from 'utils/rbacFunction';
import PermissionComponent from 'components/common/PermissionComponent';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import { PAGE, PAGE_SIZE } from 'utils/constants';

const Roles: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    const [selectedValues, setSelectedValues] = useState<any>([]);
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);
    const [payload, setPayload] = useState({
        page,
        pageSize,
    });
    const roleTableDataList = useSelector(
        (state: any) => state.userManagement.roles.rolesList
    );

    const rolesListLoading = useSelector(
        (state: any) => state.userManagement?.roles?.rolesListLoading
    );
    const rolesCountDetails = useSelector(
        (state: any) => state.userManagement?.users?.countDetails
    );

    const [roleSearchTimeout, setRoleSearchTimeout] = useState<any>(undefined);
    const [search, setSearch] = useState('');
    const [sortOrder, setSortOrder] = useState<number | null>(null);
    const [sortColumn, setSortColumn] = useState<number | null>(null);
    useEffect(() => {
        setPayload({ ...payload, page, pageSize });
    }, [page, pageSize]);

    useEffect(() => {
        search === EMPTY?.string &&
            dispatch(
                getRolesList({
                    ...payload,
                    sortOrder: sortOrder,
                    sortColumn: sortColumn,
                })
            );
    }, [payload, sortColumn, sortOrder, search]);

    useEffect(() => {
        dispatch(getAllRoles());
    }, []);

    const { currentTab } = useParams();
    useEffect(() => {
        setSelectedValues([]);
        setSearch('');
    }, [currentTab]);

    const [userPermissionDetails, setUserPermissionDetails] = useState({});
    const loggedInUserPermissionData = useSelector(
        (state: any) =>
            state.userManagement.users?.loggedInUserScreenPermissionList
    );

    const loggedInUserDetails = useSelector(
        (state: any) => state.userManagement.users?.loggedInUserDetails
    );

    useEffect(() => {
        setUserPermissionDetails(
            getObjectByResourceName(
                resourceName.roles,
                loggedInUserPermissionData
            )
        );
    }, [loggedInUserPermissionData]);

    return (
        <>
            <div className="rolesWrapper">
                <Card bordered={false}>
                    <PermissionComponent screenName={resourceName.roles}>
                        {rolesCountDetails?.activeCount +
                            rolesCountDetails?.inActiveCount >
                        0 ? (
                            <>
                                <div className="rolesWrapper__rowHeader">
                                    <Input
                                        allowClear
                                        className="usersWrapper__search"
                                        placeholder={t('roles.searchRoleUsers')}
                                        prefix={<SearchOutlined />}
                                        value={search}
                                        onChange={(e) => {
                                            setPage(PAGE);
                                            setSearch(e.target.value);
                                            if (roleSearchTimeout) {
                                                clearTimeout(roleSearchTimeout);
                                            }
                                            setRoleSearchTimeout(
                                                setTimeout(() => {
                                                    dispatch(
                                                        getRolesList({
                                                            page: PAGE,
                                                            pageSize: PAGE_SIZE,

                                                            search: e.target
                                                                .value,
                                                            sortColumn:
                                                                sortColumn,
                                                            sortOrder:
                                                                sortOrder,
                                                        })
                                                    );
                                                }, 1000)
                                            );
                                        }}
                                    />
                                    <div className="rolesWrapper__button">
                                        <CustomButton
                                            type={t('roles.createRole')}
                                            disabled={
                                                hasPermission(
                                                    userPermissionDetails,
                                                    PERMISSIONS.write
                                                )
                                                    ? false
                                                    : !loggedInUserDetails.admin
                                            }
                                            handleClick={() =>
                                                dispatch(
                                                    setEditRoleState(
                                                        ROLETYPE.create
                                                    )
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                {rolesListLoading ? (
                                    <div className="view__loader">
                                        <Spin />
                                    </div>
                                ) : (
                                    <>
                                        <Row
                                            className={
                                                roleTableDataList?.totalRecords >
                                                50
                                                    ? 'rolesWrapper__rolesListPagination'
                                                    : 'rolesWrapper__rolesList'
                                            }
                                        >
                                            <Col span={24}>
                                                <RoleTable
                                                    rolesTableDataList={
                                                        roleTableDataList?.records
                                                    }
                                                    search={selectedValues}
                                                    pageType={payload}
                                                    paginationPayload={payload}
                                                    setSortOrder={setSortOrder}
                                                    setSortColumn={
                                                        setSortColumn
                                                    }
                                                    setPage={setPage}
                                                />
                                            </Col>
                                        </Row>
                                        {roleTableDataList?.totalRecords >
                                            PAGE_SIZE && (
                                            <CustomPagination
                                                totalRecords={
                                                    roleTableDataList?.totalRecords
                                                }
                                                page={page}
                                                setPage={setPage}
                                                setPageSize={setPageSize}
                                                pageSize={pageSize}
                                            />
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <div className="rolesWrapper__noData">
                                <EmptyDataComponent
                                    textValue={t('roles.noRoles')}
                                    buttonType={{
                                        name: t('roles.createRole'),
                                        disable: false,
                                    }}
                                    loading={rolesListLoading}
                                    buttonClickHandler={() => {
                                        dispatch(
                                            setEditRoleState(ROLETYPE.create)
                                        );
                                    }}
                                />
                            </div>
                        )}
                    </PermissionComponent>
                </Card>
            </div>
        </>
    );
};
export default Roles;
