import Table, { type ColumnsType } from 'antd/es/table';
import { useState, useEffect } from 'react';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import './index.scss';
import { MoreOutlined } from '@ant-design/icons';
import { Input, Popover, Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import {
    EMPTY,
    TagMappingSubTabs,
    assetModeller,
    mappedTagsSortOption,
    sortOrder,
} from 'types/enums';
import { ReactComponent as EditIcon } from 'assets/icons/editNewIcon.svg';
import {
    getDataTypeList,
    getMappedTagsList,
    getUnitsList,
    updateTagFactor,
} from 'redux/actions/AssetModellingActions/assetModellingAction';
import { PAGE } from 'utils/constants';
import TagMappingMoreContent from './MoreContent';
import { EMPTY_MAPPED_TAGS_DATA } from 'redux/types/assetModellingTypes';
import { useTranslation } from 'react-i18next';
const { Option } = Select;

const TagTable: React.FC<any> = ({
    setPage,
    page,
    activeSubTabKey,
    setSortColumn,
    setSortOrder,
}: any) => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
    const [tableData, setTableData] = useState<any>([]);
    const rowSelection: any = {
        onSelect: (record: any, selected: boolean, selectedRows: any) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );

            if (selected) {
                setSelectedRowIds([...selectedRowIds, record?.uuid]);
            } else {
                setSelectedRowIds(
                    selectedRowIds?.filter((id: number) => id !== record?.uuid)
                );
            }
        },
        onSelectAll: (selected: boolean, selectedRows: any) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );

            if (selected) {
                const newSelectedIds = selectedRows?.map(
                    (record: any) => record?.uuid
                );
                setSelectedRowIds(newSelectedIds);
            } else {
                setSelectedRowIds([]);
            }
        },
    };
    const [popoverVisible, setPopoverVisible] = useState<
        Record<string, boolean>
    >({});
    const handlePopoverVisibleChange = (
        visible: boolean,
        record: any
    ): void => {
        setPopoverVisible((prevState) => ({
            ...prevState,
            [record.key]: visible,
        }));
    };
    const asset = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.selectedAsset
    );

    useEffect(() => {
        if (activeSubTabKey === 'ot-tags' && asset?.key) {
            dispatch(getMappedTagsList({ nodeId: asset?.key }));

            dispatch(getDataTypeList({ nodeId: asset?.key }));

            dispatch(getUnitsList({ nodeId: asset?.key }));
        }
    }, [asset?.key, activeSubTabKey]);

    const [showPopover, setShowPopover] = useState(false);
    useEffect(() => {
        setShowPopover(selectedRowIds?.length <= 1);
    }, [selectedRowIds]);
    const mappedTagsList = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.mappedTagsList
    );
    const mappedTagsListLoading = useSelector(
        (state: any) =>
            state?.assetModelling?.modelOverview?.mappedTagsListLoading
    );

    const dataTypeList = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.dataTypeList
    );
    const unitsList = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.unitsList
    );

    const [searchTimeout, setSearchTimeout] = useState<any>(undefined);
    const [searchTagName, setSearchTagName] = useState<string | null>(null);
    const [searchSource, setSearchSource] = useState<string | null>(null);
    const [searchSourceName, setSearchSourceName] = useState<string | null>(
        null
    );
    const tableDataMapper = (): [] => {
        const temp: any = [];
        mappedTagsList?.content?.map((item: any, index: number) => {
            temp.push({
                ...item,
                key: item.uuid,
                name: item.tagName,
            });
        });
        return temp;
    };
    useEffect(() => {
        activeSubTabKey === TagMappingSubTabs?.otTags &&
            setTableData(tableDataMapper());
        activeSubTabKey !== TagMappingSubTabs?.otTags &&
            dispatch({ type: EMPTY_MAPPED_TAGS_DATA }) &&
            setTableData([]);
    }, [mappedTagsList?.content, activeSubTabKey]);

    const [isPopoverVisibles, setIsPopoverVisibles] = useState<
        Record<string, boolean>
    >({});
    const handlePopoverVisibleChanges = (visible: any): any => {
        setIsPopoverVisibles(visible);
    };

    const [editingMultiplicationFactorKey, setEditingMultiplicationFactorKey] =
        useState(EMPTY?.string);
    const [editingAdditionFactorKey, setEditingAdditionFactorKey] = useState(
        EMPTY?.string
    );
    const [editingTimeOffsetKey, setEditingTimeOffsetKey] = useState(
        EMPTY?.string
    );

    const save = (key: any, value: any, tagFactor: any): any => {
        if (tagFactor === 'multiplicationFactor') {
            dispatch(
                updateTagFactor({
                    tagId: key,
                    multiplicationFactor: parseFloat(value),
                    nodeId: asset?.key,
                })
            );
            setEditingMultiplicationFactorKey(EMPTY?.string);
        }
        if (tagFactor === 'additionFactor') {
            dispatch(
                updateTagFactor({
                    tagId: key,
                    additionFactor: parseFloat(value),
                    nodeId: asset?.key,
                })
            );
            setEditingAdditionFactorKey(EMPTY?.string);
        }
        if (tagFactor === 'timeOffset') {
            dispatch(
                updateTagFactor({
                    tagId: key,
                    timeOffset: parseFloat(value),
                    nodeId: asset?.key,
                })
            );
            setEditingTimeOffsetKey(EMPTY?.string);
        }
    };
    const [showHeaderPopover, setShowHeaderPopover] = useState(false);
    useEffect(() => {
        setShowHeaderPopover(selectedRowIds?.length > 1);
    }, [selectedRowIds]);
    const [popoverheaderVisible, setPopoverheaderVisible] = useState({
        actions: false,
    });
    const handlePopoverItemClick = (): void => {
        setPopoverheaderVisible({ actions: false });
    };

    const columns: ColumnsType<any> = [
        {
            title: (
                <div>
                    {showHeaderPopover ? (
                        <Popover
                            overlayClassName="modelOverviewPopover"
                            visible={popoverheaderVisible.actions}
                            onVisibleChange={(visible) => {
                                setPopoverheaderVisible((prevState: any) => ({
                                    ...prevState,
                                    actions: visible,
                                }));
                            }}
                            content={
                                <div>
                                    <TagMappingMoreContent
                                        selectedRowIds={selectedRowIds}
                                        onItemClick={handlePopoverItemClick}
                                        setSelectedRowIds={setSelectedRowIds}
                                    />
                                </div>
                            }
                            placement="bottomLeft"
                            trigger="click"
                            overlayStyle={{ width: '162px' }}
                        >
                            <MoreOutlined />
                        </Popover>
                    ) : (
                        ''
                    )}
                </div>
            ),
            key: 'more',
            width: 56,
            fixed: 'left',

            render: (_: any, record: any) => {
                return (
                    <>
                        <div>
                            <Popover
                                overlayClassName="modelOverviewPopover"
                                visible={popoverVisible[record?.uuid]}
                                onVisibleChange={(visible) => {
                                    handlePopoverVisibleChange(visible, record);
                                }}
                                placement="bottomLeft"
                                trigger={showPopover ? 'click' : []}
                                overlayStyle={{ width: '162px' }}
                                content={
                                    <TagMappingMoreContent
                                        selectedRowIds={selectedRowIds}
                                        setPopoverVisible={setPopoverVisible}
                                        setSelectedRowIds={setSelectedRowIds}
                                        record={record?.uuid}
                                    />
                                }
                            >
                                <MoreOutlined />
                            </Popover>
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="searchBox">
                    <div className="attributeTableWrapper__columnTitle">
                        <div>{t('assetModeller.modelOverview.tagName')}</div>
                        <div className="sortArrows">
                            <UpArrow
                                cursor="pointer"
                                onClick={() => {
                                    setSortColumn(
                                        mappedTagsSortOption?.TAG_NAME
                                    );
                                    setSortOrder(sortOrder?.ascending);
                                    setPage(PAGE);
                                }}
                            />
                            <DownArrow
                                fill="white"
                                cursor="pointer"
                                onClick={() => {
                                    setSortColumn(
                                        mappedTagsSortOption?.TAG_NAME
                                    );
                                    setSortOrder(sortOrder?.descending);
                                    setPage(PAGE);
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <Input
                            className="inputSearchBox"
                            placeholder={t(
                                'assetModeller.modelOverview.searchTagsPlaceholder'
                            )}
                            value={searchTagName ?? EMPTY?.string}
                            onChange={(e) => {
                                setPage(PAGE);
                                setSearchTagName(e.target.value);
                                if (searchTimeout) {
                                    clearTimeout(searchTimeout);
                                }
                                setSearchTimeout(
                                    setTimeout(() => {
                                        dispatch(
                                            getMappedTagsList({
                                                page: page,
                                                nodeId: asset?.key,
                                                tagName: e.target.value,
                                            })
                                        );
                                    })
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'tagName',
            key: 'tagName',
            width: '150px',
            fixed: 'left',
            render: (_: any, record: any) => (
                <>
                    <div className="attributeTableWrapper__nameData">
                        <div className="blaTableWrapper__status">
                            <span className="fs-14 fw-500 name">
                                {record?.tagName?.length <
                                assetModeller.tagNameLength ? (
                                    record?.tagName
                                ) : (
                                    <Popover
                                        overlayClassName="customOverlay"
                                        content={
                                            <div className="blaName">
                                                {record?.tagName}
                                            </div>
                                        }
                                        visible={isPopoverVisibles[record?.key]}
                                        onVisibleChange={
                                            handlePopoverVisibleChanges
                                        }
                                        placement="topLeft"
                                    >
                                        {record?.tagName?.length >
                                        assetModeller.tagNameLength
                                            ? `${record?.tagName?.slice(
                                                  0,
                                                  assetModeller.tagNameLength
                                              )}...`
                                            : record?.tagName}
                                    </Popover>
                                )}
                            </span>
                        </div>
                    </div>
                </>
            ),
        },

        {
            title: (
                <div>
                    <div className="attributeTableWrapper__columnTitle">
                        <div>{t('assetModeller.modelOverview.tagId')}</div>
                    </div>
                    <div>
                        <Input
                            className="inputSearchBox"
                            placeholder={t(
                                'assetModeller.modelOverview.searchTagIdPlaceholder'
                            )}
                            disabled
                        />
                    </div>
                </div>
            ),
            dataIndex: 'tagId',
            key: 'tagId',
            width: '200px',
            render: (_: any, record: any) => (
                <>
                    <div className="attributeTableWrapper__subItems">
                        {record?.tagId}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="searchBox">
                    <div className="attributeTableWrapper__columnTitle">
                        <div>{t('assetModeller.modelOverview.source')}</div>
                        <div className="sortArrows">
                            <UpArrow cursor="pointer" onClick={() => {}} />
                            <DownArrow
                                fill="white"
                                cursor="pointer"
                                onClick={() => {}}
                            />
                        </div>
                    </div>
                    <Input
                        placeholder="Search Source"
                        className="inputSearchBox"
                        value={searchSource ?? EMPTY?.string}
                        onChange={(e) => {
                            setPage(PAGE);
                            setSearchSource(e.target.value);
                            if (searchTimeout) {
                                clearTimeout(searchTimeout);
                            }
                            setSearchTimeout(
                                setTimeout(() => {
                                    dispatch(
                                        getMappedTagsList({
                                            page: page,
                                            nodeId: asset?.key,
                                            source: e.target.value,
                                        })
                                    );
                                })
                            );
                        }}
                    />
                </div>
            ),
            dataIndex: 'source',
            key: 'source',
            width: '200px',
            render: (_: any, record: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {record?.tagOrigin}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="searchBox">
                    <div className="attributeTableWrapper__columnTitle">
                        <div>{t('assetModeller.modelOverview.sourceName')}</div>
                        <div className="sortArrows">
                            <UpArrow
                                cursor="pointer"
                                onClick={() => {
                                    setSortColumn(
                                        mappedTagsSortOption?.DEVICE_NAME
                                    );
                                    setSortOrder(sortOrder?.ascending);
                                    setPage(PAGE);
                                }}
                            />
                            <DownArrow
                                fill="white"
                                cursor="pointer"
                                onClick={() => {
                                    setSortColumn(
                                        mappedTagsSortOption?.DEVICE_NAME
                                    );
                                    setSortOrder(sortOrder?.descending);
                                    setPage(PAGE);
                                }}
                            />
                        </div>
                    </div>
                    <Input
                        placeholder={t(
                            'assetModeller.modelOverview.searchSourcePlaceholder'
                        )}
                        className="inputSearchBox"
                        value={searchSourceName ?? EMPTY?.string}
                        onChange={(e) => {
                            setPage(PAGE);
                            setSearchSourceName(e.target.value);
                            if (searchTimeout) {
                                clearTimeout(searchTimeout);
                            }
                            setSearchTimeout(
                                setTimeout(() => {
                                    dispatch(
                                        getMappedTagsList({
                                            page: page,
                                            nodeId: asset?.key,
                                            sourceName: e.target.value,
                                        })
                                    );
                                })
                            );
                        }}
                    />
                </div>
            ),
            key: 'sourceName',
            dataIndex: 'sourceName',
            width: '200px',
            render: (_: any, record: any) => (
                <>
                    <div>{record?.deviceName}</div>
                </>
            ),
        },
        {
            title: (
                <div>
                    <div className="attributeTableWrapper__columnTitle">
                        <div>{t('assetModeller.modelOverview.assetGroup')}</div>
                        <div className="sortArrows">
                            <UpArrow
                                cursor="pointer"
                                onClick={() => {
                                    setSortColumn(
                                        mappedTagsSortOption?.NODE_NAME
                                    );
                                    setSortOrder(sortOrder?.ascending);
                                    setPage(PAGE);
                                }}
                            />
                            <DownArrow
                                fill="white"
                                cursor="pointer"
                                onClick={() => {
                                    setSortColumn(
                                        mappedTagsSortOption?.NODE_NAME
                                    );
                                    setSortOrder(sortOrder?.descending);
                                    setPage(PAGE);
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <Input
                            className="inputSearchBox"
                            placeholder={t(
                                'assetModeller.modelOverview.searchAssetPlaceholder'
                            )}
                            disabled
                        />
                    </div>
                </div>
            ),
            key: 'asset',
            dataIndex: 'asset',
            width: '200px',
            render: (_: any, record: any) => (
                <>
                    <div>{record?.parentNode?.name}</div>
                </>
            ),
        },
        {
            title: (
                <div>
                    <div className="attributeTableWrapper__columnTitle">
                        <div>{t('assetModeller.modelOverview.dataType')}</div>
                        <div className="sortArrows">
                            <UpArrow
                                cursor="pointer"
                                onClick={() => {
                                    setSortColumn(
                                        mappedTagsSortOption?.DATA_TYPE
                                    );
                                    setSortOrder(sortOrder?.ascending);
                                    setPage(PAGE);
                                }}
                            />
                            <DownArrow
                                fill="white"
                                cursor="pointer"
                                onClick={() => {
                                    setSortColumn(
                                        mappedTagsSortOption?.DATA_TYPE
                                    );
                                    setSortOrder(sortOrder?.descending);
                                    setPage(PAGE);
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <Select
                            allowClear
                            className="tags__dataTypeDropdown"
                            placeholder={t(
                                'assetModeller.modelOverview.selectDataType'
                            )}
                            onChange={(option: any) => {
                                dispatch(
                                    getMappedTagsList({
                                        nodeId: asset?.key,
                                        dataType: option,
                                    })
                                );
                            }}
                        >
                            {Array.isArray(dataTypeList) &&
                                dataTypeList?.map((item: any, index: any) => (
                                    <Option
                                        className="devicetags-option"
                                        key={index}
                                        value={item}
                                    >
                                        {item}
                                    </Option>
                                ))}
                        </Select>
                    </div>
                </div>
            ),
            dataIndex: 'dataType',
            key: 'dataType',
            width: '200px',
            render: (_: any, record: any) => (
                <>
                    <div className="attributeTableWrapper__subItems">
                        {record?.dataType?.primitiveType}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div>
                    <div className="attributeTableWrapper__columnTitle">
                        <div>{t('assetModeller.modelOverview.unit')}</div>
                        <div className="sortArrows">
                            <UpArrow
                                cursor="pointer"
                                onClick={() => {
                                    setSortColumn(
                                        mappedTagsSortOption?.DATA_TYPE_UNIT
                                    );
                                    setSortOrder(sortOrder?.ascending);
                                    setPage(PAGE);
                                }}
                            />
                            <DownArrow
                                fill="white"
                                cursor="pointer"
                                onClick={() => {
                                    setSortColumn(
                                        mappedTagsSortOption?.DATA_TYPE_UNIT
                                    );
                                    setSortOrder(sortOrder?.descending);
                                    setPage(PAGE);
                                }}
                            />
                        </div>
                    </div>
                    <div>
                        <Select
                            allowClear
                            className="tags__dataTypeDropdown"
                            placeholder={t(
                                'assetModeller.modelOverview.selectUnit'
                            )}
                            onChange={(option: any) => {
                                dispatch(
                                    getMappedTagsList({
                                        nodeId: asset?.key,
                                        unit: option,
                                    })
                                );
                            }}
                        >
                            {Array.isArray(unitsList) &&
                                unitsList?.map((item: any, index: any) => (
                                    <Option
                                        className="devicetags-option"
                                        key={index}
                                        value={item}
                                    >
                                        {item}
                                    </Option>
                                ))}
                        </Select>
                    </div>
                </div>
            ),
            dataIndex: 'unit',
            key: 'unit',
            width: '200px',
            render: (_: any, record: any) => (
                <>
                    <div className="attributeTableWrapper__subItems">
                        {record?.dataType?.unit}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div>
                    <div className="attributeTableWrapper__columnTitle">
                        <div>
                            {t(
                                'assetModeller.modelOverview.multiplicationFactor'
                            )}
                        </div>
                    </div>
                    <div>
                        <Select
                            className="tags__dataTypeDropdown"
                            placeholder={t(
                                'assetModeller.modelOverview.select'
                            )}
                            disabled
                        />
                    </div>
                </div>
            ),
            dataIndex: 'multiplicationFactor',
            key: 'multiplicationFactor',
            width: '200px',
            render: (_: any, record: any) => (
                <>
                    {editingMultiplicationFactorKey === record.key ? (
                        <Input
                            defaultValue={record.multiplicationFactor}
                            onPressEnter={(e: any) => {
                                save(
                                    record?.key,
                                    e.target.value,
                                    'multiplicationFactor'
                                );
                            }}
                            onBlur={(e) =>
                                save(
                                    record?.key,
                                    e.target.value,
                                    'multiplicationFactor'
                                )
                            }
                        />
                    ) : (
                        <div className="editableRow">
                            <div>{record?.multiplicationFactor}</div>
                            <EditIcon
                                className="editIcon"
                                onClick={() => {
                                    setEditingMultiplicationFactorKey(
                                        record?.key
                                    );
                                }}
                            />
                        </div>
                    )}
                </>
            ),
        },
        {
            title: (
                <div>
                    <div className="attributeTableWrapper__columnTitle">
                        <div>
                            {t('assetModeller.modelOverview.additionFactor')}
                        </div>
                    </div>
                    <div>
                        <Select
                            className="tags__dataTypeDropdown"
                            placeholder={t(
                                'assetModeller.modelOverview.select'
                            )}
                            disabled
                        />
                    </div>
                </div>
            ),
            dataIndex: 'additionFactor',
            key: 'additionFactor',
            width: '200px',
            render: (_: any, record: any) => (
                <>
                    {editingAdditionFactorKey === record.key ? (
                        <Input
                            defaultValue={record?.additionFactor}
                            onPressEnter={(e: any) => {
                                save(
                                    record?.key,
                                    e.target.value,
                                    'additionFactor'
                                );
                            }}
                            onBlur={(e) =>
                                save(
                                    record?.key,
                                    e.target.value,
                                    'additionFactor'
                                )
                            }
                        />
                    ) : (
                        <div className="editableRow">
                            <div>{record?.additionFactor}</div>
                            <EditIcon
                                className="editIcon"
                                onClick={() => {
                                    setEditingAdditionFactorKey(record?.key);
                                }}
                            />
                        </div>
                    )}
                </>
            ),
        },
        {
            title: (
                <div>
                    <div className="attributeTableWrapper__columnTitle">
                        <div>{t('assetModeller.modelOverview.timeOffset')}</div>
                    </div>
                    <div>
                        <Select
                            className="tags__dataTypeDropdown"
                            placeholder={t(
                                'assetModeller.modelOverview.select'
                            )}
                            disabled
                        />
                    </div>
                </div>
            ),
            dataIndex: 'timeOffSet',
            key: 'timeOffSet',
            width: '200px',
            render: (_: any, record: any) => (
                <>
                    {editingTimeOffsetKey === record.key ? (
                        <Input
                            defaultValue={record?.timeOffset}
                            onPressEnter={(e: any) => {
                                save(record?.key, e.target.value, 'timeOffset');
                            }}
                            onBlur={(e) =>
                                save(record?.key, e.target.value, 'timeOffset')
                            }
                        />
                    ) : (
                        <div className="editableRow">
                            <div>{record?.timeOffset}</div>
                            <EditIcon
                                className="editIcon"
                                onClick={() => {
                                    setEditingTimeOffsetKey(record?.key);
                                }}
                            />
                        </div>
                    )}
                </>
            ),
        },
    ];
    return (
        <>
            <Table
                rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                    selectedRowKeys: selectedRowIds,
                }}
                columns={columns}
                dataSource={tableData}
                pagination={false}
                scroll={{ y: 'calc(100vh - 440px)' }}
                loading={mappedTagsListLoading}
                className="tagTable"
            />
        </>
    );
};
export default TagTable;
