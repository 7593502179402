import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import './index.scss';
import ScreenNameHeading from 'components/common/ScreenNameHeading';
import CountAnalytics from 'components/common/CountAnalytics';
import { ReactComponent as ActiveUserIcon } from 'assets/icons/activeUserCountIcon.svg';
import { ReactComponent as DeactivateUserIcon } from 'assets/icons/deacticeUserCountIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import AlertConfigurator from './AlertTableComponent';
import { ALERTTYPE } from 'types/enums';
import CreateAlert from './CreateAlert';
import { getNotificationChannelList, setAlertState } from 'redux/actions/AlertActions/alertActions';
import { setModelId } from 'redux/actions/ImplementationActions/attributeActions';
import { useTranslation } from 'react-i18next';

const Alert: React.FC = () => {
    const alertState = useSelector((state: any) => state?.alert?.alertState);
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    const alertData = useSelector((state: any) => state.alert?.alertList);
    useEffect(() => {
        dispatch(setAlertState(ALERTTYPE.display));
        dispatch(getNotificationChannelList());
        return () => {
            dispatch(setAlertState(null));
            dispatch(setModelId(null));
        };
    }, []);
    const getCountAnalyticsDetails = (): any => {
        return [
            {
                title: t('alerts.activeAlert'),
                count: alertData?.totalActiveAlert
                    ? `${alertData?.totalActiveAlert}`
                    : '0',
                icon: <ActiveUserIcon />,
            },
            {
                title: t('alerts.inactiveAlert'),
                count: alertData?.totalInActiveAlert
                    ? `${alertData?.totalInActiveAlert}`
                    : '0',
                icon: <DeactivateUserIcon />,
            },
        ];
    };

    const getAlertComponent = (): any => {
        switch (alertState) {
            case ALERTTYPE.display:
                return <AlertConfigurator />;
            case ALERTTYPE.create:
                return <CreateAlert />;
            case ALERTTYPE.view:
                return <CreateAlert />;
            case ALERTTYPE.edit:
                return <CreateAlert />;
            default:
                return <AlertConfigurator />;
        }
    };
    return (
        <>
            <div className="alertConfig">
                <Row className="alertConfig__headerWrapper">
                    <Col span={16}>
                        <ScreenNameHeading
                            heading={t('alerts.alertNotification')}
                            subHeading={t('alerts.createEditDelete')}
                        />
                    </Col>
                    <Col span={8}>
                        <CountAnalytics
                            countDetails={getCountAnalyticsDetails()}
                        />
                    </Col>
                </Row>
                <Row className="Container__tabswrapper fw-500 fs-16">
                    {getAlertComponent()}
                </Row>
            </div>
        </>
    );
};
export default Alert;
