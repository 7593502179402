import { useTranslation } from 'react-i18next';
import { capitalizeWords } from './commonFunction';

// const { t } = useTranslation('translation');

const formatString = (str: any): any => {
    return str
        .split('-')
        .map((word: any): any => {
            // Special case for known acronyms or fully uppercase words
            if (word === 'uom') {
                return 'UOM';
            }
            // Capitalize the first letter of the word
            return word.charAt(0).toUpperCase() + word.slice(1);
        })
        .join('-');
};

const removeDashFromArray = (array: any): any => {
    return array?.map((item: any) => {
        // it checks if the item contains a hyphen ("-")
        if (item.includes('-')) {
            return item
                .split('-') // mit splits the item into words using .split('-'), capitalizes the first letter of each word, and then joins the words back together with a space in between.
                .map(
                    (word: any) => word.charAt(0).toUpperCase() + word.slice(1)
                )
                .join(' ');
        }
        return item;
    });
};

// export const getBreadcrumbItem = (array: any[]): any => {
//     const translate = (key: string): any => t(`breadcrumb.${key}`);
//     if (array?.includes('dashboards')) {
//         return [{ breadcrumbName: capitalizeWords(array[0]) }]; // NOTE: array[0] taking dashboard from array only
//     } else if (array?.includes('uom-configurator')) {
//         // NOTE: slicing the array because getting this from url and only first 2 items are relevant for Breadcrumb.
//         return array
//             ?.map(formatString)
//             ?.slice(0, 2)
//             ?.map((num) => {
//                 return { breadcrumbName: capitalizeWords(num) };
//             });
//     } else if (array?.includes('business-integrator')) {
//         // NOTE: slicing the array because getting this from url and only first 2 items are relevant for Breadcrumb.
//         return removeDashFromArray(array)
//             .slice(0, 3)
//             ?.map((num: any) => {
//                 return { breadcrumbName: capitalizeWords(num) };
//             });
//     } else {
//         // NOTE: slicing the array because getting this from url and only first 2 items are relevant for Breadcrumb.
//         return array?.slice(0, 2).map((num: any) => {
//             return { breadcrumbName: capitalizeWords(translate(num)) };
//         });
//     }
// };

export const useBreadcrumb = (array: string[]): any[] => {
    const { t } = useTranslation('translation'); // Make sure 'translation' is the namespace you are using

    const translate = (key: string): any => t(`breadcrumb.${key}`);

    const getBreadcrumbItem = (array: string[]): any[] => {
        if (array?.includes('dashboards')) {
            return [
                { breadcrumbName: capitalizeWords(translate('dashboards')) },
            ];
        } else if (array?.includes('uom-configurator')) {
            return array
                ?.map(formatString)
                ?.slice(0, 2)
                ?.map((num) => ({
                    breadcrumbName: capitalizeWords(translate(num)),
                }));
        } else if (array?.includes('business-integrator')) {
            return removeDashFromArray(array)
                ?.slice(0, 3)
                ?.map((num: any) => ({
                    breadcrumbName: capitalizeWords(translate(num)),
                }));
        } else {
            return array?.slice(0, 2)?.map((num) => ({
                breadcrumbName: capitalizeWords(translate(num)),
            }));
        }
    };

    return getBreadcrumbItem(array);
};
