export const SHOW_LOADER_SUCCESS = 'SHOW_LOADER_SUCCESS';
export const SET_ALERT_STATE = 'SET_ALERT_STATE';
export const GET_ALERT_LIST = 'GET_ALERT_LIST';
export const GET_ALERT_LIST_SUCCESS = 'GET_ALERT_LIST_SUCCESS';
export const GET_ALERT_LIST_FAILURE = 'GET_ALERT_LIST_FAILURE';
export const GET_OPERATOR_LIST = 'GET_OPERATOR_LIST';
export const GET_OPERATOR_LIST_SUCCESS = 'GET_OPERATOR_LIST_SUCCESS';
export const GET_OPERATOR_LIST_FAILURE = 'GET_OPERATOR_LIST_FAILURE';
export const GET_ALERT_DETAILS = 'GET_ALERT_DETAILS';
export const GET_ALERT_DETAILS_SUCCESS = 'GET_ALERT_DETAILS_SUCCESS';
export const GET_ALERT_DETAILS_FAILURE = 'GET_ALERT_DETAILS_FAILURE';
export const GET_OPERATOR_BY_ID = 'GET_OPERATOR_BY_ID';
export const GET_OPERATOR_BY_ID_SUCCESS = 'GET_OPERATOR_BY_ID_SUCCESS';
export const GET_OPERATOR_BY_ID_FAILURE = 'GET_OPERATOR_BY_ID_FAILURE';
export const GET_KPI_LIST_BY_ASSET = 'GET_KPI_LIST_BY_ASSET';
export const GET_KPI_LIST_BY_ASSET_SUCCESS = 'GET_KPI_LIST_BY_ASSET_SUCCESS';
export const GET_KPI_LIST_BY_ASSET_FAILURE = 'GET_KPI_LIST_BY_ASSET_FAILURE';
export const GET_DURATION_LIST = 'GET_DURATION_LIST';
export const GET_DURATION_LIST_SUCCESS = 'GET_DURATION_LIST_SUCCESS';
export const GET_DURATION_LIST_FAILURE = 'GET_DURATION_LIST_FAILURE';
export const GET_CALCULATION_CYCLE_LIST = 'GET_CALCULATION_CYCLE_LIST';
export const GET_CALCULATION_CYCLE_LIST_SUCCESS =
    'GET_CALCULATION_CYCLE_LIST_SUCCESS';
export const GET_CALCULATION_CYCLE_LIST_FAILURE =
    'GET_CALCULATION_CYCLE_LIST_FAILURE';
export const GET_NOTIFICATION_CHANNEL_LIST = 'GET_NOTIFICATION_CHANNEL_LIST';
export const GET_NOTIFICATION_CHANNEL_LIST_SUCCESS =
    'GET_NOTIFICATION_CHANNEL_LIST_SUCCESS';
export const GET_NOTIFICATION_CHANNEL_LIST_FAILURE =
    'GET_NOTIFICATION_CHANNEL_LIST_FAILURE';
export const GET_CONSEQUENCE_LIST = 'GET_CONSEQUENCE_LIST';
export const GET_CONSEQUENCE_LIST_SUCCESS = 'GET_CONSEQUENCE_LIST_SUCCESS';
export const CREATE_ALERT =
    'CREATE_ALERT';
export const CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS';
export const CREATE_ALERT_FAILURE = 'CREATE_ALERT_FAILURE';
export const SET_CREATED_STATE = 'SET_CREATED_STATE';

export const UPDATE_ALERT_STATUS = 'UPDATE_ALERT_STATUS';
export const UPDATE_ALERT_STATUS_SUCCESS = 'UPDATE_ALERT_STATUS_SUCCESS';
export const UPDATE_ALERT_STATUS_FAILURE = 'UPDATE_ALERT_STATUS_FAILURE';

export const UPDATE_ALERT =
    'UPDATE_ALERT';
export const UPDATE_ALERT_SUCCESS = 'UPDATE_ALERT_SUCCESS';
export const UPDATE_ALERT_FAILURE = 'UPDATE_ALERT_FAILURE';
