import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Button, Form, Spin } from 'antd';
import './index.scss';
import { ReactComponent as BackIcon } from 'assets/icons/backIcon.svg';
import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmationIcon.svg';
import { cancelHandle, modalShow, okHandle } from 'utils/modalFunction';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import SuccessfulModal from 'components/common/Modals/SuccessfulModal';
import { useDispatch, useSelector } from 'react-redux';
import { EMPTY, UOMTYPE } from 'types/enums';
import {
    editUom,
    setUomState,
} from 'redux/actions/ConfigureActions/attributeActions';
import UomFormComponent from '../UomFormComponent';
import CustomButton from 'components/common/CustomButton';
import { parseJwt } from 'utils/jwtTokenFunction';
import { useTranslation } from 'react-i18next';

const ViewUom: React.FC<any> = ({
    setSortOrder,
    setSortColumn,
    setSearchState,
}) => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const details = parseJwt();
    const { t } = useTranslation('translation');

    const [isSaveDisable, setIsSaveDisable] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeSuccessModalOpen, setActiveSuccessModalOpen] = useState(false);
    const [onCancelClicked, setOnCancelClicked] = useState(false);

    const uomDetailsById = useSelector(
        (state: any) => state?.configure?.attributes?.uomDetailsById
    );

    const uomDetailsByIdLoading = useSelector(
        (state: any) => state.configure?.attributes?.uomDetailsByIdLoading
    );

    const uomState = useSelector(
        (state: any) => state.configure?.attributes?.uomState
    );
    const isUomUpdateSuccess = useSelector(
        (state: any) => state?.configure?.attributes?.isUomUpdated
    );

    const name = Form.useWatch('nameOfUnit', form);
    const abbreviation = Form.useWatch('abbreviation', form);
    const description = Form.useWatch('description', form);
    const metricSystem = Form.useWatch('metricSystem', form);
    const uomClasses = Form.useWatch('uomClasses', form);

    const onOkHandler = (): any => {
        if (onCancelClicked) {
            dispatch(setUomState(UOMTYPE.display));
            setOnCancelClicked(false);
        } else {
            dispatch(
                editUom({
                    name: name,
                    abbreviation: abbreviation,
                    desc: description,
                    uomClassId: Number.isInteger(uomClasses)
                        ? uomClasses
                        : null,
                    newUomClass: Number.isInteger(uomClasses)
                        ? null
                        : uomClasses,
                    metricSystemId: metricSystem,
                    requestedBy: details?.username,
                    uomDetailsId: uomDetailsById?.id,
                })
            );
            setOnCancelClicked(false);
            localStorage.removeItem('recentUomClass');
        }
        setIsModalOpen(false);
        setOnCancelClicked(false);
        setSortOrder();
        setSortColumn();
        setSearchState('');
    };

    useEffect(() => {
        isUomUpdateSuccess &&
            modalShow(activeSuccessModalOpen, setActiveSuccessModalOpen);
        okHandle(isModalOpen, setIsModalOpen);
    }, [isUomUpdateSuccess]);

    const valuesChange = (): any => {
        const getValues = form.getFieldsValue();
        if (
            getValues?.nameOfUnit &&
            getValues?.abbreviation &&
            getValues?.metricSystem &&
            getValues?.uomClasses
        ) {
            if (isSaveDisable) {
                setIsSaveDisable(false);
            }
        } else {
            if (!isSaveDisable) {
                setIsSaveDisable(true);
            }
        }
    };

    const onHandlerCancel = (): any => {
        setOnCancelClicked(true);
    };

    useEffect(() => {
        if (onCancelClicked) {
            modalShow(isModalOpen, setIsModalOpen);
        }
    }, [onCancelClicked]);

    return (
        <>
            {uomDetailsByIdLoading ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                uomDetailsById?.name && (
                    <>
                        <Form
                            className="attributeFormWrapper__form"
                            form={form}
                            layout="vertical"
                            disabled={uomState === UOMTYPE.view}
                            initialValues={{
                                nameOfUnit:
                                    uomDetailsById?.name || EMPTY.string,
                                abbreviation:
                                    uomDetailsById?.abbreviation ||
                                    EMPTY.string,
                                description:
                                    uomDetailsById?.description || EMPTY.string,
                                uomClasses:
                                    uomDetailsById?.unitOfMeasurementClass
                                        ?.id || EMPTY.string,
                                metricSystem:
                                    uomDetailsById?.metricSystem?.id ||
                                    EMPTY.string,
                            }}
                            onValuesChange={valuesChange}
                            onFinish={onOkHandler}
                        >
                            <>
                                <div className="viewAttributeWrapper uom">
                                    <Card
                                        className="viewUomCard"
                                        bordered={false}
                                        bodyStyle={{ padding: 0 }}
                                    >
                                        <Row className="viewAttributeWrapper__headerWrapper">
                                            <Col span={24}>
                                                <Row>
                                                    <Col
                                                        span={0.5}
                                                        className="viewAttributeWrapper__backIcon"
                                                    >
                                                        <BackIcon
                                                            onClick={() => {
                                                                if (
                                                                    uomState ===
                                                                    UOMTYPE.view
                                                                ) {
                                                                    dispatch(
                                                                        setUomState(
                                                                            UOMTYPE.display
                                                                        )
                                                                    );
                                                                } else {
                                                                    dispatch(
                                                                        setUomState(
                                                                            UOMTYPE.view
                                                                        )
                                                                    );
                                                                }
                                                                setSortOrder();
                                                                setSortColumn();
                                                                setSearchState(
                                                                    EMPTY.string
                                                                );
                                                            }}
                                                        />
                                                    </Col>
                                                    <Col
                                                        span={22}
                                                        className="viewAttributeWrapper__headingName fw-500 fs-20"
                                                    >
                                                        {uomState ===
                                                        UOMTYPE.view
                                                            ? t(
                                                                  'UomConfigurator.view'
                                                              )
                                                            : t(
                                                                  'UomConfigurator.edit'
                                                              )}
                                                    </Col>
                                                    <Col span={1}>
                                                        {uomState ===
                                                            UOMTYPE.view && (
                                                            <Button
                                                                type="primary"
                                                                ghost
                                                                className="addNewButton"
                                                                onClick={() => {
                                                                    dispatch(
                                                                        setUomState(
                                                                            UOMTYPE.edit
                                                                        )
                                                                    );
                                                                }}
                                                                disabled={
                                                                    !uomDetailsById?.isActive
                                                                }
                                                            >
                                                                {t(
                                                                    'UomConfigurator.edit'
                                                                )}
                                                            </Button>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <div className="viewAttributeWrapper__scrollContent">
                                            <UomFormComponent form={form} />
                                        </div>
                                        {uomState === UOMTYPE.edit && (
                                            <div className="editAttributeWrapper__createRoleFooter uomEditFooter">
                                                <div className="editAttributeWrapper__footerButtonWrapper">
                                                    <CustomButton
                                                        type={'Cancel'}
                                                        disabled={false}
                                                        handleClick={() => {
                                                            onHandlerCancel();
                                                        }}
                                                    />
                                                </div>
                                                <div className="editAttributeWrapper__footerButtonWrapper">
                                                    {
                                                        <CustomButton
                                                            type={'Save'}
                                                            disabled={
                                                                isSaveDisable
                                                            }
                                                            handleClick={() => {
                                                                modalShow(
                                                                    isModalOpen,
                                                                    setIsModalOpen
                                                                );
                                                            }}
                                                        />
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </Card>
                                </div>

                                <ConfirmationModal
                                    open={isModalOpen}
                                    icon={<ConfirmationIcon />}
                                    onOk={() => onOkHandler()}
                                    onCancel={() => {
                                        setOnCancelClicked(false);
                                        cancelHandle(
                                            isModalOpen,
                                            setIsModalOpen
                                        );
                                    }}
                                    text={
                                        onCancelClicked
                                            ? t(
                                                  'UomConfigurator.editModal.cancelClicked'
                                              )
                                            : t(
                                                  'UomConfigurator.editModal.saveEditChnages'
                                              )
                                    }
                                />
                                <SuccessfulModal
                                    open={activeSuccessModalOpen}
                                    onOk={() => onOkHandler()}
                                    onCancel={() => {
                                        dispatch(setUomState(UOMTYPE.display));
                                        cancelHandle(
                                            activeSuccessModalOpen,
                                            setActiveSuccessModalOpen
                                        );
                                    }}
                                    text={t('UomConfigurator.savedSuccesfully')}
                                />
                            </>
                        </Form>
                    </>
                )
            )}
        </>
    );
};

export default ViewUom;
