import { Popover } from 'antd';
import Table, { type ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { type DataType } from 'types/interfaces/PropsInterfaces';
import { MoreOutlined } from '@ant-design/icons';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import ModelOverviewMoreContent from '../MoreContent';
import './index.scss';
import moment from 'moment';
import { DATE_TIME_FORMAT, PAGE, modelInfoUrl } from 'utils/constants';
import { model, modelSortOption, sortingOrder } from 'types/enums';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PrimaryKeyIcon } from 'assets/icons/primaryKeyIcon.svg';
import { OPEN_SUB_MODEL_TABS } from 'redux/types/assetModellingTypes';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ModelOverviewTable: React.FC<any> = ({
    setOpenModelInfo,
    setEditModel,
    data,
    setRecordDetails,
    setSortOrder,
    setSortColumn,
    setPage,
}) => {
    const { t } = useTranslation('translation');
    const navigate = useNavigate();
    const modelListLoading = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.modelListLoading
    );
    const dispatch = useDispatch();
    const [popoverVisible, setPopoverVisible] = useState<
        Record<string, boolean>
    >({});
    const [tableData, setTableData] = useState<any>([]);
    const tableDataMapper = (): [] => {
        const temp: any = [];
        data?.map((item: any, index: number) => {
            temp.push({
                ...item,
                key: item.id,
            });
        });
        return temp;
    };
    useEffect(() => {
        setTableData(tableDataMapper());
    }, [data]);
    const handlePopoverVisibleChange = (
        visible: boolean,
        record: any
    ): void => {
        setPopoverVisible((prevState) => ({
            ...prevState,
            [record.key]: visible,
        }));
    };
    const [isPopoverVisibles, setIsPopoverVisibles] = useState<
        Record<string, boolean>
    >({});
    const handlePopoverVisibleChanges = (visible: any): any => {
        setIsPopoverVisibles(visible);
    };
    const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
    const rowSelection: any = {
        onSelect: (record: any, selected: boolean, selectedRows: any) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );

            if (selected) {
                setSelectedRowIds([...selectedRowIds, record?.id]);
            } else {
                setSelectedRowIds(
                    selectedRowIds?.filter((id: number) => id !== record?.id)
                );
            }
        },
        onSelectAll: (selected: boolean, selectedRows: DataType[]) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );

            if (selected) {
                const newSelectedIds = selectedRows?.map(
                    (record) => record?.id
                );
                setSelectedRowIds(newSelectedIds);
            } else {
                setSelectedRowIds([]);
            }
        },
    };
    const [showPopover, setShowPopover] = useState(false);
    useEffect(() => {
        setShowPopover(selectedRowIds.length <= 1);
    }, [selectedRowIds]);
    const columns: ColumnsType<any> = [
        {
            title: (
                <div>
                    <MoreOutlined />
                </div>
            ),
            key: 'more',
            width: 56,
            render: (_: any, record: any) => {
                return (
                    <>
                        <div>
                            <Popover
                                overlayClassName="modelOverviewPopover"
                                visible={popoverVisible[record.key]}
                                onVisibleChange={(visible) => {
                                    handlePopoverVisibleChange(visible, record);
                                }}
                                placement="bottomLeft"
                                trigger={showPopover ? 'click' : []}
                                content={
                                    <ModelOverviewMoreContent
                                        setEditModel={setEditModel}
                                        setOpenModelInfo={setOpenModelInfo}
                                        record={record}
                                        setRecordDetails={setRecordDetails}
                                        setPopoverVisible={setPopoverVisible}
                                        setPage={setPage}
                                    />
                                }
                                overlayStyle={{ width: '162px' }}
                            >
                                <MoreOutlined />
                            </Popover>
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.modelOverview.modelName')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortingOrder?.ascending);
                                setSortColumn(modelSortOption?.NAME);
                                setPage(PAGE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortingOrder?.descending);
                                setSortColumn(modelSortOption?.NAME);
                                setPage(PAGE);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            className: 'column__name',
            width: '223px',
            render: (_: any, record: any) => (
                <>
                    <div
                        className="attributeTableWrapper__nameData"
                        onClick={() => {
                            setRecordDetails(record);
                        }}
                    >
                        <div className="blaTableWrapper__status">
                            <span
                                className="fs-14 fw-500 name"
                                onClick={() => {
                                    dispatch({
                                        type: OPEN_SUB_MODEL_TABS,
                                    });
                                    setOpenModelInfo(true);
                                    navigate(`${modelInfoUrl}${record?.id}`, {
                                        replace: true,
                                    });
                                }}
                            >
                                {record?.name?.replace(/\s/g, '')?.length <
                                model?.modelNameLength ? (
                                    <>
                                        {record?.name}
                                        <span>
                                            {record?.isPrimary && (
                                                <PrimaryKeyIcon />
                                            )}
                                        </span>
                                    </>
                                ) : (
                                    <Popover
                                        overlayClassName="customOverlay"
                                        content={
                                            <div className="blaName">
                                                {record?.name}
                                            </div>
                                        }
                                        visible={isPopoverVisibles[record?.key]}
                                        onVisibleChange={
                                            handlePopoverVisibleChanges
                                        }
                                        placement="topLeft"
                                    >
                                        {record?.name?.length >
                                        model?.modelNameLength
                                            ? `${record?.name?.slice(
                                                  0,
                                                  model?.modelNameLength
                                              )}...`
                                            : record?.name}{' '}
                                        <span>
                                            {record?.isPrimary && (
                                                <PrimaryKeyIcon />
                                            )}
                                        </span>
                                    </Popover>
                                )}
                            </span>
                        </div>
                    </div>
                </>
            ),
        },

        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.modelOverview.description')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortingOrder?.ascending);
                                setSortColumn(modelSortOption?.DESCRIPTION);
                                setPage(PAGE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortingOrder?.descending);
                                setSortColumn(modelSortOption?.DESCRIPTION);
                                setPage(PAGE);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'description',
            key: 'description',
            className: 'column__description',
            width: '422px',
            render: (_: any, record: any) => (
                <>
                    <div className="attributeTableWrapper__subItems">
                        <span className="fs-14 fw-500 name">
                            {record?.description?.replace(/\s/g, '')?.length <
                            model?.modelDescriptionLength ? (
                                record?.description
                            ) : (
                                <Popover
                                    overlayClassName="customOverlay"
                                    content={
                                        <div className="blaName">
                                            {record?.description}
                                        </div>
                                    }
                                    visible={isPopoverVisibles[record?.key]}
                                    onVisibleChange={
                                        handlePopoverVisibleChanges
                                    }
                                    placement="topLeft"
                                >
                                    {record?.description?.length >
                                    model?.modelDescriptionLength
                                        ? `${record?.description?.slice(
                                              0,
                                              model?.modelDescriptionLength
                                          )}...`
                                        : record?.description}
                                </Popover>
                            )}
                        </span>
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.modelOverview.lastModified')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortingOrder?.ascending);
                                setSortColumn(modelSortOption?.UPDATE_ON);
                                setPage(PAGE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortingOrder?.descending);
                                setSortColumn(modelSortOption?.UPDATE_ON);
                                setPage(PAGE);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'lastModified',
            key: 'lastModified',
            width: '200px',
            render: (_: any, record: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {moment(record?.updatedOn).format(DATE_TIME_FORMAT)}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>{t('assetModeller.modelOverview.createdOn')}</div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortingOrder?.ascending);
                                setSortColumn(modelSortOption?.CREATED_ON);
                                setPage(PAGE);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                setSortOrder(sortingOrder?.descending);
                                setSortColumn(modelSortOption?.CREATED_ON);
                                setPage(PAGE);
                            }}
                        />
                    </div>
                </div>
            ),
            key: 'createdOn',
            dataIndex: 'createdOn',
            width: '200px',
            render: (_: any, record: any) => (
                <>
                    <div>
                        {moment(record?.createdOn).format(DATE_TIME_FORMAT)}
                    </div>
                </>
            ),
        },
    ];
    return (
        <>
            <div>
                <Table
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                        selectedRowKeys: selectedRowIds,
                    }}
                    columns={columns}
                    dataSource={tableData}
                    loading={modelListLoading}
                    pagination={false}
                    scroll={{ y: 'calc(100vh - 390px)' }}
                />
            </div>
        </>
    );
};
export default ModelOverviewTable;
