import TabsComponent from 'components/common/TabsComponent';
import { useEffect, useState } from 'react';
import ModelInfo from './ModelInfo';
import TagMapping from './TagMapping';
import {
    DISPLAY_TOTAL_TAGS,
    REMOVE_TOTAL_TAGS_COUNT,
} from 'redux/types/assetModellingTypes';
import { useDispatch, useSelector } from 'react-redux';
import Modelling from './Modelling';
import { getTreeByModelId } from 'redux/actions/AssetModellingActions/assetModellingAction';
const ModalTabsSection: React.FC<any> = ({
    editModel,
    setEditModel,
    setOpenModelInfo,
    recordDetails,
    setPage,
}) => {
    const [treeNode, setTreeNode] = useState([]);
    const [treeEdge, setTreeEdge] = useState([]);
    const [activeTabKey, setActiveTabKey] = useState('');
    const dispatch = useDispatch();
    useEffect(() => {
        setActiveTabKey('modal-information');
    }, []);
    useEffect(() => {
        activeTabKey !== 'tag-mapping' &&
            dispatch({ type: REMOVE_TOTAL_TAGS_COUNT });
        activeTabKey === 'tag-mapping' &&
            dispatch({ type: DISPLAY_TOTAL_TAGS });
    }, [activeTabKey]);

    const modelDetails = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.modelDetails
    );
    useEffect(() => {
        modelDetails?.id && dispatch(getTreeByModelId(modelDetails?.id));
    }, [modelDetails]);
    const position = { x: 400, y: 400 };

    const getElements = (
        tree: any,
        resultNodes: any,
        resultEdges: any,
        parentNode?: any
    ): any => {
        const nodeArray: any = resultNodes.length ? resultNodes : [];
        const edgeArray: any = resultEdges.length ? resultEdges : [];
        const tempNode: any = {};
        const tempNodeEdge: any = {};
        tempNode.id = `node-${tree.id}`;
        tempNode.key = `node-${tree.id}`;
        tempNode.position = position;
        tempNode.data = {
            label: tree?.name,
            nodeType: { ...tree?.nodeType },
            id: tree?.id,
            parentId: tree?.parentId,
        };
        if (tree?.nodeType?.level === 0) {
            tempNode.type = 'input';
        } else if (tree?.childNodes?.length === 0) {
            tempNode.type = 'output';
        }
        nodeArray.push(tempNode);
        tempNodeEdge.id = `edge-${tree.id}`;
        tempNodeEdge.source = parentNode;
        tempNodeEdge.type = 'step';
        tempNodeEdge.target = `node-${tree.id}`;
        tempNodeEdge.animated = false;
        edgeArray.push(tempNodeEdge);
        if (tree.childNodes?.length) {
            tree.childNodes.forEach((item: any) => {
                getElements(item, nodeArray, edgeArray, `node-${tree.id}`);
            });
        }
        return {
            nodeArray,
            edgeArray,
        };
    };
    const treeObject = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.getTreeByModelId
    );
    useEffect(() => {
        if (treeObject) {
            const treeObj = getElements(treeObject, [], []);
            setTreeNode(treeObj?.nodeArray);
            setTreeEdge(
                treeObj?.edgeArray.slice(1, treeObj?.nodeArray?.length)
            );
        } else {
            const treeObj = getElements(modelDetails?.rootNode, [], []);
            setTreeNode(treeObj?.nodeArray);
        }
    }, [treeObject, modelDetails]);
    const tabItems = [
        {
            key: 'modal-information',
            label: `Model Information`,
            children: (
                <ModelInfo
                    editModel={editModel}
                    setEditModel={setEditModel}
                    setOpenModelInfo={setOpenModelInfo}
                    recordDetails={recordDetails}
                    setPage={setPage}
                />
            ),
        },
        {
            key: 'modelling',
            label: `Modelling`,
            children: (treeNode?.length || treeEdge?.length) && (
                <Modelling treeNode={treeNode} treeEdge={treeEdge} />
            ),
        },
        {
            key: 'tag-mapping',
            label: `Tag Mapping`,
            children: <TagMapping activeTabKey={activeTabKey}/>,
        },
    ];
    return (
        <>
            <div className="assetModelling">
                <div className="assetModelling__tabswrapper fw-500 fs-16">
                    <TabsComponent
                        tabItem={tabItems}
                        setTabKey={activeTabKey}
                        setActiveTabKey={setActiveTabKey}
                        activeTabKey={activeTabKey}
                    />
                </div>
            </div>
        </>
    );
};
export default ModalTabsSection;
