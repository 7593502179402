import { Button, Input } from 'antd';
import React, {  useEffect, useState } from 'react';
import './index.scss'
import { useDispatch, useSelector } from 'react-redux';
import { ruleCRUDEnum, ruleCategoryEnum } from 'types/enums/FormulaCreatorEnum';
import { updateFormulaAction } from 'redux/actions/FormulaCreatorActions/formulaCreatorActions';
const NumberCustomPopover: React.FC<any> = ({ hideInput }) => {
    const { TextArea } = Input;
    const [textVal, setTextVal] = useState<string>('');
    const onChange = (val: any):any => {
        setTextVal(val);
    };
    const focusedIndexRedux = useSelector(
        (state: any) => state?.formulaCreator?.focusedIndex
    );
    const expressionArray = useSelector(
        (state: any) => state?.formulaCreator?.expressionArray
    );
    const dispatch = useDispatch();
    const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
        const charCode = e.which ? e.which : e.keyCode;
        const char = String.fromCharCode(charCode);
        const allowedKeys = [8, 37, 39, 46,189,109]; // Key codes for Backspace, ArrowLeft, ArrowRight, and Delete
        
        // Allow only numbers (key codes 48 to 57 represent digits '0' to '9') and specific keys
        if (!/[0-9-]/.test(char) && !allowedKeys.includes(charCode)) {
            e.preventDefault();
        }
    };
    const operandHandler = (item: any) :void=> {
        const payload = {
            index: expressionArray.length?focusedIndexRedux:0,
            type:ruleCRUDEnum.UPDATE,

            details: {
                ruleCategoryId: ruleCategoryEnum.TEXT,
                detail: {
                    text: textVal,
                },
            },
        };
        dispatch(updateFormulaAction(payload))
    };
    useEffect(() => {
        setTextVal('')
    }, [hideInput])
    
    
    return (
        <div className="customPopOverContent">
          
                <TextArea
                    value={textVal}
                    allowClear
                    onChange={(e) => {
                        onChange(e.target.value);
                    }}
                maxLength={100}
                    onKeyPress={handleKeyPress}
                />
           
            <hr />
            <div className='customPopOverContent__footerButtons'>
                <Button onClick={hideInput}>Cancel</Button>
                <Button onClick={operandHandler}>Add</Button>

            </div>
        </div>
    );
};

export default NumberCustomPopover;
