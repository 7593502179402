import { Col, Input, Popover, Row, Spin, Table } from 'antd';
import { SearchOutlined, MoreOutlined } from '@ant-design/icons';
import CustomButton from 'components/common/CustomButton';
import './index.scss';
import { useEffect, useState } from 'react';
import { type DataType } from 'types/interfaces/PropsInterfaces';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import { type ColumnsType } from 'antd/es/table';
import { ReactComponent as ActiveDotIcon } from 'assets/icons/activeDot.svg';
import { ReactComponent as InactiveDotIcon } from 'assets/icons/inactiveDot.svg';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import { attribute, EMPTY, sortOrder, UOMTable, UOMTYPE } from 'types/enums';
import {
    getUomDetailsById,
    getUOMTableList,
    setUomState,
} from 'redux/actions/ConfigureActions/attributeActions';
import { useDispatch, useSelector } from 'react-redux';
import CustomPagination from 'components/common/CustomPagination';
import UomMoreContent from './UomMoreContent';
import { useTranslation } from 'react-i18next';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import UomMultipleActiveDeactive from './UomMoreContent/UomMultipleActiveDeactive';

const UOMConfiguratorTable: React.FC<any> = ({
    data,
    payload,
    setPage,
    page,
    setPageSize,
    pageSize,
    setSortOrder,
    setSortColumn,
    searchState,
    setSearchState,
}) => {
    const { t } = useTranslation('translation');

    const [selectedActiveIds, setSelectedActiveIds] = useState<any>([]);
    const [selectedInactiveIds, setSelectedInactiveIds] = useState<any>([]);
    const [tableData, setTableData] = useState<any>([]);
    const dispatch = useDispatch();
    const [attributeSearchTimeout, setAttributeSearchTimeout] =
        useState<any>(undefined);

    const [popoverVisible, setPopoverVisible] = useState<
        Record<string, boolean>
    >({});
    const uomListLoading = useSelector(
        (state: any) => state.configure?.attributes?.uomListLoading
    );

    const handlePopoverVisibleChange = (
        visible: boolean,
        record: any
    ): void => {
        setPopoverVisible((prevState) => ({
            ...prevState,
            [record.key]: visible,
        }));
    };

    const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);

    const tableDataMapper = (): [] => {
        const temp: any = [];
        data?.pageResponse?.records?.map((item: any, index: number) => {
            temp.push({
                ...item,
                key: item.id,
            });
        });
        return temp;
    };
    useEffect(() => {
        setTableData(tableDataMapper());

        // Timeout is used to delay the popUp confirmation message for 1sec
        setTimeout(() => {
            setSelectedRowIds([]);
            setSelectedRows([]);
        }, 1000);
    }, [data]);

    const rowSelection: any = {
        onSelect: (record: any, selected: boolean, selectedRows: any) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );

            setSelectedActiveIds([...selectedActiveRows]);
            setSelectedInactiveIds([...selectedInactiveRows]);
            if (selected) {
                setSelectedRowIds([...selectedRowIds, record?.id]);
                setSelectedRows([...selectedRows, record]);
            } else {
                setSelectedRowIds(
                    selectedRowIds?.filter((id: number) => id !== record?.id)
                );

                setSelectedRows(
                    selectedRows.filter((row: any) => row?.key !== record?.key)
                );
            }
        },
        onSelectAll: (selected: boolean, selectedRows: DataType[]) => {
            const selectedActiveRows: number[] = [];
            const selectedInactiveRows: number[] = [];
            selectedRows?.map((item: any) =>
                item?.isActive
                    ? selectedActiveRows.push(item?.id)
                    : selectedInactiveRows.push(item?.id)
            );

            setSelectedActiveIds([...selectedActiveRows]);
            setSelectedInactiveIds([...selectedInactiveRows]);
            if (selected) {
                const newSelectedIds = selectedRows?.map(
                    (record) => record?.id
                );
                setSelectedRowIds(newSelectedIds);
                setSelectedRows(selectedRows);
            } else {
                setSelectedRowIds([]);
                setSelectedRows([]);
            }
        },
    };

    const [popoverheaderVisible, setPopoverheaderVisible] = useState({
        actions: false,
    });

    const handlePopoverItemClick = (): void => {
        setPopoverheaderVisible({ actions: false });
    };

    const [isPopoverVisibles, setIsPopoverVisibles] = useState<
        Record<string, boolean>
    >({});

    const handlePopoverVisibleChanges = (visible: any): any => {
        setIsPopoverVisibles(visible);
    };

    const [showPopover, setShowPopover] = useState(false);
    const [showHeaderPopover, setShowHeaderPopover] = useState(false);
    useEffect(() => {
        // Check if selectedRowIds has any entries// will use later
        setShowPopover(selectedRowIds.length <= 1);
        setShowHeaderPopover(selectedRowIds.length > 1);
    }, [selectedRowIds]);

    const SearchNoDataText = (
        <EmptyDataComponent
            textValue="No Results, Try a different search item"
            loading={uomListLoading}
        />
    );

    const onSorting = (columnName: any, sortOrder: any): any => {
        setPage(PAGE);
        setSortColumn(columnName);
        setSortOrder(sortOrder);
    };

    const columns: ColumnsType<any> = [
        {
            title: (
                <div>
                    {showHeaderPopover ? (
                        <Popover
                            visible={popoverheaderVisible.actions}
                            onVisibleChange={(visible) => {
                                setPopoverheaderVisible((prevState: any) => ({
                                    ...prevState,
                                    actions: visible,
                                }));
                            }}
                            content={
                                <div className="custom-popover-content">
                                    <UomMultipleActiveDeactive
                                        multipleRecord={selectedRows}
                                        selectedUserIds={selectedRowIds}
                                        onItemClick={handlePopoverItemClick}
                                        selectedActiveIds={selectedActiveIds}
                                        selectedInactiveIds={
                                            selectedInactiveIds
                                        }
                                        paginatedPayload={payload}
                                        searchPayload={searchState}
                                    />
                                </div>
                            }
                            placement="bottomLeft"
                            trigger="click"
                        >
                            <MoreOutlined />
                        </Popover>
                    ) : (
                        EMPTY.string
                    )}
                </div>
            ),
            key: 'more',
            width: 56,
            render: (_: any, record: any) => {
                return (
                    <>
                        <div>
                            <Popover
                                visible={popoverVisible[record.key]}
                                onVisibleChange={(visible) => {
                                    handlePopoverVisibleChange(visible, record);
                                }}
                                placement="bottomLeft"
                                trigger={showPopover ? 'click' : []}
                                content={
                                    <UomMoreContent
                                        record={record}
                                        setPopoverVisible={setPopoverVisible}
                                        paginatedPayload={payload}
                                        searchPayload={searchState}
                                    />
                                }
                                overlayStyle={{ width: '162px' }}
                            >
                                <MoreOutlined />
                            </Popover>
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'UomConfigurator.uomTableColumnHeaderTitel.nameOfUnit'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(UOMTable.NAME, sortOrder.ascending);
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(UOMTable.NAME, sortOrder.descending);
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            className: 'column__name',
            render: (_: any, data: any) => (
                <>
                    <div
                        className="attributeTableWrapper__nameData"
                        onClick={() => {
                            dispatch(getUomDetailsById(data?.id));
                            dispatch(setUomState(UOMTYPE.view));
                        }}
                    >
                        <div className="blaTableWrapper__status">
                            {data.isActive ? (
                                <>
                                    <ActiveDotIcon />
                                </>
                            ) : (
                                <InactiveDotIcon />
                            )}

                            <span className="fs-14 fw-400 name">
                                {data?.name?.length <
                                attribute.attributeNameLength ? (
                                    data?.name
                                ) : (
                                    <Popover
                                        overlayClassName="customOverlay"
                                        content={
                                            <div className="blaName">
                                                {data?.name}
                                            </div>
                                        }
                                        visible={isPopoverVisibles[data?.key]}
                                        onVisibleChange={
                                            handlePopoverVisibleChanges
                                        }
                                        placement="topLeft"
                                    >
                                        {data?.name?.length >
                                        attribute.attributeNameLength
                                            ? `${data?.name?.slice(
                                                  0,
                                                  attribute.attributeNameLength
                                              )}...`
                                            : data?.name}
                                    </Popover>
                                )}
                            </span>
                        </div>
                    </div>
                </>
            ),
        },

        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'UomConfigurator.uomTableColumnHeaderTitel.description'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    UOMTable.DESCRIPTION,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    UOMTable.DESCRIPTION,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'description',
            key: 'description',
            className: 'column__description',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__subItems">
                        <span className="fs-14 fw-400 name">
                            {data?.description?.replace(/\s/g, EMPTY.string)
                                ?.length <
                            attribute?.attributeDescriptionLength ? (
                                data?.description
                            ) : (
                                <Popover
                                    overlayClassName="customOverlay"
                                    content={
                                        <div className="blaName">
                                            {data?.description}
                                        </div>
                                    }
                                    visible={isPopoverVisibles[data?.key]}
                                    onVisibleChange={
                                        handlePopoverVisibleChanges
                                    }
                                    placement="topLeft"
                                >
                                    {data?.description?.length >
                                    attribute?.attributeNameLength
                                        ? `${data?.description?.slice(
                                              0,
                                              attribute?.attributeDescriptionLength
                                          )}...`
                                        : data?.description}
                                </Popover>
                            )}
                        </span>
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'UomConfigurator.uomTableColumnHeaderTitel.abbreviation'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    UOMTable.ABBREVIATION,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    UOMTable.ABBREVIATION,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'abbreviation',
            key: 'abbreviation',
            className: 'column__properties',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status fw-400">
                        {data?.abbreviation}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'UomConfigurator.uomTableColumnHeaderTitel.uomClass'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    UOMTable.UOM_CLASSES,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    UOMTable.UOM_CLASSES,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            key: 'uomClasses',
            dataIndex: 'uomClasses',
            className: 'column__category',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status fw-400">
                        {data?.uomClasses.replace(/\s/g, EMPTY.string)?.length <
                        attribute?.attributeDescriptionLength ? (
                            data?.uomClasses
                        ) : (
                            <Popover
                                overlayClassName="customOverlay"
                                content={
                                    <div className="blaName">
                                        {data?.uomClasses}
                                    </div>
                                }
                                visible={isPopoverVisibles[data?.key]}
                                onVisibleChange={handlePopoverVisibleChanges}
                                placement="topLeft"
                            >
                                {data?.uomClasses.length >
                                attribute?.attributeNameLength
                                    ? `${data?.uomClasses.slice(
                                          0,
                                          attribute?.attributeNameLength
                                      )}...`
                                    : data?.uomClasses}
                            </Popover>
                        )}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'UomConfigurator.uomTableColumnHeaderTitel.metricSystem'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    UOMTable.METRIC_SYSTEM,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    UOMTable.METRIC_SYSTEM,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'metricSystem',
            key: 'metricSystem',
            className: 'column__uom',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status fw-400">
                        {data?.metricSystem}
                    </div>
                </>
            ),
        },
    ];

    return (
        <>
            {data?.totalActiveUom + data?.totalInActiveUom > 0 ? (
                <>
                    <div className="uomConfigurator__rowHeader">
                        <Input
                            allowClear
                            className="uomConfigurator__search"
                            placeholder={t('UomConfigurator.searchPlaceHolder')}
                            prefix={<SearchOutlined />}
                            value={searchState}
                            onChange={(e) => {
                                setSearchState(e.target.value);
                                if (attributeSearchTimeout) {
                                    clearTimeout(attributeSearchTimeout);
                                }
                                // Debouncing for search implemented
                                setAttributeSearchTimeout(
                                    setTimeout(() => {
                                        dispatch(
                                            getUOMTableList({
                                                page: PAGE,
                                                pageSize: PAGE_SIZE,
                                                status: 0,
                                                search: e.target.value,
                                            })
                                        );
                                    }, 1000)
                                );
                                setPage(PAGE);
                            }}
                        />

                        <div className="uomConfigurator__button">
                            <CustomButton
                                type={t('UomConfigurator.addUomButton')}
                                disabled={false}
                                handleClick={() => {
                                    dispatch(setUomState(UOMTYPE.create));
                                }}
                            />
                        </div>
                    </div>
                    {uomListLoading ? (
                        <div className="view__loader">
                            <Spin />
                        </div>
                    ) : (
                        <>
                            <Row
                                className={
                                    data?.pageResponse?.totalRecords > 50
                                        ? 'uomConfigurator__uomListPagination'
                                        : 'uomConfigurator__uomList'
                                }
                            >
                                <Col span={24}>
                                    <Table
                                        rowSelection={{
                                            type: 'checkbox',
                                            ...rowSelection,
                                            selectedRowKeys: selectedRowIds,
                                        }}
                                        columns={columns}
                                        dataSource={tableData}
                                        pagination={false}
                                        loading={uomListLoading}
                                        scroll={{
                                            y: `${
                                                data?.pageResponse
                                                    ?.totalRecords > 50
                                                    ? 'calc(100vh - 390px)'
                                                    : 'calc(100vh - 340px)'
                                            }`,
                                        }}
                                        locale={{
                                            emptyText: SearchNoDataText,
                                        }}
                                    />
                                </Col>
                            </Row>
                            {data?.pageResponse?.totalRecords > 50 ? (
                                <CustomPagination
                                    totalRecords={
                                        data?.pageResponse?.totalRecords
                                    }
                                    setPage={setPage}
                                    page={page}
                                    setPageSize={setPageSize}
                                    pageSize={pageSize}
                                />
                            ) : (
                                EMPTY.string
                            )}
                        </>
                    )}
                </>
            ) : (
                <div className="uomConfigurator__tableEmpty">
                    <EmptyDataComponent
                        textValue={EMPTY.string}
                        buttonType={{
                            name: 'New UOM',
                            disable: false,
                        }}
                        secondaryTextValue={t('UomConfigurator.emptyUom')}
                        bulkImport={false}
                        buttonClickHandler={() => {
                            dispatch(setUomState(UOMTYPE.create));
                        }}
                    />
                </div>
            )}
        </>
    );
};
export default UOMConfiguratorTable;
