import { message } from "antd";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { getAllMonitoringServices, getCreatedByListServices, getLabelsListServices, getMonitoringByIdServices, getMonitoringListServices, reprocessMonitoringServices } from "redux/services/BusinessIntegratorServices/monitoringServices";
import * as types from "redux/types/businessIntegratorTypes";

export function* getLabelsListSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const labelsList = yield call(getLabelsListServices, payload);
    yield put({
      type: types.GET_LABELS_LIST_SUCCESS,
      response: labelsList,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_LABELS_LIST_FAILURE,
    });
  }
}

export function* getMonitoringListSaga({
    type,
    payload,
  }: {
    type: string;
    payload: any;
  }): Generator<any> {
    try {
      const getMonitoringList = yield call(getMonitoringListServices, payload);
      yield put({
        type: types.GET_MONITORING_LIST_SUCCESS,
        response: getMonitoringList,
      });
    } catch (error: any) {
      yield put({
        type: types.GET_MONITORING_LIST_FAILURE,
      });
    }
}

export function* getCreatedByListSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const crteatedByList = yield call(getCreatedByListServices, payload);
    yield put({
      type: types.GET_CREATEDBY_LIST_SUCCESS,
      response: crteatedByList,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_CREATEDBY_LIST_FAILURE,
    });
  }
}

export function* getAllMonitoringSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const allMonitoringList = yield call(getAllMonitoringServices, payload);
    yield put({
      type: types.GET_ALL_MONITORING_LIST_SUCCESS,
      response: allMonitoringList,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_ALL_MONITORING_LIST_FAILURE,
    });
  }
}

export function* getMonitoringByIdSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const getMonitoringById = yield call(getMonitoringByIdServices, payload);
    yield put({
      type: types.GET_MONITORING_BY_ID_SUCCESS,
      response: getMonitoringById,
    });
  } catch (error: any) {
    yield put({
      type: types.GET_MONITORING_BY_ID_FAILURE,
    });
  }
}

export function* reprocessMonitoringSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    const reprocessMonitoring: any = yield call(reprocessMonitoringServices, payload);
    yield put({
      type: types.REPROCESS_MONITORING_SUCCESS,
      response: reprocessMonitoring,
    });
    message.success(reprocessMonitoring?.data?.message);
  } catch (error: any) {
    yield put({
      type: types.REPROCESS_MONITORING_FAILURE,
    });
  }
}


export function* monitoringSaga(): any {
  yield all([takeLatest(types.GET_LABELS_LIST, getLabelsListSaga)]);
  yield all([takeLatest(types.GET_MONITORING_LIST, getMonitoringListSaga)]);
  yield all([takeLatest(types.GET_CREATEDBY_LIST, getCreatedByListSaga)]);
  yield all([takeLatest(types.GET_ALL_MONITORING_LIST, getAllMonitoringSaga)]);
  yield all([takeLatest(types.GET_MONITORING_BY_ID, getMonitoringByIdSaga)]);
  yield all([takeLatest(types.REPROCESS_MONITORING, reprocessMonitoringSaga)]);
}
