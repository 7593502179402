import { baseUrlSetter } from 'utils/commonFunction';
import Api from '../index';
import { message } from 'antd';

export const getAlertListsService = async (payload: any): Promise<any> => {
    baseUrlSetter('configure');
    const params = {
        pageNumber: payload?.page,
        pageSize: payload?.pageSize,
        assetId: payload?.assetId,
        ...(payload?.sortColumn !== ''
            ? { sortColumn: payload?.sortColumn }
            : {}),
        ...(payload?.sortOrder !== '' ? { sortOrder: payload?.sortOrder } : {}),
        ...(payload?.search !== '' ? { search: payload?.search } : {}),
    };
    try {
        const response = Api.get(`/alert`, {
            params: params,
        });

        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const getAlertDetailsService = async (payload: any): Promise<any> => {
    baseUrlSetter('configure');
    try {
        const response = Api.get(`/alert/${payload}`);

        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const getOperatorsService = async (payload: any): Promise<any> => {
    baseUrlSetter('configure');
    try {
        const response = Api.get(`/operator/list`);

        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const getOperatorByIdService = async (payload: any): Promise<any> => {
    baseUrlSetter('configure');
    try {
        const response = Api.get(`/operator/value/list/${payload?.id}`);

        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const getKpiListByAssetService = async (payload: any): Promise<any> => {
    baseUrlSetter('configure');
    const params = {
        assetId: payload?.assetId,
        isValidated: 1,
        includeChildNodes: false,
        activeStatus: 1,
        sortColumn: 1,
        sortOrder: 1,
    };
    try {
        const response = Api.get(`/kpi/details/list`, {
            params: params,
        });

        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const getDurationListService = async (payload: any): Promise<any> => {
    baseUrlSetter('configure');

    try {
        const response = Api.get(`/duration/list`);

        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const getCalculationCycleListService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('configure');

    try {
        const response = Api.get(`/duration/aggregate/list`);

        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const getNotificationChannelListService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('configure');

    try {
        const response = Api.get(`/notification/channel/list`);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const getConsequenceListService = async (payload: any): Promise<any> => {
    baseUrlSetter('configure');
    try {
        const response = Api.get(`/consequence/list`);

        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const createAlertService = async (payload: any): Promise<any> => {
    baseUrlSetter('configure');
    const params = payload;
    try {
        const response = Api.post(`/alert/create`, params);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};

export const updateAlertService = async (payload: any): Promise<any> => {
    baseUrlSetter('configure');
    const params = payload?.details;
    try {
        const response = Api.put(`/alert/edit/${payload?.alertId}`, params);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};
export const updateAlertStatusService = async (payload: any): Promise<any> => {
    baseUrlSetter('configure');
    try {
        const response = Api.put(`/alert/status`, payload);
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(error?.response?.data?.message);
        throw new Error(error);
    }
};
