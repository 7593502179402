import { Row, Col } from 'antd';
import CountAnalytics from 'components/common/CountAnalytics';
import ScreenNameHeading from 'components/common/ScreenNameHeading';
import { ReactComponent as ActiveUserIcon } from 'assets/icons/activeUserCountIcon.svg';
import { ReactComponent as DeactivateUserIcon } from 'assets/icons/deacticeUserCountIcon.svg';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const Heading: React.FC<any> = ({ viewDetailState }) => {
    const { t } = useTranslation('translation');

    const kpiDetail = useSelector(
        (state: any) => state.kpi?.kpiImplementation?.KpiDetailsData
    );
    const [validatedCount, setValidatedCount] = useState<any>(
        kpiDetail?.totalValidatedNodeCount
    );
    const [nonValidatedCount, setNonValidatedCount] = useState<any>(
        kpiDetail?.totalNodeCount - kpiDetail?.totalValidatedNodeCount
    );
    useEffect(() => {
        setValidatedCount(kpiDetail?.totalValidatedNodeCount);
        setNonValidatedCount(
            kpiDetail?.totalNodeCount - kpiDetail?.totalValidatedNodeCount
        );
    }, [kpiDetail?.totalValidatedNodeCount]);

    return (
        <>
            <div className="calendarConfigurator">
                <Row className="calendarConfigurator__headerWrapper">
                    <Col span={18}>
                        <ScreenNameHeading
                            heading={t('KPIImplementation.heading')}
                            subHeading={t('KPIImplementation.subHeading')}
                        />
                    </Col>
                    {viewDetailState ? (
                        <Col span={6}>
                            <CountAnalytics
                                customClassName="implementationCountAnalytics"
                                countDetails={[
                                    {
                                        title: t('KPIImplementation.Validated'),
                                        count:
                                            validatedCount > 0
                                                ? validatedCount
                                                : '--',
                                        icon: <ActiveUserIcon />,
                                    },
                                    {
                                        title: t('KPIImplementation.Pending'),
                                        count:
                                            nonValidatedCount > 0
                                                ? nonValidatedCount
                                                : '--',
                                        icon: <DeactivateUserIcon />,
                                    },
                                ]}
                            />
                        </Col>
                    ) : (
                        <></>
                    )}
                </Row>
            </div>
        </>
    );
};
export default Heading;
