import { message } from 'antd';
import initialState from '../initialStates';

import * as types from 'redux/types/formulaCreationTypes';
import { ruleCRUDEnum } from 'types/enums/FormulaCreatorEnum';

export default function formulaCreatorReducers(
    state = initialState.formulaCreator,
    action: any
): any {
    let tempExpressArray, newExpressionArray, expressionList, arr, expressArray;
    let tempArray: any = [];
    let fetchArray: any = [];

    switch (action.type) {
        case types.SET_FOCUSED_INDEX:
            state = {
                ...state,
                focusedIndex: action.payload,
            };
            return state;
        case types.SET_SELECTED_ASSET:
            state = {
                ...state,
                selectedAsset: action.payload,
            };
            return state;
        case types.UPDATE_FREQUENCY:
            state = {
                ...state,
                formulaData: {
                    ...state.formulaData,
                    frequencyInMillis: action.payload,
                    isScheduled: Boolean(action.payload > 1),
                },
            };
            return state;
        case types.UPDATE_FORMULA:
            switch (action.payload.type) {
                case ruleCRUDEnum.UPDATE:
                    tempArray = state.formulaData?.ruleData
                        ? state.formulaData?.ruleData
                        : [];
                    tempArray[action?.payload?.index] =
                        action?.payload?.details;
                    tempExpressArray = state.expressionArray;
                    
                    switch (action.payload.details.ruleCategoryId) {
                        case 0:
                            tempExpressArray[action.payload.index] =
                                action.payload.details.detail.text;
                            break;
                        
                        case 1:
                            tempExpressArray[action.payload.index] =
                                action.payload.details.detail.name;
                            break;
                            case 2:
                                tempExpressArray[action.payload.index] =
                                    action.payload.details.detail.name;
                            break;
                            case 3:
                                tempExpressArray[action.payload.index] =
                                    action.payload.details.detail.name;
                                break;

                        default:
                            break;
                    }
                    state = {
                        ...state,
                        focusedIndex:action.payload.index?state.focusedIndex:action.payload.index,
                        formulaData: {
                            ...state.formulaData,
                            ruleData: tempArray,
                        },
                        expressionArray: tempExpressArray,
                    };
                    break;

                case ruleCRUDEnum.DELETE:
                    newExpressionArray = [...state.expressionArray];
                    expressionList = state.formulaData?.ruleData
                        ? [...state.formulaData.ruleData]
                        : newExpressionArray;
                    expressionList.splice(action.payload.index, 1);
                    newExpressionArray.splice(action.payload.index, 1);

                    state = {
                        ...state,
                        formulaData: {
                            ...state.formulaData,
                            ruleData: expressionList,
                        },
                        expressionArray: newExpressionArray,
                    };
                    break;

                case ruleCRUDEnum.CREATE:
                    // Intially we are checking, whether it is new array for formula or not
                    if (Object.keys(state.formulaData).length) {
                        arr = state?.formulaData?.ruleData;
                        expressArray = state.expressionArray;
                        // checking the node exist or not where we are adding object in ruleData and string in expression array
                        if (arr?.length - 1 === action.payload.index) {
                            arr.splice(
                                action.payload.index + 1,
                                1,
                                action.payload?.details
                            );
                            expressArray?.splice(
                                action.payload.index + 1,
                                1,
                                action.payload.details.detail.text
                            );
                        } else {
                            arr?.splice(
                                action.payload.index + 1,
                                0,
                                action.payload?.details
                            );
                            expressArray?.splice(
                                action.payload.index + 1,
                                0,
                                action.payload?.details.detail.text
                            );
                        }
                        state = {
                            ...state,
                            formulaData: {
                                ...state.formulaData,
                                ruleData: arr,
                            },
                            focusedIndex: action.payload.index + 1,
                            expressionArray: expressArray,
                        };
                    } else {
                        state = {
                            ...state,
                            formulaData: {
                                ...action.payload.infoObject,
                                ruleData: [action.payload.details],
                            },
                            expressionArray: action.payload.details
                                .ruleCategoryId
                                ? [action.payload.details.detail.name]
                                : [action.payload.details.detail.text],
                        };
                    }

                    break;
                default:
            }
            // }
            return state;
        case types.CLEAR_FORMULA:
            state = {
                ...state,
                focusedIndex: 0,
                expressionArray: [],
                loader: false,
                formulaData: { ...state.formulaData, ruleData: [] },
            };

            return state;
        case types.VIEW_FORMULA_OPEN:
            state = {
                ...state,
                focusedIndex: 0,
                expressionArray: [],
                viewMeta: {
                    ...state.viewMeta,
                    ...action.payload,
                    view: true,
                },
                selectedAsset: action.payload.assetId,
            };
            return state;
        case types.VIEW_FORMULA_CLOSE:
            state = {
                ...state,
                viewMeta: {
                    ...state.viewMeta,
                    view: false,
                },
                formulaData: {},
                expressionArray: [],
            };
            return state;
        case types.LIST_ATTRIBUTES:
            state = {
                ...state,
                attributesList: {
                    ...state.attributesList,
                    loader: true,
                },
            };
            return state;
        case types.LIST_ATTRIBUTES_SUCCESS:
            state = {
                ...state,
                attributesList: {
                    ...state.attributesList,
                    loader: false,
                    data: action.response.data.pageResponse.records,
                },
            };
            return state;
        case types.LIST_ATTRIBUTES_FAILURE:
            state = {
                ...state,
                attributesList: {
                    ...state.attributesList,
                    loader: false,
                },
            };
            return state;

        case types.LIST_ATTRIBUTES_WITHOUT_ASSET:
            state = {
                ...state,
                attributesList: {
                    ...state.attributesListWithoutAssest,
                    loader: true,
                },
            };
            return state;
        case types.LIST_ATTRIBUTES_WITHOUT_ASSET_SUCCESS:
            state = {
                ...state,
                attributesList: {
                    ...state.attributesListWithoutAssest,
                    loader: false,
                    data: action?.response?.data?.pageResponse?.records,
                },
            };
            return state;
        case types.LIST_ATTRIBUTES_WITHOUT_ASSET_FAILURE:
            state = {
                ...state,
                attributesList: {
                    ...state.attributesListWithoutAssest,
                    loader: false,
                },
            };
            return state;
        case types.LIST_TAGS:
            state = {
                ...state,
                tagsList: {
                    ...state.tagsList,
                    loader: true,
                },
            };
            return state;
        case types.LIST_TAGS_SUCCESS:
            state = {
                ...state,
                tagsList: {
                    ...state.tagsList,
                    loader: false,
                    data: action.payload.content,
                },
            };
            return state;
        case types.LIST_TAGS_FAILURE:
            state = {
                ...state,
                tagsList: {
                    ...state.tagsList,
                    loader: false,
                },
            };
            return state;
        case types.LIST_KPIS:
            state = {
                ...state,
                KPIsList: {
                    ...state.KPIsList,
                    loader: true,
                },
            };
            return state;
        case types.LIST_KPIS_SUCCESS:
            state = {
                ...state,
                KPIsList: {
                    ...state.KPIsList,
                    loader: false,
                    data: action.response.data,
                },
            };
            return state;
        case types.LIST_KPIS_FAILURE:
            state = {
                ...state,
                KPIsList: {
                    ...state.KPIsList,
                    loader: false,
                },
            };
            return state;
        case types.CREATE_FORMULA:
            state = {
                ...state,
                responseObject: { ...state.responseObject, loader: true },
            };
            return state;
        case types.CREATE_FORMULA_SUCCESS:
            state = {
                ...state,
                responseObject: { data: action.payload, loader: false },
            };
            message.success(action.payload.data.message);
            return state;
        case types.CREATE_FORMULA_FAILURE:
            state = {
                ...state,
                responseObject: { ...state.responseObject, loader: false },
            };
            return state;
        case types.FETCH_FORMULA:
            state = {
                ...state,
                loader: true,
            };
            return state;
        case types.FETCH_FORMULA_SUCCESS:
            action?.payload?.data?.ruleData?.map((item: any) => {
                if (item?.ruleCategoryId === 0) {
                    fetchArray = [...fetchArray, item.detail.text];
                } else {
                    fetchArray = [...fetchArray, item.detail.name];
                }
            });

            state = {
                ...state,
                loader: false,
                formulaData: !action.payload.data.isScheduled
                    ? { ...action.payload.data, frequencyInMillis: 1 }
                    : action.payload.data,
                expressionArray: fetchArray,
            };
            return state;
        case types.FETCH_FORMULA_FAILURE:
            state = {
                ...state,
                loader: false,
            };
            return state;
        case types.UPDATE_RULE_FORMULA:
            state = {
                ...state,
                responseObject: { ...state.responseObject, loader: true },
            };
            return state;
        case types.UPDATE_RULE_FORMULA_SUCCESS:
            state = {
                ...state,
                responseObject: { data: action.payload, loader: false },
            };
            message.success(action.payload.data.message);

            return state;
        case types.UPDATE_RULE_FORMULA_FAILURE:
            state = {
                ...state,
                responseObject: { ...state.responseObject, loader: false },
            };
            return state;
        case types.UPDATE_FORMULA_FOR_KPI:
            action?.payload?.ruleData?.map((item: any) => {
                if (item?.ruleCategoryId === 0) {
                    fetchArray = [...fetchArray, item.detail.text];
                } else {
                    fetchArray = [...fetchArray, item.detail.name];
                }
            });
          
            state = { ...state, formulaData: action.payload,expressionArray:fetchArray };
            return state;
        default:
            return state;
    }
}
