import React, { useState, useEffect } from 'react';
import CustomPagination from 'components/common/CustomPagination';
import ConfigurationTable from './ConfigurationTable';
import CustomButton from 'components/common/CustomButton';
import { Card, Row, Col, Tabs, Input, Spin } from 'antd';
import './index.scss';
import { PAGE, PAGE_SIZE } from 'utils/constants';
import { EMPTY, EquipmentState } from 'types/enums';
import { SearchOutlined } from '@ant-design/icons';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import CreateNewClassForm from './CreateNewClassForm';
import NodeType from './NodeType';
import { useDispatch, useSelector } from 'react-redux';
import ViewEquipmentClass from './ViewEquipmentClass';
import EditEquipmentClass from './EditEquipmentClass';
import {
    getEquipmentClassList,
    getNodeTypeDetails,
    setEquipmentState,
} from 'redux/actions/AssetModellingActions/assetModellingAction';
import { useTranslation } from 'react-i18next';

const Configuration: React.FC = (): any => {
    const { t } = useTranslation('translation');
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const { TabPane } = Tabs;
    const [activeTab, setActiveTab] = useState('equipmentClass');
    const [sortOrder, setSortOrder] = useState<number | null>(null);
    const [sortColumn, setSortColumn] = useState<number | null>(null);

    const handleTabChange = (key: any): any => {
        setActiveTab(key);
        setSearchState(EMPTY.string);
        if (key === 'equipmentClass') {
            dispatch(
                getEquipmentClassList({
                    ...payload,
                    name: EMPTY.string,
                    page: PAGE,
                })
            );
        } else if (key === 'nodeType') {
            dispatch(getNodeTypeDetails({ ...payload }));
        }
    };
    const [searchState, setSearchState] = useState(EMPTY.string);
    const [tableSearchTimeout, setTableSearchTimeout] =
        useState<any>(undefined);

    const dispatch = useDispatch();
    const [payload, setPayload] = useState<any>({
        page: PAGE,
        pageSize: PAGE_SIZE,
    });

    useEffect(() => {
        setPayload((prevPayload: typeof payload) => ({
            ...prevPayload,
            page,
            pageSize,
        }));
    }, [page, pageSize]);

    const displayState = useSelector(
        (state: any) => state.assetModelling?.equipment?.eqipmentState
    );
    const configurationDataLoading = useSelector(
        (state: any) =>
            state?.assetModelling?.equipment?.configurationDataLoading
    );

    const configurationData = useSelector(
        (state: any) => state.assetModelling?.equipment?.equipmentClassTableList
    );

    const handleSearchChange = (e: any): any => {
        const searchValue = e.target.value;
        setSearchState(searchValue);
        if (tableSearchTimeout) {
            clearTimeout(tableSearchTimeout);
        }

        if (searchValue === EMPTY.string) {
            // Reset page and pageSize states
            setPage(PAGE);
            setPageSize(PAGE_SIZE);

            dispatch(
                getEquipmentClassList({
                    ...payload,
                    name: EMPTY.string,
                    page: PAGE,
                    pageSize: PAGE_SIZE,
                })
            );
        } else {
            // Debouncing for search implemented
            setTableSearchTimeout(
                setTimeout(() => {
                    dispatch(
                        getEquipmentClassList({
                            ...payload,
                            name: searchValue,
                            page: PAGE,
                        })
                    );
                }, 1000)
            );
        }
    };

    useEffect(() => {
        dispatch(
            getEquipmentClassList({
                sortColumn: sortColumn,
                sortOrder: sortOrder,
                page: page,
                pageSize: pageSize,
                name: searchState ?? EMPTY?.string,
            })
        );
    }, [sortOrder, sortColumn, page, pageSize]);
    return (
        <>
            <Tabs
                className="configurationTabs"
                activeKey={activeTab}
                onChange={handleTabChange}
            >
                <TabPane
                    tab={t('assetModeller.configuration.equipmentClass')}
                    key="equipmentClass"
                >
                    <div className="configurationWrapper">
                        <Card bordered={false}>
                            <>
                                <Row className="configurationWrapper__heading">
                                    <Col span={12}>
                                        <Input
                                            allowClear
                                            className="configurationWrapper__search"
                                            placeholder={t(
                                                'assetModeller.configuration.search'
                                            )}
                                            prefix={<SearchOutlined />}
                                            value={searchState}
                                            onChange={handleSearchChange}
                                        />
                                    </Col>
                                    <Col span={12}>
                                        <div className="configurationWrapper__addEquipmentClassBtn">
                                            {configurationData?.records
                                                ?.length === 0 ? null : (
                                                <CustomButton
                                                    type={t(
                                                        'assetModeller.configuration.addEquipmentClass'
                                                    )}
                                                    disabled={false}
                                                    handleClick={() => {
                                                        dispatch(
                                                            setEquipmentState(
                                                                EquipmentState?.add
                                                            )
                                                        );
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                                {configurationData?.records?.length > 0 ? (
                                    <>
                                        {configurationDataLoading ? (
                                            <div className="view__loader">
                                                <Spin />
                                            </div>
                                        ) : (
                                            <Row>
                                                <Col span={24}>
                                                    <div className="configurationTable">
                                                        <ConfigurationTable
                                                            data={
                                                                configurationData
                                                                    ?.paginatedResponse
                                                                    ?.records
                                                            }
                                                            payload={payload}
                                                            search={searchState}
                                                            setSearchState={
                                                                setSearchState
                                                            }
                                                            setPage={setPage}
                                                            setPageSize={
                                                                setPageSize
                                                            }
                                                            setSortColumn={
                                                                setSortColumn
                                                            }
                                                            setSortOrder={
                                                                setSortOrder
                                                            }
                                                        />
                                                    </div>
                                                </Col>
                                            </Row>
                                        )}
                                    </>
                                ) : (
                                    <div className="configurationTableWrapper__configurationTableEmpty">
                                        {searchState !== EMPTY.string ? (
                                            <EmptyDataComponent textValue="No Results, Try a different search item" />
                                        ) : (
                                            <EmptyDataComponent
                                                textValue={t(
                                                    'assetModeller.configuration.noEquipmentClassText'
                                                )}
                                                buttonType={{
                                                    name: 'New Equipment Class',
                                                    disable: false,
                                                }}
                                                buttonClickHandler={() => {
                                                    dispatch(
                                                        setEquipmentState(
                                                            EquipmentState?.add
                                                        )
                                                    );
                                                }}
                                            />
                                        )}
                                    </div>
                                )}
                            </>
                        </Card>
                        <div>
                            {configurationData?.totalRecords > PAGE_SIZE && (
                                <CustomPagination
                                    totalRecords={
                                        configurationData?.totalRecords
                                    }
                                    setPage={setPage}
                                    page={page}
                                    setPageSize={setPageSize}
                                    pageSize={pageSize}
                                />
                            )}
                        </div>
                    </div>
                </TabPane>
                <TabPane
                    tab={t('assetModeller.configuration.nodeType')}
                    key="nodeType"
                >
                    <NodeType />
                </TabPane>
            </Tabs>
            {displayState === 'Add' && (
                <CreateNewClassForm
                    open={displayState === 'Add'}
                    paginatedPayload={payload}
                    setSearchState={setSearchState}
                    setPage={setPage}
                    setPageSize={setPageSize}
                />
            )}
            {displayState === 'View' && (
                <ViewEquipmentClass
                    open={displayState === 'View'}
                    setSearchState={setSearchState}
                />
            )}
            {displayState === 'Edit' && (
                <EditEquipmentClass
                    open={displayState === 'Edit'}
                    paginatedPayload={payload}
                    setSearchState={setSearchState}
                    setPage={setPage}
                    setPageSize={setPageSize}
                />
            )}
        </>
    );
};

export default Configuration;
