import { baseUrlSetter } from "utils/commonFunction";
import Api from "..";
import { message } from "antd";

export const getLabelsListServices = async (
  payload: any
): Promise<any> => {
  baseUrlSetter("deviceManagement");
  const params = payload;
  try {
    const response = Api.get(`/itIntegration/getLabelsList`, {
      params: params,
    });
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const getMonitoringListServices = async (
    payload: any
  ): Promise<any> => {
    baseUrlSetter("deviceManagement");
    const params = payload;
    try {
      const response = Api.get(`/itIntegration/getMonitoringList`, {
        params: params,
      });
      return await Promise.resolve(response);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
      throw new Error(error);
    }
  };

  export const getCreatedByListServices = async (
    payload: any
  ): Promise<any> => {
    baseUrlSetter("deviceManagement");
    const params = payload;
    try {
      const response = Api.get(`/itIntegration/getCreatedByList`, {
        params: params,
      });
      return await Promise.resolve(response);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
      throw new Error(error);
    }
  };

  export const getAllMonitoringServices = async (
    payload: any
  ): Promise<any> => {
    baseUrlSetter("deviceManagement");
    const params = payload;
    try {
      const response = Api.get(`/itIntegration/getMonitorNameList`, {
        params: params,
      });
      return await Promise.resolve(response);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
      throw new Error(error);
    }
  };


  export const getMonitoringByIdServices = async (payload: any): Promise<any> => {
    baseUrlSetter("deviceManagement");
    const params = payload;
    try {
      const response = Api.get(`/itIntegration/${params}`);
      return await Promise.resolve(response);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
      throw new Error(error);
    }
  };

  export const reprocessMonitoringServices = async (payload: any): Promise<any> => {
    baseUrlSetter("deviceManagement");
    const params = payload;
    try {
      const response = Api.post(`/itIntegration/reProcess`, params);
      return await Promise.resolve(response);
    } catch (error: any) {
      message.error(error?.response?.data?.message);
      throw new Error(error);
    }
  };