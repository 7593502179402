import React from 'react';
import { List, Radio, type RadioChangeEvent } from 'antd';
import './index.scss';
import { reasonCodeLanguage } from 'types/enums';
import { reasonCodeMarathiLanguage } from 'types/enums/reasonCodeMarathiLanguage';

const ReasonCodeModal: React.FC<any> = ({
    setReasonCodeId,
    assetName,
    reasonCodeData,
    reasonCodeId,
    modalLanguageState,
    setModalLanguageState,
    reasonCodeMachineData,
}) => {
    const onChange = (e: RadioChangeEvent): void => {
        setReasonCodeId(e.target.value);
    };

    const translatedReasonCode = (item: any, languageState: string): any => {
        if (languageState === reasonCodeLanguage.MARATHI) {
            return reasonCodeMarathiLanguage[item]?.marathiName || item?.name;
        } else {
            return reasonCodeMarathiLanguage[item]?.name || item?.name;
        }
    };

    const modalHeader = (
        <div className="reasonCodeModal__body__header fs-20 fw-500">
            <div>
                {modalLanguageState === reasonCodeLanguage.MARATHI
                    ? reasonCodeMarathiLanguage?.assignReasonCode?.marathiName
                    : reasonCodeMarathiLanguage?.assignReasonCode?.name}
            </div>
            <div>
                <Radio.Group
                    value={modalLanguageState}
                    onChange={(e: any) => {
                        setModalLanguageState(e.target.value);
                    }}
                >
                    <Radio.Button value={reasonCodeLanguage.MARATHI}>
                        {reasonCodeLanguage.MARATHI}
                    </Radio.Button>
                    <Radio.Button value={reasonCodeLanguage.ENGLISH}>
                        {reasonCodeLanguage.ENGLISH}
                    </Radio.Button>
                </Radio.Group>
            </div>
        </div>
    );

    return (
        <>
            <div className="customModal__body">
                {modalHeader}
                <div className="resonCodeModalContent">
                    <Radio.Group
                        onChange={(e) => {
                            onChange(e);
                        }}
                        value={reasonCodeId}
                        className="resonCodeModalContent__content"
                    >
                        {reasonCodeData && reasonCodeData.length > 0 && (
                            <div className="resonCodeModalContent__stoppage">
                                <div className="resonCodeModalContent__heading fs-20 fw-400">
                                    {modalLanguageState ===
                                    reasonCodeLanguage.MARATHI
                                        ? reasonCodeMarathiLanguage?.Generic
                                              ?.marathiName
                                        : reasonCodeMarathiLanguage?.Generic
                                              ?.name}
                                </div>
                                <div className="resonCodeModalUnplannedContent">
                                    <List
                                        dataSource={reasonCodeData}
                                        renderItem={(item: any) => (
                                            <List.Item key={item.reasonCodeId}>
                                                <Radio
                                                    className="resonCodeModalPlannedContentList"
                                                    id={item.reasonCodeId}
                                                    value={item.reasonCodeId}
                                                >
                                                    <div className="resonCodeModalPlannedContentList__text">
                                                        {modalLanguageState ===
                                                        reasonCodeLanguage.MARATHI
                                                            ? item?.marathiName
                                                            : item?.name}
                                                    </div>
                                                </Radio>
                                            </List.Item>
                                        )}
                                    />
                                </div>
                            </div>
                        )}
                        {reasonCodeMachineData &&
                            reasonCodeMachineData.length > 0 && (
                                <div className="resonCodeModalContent__stoppage">
                                    <div className="resonCodeModalContent__heading fs-20 fw-400">
                                        {modalLanguageState ===
                                        reasonCodeLanguage.MARATHI
                                            ? reasonCodeMarathiLanguage?.Machine
                                                  ?.marathiName
                                            : reasonCodeMarathiLanguage?.Machine
                                                  ?.name}
                                        -
                                        {translatedReasonCode(
                                            assetName,
                                            modalLanguageState
                                        )}
                                    </div>
                                    <div className="resonCodeModalUnplannedContent">
                                        <List
                                            dataSource={reasonCodeMachineData}
                                            renderItem={(item: any) => (
                                                <List.Item
                                                    key={item.reasonCodeId}
                                                >
                                                    <Radio
                                                        className="resonCodeModalPlannedContentList"
                                                        id={item.reasonCodeId}
                                                        value={
                                                            item.reasonCodeId
                                                        }
                                                    >
                                                        <div className="resonCodeModalPlannedContentList__text">
                                                            {modalLanguageState ===
                                                            reasonCodeLanguage.MARATHI
                                                                ? item?.marathiName
                                                                : item?.name}
                                                        </div>
                                                    </Radio>
                                                </List.Item>
                                            )}
                                        />
                                    </div>
                                </div>
                            )}
                    </Radio.Group>
                </div>
            </div>
        </>
    );
};

export default ReasonCodeModal;
