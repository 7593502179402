import { Button, Tooltip } from 'antd';
import { useEffect, useRef, useState, type FC } from 'react';
import { functionsListData } from 'utils/constants';
import './index.scss';
import { updateFormulaAction } from 'redux/actions/FormulaCreatorActions/formulaCreatorActions';
import { useDispatch, useSelector } from 'react-redux';
import { ruleCRUDEnum, ruleCategoryEnum } from 'types/enums/FormulaCreatorEnum';
import EmptyDataComponent from 'components/common/EmptyDataComponent';

const FunctionsListing: FC<any> = ({ functionType, functionSearch }) => {
    const dispatch = useDispatch();
    const focusedIndexRedux = useSelector(
        (state: any) => state?.formulaCreator?.focusedIndex
    );
    const expressionArray = useSelector(
        (state: any) => state?.formulaCreator?.expressionArray
    );
    const functionListingRef = useRef<any>(null);
    const [functionsDataFlag, setFunctionsDataFlag] = useState<any>(null)
    useEffect(() => {
        const containsFunctionListingContainer = functionListingRef?.current?.querySelector('.functionsListing__container') !== null;
        setFunctionsDataFlag(containsFunctionListingContainer && functionsListData.some((item:any) => item.lists.some((ele:any) => ele.functionName.toLowerCase().includes(functionSearch.toLowerCase()))));
    }, [functionSearch, functionsListData]);
    
    const operandHandler = (item: any): void => {
        const payload = {
            index: expressionArray.length?focusedIndexRedux:0,
            type: ruleCRUDEnum.UPDATE,

            details: {
                ruleCategoryId: ruleCategoryEnum.TEXT,
                detail: {
                    text: item,
                },
            },
        };
        dispatch(updateFormulaAction(payload));
    };
    return (
        <>
            {functionsDataFlag ?
                <div className="functionsListing" ref={functionListingRef}>
                    {functionsListData?.map((item: any) => (
                        functionSearch.length ? item.lists.filter((ele: any) => {
                            
                    
                            return ele.functionName.toLowerCase().includes(functionSearch.toLowerCase())
                        }
                        ).length ?
                
                            <div
                                key={item.key}
                                style={{
                                    display:
                                        functionType?.includes(item.key) ||
                                            functionType.includes('all')
                                            ? 'block'
                                            : 'none',
                                }}
                                className="functionsListing__container"
                            >
                                <div
                                    className="functionsListing__container__title"
                                    key={item.key}
                                >
                                    <hr />
                                    <span>{item.title}</span>
                                    <hr />
                                </div>
                                <div className="functionsListing__container__buttons">
                                    {functionSearch.length
                                        ? item?.lists
                                            ?.filter((ele: any) =>
                                                ele?.functionProps.labelToAdd
                                                    .toLowerCase()
                                                    .includes(
                                                        functionSearch.toLowerCase()
                                                    )
                                            )
                                            ?.map((ele: any) => (
                                                <Tooltip
                                                    placement="top"
                                                    title={
                                                        <div className="function-tooltip">
                                                            <div className="function-tooltip__value">
                                                                <span
                                                                    style={{
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    Syntax:
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        fontWeight: 400,
                                                                        marginLeft:
                                                                            '12px',
                                                                    }}
                                                                >
                                                        
                                                                    {
                                                                        ele?.functionProps
                                                                            ?.syntax
                                                                    }
                                                                </span>
                                                            </div>
                                                            <div className="function-tooltip__value">
                                                                <span
                                                                    style={{
                                                                        fontWeight: 500,
                                                                    }}
                                                                >
                                                                    Description:
                                                                </span>
                                                                <span
                                                                    style={{
                                                                        fontWeight: 400,
                                                                        marginLeft:
                                                                            '12px',
                                                                    }}
                                                                >
                                                                    {' '}
                                                                    {
                                                                        ele?.functionProps
                                                                            ?.description
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    }
                                                    key={ele.functionName}
                                                >
                                                    <Button
                                                        className="function-button"
                                                        key={ele.functionName}
                                                        onClick={() => {
                                                            operandHandler(
                                                                ele.functionName
                                                            );
                                                        }}
                                                    >
                                                        <span>{ele.functionProps.labelToAdd}</span>
                                                    </Button>
                                                </Tooltip>
                                            ))
                                        : item?.lists?.map((ele: any) => (
                                            <Tooltip
                                                placement="top"
                                                trigger={['hover']}
                                                title={
                                                    <div className="function-tooltip">
                                                        <div className="function-tooltip__value">
                                                            <span
                                                                style={{
                                                                    fontWeight: 500,
                                                                    fontSize: '14px',
                                                                }}
                                                            >
                                                                Syntax:
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight: 400,
                                                                    marginLeft: '4px',
                                                                    fontSize: '12px',
                                                                }}
                                                            >
                                                                {' '}
                                                                {
                                                                    ele?.functionProps
                                                                        ?.syntax
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="function-tooltip__value">
                                                            <span
                                                                style={{
                                                                    fontWeight: 500,
                                                                    fontSize: '14px',
                                                                }}
                                                            >
                                                                Description:
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight: 400,
                                                                    marginLeft: '4px',
                                                                    fontSize: '12px',
                                                                }}
                                                            >
                                                                {' '}
                                                                {
                                                                    ele?.functionProps
                                                                        ?.description
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                key={ele.functionName}
                                            >
                                                <Button
                                                    className="function-button"
                                                    key={ele.functionName}
                                                    onClick={() => {
                                                        operandHandler(ele.functionName);
                                                    }}
                                                >
                                                    <span>{ele.functionProps.labelToAdd}</span>
                                                </Button>
                                            </Tooltip>
                                        ))}
                                </div>
                            </div> : null : <div
                                key={item.key}
                                style={{
                                    display:
                                        functionType?.includes(item.key) ||
                                            functionType.includes('all')
                                            ? 'block'
                                            : 'none',
                                }}
                                className="functionsListing__container"
                            >
                            <div
                                className="functionsListing__container__title"
                                key={item.key}
                            >
                                <hr />
                                <span>{item.title}</span>
                                <hr />
                            </div>
                            <div className="functionsListing__container__buttons">
                                {functionSearch.length
                                    ? item?.lists
                                        ?.filter((ele: any) => {
                                            return ele?.functionName
                                                .toLowerCase()
                                                .includes(
                                                    functionSearch.toLowerCase()
                                                )
                                        }
                                        )
                                        ?.map((ele: any) => (
                                            <Tooltip
                                                placement="top"
                                                title={
                                                    <div className="function-tooltip">
                                                        <div className="function-tooltip__value">
                                                            <span
                                                                style={{
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                Syntax:
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight: 400,
                                                                    marginLeft:
                                                                        '12px',
                                                                }}
                                                            >
                                                                {' '}
                                                                {
                                                                    ele?.functionProps
                                                                        ?.syntax
                                                                }
                                                            </span>
                                                        </div>
                                                        <div className="function-tooltip__value">
                                                            <span
                                                                style={{
                                                                    fontWeight: 500,
                                                                }}
                                                            >
                                                                Description:
                                                            </span>
                                                            <span
                                                                style={{
                                                                    fontWeight: 400,
                                                                    marginLeft:
                                                                        '12px',
                                                                }}
                                                            >
                                                                {' '}
                                                                {
                                                                    ele?.functionProps
                                                                        ?.description
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                }
                                                key={ele.functionName}
                                            >
                                                <Button
                                                    className="function-button"
                                                    key={ele.functionName}
                                                    onClick={() => {
                                                        operandHandler(
                                                            ele.functionName
                                                        );
                                                    }}
                                                >
                                                    <span>{ele.functionProps.labelToAdd}</span>
                                                </Button>
                                            </Tooltip>
                                        ))
                                    : item?.lists?.map((ele: any) => (
                                        <Tooltip
                                            placement="top"
                                            trigger={['hover']}
                                            title={
                                                <div className="function-tooltip">
                                                    <div className="function-tooltip__value">
                                                        <span
                                                            style={{
                                                                fontWeight: 500,
                                                                fontSize: '14px',
                                                            }}
                                                        >
                                                            Syntax:
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontWeight: 400,
                                                                marginLeft: '4px',
                                                                fontSize: '12px',
                                                            }}
                                                        >
                                                            {' '}
                                                            {
                                                                ele?.functionProps
                                                                    ?.syntax
                                                            }
                                                        </span>
                                                    </div>
                                                    <div className="function-tooltip__value">
                                                        <span
                                                            style={{
                                                                fontWeight: 500,
                                                                fontSize: '14px',
                                                            }}
                                                        >
                                                            Description:
                                                        </span>
                                                        <span
                                                            style={{
                                                                fontWeight: 400,
                                                                marginLeft: '4px',
                                                                fontSize: '12px',
                                                            }}
                                                        >
                                                            {' '}
                                                            {
                                                                ele?.functionProps
                                                                    ?.description
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            }
                                            key={ele.functionName}
                                        >
                                            <Button
                                                className="function-button"
                                                key={ele.functionName}
                                                onClick={() => {
                                                    operandHandler(ele.functionName);
                                                }}
                                            >
                                                <span>{ele.functionProps.labelToAdd}</span>
                                            </Button>
                                        </Tooltip>
                                    ))}
                            </div>
                        </div>
                    ))}
                </div> : <div className='emptyFunctionListing'> <EmptyDataComponent textValue='Functions not found' /></div>}
            </>
    );
};

export default FunctionsListing;
