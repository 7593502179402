import { all, fork } from "redux-saga/effects";
import { sideNavSaga } from "./NavSagas/navSaga";
import { usersSaga } from "./UserManagementSagas/userSaga";
import { groupsSaga } from "./UserManagementSagas/groupsSaga";
import { loginSaga } from "./AuthSaga/authSaga";
import { roleSaga } from "./UserManagementSagas/rolesSaga";
import { bulkUploadSaga } from "./BulkUploadSaga/bulkUploadSaga";
import { blaSaga } from "./DeviceManagementSaga/blaSaga";
import { tagSaga } from "./DeviceManagementSaga/tagSaga";
import { deviceSaga } from "./DeviceManagementSaga/deviceSaga";
import { nocilSaga } from "./NocilDashboard/nocilSaga";
import { manualSaga } from "./ManualEntrySaga/manualEntrySaga";
import { calendarConfiguratorSaga } from "./CalendarConfiguratorSaga";
import { operatorSaga } from "./OperatorEntrySaga/operatorEntrySaga";
import { dataVisualizationSaga } from "./DataExplorerSaga/dataVisualizationSaga";
import { dashboardReportingSaga } from "./DataExplorerSaga/dashboardReportingSaga";
import { attributeSaga } from "./ConfigureSaga/attributeSaga";
import { attributeImplementationSaga } from "./ImplementationSaga/attributeSaga";
import { timeCapsuleImplementationSaga } from "./ImplementationSaga/timeCapsuleSaga";
import { tableDefinitionSaga } from "./ConfigureSaga/tableSaga";
import { timeCapsuleSaga } from "./ConfigureSaga/timeCapsuleSaga";
import { kpiSaga } from "./ConfigureSaga/kpiSaga";
import { formulaCreatorListingSaga } from "./FormulaCreatorSaga/formulaCreatorListingSaga";
import { kpiImplementationSaga } from "./KpisSaga/kpiImplementationSaga";
import { reasonCodeSaga } from "./ReasonCodeSaga/reasonCodeSaga";
import { alertSaga } from "./AlertSaga/alertSaga";
import { assetModellingSaga } from "./AssetModellingSaga/assetModellingSaga";
import { tableSaga } from "./ImplementationSaga/tableSaga";
import { connectionsSaga } from "./BusinessIntegratorSaga/connectionsSaga";
import { monitoringSaga } from "./BusinessIntegratorSaga/monitoringSaga";
import { mappingSaga } from "./BusinessIntegratorSaga/mappingSaga";
import { operationSaga } from "./BusinessIntegratorSaga/operationSaga";
import { notificationConfigurationSaga } from "./notificationConfigurationSaga/notificationConfiguration";
export const rootSaga = function* root(): any {
  yield all([fork(sideNavSaga)]);
  yield all([fork(usersSaga)]);
  yield all([fork(loginSaga)]);
  yield all([fork(groupsSaga)]);
  yield all([fork(roleSaga)]);
  yield all([fork(blaSaga)]);
  yield all([fork(tagSaga)]);
  yield all([fork(bulkUploadSaga)]);
  yield all([fork(deviceSaga)]);
  yield all([fork(dataVisualizationSaga)]);
  yield all([fork(nocilSaga)]);
  yield all([fork(manualSaga)]);
  yield all([fork(calendarConfiguratorSaga)]);
  yield all([fork(operatorSaga)]);
  yield all([fork(attributeSaga)]);
  yield all([fork(tableDefinitionSaga)]);
  yield all([fork(timeCapsuleSaga)]);
  yield all([fork(kpiSaga)]);
  yield all([fork(dashboardReportingSaga)]);
  yield all([fork(attributeImplementationSaga)]);
  yield all([fork(kpiImplementationSaga)]);
  yield all([fork(dashboardReportingSaga)]);
  yield all([fork(timeCapsuleImplementationSaga)]);
  yield all([fork(tableSaga)]);
  yield all([fork(formulaCreatorListingSaga)]);
  yield all([fork(reasonCodeSaga)]);
  yield all([fork(alertSaga)]);
  yield all([fork(assetModellingSaga)]);
  yield all([fork(connectionsSaga)]);
  yield all([fork(monitoringSaga)]);
  yield all([fork(mappingSaga)]);
  yield all([fork(operationSaga)])
  yield all([fork(notificationConfigurationSaga)]);
};
