import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import translation_en from "./en/translation_en.json";
import translation_german from "./german/translation_german.json";
import translation_dutch from "./dutch/translation_dutch.json";

import Cookies from "universal-cookie";

const cookies = new Cookies();

i18next.use(initReactI18next).init({
  lng: cookies.get("lang"),
  fallbackLng: "en_IN",
  resources: {
    en_US: {
      translation: translation_en,
    },
    ger: {
      translation: translation_german,
    },
    en_IN: {
      translation: translation_en,
    },
    dut: {
      translation: translation_dutch,
    },
  },
});
export default i18next;
