import {
    ACTIVATE_DEACTIVATE_PROVIDER_CONFIG,
    ACTIVATE_DEACTIVATE_PROVIDER_CONFIG_FAIL,
    ACTIVATE_DEACTIVATE_PROVIDER_CONFIG_SUCCESS,
    CREATE_PROVIDER_CONFIG,
    CREATE_PROVIDER_CONFIG_FAIL,
    CREATE_PROVIDER_CONFIG_SUCCESS,
    GET_ALL_CHANNEL,
    GET_ALL_CHANNEL_FAIL,
    GET_ALL_CHANNEL_SUCCESS,
    GET_ALL_PROVIDER,
    GET_ALL_PROVIDER_CONFIG,
    GET_ALL_PROVIDER_CONFIG_FAIL,
    GET_ALL_PROVIDER_CONFIG_SUCCESS,
    GET_ALL_PROVIDER_FAIL,
    GET_ALL_PROVIDER_SUCCESS,
    GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID,
    GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID_FAIL,
    GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID_SUCCESS,
    GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID,
    GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID_FAIL,
    GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID_SUCCESS,
    SET_NOTIFICATION_STATE,
    TEST_PROVIDER_CONFIG_FAIL,
    TEST_PROVIDER_CONFIG_SUCCESS,
    UPDATE_PROVIDER_CONFIG,
    UPDATE_PROVIDER_CONFIG_FAIL,
    UPDATE_PROVIDER_CONFIG_SUCCESS,
} from 'redux/types/notificationConfigurationTypes';
import notificationConfigurationInitialState from '../notificationConfigurationIntialstate';

export default function notificationConfigurationReducer(
    state = notificationConfigurationInitialState.notificationConfiguration,
    action: any
): any {
    switch (action.type) {
        case SET_NOTIFICATION_STATE:
            state = {
                ...state,
                notificationState: action.payload,
                allProviderConfig: {},
                isUpdated:false
            };
            return state;
        case GET_ALL_CHANNEL:
            state = {
                ...state,
                isChannel: true,
            };
            return state;
        case GET_ALL_CHANNEL_SUCCESS:
            state = {
                ...state,
                channelList: action?.response?.data?.data?.channels,
                isChannel: false,
            };
            return state;
        case GET_ALL_CHANNEL_FAIL:
            state = {
                ...state,
                isChannel: false,
            };
            return state;
        case GET_ALL_PROVIDER:
            state = {
                ...state,
                providerListId: [],
                isProviderCardShow: true,
            };
            return state;
        case GET_ALL_PROVIDER_SUCCESS:
            state = {
                ...state,
                providerListId: action.response.data.data.providers,
                isProviderCardShow: false,
            };
            return state;
        case GET_ALL_PROVIDER_FAIL:
            state = {
                ...state,
                isProviderCardShow: false,
            };
            return state;
        case GET_ALL_PROVIDER_CONFIG:
            state = {
                ...state,
                allProviderConfig: {},
            };
            return state;
        case GET_ALL_PROVIDER_CONFIG_SUCCESS:
            state = {
                ...state,
                allProviderConfig: action.response.data.data,
            };
            return state;
        case GET_ALL_PROVIDER_CONFIG_FAIL:
            state = {
                ...state,
                allProviderConfig: {},
            };
            return state;
        case GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID:
            state = {
                ...state,
                providerConfigCreateFields: {},
            };
            return state;
        case GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID_SUCCESS:
            state = {
                ...state,
                providerConfigCreateFields: action.response.data.data,
                testVerification: false,
                isProviderConfigureCreated: false,
            };
            return state;
        case GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID_FAIL:
            state = {
                ...state,
            };
            return state;
        case TEST_PROVIDER_CONFIG_SUCCESS:
            state = {
                ...state,
                testVerification: true,
            };
            return state;
        case TEST_PROVIDER_CONFIG_FAIL:
            state = {
                ...state,
                testVerification: false,
            };
            return state;
        case GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID:
            state = {
                ...state,
                providerConfigUpdateFields: {},
            };
            return state;
        case GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID_SUCCESS:
            state = {
                ...state,
                providerConfigUpdateFields: action.response.data.data,
            };
            return state;
        case GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID_FAIL:
            state = {
                ...state,
            };
            return state;
        case CREATE_PROVIDER_CONFIG:
            state = {
                ...state,
                isProviderConfigureCreated: false,
            };
            return state;
        case CREATE_PROVIDER_CONFIG_SUCCESS:
            state = {
                ...state,
                isProviderConfigureCreated: true,
                createProviderConfig: action?.response?.data?.data,
            };
            return state;
        case CREATE_PROVIDER_CONFIG_FAIL:
            state = {
                ...state,
                isProviderConfigureCreated: false,
            };
            return state;
        case UPDATE_PROVIDER_CONFIG:
            state = {
                ...state,
                isUpdated: false,
                isProviderConfigureCreated: false,
            };
            return state;
        case UPDATE_PROVIDER_CONFIG_SUCCESS:
            state = {
                ...state,
                isUpdated: true,
                isProviderConfigureCreated: true,
                updateProviderConfig: action?.response?.data?.data,
            };
            return state;
        case UPDATE_PROVIDER_CONFIG_FAIL:
            state = {
                ...state,
                isUpdated: false,
                isProviderConfigureCreated: false,
            };
            return state;
            case ACTIVATE_DEACTIVATE_PROVIDER_CONFIG:
                state = {
                    ...state,
                    activateDeactivateConfig:false,
                };
                return state;
            case ACTIVATE_DEACTIVATE_PROVIDER_CONFIG_SUCCESS:
                state = {
                    ...state,
                    activateDeactivateConfig:true,
                };
                return state;
            case ACTIVATE_DEACTIVATE_PROVIDER_CONFIG_FAIL:
                state = {
                    ...state,
                    activateDeactivateConfig:false,
                  
                };
                return state;
        default:
            return state;
    }
}
