import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import './index.scss';
import CustomButton from 'components/common/CustomButton';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import {
    getConnectionsList,
    setConnectionsState,
} from 'redux/actions/BusinessIntegratorActions/connectionsActions';
import { ConnectionsState } from 'types/enums/businessIntegratorEnum';
import ConnectionTable from './connectionTable';
import { useTranslation } from 'react-i18next';
import { EMPTY } from 'types/enums';
import { PAGE, PAGE_SIZE } from 'utils/constants';

const Connections: React.FC = (): any => {
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');

    // will be use later
    const [searchState, setSearchState] = useState<any>(EMPTY.string);
    const [connectionsSearchTimeout, setConnectionsSearchTimeout] =
        useState<any>(undefined);
    const [page, setPage] = useState<number>(PAGE);
    const [pageSize, setPageSize] = useState(PAGE_SIZE);
    const [sortOrder, setSortOrder] = useState<any>();
    const [sortColumn, setSortColumn] = useState<any>();
    const connectionsTableList = useSelector(
        (state: any) => state.businessIntegrator.connections.connectionsList
    );
    const connectionsTableLoader = useSelector(
        (state: any) =>
            state.businessIntegrator.connections.connectionTableLoader
    );
    useEffect(() => {
        dispatch(
            getConnectionsList({
                pageNum: page,
                pageSize: pageSize,
                sortOrder: sortOrder,
                sortColumn: sortColumn,
            })
        );
    }, [pageSize, page, sortOrder, sortColumn]);

    const onConnectionSearch = (e: any): void => {
        setSearchState(e.target.value);
        if (connectionsSearchTimeout) {
            clearTimeout(connectionsSearchTimeout);
        }
        // Debouncing for search implemented
        setConnectionsSearchTimeout(
            setTimeout(() => {
                dispatch(
                    getConnectionsList({
                        pageNum: PAGE,
                        pageSize: PAGE_SIZE,
                        search: e.target.value,
                    })
                );
            }, 1000)
        );
        setPage(PAGE);
    };

    return (
        <>
            <div className="attributeWrapper connectionWrapper">
                <Card bordered={false}>
                    {connectionsTableList?.errorConnections +
                        connectionsTableList?.healthyConnections +
                        connectionsTableList?.warningConnections +
                        connectionsTableList?.inactiveConnections >
                    0 ? (
                        <>
                            <div className="attributeWrapper__rowHeader">
                                <Input
                                    allowClear
                                    className="attributeWrapper__search"
                                    placeholder={t(
                                        'BusinessIntegrator.searchPlaceHolder'
                                    )}
                                    prefix={<SearchOutlined />}
                                    value={searchState}
                                    onChange={(e) => {
                                        onConnectionSearch(e);
                                    }}
                                />
                                <div className="connectionWrapper__button">
                                    <CustomButton
                                        type={t(
                                            'BusinessIntegrator.connections.newConnectionButton'
                                        )}
                                        disabled={false}
                                        handleClick={() => {
                                            dispatch(
                                                setConnectionsState(
                                                    ConnectionsState.addConnections
                                                )
                                            );
                                        }}
                                    />
                                </div>
                            </div>
                            <ConnectionTable
                                setPage={setPage}
                                page={page}
                                setPageSize={setPageSize}
                                pageSize={pageSize}
                                setSortOrder={setSortOrder}
                                setSortColumn={setSortColumn}
                                setSearchState={setSearchState}
                            />
                        </>
                    ) : (
                        <>
                            <div className="connectionWrapper__noData">
                                <EmptyDataComponent
                                    textValue={t(
                                        'BusinessIntegrator.connections.emptyComponent.text'
                                    )}
                                    buttonType={{
                                        name: t(
                                            'BusinessIntegrator.connections.newConnectionButton'
                                        ),
                                        disable: false,
                                    }}
                                    loading={connectionsTableLoader}
                                    buttonClickHandler={() => {
                                        dispatch(
                                            setConnectionsState(
                                                ConnectionsState.addConnections
                                            )
                                        );
                                    }}
                                />
                            </div>
                        </>
                    )}
                </Card>
            </div>
        </>
    );
};

export default Connections;
