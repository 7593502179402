import {
    Drawer,
    Row,
    Col,
    Switch,
    Button,
    Divider,
    Form,
    Input,
    Spin,
    Select,
} from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import CustomButton from 'components/common/CustomButton';
import { BUTTONTYPE, EquipmentModule, nodeTypeLevels } from 'types/enums';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
    CLOSE_ADD_NODE_DRAWER,
    CLOSE_EDIT_NODE_DETAILS,
    EMPTY_EQUIPMENT_CLASS_DETAILS,
} from 'redux/types/assetModellingTypes';
import { useEffect, useState } from 'react';
import { parseJwt } from 'utils/jwtTokenFunction';
import {
    addNode,
    editNode,
    getEquipmentClassId,
    getEquipmentClassList,
    getNodeTypeId,
} from 'redux/actions/AssetModellingActions/assetModellingAction';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const NewNodeFormDrawer: React.FC<any> = ({ nodeDetailsById }): any => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    useEffect(() => {
        if (
            nodeDetailsById?.node?.nodeType?.level ===
            parseInt(EquipmentModule?.level)
        ) {
            dispatch(getEquipmentClassList({ page: 1, pageSize: 0 }));
        }
    }, [nodeDetailsById]);

    const nodeDetailsLoading = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.nodeDetailsLoading
    );
    const modelDetails = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.modelDetails
    );

    const editNodeDetails = useSelector(
        (state: any) => state?.assetModelling?.modelOverview?.editNodeDetails
    );
    const details = parseJwt();
    const nodeTypeDetails = useSelector(
        (state: any) => state?.assetModelling?.nodeType?.nodeTypeById
    );
    const [payload, setPayload] = useState({
        name: '',
        description: '',
        createdBy: details?.username,
        updatedBy: details?.username,
        model: {
            id: modelDetails?.id,
            name: modelDetails?.name,
            description: modelDetails?.description,
            umiIndex: modelDetails?.umiIndex,
            modelType: modelDetails?.modelType,
        },
        nodeType: {
            id: nodeTypeDetails?.id,
            name: nodeTypeDetails?.name,
            level: nodeTypeDetails?.level,
            description: nodeTypeDetails?.description,
            umiIndex: nodeTypeDetails?.umiIndex,
            categories: nodeTypeDetails?.categories,
        },
        parentId: nodeDetailsById?.node?.id,
        isCloudPushEligible: true,
        assetGroupId: '',
        equipmentAttributeList: [],
        streamList: [],
        nodeAttributeList: [],
    });

    useEffect(() => {
        nodeDetailsById?.node?.nodeType?.id &&
            dispatch(getNodeTypeId(nodeDetailsById?.node?.nodeType?.id));
    }, [nodeDetailsById]);
    const equipmentDataById = useSelector(
        (state: any) => state?.assetModelling?.equipment?.equipmentClassById
    );

    const { name, description, assetGroupId } = payload;

    const initialValues = {
        assetName: editNodeDetails ? nodeDetailsById?.node?.name : '',
        assetId: editNodeDetails ? nodeDetailsById?.node?.assetGroupId : '',
        description: editNodeDetails ? nodeDetailsById?.node?.description : '',
        pushToCloud:
            editNodeDetails && nodeDetailsById.node?.isCloudPushEligible,
        equipmentClass:
            editNodeDetails &&
            nodeDetailsById?.node?.nodeType?.level ===
                parseInt(EquipmentModule?.level)
                ? nodeDetailsById?.node?.equipmentClass?.name
                : null,
    };
    const [enableField, setEnableField] = useState(false);
    useEffect(() => {
        editNodeDetails &&
            nodeDetailsById?.node?.nodeType?.level ===
                parseInt(EquipmentModule?.level) &&
            setEnableField(true);

        !editNodeDetails &&
            nodeDetailsById?.node?.nodeType?.level === 4 &&
            setEnableField(true);
        editNodeDetails &&
            nodeDetailsById?.node?.nodeType?.level !==
                parseInt(EquipmentModule?.level) &&
            setEnableField(false);
    }, [nodeDetailsById?.node?.nodeType?.level]);
    const nodeAddition = (value: any): any => {
        if (nodeDetailsById?.node?.nodeType?.id) {
            if (enableField) {
                dispatch(
                    addNode({
                        ...payload,
                        parentId: nodeDetailsById?.node?.id,
                        modelId: modelDetails?.id,
                        nodeType: {
                            id: nodeTypeDetails?.id,
                            name: nodeTypeDetails?.name,
                            level: nodeTypeDetails?.level,
                            description: nodeTypeDetails?.description,
                            umiIndex: nodeTypeDetails?.umiIndex,
                            categories: nodeTypeDetails?.categories,
                        },
                        equipmentClass: {
                            id: equipmentDataById?.id,
                            name: equipmentDataById?.name,
                            description: equipmentDataById?.description,
                            categories: equipmentDataById?.categories,
                            umiIndex: equipmentDataById?.umiIndex,
                        },
                        isCloudPushEligible: value.pushToCloud,
                    })
                );
            } else {
                dispatch(
                    addNode({
                        ...payload,
                        parentId: nodeDetailsById?.node?.id,
                        modelId: modelDetails?.id,
                        nodeType: {
                            id: nodeTypeDetails?.id,
                            name: nodeTypeDetails?.name,
                            level: nodeTypeDetails?.level,
                            description: nodeTypeDetails?.description,
                            umiIndex: nodeTypeDetails?.umiIndex,
                            categories: nodeTypeDetails?.categories,
                        },

                        isCloudPushEligible: value.pushToCloud,
                    })
                );
            }
        }

        dispatch({
            type: CLOSE_ADD_NODE_DRAWER,
        });
        dispatch({
            type: EMPTY_EQUIPMENT_CLASS_DETAILS,
        });
    };
    const equipmentList = useSelector(
        (state: any) =>
            state?.assetModelling?.equipment?.equipmentClassTableList?.records
    );

    const editNodeInfo = (values: any): any => {
        dispatch(
            editNode({
                ...payload,
                id: nodeDetailsById?.node?.id,
                parentId: Object.keys(nodeDetailsById?.parentNode)?.length
                    ? nodeDetailsById?.parentNode?.id
                    : 0,
                modelId: modelDetails?.id,
                assetGroupId: values?.assetId,
                name: values?.assetName,
                description: values?.description,
                nodeType: {
                    id: nodeTypeDetails?.id,
                    name: nodeTypeDetails?.name,
                    level: nodeTypeDetails?.level,
                    description: nodeTypeDetails?.description,
                    umiIndex: nodeTypeDetails?.umiIndex,
                    categories: nodeTypeDetails?.categories,
                },
                equipmentClass: {
                    id: equipmentDataById?.id,
                    name: equipmentDataById?.name,
                    description: equipmentDataById?.description,
                    categories: equipmentDataById?.categories,
                    umiIndex: equipmentDataById?.umiIndex,
                },
                isCloudPushEligible: values.pushToCloud,
            })
        );
        dispatch({
            type: CLOSE_EDIT_NODE_DETAILS,
        });
        dispatch({
            type: EMPTY_EQUIPMENT_CLASS_DETAILS,
        });
    };
    const closeDrawer = (): any => {
        if (editNodeDetails) {
            dispatch({ type: CLOSE_EDIT_NODE_DETAILS });
            dispatch({
                type: EMPTY_EQUIPMENT_CLASS_DETAILS,
            });
        } else {
            dispatch({ type: CLOSE_ADD_NODE_DRAWER });
            dispatch({
                type: EMPTY_EQUIPMENT_CLASS_DETAILS,
            });
        }
    };
    useEffect(() => {
        if (!editNodeDetails && nodeDetailsById?.node?.nodeType?.level === 4) {
            dispatch(getEquipmentClassList({ page: 1, pageSize: 0 }));
        }
    }, [nodeDetailsById, editNodeDetails]);

    return (
        <>
            {nodeDetailsLoading ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                <>
                    <Drawer
                        className="modellingDrawer"
                        placement="right"
                        size="default"
                        getContainer={false}
                        closable={false}
                        open={true}
                        onClose={closeDrawer}
                    >
                        <>
                            <div>
                                <Form
                                    layout="vertical"
                                    className="modellingDrawer__form"
                                    initialValues={initialValues}
                                    onFinish={
                                        editNodeDetails
                                            ? editNodeInfo
                                            : nodeAddition
                                    }
                                >
                                    <Row>
                                        <Col span={9}>
                                            <Row>
                                                <Col
                                                    span={24}
                                                    className="modellingDrawer__heading fw-500 fs-16"
                                                >
                                                    <span>
                                                        {editNodeDetails
                                                            ? t(
                                                                  'assetModeller.modelOverview.editNode'
                                                              )
                                                            : t(
                                                                  'assetModeller.modelOverview.addNode'
                                                              )}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={8}>
                                            <div className="pushToCloud">
                                                {t(
                                                    'assetModeller.modelOverview.pushToCloud'
                                                )}
                                            </div>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                name="pushToCloud"
                                                valuePropName="checked"
                                            >
                                                <Switch />
                                            </Form.Item>
                                        </Col>
                                        <Col span={2}>
                                            <Button
                                                className="modellingDrawer__icon"
                                                type="text"
                                                onClick={() => {
                                                    editNodeDetails
                                                        ? dispatch({
                                                              type: CLOSE_EDIT_NODE_DETAILS,
                                                          }) &&
                                                          dispatch({
                                                              type: EMPTY_EQUIPMENT_CLASS_DETAILS,
                                                          })
                                                        : dispatch({
                                                              type: CLOSE_ADD_NODE_DRAWER,
                                                          }) &&
                                                          dispatch({
                                                              type: EMPTY_EQUIPMENT_CLASS_DETAILS,
                                                          });
                                                }}
                                                icon={<CloseOutlined />}
                                            ></Button>
                                        </Col>
                                    </Row>
                                    <Divider className="modellingDrawer__divider" />
                                    <div className="scrollable-content">
                                        <Form.Item
                                            name="nodeType"
                                            label={t(
                                                'assetModeller.modelOverview.nodeType'
                                            )}
                                        >
                                            <Input
                                                className="modellingDrawer__inputField"
                                                disabled
                                                defaultValue={
                                                    !editNodeDetails
                                                        ? nodeDetailsById?.node
                                                              ?.nodeType
                                                              ?.level === 0 // 0 here refers to level of nodeType- 'Enterprise'
                                                            ? nodeTypeLevels?.LEVEL_1
                                                            : nodeDetailsById
                                                                  ?.node
                                                                  ?.nodeType
                                                                  ?.level === 1 // 1 here refers to level of nodeType- 'Site'
                                                            ? nodeTypeLevels?.LEVEL_2
                                                            : nodeDetailsById
                                                                  ?.node
                                                                  ?.nodeType
                                                                  ?.level === 2 // 2 here refers to level of nodeType- 'Area'
                                                            ? nodeTypeLevels?.LEVEL_3
                                                            : nodeDetailsById
                                                                  ?.node
                                                                  ?.nodeType
                                                                  ?.level === 3 // 3 here refers to level of nodeType- 'Process Cell'
                                                            ? nodeTypeLevels?.LEVEL_4
                                                            : nodeDetailsById
                                                                  ?.node
                                                                  ?.nodeType
                                                                  ?.level === 4 // 4 here refers to level of nodeType- 'Unit'
                                                            ? nodeTypeLevels?.LEVEL_5
                                                            : 'Level-' +
                                                              nodeDetailsById
                                                                  ?.node
                                                                  ?.nodeType
                                                                  ?.level +
                                                              ' ' +
                                                              nodeDetailsById
                                                                  ?.node
                                                                  ?.nodeType
                                                                  ?.name
                                                        : 'Level-' +
                                                          nodeDetailsById?.node
                                                              ?.nodeType
                                                              ?.level +
                                                          ' ' +
                                                          nodeDetailsById?.node
                                                              ?.nodeType?.name
                                                }
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="nodeParent"
                                            label={t(
                                                'assetModeller.modelOverview.nodeParent'
                                            )}
                                        >
                                            <Input
                                                className="modellingDrawer__inputField"
                                                disabled
                                                defaultValue={
                                                    editNodeDetails
                                                        ? nodeDetailsById
                                                              ?.parentNode?.name
                                                        : nodeDetailsById?.node
                                                              ?.name
                                                }
                                            />
                                        </Form.Item>
                                        <Divider className="modellingDrawer__divider" />
                                        <Form.Item
                                            name="assetName"
                                            label={t(
                                                'assetModeller.modelOverview.assetName'
                                            )}
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Asset/Asset Group Name is required',
                                                },
                                                {
                                                    validator: async (
                                                        _,
                                                        value
                                                    ) => {
                                                        if (
                                                            value &&
                                                            value.trimStart() !==
                                                                value
                                                        ) {
                                                            throw new Error(
                                                                'Asset/Asset Group Name cannot start with a space'
                                                            );
                                                        }
                                                    },
                                                },
                                            ]}
                                        >
                                            <Input
                                                className="modellingDrawer__inputField"
                                                value={name}
                                                onChange={(e: any) => {
                                                    setPayload({
                                                        ...payload,
                                                        name: e?.target?.value,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="assetId"
                                            label={t(
                                                'assetModeller.modelOverview.assetId'
                                            )}
                                        >
                                            <Input
                                                className="modellingDrawer__inputField"
                                                value={assetGroupId}
                                                onChange={(e: any) => {
                                                    setPayload({
                                                        ...payload,
                                                        assetGroupId:
                                                            e?.target?.value,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="description"
                                            label={t(
                                                'assetModeller.modelOverview.description'
                                            )}
                                        >
                                            <Input
                                                className="modellingDrawer__inputField"
                                                value={description}
                                                onChange={(e: any) => {
                                                    setPayload({
                                                        ...payload,
                                                        description:
                                                            e?.target?.value,
                                                    });
                                                }}
                                            />
                                        </Form.Item>
                                        {((editNodeDetails &&
                                            nodeDetailsById?.node?.nodeType
                                                .level ===
                                                parseInt(
                                                    EquipmentModule?.level
                                                )) ||
                                            (!editNodeDetails &&
                                                nodeDetailsById?.node?.nodeType
                                                    .level === 4)) && (
                                            <>
                                                <Form.Item name="equipmentClass">
                                                    <Select
                                                        placeholder={t(
                                                            'assetModeller.modelOverview.equipmentclassPlaceholder'
                                                        )}
                                                        onChange={(
                                                            key,
                                                            option: any
                                                        ) => {
                                                            dispatch(
                                                                getEquipmentClassId(
                                                                    parseInt(
                                                                        option?.key
                                                                    )
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        {equipmentList?.map(
                                                            (item: any) => (
                                                                <Option
                                                                    key={
                                                                        item?.id
                                                                    }
                                                                    value={
                                                                        item?.name
                                                                    }
                                                                >
                                                                    {
                                                                        item?.value
                                                                    }
                                                                </Option>
                                                            )
                                                        )}
                                                    </Select>
                                                </Form.Item>
                                            </>
                                        )}
                                    </div>
                                    <div className="modellingDrawer__button">
                                        <Divider />
                                        <Row className="modellingDrawer__footerButtons">
                                            <Col
                                                span={8}
                                                className="modellingDrawer__cancelButton"
                                            >
                                                <CustomButton
                                                    type={BUTTONTYPE.cancel}
                                                    disabled={false}
                                                    handleClick={() => {
                                                        editNodeDetails
                                                            ? dispatch({
                                                                  type: CLOSE_EDIT_NODE_DETAILS,
                                                              }) &&
                                                              dispatch({
                                                                  type: EMPTY_EQUIPMENT_CLASS_DETAILS,
                                                              })
                                                            : dispatch({
                                                                  type: CLOSE_ADD_NODE_DRAWER,
                                                              }) &&
                                                              dispatch({
                                                                  type: EMPTY_EQUIPMENT_CLASS_DETAILS,
                                                              });
                                                    }}
                                                />
                                            </Col>
                                            <Col
                                                span={8}
                                                className="modellingDrawer__createBtn"
                                            >
                                                <CustomButton
                                                    disabled={false}
                                                    type={
                                                        editNodeDetails
                                                            ? 'Save'
                                                            : BUTTONTYPE.createNode
                                                    }
                                                    typeOfButton={'submit'}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </Form>
                            </div>
                        </>
                    </Drawer>
                </>
            )}
        </>
    );
};
export default NewNodeFormDrawer;
