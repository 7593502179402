import { Button } from 'antd';
import React from 'react';
import './index.scss'
import { useDispatch } from 'react-redux';
import { clearFormulaAction } from 'redux/actions/FormulaCreatorActions/formulaCreatorActions';
import { ReactComponent as ClearALL } from 'assets/icons/clearAll.svg';

const ClearAllCustomPopover: React.FC<any> = ({ hideInput }) => {
 
   
    const dispatch = useDispatch();
    const clearAllhandler = () :void=> {
        hideInput()
        dispatch(clearFormulaAction())
   }
  
    return (
        <div className="customPopOverContent">
          
            <div className='customPopOverContent__container' >
                <div className='customPopOverContent__title' ><span > Clear all the Formula</span></div>
                <ClearALL />
                <div className='customPopOverContent__subText' ><span >Would you like to clear everything</span></div>
            </div>
           
            <hr />
            <div className='customPopOverContent__footerButtons'>
                <Button onClick={hideInput}>Cancel</Button>
                <Button onClick={() => { clearAllhandler() }}>Clear</Button>

            </div>
        </div>
    );
};

export default ClearAllCustomPopover;
