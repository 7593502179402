import { type FC } from 'react';
import { operatorTypeEnum, ruleCRUDEnum, ruleCategoryEnum } from 'types/enums/FormulaCreatorEnum';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormulaAction } from 'redux/actions/FormulaCreatorActions/formulaCreatorActions';
const OperatorsLists: FC<any> = ({ focusedIndex, setComponents }) => {
    const OperatorsListData = [
        { type: operatorTypeEnum.Assignment, lists: [':='] },
        { type: operatorTypeEnum.Parenthesis, lists: ['(', ')'] },
        { type: operatorTypeEnum.Arithmetic, lists: ['+', '-', '*', '/', '%'] },
        {
            type: operatorTypeEnum.Comparison,
            lists: ['=', '==', '>', '<', '>=', '<=', '!='],
        },
        { type: operatorTypeEnum.Logical, lists: ['&', '!', '|'] },
        { type: operatorTypeEnum.Comma, lists: [','] },

    ];
    const listOperatorsData = (type: string):any => {
        return OperatorsListData?.find((operator) => operator.type === type)
            ?.lists;
    };
    const expressionArray = useSelector(
        (state: any) => state?.formulaCreator?.expressionArray
    );
    const dispatch = useDispatch();
    const focusedIndexRedux = useSelector(
        (state: any) => state?.formulaCreator?.focusedIndex
    );
   
    const operatorHandler = (item: any):void => {
        const payload = {
            index: expressionArray.length?focusedIndexRedux:0,
            type:ruleCRUDEnum.UPDATE,

            details: {
                ruleCategoryId: ruleCategoryEnum.TEXT,
                detail: {
                    text: item,
                },
            },
        };
        dispatch(updateFormulaAction(payload))
    };
    return (
        <div className="operatorContainer">
            <div className="operatorContainer__type">
                {listOperatorsData(operatorTypeEnum.Assignment)?.map((item:any) => (
                    <button onClick={()=>{operatorHandler(item)}} key={item}>{item}</button>
                ))}
            </div>
            <div className="operatorContainer__type">
                {listOperatorsData(operatorTypeEnum.Arithmetic)?.map((item:any) => (
                    <button onClick={()=>{operatorHandler(item)}} key={item}>{item}</button>
                ))}
            </div>
            <div className="operatorContainer__type">
                {listOperatorsData(operatorTypeEnum.Comparison)?.map((item:any) => (
                    <button  onClick={()=>{operatorHandler(item)}}key={item}>{item}</button>
                ))}
            </div>
            <div className="operatorContainer__type">
                {listOperatorsData(operatorTypeEnum.Logical)?.map((item:any) => (
                    <button onClick={()=>{operatorHandler(item)}} key={item}>{item}</button>
                ))}
            </div>
            <div className="operatorContainer__type">
                {listOperatorsData(operatorTypeEnum.Parenthesis)?.map(
                    (item:any) => (
                        <button onClick={()=>{operatorHandler(item)}} key={item}>{item}</button>
                    )
                )}
            </div>
            <div className="operatorContainer__type">
                {listOperatorsData(operatorTypeEnum.Comma)?.map(
                    (item:any) => (
                        <button onClick={()=>{operatorHandler(item)}} key={item}>{item}</button>
                    )
                )}
            </div>
        </div>
    );
};

export default OperatorsLists;
