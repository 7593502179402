import React from 'react';
import { Card, Col, Row } from 'antd';
import './index.scss';
import ImplementationAssetsGroup from 'pages/Implementation/ImplementationAssetsGroup';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import { useSelector } from 'react-redux';
import AlertTableComponent from './AlertTableComponent';
import { useTranslation } from 'react-i18next';

const AlertConfigurator: React.FC = () => {
    const { t } = useTranslation('translation');
    const selectedAsset = useSelector(
        (state: any) => state?.implementation?.attribute?.selectedAsset
    );

    return (
        <>
            <div className="configure">
                <Row className="Container__tabswrapper fw-500 fs-16">
                    <Col span={6}>
                        <ImplementationAssetsGroup />
                    </Col>
                    <Col span={18} className="tableSection">
                        <div className="alertWrapper">
                            <Card bordered={false}>
                                <>
                                    {Object.keys(selectedAsset)?.length ? (
                                        <AlertTableComponent
                                            selectedAssetkey={
                                                selectedAsset?.key
                                            }
                                        />
                                    ) : (
                                        <div className="implementationEmptyDataComponent">
                                            <EmptyDataComponent
                                                textValue={t('alerts.noData')}
                                                secondaryTextValue={t(
                                                    'alerts.selectAssetText'
                                                )}
                                            />{' '}
                                        </div>
                                    )}
                                </>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
};
export default AlertConfigurator;
