import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  ACTIVATE_DEACTIVATE_PROVIDER_CONFIG,
  ACTIVATE_DEACTIVATE_PROVIDER_CONFIG_FAIL,
  ACTIVATE_DEACTIVATE_PROVIDER_CONFIG_SUCCESS,
  CREATE_PROVIDER_CONFIG,
  CREATE_PROVIDER_CONFIG_FAIL,
  CREATE_PROVIDER_CONFIG_SUCCESS,
  GET_ALL_CHANNEL,
  GET_ALL_CHANNEL_FAIL,
  GET_ALL_CHANNEL_SUCCESS,
  GET_ALL_PROVIDER,
  GET_ALL_PROVIDER_CONFIG,
  GET_ALL_PROVIDER_CONFIG_FAIL,
  GET_ALL_PROVIDER_CONFIG_SUCCESS,
  GET_ALL_PROVIDER_FAIL,
  GET_ALL_PROVIDER_SUCCESS,
  GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID,
  GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID_FAIL,
  GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID_SUCCESS,
  GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID,
  GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID_FAIL,
  GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID_SUCCESS,
  TEST_PROVIDER_CONFIG,
  TEST_PROVIDER_CONFIG_FAIL,
  TEST_PROVIDER_CONFIG_SUCCESS,
  UPDATE_PROVIDER_CONFIG,
  UPDATE_PROVIDER_CONFIG_FAIL,
  UPDATE_PROVIDER_CONFIG_SUCCESS,
} from "redux/types/notificationConfigurationTypes";

import { SHOW_LOADER_SUCCESS } from "redux/types/deviceManagementTypes";
import {
  activateDeactivateProviderConfigurationService,
  createProviderConfigurationService,
  getAllChannelServices,
  getAllProviderConfigServices,
  getAllProviderServices,
  getProviderConfigCreateFieldsByIdServices,
  getProviderConfigUpdateFieldsByIdServices,
  testProviderConfigurationService,
  updateProviderConfigurationService,
} from "redux/services/NotificationConfigurationServices";
import { message } from "antd";
// import { message } from 'antd';

export function* getAllChannelSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: true });
    const getAllChannelData = yield call(getAllChannelServices, payload);
    yield put({
      type: GET_ALL_CHANNEL_SUCCESS,
      response: getAllChannelData,
    });
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error: any) {
    yield put({
      type: GET_ALL_CHANNEL_FAIL,
      response: error,
    });
  }
}

export function* getAllProviderSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: true });
    const getAllProviderData = yield call(getAllProviderServices, payload);
    yield put({
      type: GET_ALL_PROVIDER_SUCCESS,
      response: getAllProviderData,
    });
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error: any) {
    yield put({
      type: GET_ALL_PROVIDER_FAIL,
      response: error,
    });
  }
}

export function* updateProviderConfigurationSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: true });
    const isProviderConfigurationEdited = yield call(
      updateProviderConfigurationService,
      payload
    );
    yield put({
      type: UPDATE_PROVIDER_CONFIG_SUCCESS,
      response: isProviderConfigurationEdited,
    });
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error: any) {
    yield put({
      type: UPDATE_PROVIDER_CONFIG_FAIL,
      response: error,
    });
  }
}

export function* createProviderConfigurationSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: true });
    const isProviderConfigurationCreated = yield call(
      createProviderConfigurationService,
      payload
    );
    yield put({
      type: CREATE_PROVIDER_CONFIG_SUCCESS,
      response: isProviderConfigurationCreated,
    });
    if (isProviderConfigurationCreated) {
      message.success("Provider Configuration Saved Successfully");
    }
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error: any) {
    yield put({
      type: CREATE_PROVIDER_CONFIG_FAIL,
      response: error,
    });
  }
}

export function* testProviderConfigurationSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: true });
    const isProviderConfigurationTested = yield call(
      testProviderConfigurationService,
      payload
    );
    yield put({
      type: TEST_PROVIDER_CONFIG_SUCCESS,
      response: isProviderConfigurationTested,
    });
    if (isProviderConfigurationTested) {
      message.success("Test notification sent successfully");
    }
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error: any) {
    yield put({
      type: TEST_PROVIDER_CONFIG_FAIL,
      response: error,
    });
  }
}

export function* activateDeactivateProviderConfigurationSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: true });
    const getAllProviderConfigData: any = yield call(
      getAllProviderConfigServices,
      payload
    );
    if (getAllProviderConfigData) {
      const isProviderConfigurationActiveDeactive = yield call(
        activateDeactivateProviderConfigurationService,
        getAllProviderConfigData?.data?.data[0]?._id
      );
      yield put({
        type: ACTIVATE_DEACTIVATE_PROVIDER_CONFIG_SUCCESS,
        response: isProviderConfigurationActiveDeactive,
      });
      const getAllProviderData = yield call(getAllProviderServices, {
        type: payload?.selectedChannel?.toLocaleLowerCase(),
      });
      yield put({
        type: GET_ALL_PROVIDER_SUCCESS,
        response: getAllProviderData,
      });
    }

    // if (isProviderConfigurationTested) {
    //   message.success("Test notification sent successfully");
    // }
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error: any) {
    yield put({
      type: ACTIVATE_DEACTIVATE_PROVIDER_CONFIG_FAIL,
      response: error,
    });
  }
}
export function* getProviderConfigCreateFieldsByIdSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: true });
    const providerConfigByIdData = yield call(
      getProviderConfigCreateFieldsByIdServices,
      payload
    );
    yield put({
      type: GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID_SUCCESS,
      response: providerConfigByIdData,
    });
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error: any) {
    yield put({
      type: GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID_FAIL,
      response: error,
    });
  }
}

export function* getProviderConfigUpdateFieldsByIdSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: true });
    const providerConfigByIdData = yield call(
      getProviderConfigUpdateFieldsByIdServices,
      payload
    );
    yield put({
      type: GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID_SUCCESS,
      response: providerConfigByIdData,
    });
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error: any) {
    yield put({
      type: GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID_FAIL,
      response: error,
    });
  }
}

export function* getAllProviderConfigSaga({
  type,
  payload,
}: {
  type: string;
  payload: any;
}): Generator<any> {
  try {
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: true });
    const getAllProviderConfigData = yield call(
      getAllProviderConfigServices,
      payload
    );
    yield put({
      type: GET_ALL_PROVIDER_CONFIG_SUCCESS,
      response: getAllProviderConfigData,
    });
    yield put({ type: SHOW_LOADER_SUCCESS, showLoader: false });
  } catch (error: any) {
    yield put({
      type: GET_ALL_PROVIDER_CONFIG_FAIL,
      response: error,
    });
  }
}

export function* notificationConfigurationSaga(): any {
  yield all([takeLatest(GET_ALL_CHANNEL, getAllChannelSaga)]);
  yield all([takeLatest(GET_ALL_PROVIDER, getAllProviderSaga)]);
  yield all([
    takeLatest(UPDATE_PROVIDER_CONFIG, updateProviderConfigurationSaga),
  ]);
  yield all([
    takeLatest(CREATE_PROVIDER_CONFIG, createProviderConfigurationSaga),
  ]);
  yield all([takeLatest(TEST_PROVIDER_CONFIG, testProviderConfigurationSaga)]);
  yield all([
    takeLatest(
      GET_PROVIDER_CONFIG_CREATE_FIELDS_BY_ID,
      getProviderConfigCreateFieldsByIdSaga
    ),
  ]);
  yield all([
    takeLatest(
      GET_PROVIDER_CONFIG_UPDATE_FIELDS_BY_ID,
      getProviderConfigUpdateFieldsByIdSaga
    ),
  ]);
  yield all([takeLatest(GET_ALL_PROVIDER_CONFIG, getAllProviderConfigSaga)]);
  yield all([
    takeLatest(
      ACTIVATE_DEACTIVATE_PROVIDER_CONFIG,
      activateDeactivateProviderConfigurationSaga
    ),
  ]);
}
