export const SELECTED_ASSET = 'SELECTED_ASSET';
export const SET_SELECTED_MODEL = 'SET_SELECTED_MODEL';
export const UPDATE_MODEL = 'UPDATE_MODEL';
// Attribute Implementation

// Attribute Implementation

export const GET_ASSIGNED_ATTRIBUTE_LIST = 'GET_ASSIGNED_ATTRIBUTE_LIST';
export const GET_ASSIGNED_ATTRIBUTE_LIST_SUCCESS ='GET_ASSIGNED_ATTRIBUTE_LIST_SUCCESS';
export const GET_ASSIGNED_ATTRIBUTE_LIST_FAILURE ='GET_ASSIGNED_ATTRIBUTE_LIST_FAILURE';

export const GET_UNASSIGNED_ATTRIBUTE_LIST = 'GET_UNASSIGNED_ATTRIBUTE_LIST';
export const GET_UNASSIGNED_ATTRIBUTE_LIST_SUCCESS ='GET_UNASSIGNED_ATTRIBUTE_LIST_SUCCESS';
export const GET_UNASSIGNED_ATTRIBUTE_LIST_FAILURE ='GET_UNASSIGNED_ATTRIBUTE_LIST_FAILURE';

export const ASSIGN_ATTRIBUTE_LIST_TO_ASSET = 'ASSIGN_ATTRIBUTE_LIST_TO_ASSET';
export const ASSIGN_ATTRIBUTE_LIST_TO_ASSET_SUCCESS ='ASSIGN_ATTRIBUTE_LIST_TO_ASSET_SUCCESS';
export const ASSIGN_ATTRIBUTE_LIST_TO_ASSET_FAILURE ='ASSIGN_ATTRIBUTE_LIST_TO_ASSET_FAILURE';

export const VALIDATE_STATIC_VALUE_ATTRIBUTE = 'VALIDATE_STATIC_VALUE_ATTRIBUTE';
export const VALIDATE_STATIC_VALUE_ATTRIBUTE_SUCCESS ='VALIDATE_STATIC_VALUE_ATTRIBUTE_SUCCESS';
export const VALIDATE_STATIC_VALUE_ATTRIBUTE_FAILURE ='VALIDATE_STATIC_VALUE_ATTRIBUTE_FAILURE';

export const EDIT_VALUE_ATTRIBUTE = 'EDIT_VALUE_ATTRIBUTE';
export const EDIT_VALUE_ATTRIBUTE_SUCCESS ='EDIT_VALUE_ATTRIBUTE_SUCCESS';
export const EDIT_VALUE_ATTRIBUTE_FAILURE ='EDIT_VALUE_ATTRIBUTE_FAILURE';


export const SET_ATTRIBUTE_IMPL_STATE='SET_ATTRIBUTE_IMPL_STATE';
export const GET_TAG_LIST_DATA = 'GET_TAG_LIST_DATA';
export const GET_TAG_LIST_DATA_SUCCESS = 'GET_TAG_LIST_DATA_SUCCESS';
export const GET_TAG_LIST_DATA_FAILURE = 'GET_TAG_LIST_DATA_FAILURE';


export const CLEAR_ASSIGNED_ATTRIBUTE_DATA='CLEAR_ASSIGNED_ATTRIBUTE_DATA'
export const VALIDATE_TAG_VALUE_ATTRIBUTE = 'VALIDATE_TAG_VALUE_ATTRIBUTE';
export const VALIDATE_TAG_VALUE_ATTRIBUTE_SUCCESS ='VALIDATE_TAG_VALUE_ATTRIBUTE_SUCCESS';
export const VALIDATE_TAG_VALUE_ATTRIBUTE_FAILURE ='VALIDATE_TAG_VALUE_ATTRIBUTE_FAILURE';

export const VALIDATE_TAG_VALUE_ATTRIBUTE_EDIT = 'VALIDATE_TAG_VALUE_ATTRIBUTE_EDIT';
export const VALIDATE_TAG_VALUE_ATTRIBUTE_SUCCESS_EDIT ='VALIDATE_TAG_VALUE_ATTRIBUTE_SUCCESS_EDIT';
export const VALIDATE_TAG_VALUE_ATTRIBUTE_FAILURE_EDIT ='VALIDATE_TAG_VALUE_ATTRIBUTE_FAILURE_EDIT';


export const REMOVE_TAG_DATA = 'REMOVE_TAG_DATA'
export const EDIT_MODAL_DEFAULT_STATE = 'EDIT_MODAL_DEFAULT_STATE'
export const GET_ASSIGNED_TIME_CAPSULE_LIST = 'GET_ASSIGNED_TIME_CAPSULE_LIST';
export const GET_ASSIGNED_TIME_CAPSULE_LIST_SUCCESS =
    'GET_ASSIGNED_TIME_CAPSULE_LIST_SUCCESS';
export const GET_ASSIGNED_TIME_CAPSULE_LIST_FAILURE =
    'GET_ASSIGNED_TIME_CAPSULE_LIST_FAILURE';

export const GET_UNASSIGNED_TIME_CAPSULE_LIST =
    'GET_UNASSIGNED_TIME_CAPSULE_LIST';
export const GET_UNASSIGNED_TIME_CAPSULE_LIST_SUCCESS =
    'GET_UNASSIGNED_TIME_CAPSULE_LIST_SUCCESS';
export const GET_UNASSIGNED_TIME_CAPSULE_LIST_FAILURE =
    'GET_UNASSIGNED_TIME_CAPSULE_LIST_FAILURE';

export const ASSIGN_TIME_CAPSULE_TO_ASSET = 'ASSIGN_TIME_CAPSULE_TO_ASSET';
export const ASSIGN_TIME_CAPSULE_TO_ASSET_SUCCESS =
    'ASSIGN_TIME_CAPSULE_TO_ASSET_SUCCESS';
export const ASSIGN_TIME_CAPSULE_TO_ASSET_FAILURE =
    'ASSIGN_TIME_CAPSULE_TO_ASSET_FAILURE';

export const REMOVE_TIME_CAPSULE_FROM_ASSET = 'REMOVE_TIME_CAPSULE_FROM_ASSET';
export const REMOVE_TIME_CAPSULE_FROM_ASSET_SUCCESS =
    'REMOVE_TIME_CAPSULE_FROM_ASSET_SUCCESS';
export const REMOVE_TIME_CAPSULE_FROM_ASSET_FAILURE =
    'REMOVE_TIME_CAPSULE_FROM_ASSET_FAILURE';

export const CLEAR_ASSIGNED_TIME_CAPSULE_DATA =
    'CLEAR_ASSIGNED_TIME_CAPSULE_DATA';
export const SHOW_LOADER_SUCCESS = 'SHOW_LOADER_SUCCESS';

export const GET_TAGS_LIST='GET_TAGS_LIST';
export const GET_TAGS_LIST_SUCCESS='GET_TAGS_LIST_SUCCESS';
export const GET_TAGS_LIST_FAILURE='GET_TAGS_LIST_FAILURE';

export const GET_ATTRIBUTES_LIST='GET_ATTRIBUTES_LIST';
export const GET_ATTRIBUTES_LIST_SUCCESS='GET_ATTRIBUTES_LIST_SUCCESS';
export const GET_ATTRIBUTES_LIST_FAILURE='GET_ATTRIBUTES_LIST_FAILURE';


export const ADD_EDIT_TIME_CAPSULE_VALIDATION= 'ADD_EDIT_TIME_CAPSULE_VALIDATION';
export const ADD_EDIT_TIME_CAPSULE_VALIDATION_SUCCESS= 'ADD_EDIT_TIME_CAPSULE_VALIDATION_SUCCESS';
export const ADD_EDIT_TIME_CAPSULE_VALIDATION_FAILURE= 'ADD_EDIT_TIME_CAPSULE_VALIDATION_FAILURE';

export const GET_VALIDATED_TIME_CAPSULE_DETAILS='GET_VALIDATED_TIME_CAPSULE_DETAILS';
export const GET_VALIDATED_TIME_CAPSULE_DETAILS_SUCCESS='GET_VALIDATED_TIME_CAPSULE_DETAILS_SUCCESS';
export const GET_VALIDATED_TIME_CAPSULE_DETAILS_FAILURE='GET_VALIDATED_TIME_CAPSULE_DETAILS_FAILURE';

export const GET_CONDITIONS='GET_CONDITIONS';
export const GET_CONDITIONS_SUCCESS='GET_CONDITIONS_SUCCESS';
export const GET_CONDITIONS_FAILURE='GET_CONDITIONS_FAILURE';

export const CLEAR_TIME_CAPSULE_DATA='CLEAR_TIME_CAPSULE_DATA';

// table implementation types

export const GET_ASSIGNED_TABLE_LIST = 'GET_ASSIGNED_TABLE_LIST';
export const GET_ASSIGNED_TABLE_LIST_SUCCESS = 'GET_ASSIGNED_TABLE_LIST_SUCCESS';
export const GET_ASSIGNED_TABLE_LIST_FAILURE = 'GET_ASSIGNED_TABLE_LIST_FAILURE';

export const GET_UNASSIGNED_TABLE_LIST = 'GET_UNASSIGNED_TABLE_LIST';
export const GET_UNASSIGNED_TABLE_LIST_SUCCESS = 'GET_UNASSIGNED_TABLE_LIST_SUCCESS';
export const GET_UNASSIGNED_TABLE_LIST_FAILURE = 'GET_UNASSIGNED_TABLE_LIST_FAILURE';

export const ASSIGN_TABLE_TO_ASSET = 'ASSIGN_TABLE_TO_ASSET';
export const ASSIGN_TABLE_TO_ASSET_SUCCESS = 'ASSIGN_TABLE_TO_ASSET_SUCCESS';
export const ASSIGN_TABLE_TO_ASSET_FAILURE = 'ASSIGN_TABLE_TO_ASSET_FAILURE';

export const UNASSIGN_TABLE_TO_ASSET = 'UNASSIGN_TABLE_TO_ASSET';
export const UNASSIGN_TABLE_TO_ASSET_SUCCESS = 'UNASSIGN_TABLE_TO_ASSET_SUCCESS';
export const UNASSIGN_TABLE_TO_ASSET_FAILURE = 'UNASSIGN_TABLE_TO_ASSET_FAILURE';

export const RESET_ASSIGN_TABLE_STATUS = 'RESET_ASSIGN_TABLE_STATUS';
export const RESET_VALIDATE_TABLE_STATUS = 'RESET_VALIDATE_TABLE_STATUS';
export const CLEAR_TABLE_DATA = 'CLEAR_TABLE_DATA';

export const GET_TABLE_VALUES = 'GET_TABLE_VALUES';
export const GET_TABLE_VALUES_SUCCESS = 'GET_TABLE_VALUES_SUCCESS';
export const GET_TABLE_VALUES_FAILURE = 'GET_TABLE_VALUES_FAILURE';

export const VALIDATE_TABLE = 'VALIDATE_TABLE';
export const VALIDATE_TABLE_SUCCESS = 'VALIDATE_TABLE_SUCCESS';
export const VALIDATE_TABLE_FAILURE = 'VALIDATE_TABLE_FAILURE';

export const EDIT_TABLE = 'EDIT_TABLE';
export const EDIT_TABLE_SUCCESS = 'EDIT_TABLE_SUCCESS';
export const EDIT_TABLE_FAILURE = 'EDIT_TABLE_FAILURE';
