import { baseUrlSetter } from "utils/commonFunction";
import Api from "../index";
import { message } from "antd";

export const getAllChannelServices = async (payload: any): Promise<any> => {
  baseUrlSetter("notification");
  try {
    const response = await Promise.resolve(Api.get(`/api/channel`));
    return response;
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const getAllProviderServices = async (payload: any): Promise<any> => {
  baseUrlSetter("notification");

  try {
    const response = Api.get(`/api/provider`, { params: payload });
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const getAllProviderConfigServices = async (
  payload: any
): Promise<any> => {
  baseUrlSetter("notification");
  const params = {
    name: payload.providerName,
    sortOrder: "desc",
    limit: 1,
    sort: "createdAt",
    channel: payload.channelName,
  };

  try {
    const response = Api.get(`api/provider-config`, { params: params });
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};
export const updateProviderConfigurationService = async (
  payload: any
): Promise<any> => {
  baseUrlSetter("notification");
  try {
    const response = Api.put(`/api/provider-config/${payload._id}`, payload);
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const createProviderConfigurationService = async (
  payload: any
): Promise<any> => {
  baseUrlSetter("notification");
  try {
    const response = await Promise.resolve(
      Api.post(`/api/provider-config`, payload)
    );
    return response;
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const testProviderConfigurationService = async (
  payload: any
): Promise<any> => {
  baseUrlSetter("notification");
  const params = payload.recipient ? { params: payload.recipient } : {};
  try {
    const response = await Promise.resolve(
      Api.get(`/api/provider-config/verify/${payload.providerConfigId}`,{params})
    );
    return response;
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};

export const activateDeactivateProviderConfigurationService = async (
  payload: any
): Promise<any> => {
  baseUrlSetter("notification");
  try {
    const response = await Api.get(
      `api/provider-config/status-toggle/${payload}`
    );
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(
      error.response.data.message
        ? error.response.data.message
        : "Internal Server Error"
    );
    throw new Error(error);
  }
};

export const getProviderConfigCreateFieldsByIdServices = async (
  payload: any
): Promise<any> => {
  baseUrlSetter("notification");
  try {
    const response = Api.get(`/api/provider/${payload}`);
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};
export const getProviderConfigUpdateFieldsByIdServices = async (
  payload: any
): Promise<any> => {
  baseUrlSetter("notification");
  try {
    const response = Api.get(`/api/provider-config/${payload}`);
    return await Promise.resolve(response);
  } catch (error: any) {
    message.error(error?.response?.data?.message);
    throw new Error(error);
  }
};
