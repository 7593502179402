import { setConnectionsState } from 'redux/actions/BusinessIntegratorActions/connectionsActions';
import './index.scss';
import {
    ConnectionsState,
    MoreContentEnum,
} from 'types/enums/businessIntegratorEnum';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const MoreContent: React.FC<{
    record: any;
    setPopoverVisible: Function;
}> = ({ record }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const values = [
        {
            title: MoreContentEnum.view,
            disable: false,
        },
        {
            title: MoreContentEnum.edit,
            disable: false,
        },
        {
            title: MoreContentEnum.copy,
            disable: false,
        },
    ];

    // To hide edit functionality from active connection and 1 is for indexPosition of values ie edit
    if (record?.streamingOn) {
        values.splice(1, 1);
    }

    const handleClick = (viewType: any): any => {
        if (viewType === MoreContentEnum.view) {
            dispatch(setConnectionsState(ConnectionsState.viewConnections));
            navigate(
                `/connect/business-integrator/connections/view/${record.id}`
            );
        } else if (viewType === MoreContentEnum.copy) {
            dispatch(setConnectionsState(ConnectionsState.copyConnections));
            navigate(
                `/connect/business-integrator/connections/copy/${record.id}`
            );
        } else {
            dispatch(setConnectionsState(ConnectionsState.editConnection));
            navigate(
                `/connect/business-integrator/connections/edit/${record.id}`
            );
        }
    };

    return (
        <>
            <div className="more-container">
                <div className={`more-content`}>
                    <ul>
                        {values?.map((item: any) => {
                            return (
                                <>
                                    <li
                                        key={item.title}
                                        className={`moreContent__items  ${
                                            item.disable ? 'display-none' : null
                                        }`}
                                        onClick={() => {
                                            handleClick(item.title);
                                        }}
                                    >
                                        <span className="moreContent__icon">
                                            {item.icon}
                                        </span>
                                        <span className="moreContent__option">
                                            {item.title}
                                        </span>
                                    </li>
                                </>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default MoreContent;
