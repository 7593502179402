export const TIMER = 60 * 60 * 1000;

export const PAGE = 1;

export const PAGE_SIZE = 50;

export const DATA_TABLE_PAGE_SIZE = 10;

export const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DATE_TIME_FORMAT = 'DD-MM-YY HH:mm:ss';
export const DATE_ONLY_FORMAT = 'DD-MM-YYYY';
export const DATE_FORMAT_MANUAL_ENTRY = 'DD-MM-YYYY HH:mm:ss';
export const BoilerHeading = '30 TPH Boiler - 2 Dashboard';

export const tooltipDateTimeFormat = '%Y-%m-%d %H:%M';

export const maxRowSelectionCount = 15;

export const MULTI_AXIS_CHART_COLORS = [
    '#2E4374', // red
    '#0000FF', // blue
    '#FF6969', // green
    '#B8B800', // yellow
    '#D83F31', // orange
    '#800080', // purple
    '#EE96A4', // pink
    '#A52A2A', // brown
    '#35A29F', // gray
    '#F39F5A', // black
    '#5BCBFC', // white
    '#BC7AF9', // teal
    '#000080', // navy
    '#FF00FF', // magenta
    '#00FFFF', // cyan
];

// Note: keys are named with space because this is the only parameter which is available when a checkbox is clicked,
// hence names of checkbox is being used
export const criticalAreaColorMapper: any = {
    'Average Steam Flow': MULTI_AXIS_CHART_COLORS[0],
    'Excess Oxygen': MULTI_AXIS_CHART_COLORS[1],
    'Average Steam Pressure': MULTI_AXIS_CHART_COLORS[2],
    'Average Steam Temperature': MULTI_AXIS_CHART_COLORS[3],
    'Economizer - Flue Gas Inlet Temperature': MULTI_AXIS_CHART_COLORS[4],
    'Economizer - Flue Gas Exit Temperature': MULTI_AXIS_CHART_COLORS[5],
    'Economizer - Water Inlet Temperature': MULTI_AXIS_CHART_COLORS[6],
    'Economizer - Water Outlet Temperature': MULTI_AXIS_CHART_COLORS[7],
    'APH - Air Inlet Temperature': MULTI_AXIS_CHART_COLORS[8],
    'APH - Air Outlet Temperature': MULTI_AXIS_CHART_COLORS[9],
    'APH - Flue Gas Inlet Temperature': MULTI_AXIS_CHART_COLORS[10],
    'APH - Flue Gas Exit Temperature': MULTI_AXIS_CHART_COLORS[11],
};
export const maxInputLength = 250;
export const maxDeviceNameLength = 60;
export const maxTagNameLength = 100;
export const nocilDashboardUrl = 'nocil-dashboard';
export const chartDateFormat = '%Y-%m-%d %H:%M:%S';
export const oneMinToMilliSecond = 60 * 1000;
export const millisecondsInDay = 24 * 60 * 60 * 1000;
export const passwordMaxLength = 128;
export const functionsListData: any = [
    {
        key: 'number',
        title: 'Number',
        lists: [
            {
                functionName: 'ABS',
                functionProps: {
                    labelToAdd: 'ABS',
                    syntax: 'ABS(x)',
                    description: `Returns the absolute value of given number`,
                },
            },
            {
                functionName: 'CEILING',
                functionProps: {
                    labelToAdd: 'CEILING',
                    syntax: 'CEILING(x)',
                    description: `Rounds the number to nearest integer of equal or greater value`,
                },
            },
            {
                functionName: 'FACT',
                functionProps: {
                    labelToAdd: 'FACT',
                    syntax: 'FACT(x)',
                    description: `Returns the factorial of a number`,
                },
            },
            {
                functionName: 'FLOOR',
                functionProps: {
                    labelToAdd: 'FLOOR',
                    syntax: 'FLOOR(x)',
                    description: `Returns the largest integer value that is smaller than or equal to a number`,
                },
            },
            {
                functionName: 'LOG',
                functionProps: {
                    labelToAdd: 'LOG',
                    syntax: 'LOG(number,base)',
                    description: `Returns the natural logarithm of a specified number`,
                },
            },
            {
                functionName: 'LOG10',
                functionProps: {
                    labelToAdd: 'LOG10',
                    syntax: 'LOG10(x)',
                    description: `Returns the natural logarithm of a number to base 10`,
                },
            },
            {
                functionName: 'ROUND',
                functionProps: {
                    labelToAdd: 'ROUND',
                    syntax: 'ROUND(number,decimals)',
                    description: `Rounds a number to a specified number of decimal places`,
                },
            },
            {
                functionName: 'SQRT',
                functionProps: {
                    labelToAdd: 'SQRT',
                    syntax: 'SQRT(number)',
                    description: `Returns the square root of a number`,
                },
            },
            {
                functionName: 'DELTA',
                functionProps: {
                    labelToAdd: 'DELTA',
                    syntax: 'DELTA(expression)',
                    description: `Returns the sum of difference of current value to previous value on a incremental tag`,
                },
            },
            {
                functionName: 'DEG',
                functionProps: {
                    labelToAdd: 'DEG',
                    syntax: 'DEG(number)',
                    description: `Converts an angle measured in radians to an approximately equivalent angle measured in degrees`,
                },
            },
            {
                functionName: 'RAD',
                functionProps: {
                    labelToAdd: 'RAD',
                    syntax: 'RAD(Integer or Real)',
                    description: `Converts an angle measured in degrees to an approximately equivalent angle measured in radians`,
                },
            },
        ],
    },
    {
        key: 'logical',
        title: 'Logical',
        lists: [
            {
                functionName: 'IF',
                functionProps: {
                    labelToAdd: 'IF',
                    syntax: 'IF(condition, value_if_true, value_if_false)',
                    description: `Returns a value if a condition is TRUE, or another value if a condition is FALSE`,
                },
            },
            {
                functionName: 'NOT',
                functionProps: {
                    labelToAdd: 'NOT',
                    syntax: `NOT(expression)`,
                    description: `Reverses the value of its argument`,
                },
            },
        ],
    },
    {
        key: 'date',
        title: 'Date',
        lists: [
            {
                functionName: 'DT_DATE_NEW',
                functionProps: {
                    labelToAdd: 'DT_DATE_NEW',
                    syntax: 'DT_DATE_NEW(year, month, day [,hour, minute, second, millis, nanos] [,zoneId])',
                    description: `Returns a new DATE_TIME value with the given parameters. Any optional time zone (string) can be specified, e.g. “Europe/Berlin”, or “GMT+02:00. If no zone id is specified, the configured zone id is used.`,
                },
            },
            {
                functionName: 'DT_DATE_PARSE',
                functionProps: {
                    labelToAdd: 'DT_DATE_PARSE',
                    syntax: `DT_DATE_PARSE(value [,zoneId] [,format, …])`,
                    description: `Converts the given string value to a date time value by using the optional time zone and formats. All formats are used until the first matching format. Without a format, the configured formats are used. Time zone can be NULL, the the configured time zone is used.`,
                },
            },
            {
                functionName: 'DT_DATE_FORMAT',
                functionProps: {
                    labelToAdd: 'DT_DATE_FORMAT',
                    syntax: `DT_DATE_FORMAT(value, [,format] [,zoneId])`,
                    description: `Formats the given date-time to a string using the given optional format and time zone. Without a format, the first configured format is used. The zone id defaults to the configured zone id.`,
                },
            },
            {
                functionName: 'DT_DATE_TO_EPOCH',
                functionProps: {
                    labelToAdd: 'DT_DATE_TO_EPOCH',
                    syntax: `DT_DATE_TO_EPOCH(value)`,
                    description:
                        'Converts the given value to epoch timestamp in millisecond',
                },
            },
            {
                functionName: 'DT_DURATION_NEW',
                functionProps: {
                    labelToAdd: 'DT_DURATION_NEW',
                    syntax: `DT_DURATION_NEW(days [,hours, minutes, seconds, nanos])`,
                    description:
                        'Returns a new DURATION value with the given parameters.',
                },
            },

            {
                functionName: 'DT_DURATION_FROM_MILLIS',
                functionProps: {
                    labelToAdd: 'DT_DURATION_FROM_MILLIS',
                    syntax: `DT_DURATION_FROM_MILLIS(millis)`,
                    description:
                        'Returns a new DURATION value with the given milliseconds.',
                },
            },
            {
                functionName: 'DT_DURATION_TO_MILLIS',
                functionProps: {
                    labelToAdd: 'DT_DURATION_TO_MILLIS',
                    syntax: `DT_DURATION_TO_MILLIS(value)`,
                    description:
                        'Converts the given duration to a milliseconds value.',
                },
            },
            {
                functionName: 'DT_DURATION_PARSE',
                functionProps: {
                    labelToAdd: 'DT_DURATION_PARSE',
                    syntax: `DT_DURATION_PARSE(value)`,
                    description:
                        'Converts the given ISO-8601 duration string representation to a duration value. E.g. “P2DT3H4M” parses 2 days, 3 hours and 4 minutes.',
                },
            },
            //  TODO : will have this once we have a scope for time delta functions
            // {
            //     functionName: 'TIMEDELTA',
            //     functionProps: {
            //         labelToAdd: 'TIMEDELTA',
            //         syntax: `TIMEDELTA(start timestamp, end timestamp)`,
            //         description:
            //             'Returns the difference between end timestamp and start timestamp in milliseconds',
            //     },
            // },
        ],
    },
    {
        key: 'aggregate',
        title: 'Aggregate',
        lists: [
            {
                functionName: 'MAXIMUM',
                functionProps: {
                    labelToAdd: 'MAX',
                    syntax: `MAX(expression)`,
                    description: 'Returns the maximum value in a set of values',
                },
            },
            {
                functionName: 'MINIMUM',
                functionProps: {
                    labelToAdd: 'MINIMUM',
                    syntax: `MINIMUM(expression)`,
                    description: 'Returns the minimum value in a set of values',
                },
            },
            {
                functionName: 'SUMM',
                functionProps: {
                    labelToAdd: 'SUM',
                    syntax: `SUM(expression)`,
                    description: 'Calculates the sum of a set of values',
                },
            },
            {
                functionName: 'AVERAGE',
                functionProps: {
                    labelToAdd: 'AVERAGE',
                    syntax: `AVERAGE(expression)`,
                    description: 'Calculates the average of a set of values.',
                },
            },
            {
                functionName: 'STDDEV',
                functionProps: {
                    labelToAdd: 'STDDEV',
                    syntax: `STDDEV(expression)`,
                    description:
                        'Calculates the standard deviation of a set of values',
                },
            },
        ],
    },
    // TODO : will have this once we have a scope for string functions
    // {
    //     key: 'string',
    //     title: 'String',
    //     lists: [
    //         {
    //             functionName: 'STR_CONTAINS',
    //             functionProps: {
    //                 labelToAdd: 'STR_CONTAINS',
    //                 syntax: `STR_CONTAINS('string','substring')`,
    //                 description:'Returns true, if the string contains the substring (case-insensitive)'
    //             },
    //         },
    //         {
    //             functionName: 'STR_LOWER',
    //             functionProps: {
    //                 labelToAdd: 'STR_LOWER',
    //                 syntax: `STR_LOWER(text)`,
    //                 description:'Converts a string to lower-case'
    //             },
    //         },
    //         {
    //             functionName: 'STR_UPPER',
    //             functionProps: {
    //                 labelToAdd: 'STR_UPPER',
    //                 syntax: `STR_UPPER(text)`,
    //                 description:'Converts a string to upper-case'
    //             },
    //         },

    //     ]
    // },
    {
        key: 'advanced',
        title: 'Advanced',
        lists: [
            {
                functionName: 'COALESCE',
                functionProps: {
                    labelToAdd: 'COALESCE',
                    syntax: `COALESCE(val1, val2, ...., val_n)`,
                    description: 'Return the first non-null value in a list',
                },
            },
            {
                functionName: 'SteamEnthalpy',
                functionProps: {
                    labelToAdd: 'SteamEnthalpy',
                    syntax: `SteamEnthalpy(tag1, tag2)`,
                    description:
                        'Calculate the steam enthalpy based on given tags where tag1 represents Steam Pressure and tag2 represents Steam Temperature',
                },
            },
            {
                functionName: 'WaterEnthalpy',
                functionProps: {
                    labelToAdd: 'WaterEnthalpy',
                    syntax: `WaterEnthalpy (tag)`,
                    description:
                        'Calculate the water enthalpy based on given tags where tag represents Water Temperature',
                },
            },
        ],
    },
];
export const resendOtpTimer = 60;

export const DEFAULT_PAGE_SIZE = 0;
export const modelInfoUrl = '/transform/contextualization/modelOverview/';
export const maxInputNumberLength = 10;

export const samplingTimeToTickIntervalById: any = {
    0: null, // auto
    1: 1000 * 30, // 30 seconds
    2: 1000 * 60, // 1 Minute
    3: 1000 * 60 * 5, // 5 Minutes
    4: 1000 * 60 * 10, // 10 Minutes
    5: 1000 * 60 * 15, // 15 Minutes
    6: 1000 * 60 * 30, // 30 Minutes
    7: 1000 * 60 * 60, // 1 Hour
    8: 1000 * 60 * 60 * 2, // 2 Hours
    9: 1000 * 60 * 60 * 8, // 8 Hours
    10: 1000 * 60 * 60 * 24, // 1 Day
    11: 1000 * 60 * 60 * 24 * 7, // 1 Week
    12: 1000 * 60 * 60 * 24 * 30, // 1 Month (approximation)
};

export const samplingTimeToTickIntervalByDateRange: any = {
    Yesterday: 1000 * 60 * 60,
    Today: 1000 * 60 * 60,
    'Last Week': 1000 * 60 * 60 * 24,
    'This Week': 1000 * 60 * 60 * 24,
    MTD: 1000 * 60 * 60 * 24,
    YTD: 1000 * 60 * 60 * 24 * 30,
    'Shift A': 1000 * 60 * 60,
    'Shift B': 1000 * 60 * 60,
    'Shift C': 1000 * 60 * 60,
    Custom: null,
};
