import { baseUrlSetter } from 'utils/commonFunction';
import Api from '..';
import { message } from 'antd';

export const getAssignedTimeCapsuleListService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('configure');
    const params = {
        pageNumber: payload?.page,
        pageSize: payload?.pageSize,
        sortColumn: payload?.sortColumn,
        sortOrder: payload?.sortOrder,
        isAssign: payload?.isAssign,
        search: payload?.search,
    };
    try {
        const response = Api.get(`/configure/timeCapsule/${payload?.assetId}`, {
            params: params,
        });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const getUnAssignedTimeCapsuleListService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('configure');
    const params = {
        pageNumber: payload?.page,
        pageSize: payload?.pageSize,
        sortColumn: payload?.sortColumn,
        sortOrder: payload?.sortOrder,
        isAssign: payload?.isAssign,
        search: payload?.search,
    };
    try {
        const response = Api.get(`/configure/timeCapsule/${payload?.assetId}`, {
            params: params,
        });
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const assignTimeCapsuleToAssetService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('configure');
    const params = payload;
    try {
        const response = await Promise.resolve(
            Api.put(`/configure/timeCapsule/assign`, params)
        );
        return await Promise.resolve(response);
    } catch (error: any) {
        message?.error(
            error?.response?.data?.message
                ? error?.response?.data?.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const removeTimeCapsuleFromAssetService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('configure');
    const params = payload;
    try {
        const response = await Promise.resolve(
            Api.put(`/configure/timeCapsule/assign`, params)
        );
        return await Promise.resolve(response);
    } catch (error: any) {
        message?.error(
            error?.response?.data?.message
                ? error?.response?.data?.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const getTagsListService = async (payload: any): Promise<any> => {
    baseUrlSetter('asset');
    const params = {
        pageNumber: 0,
        includeChildNode: false,
    };
    try {
        const response = await Promise.resolve(
            Api.get(`/stream/paginated/list/${payload?.assetId}`, {
                params: params,
            })
        );
        return await Promise.resolve(response);
    } catch (error: any) {
        message?.error(
            error?.response?.data?.message
                ? error?.response?.data?.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const getAttributesListService = async (payload: any): Promise<any> => {
    baseUrlSetter('configure');
    const params = {
        pageNumber: 0,
        assignStatus: 1,
        status: 1,
        isValidated: true,
        assetId: payload?.assetId,
        search: payload?.search
    };
    try {
        const response = await Promise.resolve(
            Api.get('/impl/attribute', {
                params: params,
            })
        );
        return await Promise.resolve(response);
    } catch (error: any) {
        message?.error(
            error?.response?.data?.message
                ? error?.response?.data?.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const addEditTimeCapsuleValidationService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('configure');
    const params = payload;
    try {
        const response = await Promise.resolve(
            Api.put(`/configure/timeCapsule/validate`, params)
        );
        return await Promise.resolve(response);
    } catch (error: any) {
        message?.error(
            error?.response?.data?.message
                ? error?.response?.data?.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const getValidatedTimeCapsuleDetailsService = async (
    payload: any
): Promise<any> => {
    baseUrlSetter('configure');
    const params = {
        assetId: payload?.assetId,
    };
    try {
        const response = Api.get(
            `/configure/timeCapsule/details/${payload?.id}`,
            {
                params: params,
            }
        );
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};

export const getConditionsService = async (): Promise<any> => {
    baseUrlSetter('configure');
    try {
        const response = Api.get('/condition/list');
        return await Promise.resolve(response);
    } catch (error: any) {
        message.error(
            error.response.data.message
                ? error.response.data.message
                : 'Internal Server Error'
        );
        throw new Error(error);
    }
};
