import React, { useEffect, useState } from 'react';
import { Select, Input, Checkbox } from 'antd';
import './index.scss';
import TabsComponent from '../../../../components/common/TabsComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from 'redux/actions/UserManagementActions/usersAction';
import { getAllRoles } from 'redux/actions/UserManagementActions/rolesAction';
import { SearchOutlined } from '@ant-design/icons';
import { ALERTTYPE, StatusType, userSelectionId } from 'types/enums';
import { useTranslation } from 'react-i18next';

const CustomSelectWithTab: React.FC<any> = ({
    placeholder,
    conditions,
    setConditions,
    index,
    usersList,
    rolesList,
    deleted,
}) => {
    const [tabKey, setTabKey] = useState('users');
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    const [searchState, setSearchState] = useState('');
    const [checkedListUsers, setCheckedListUsers] = useState<any>([]);
    const [checkedListRoles, setCheckedListRoles] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [searchTimeout, setSearchTimeout] = useState<any>(undefined);
    const alertState = useSelector((state: any) => state?.alert?.alertState);

    useEffect(() => {
        setSearchState('');
        dispatch(getAllUsers({ status: StatusType.Active }));
        dispatch(getAllRoles({ status: StatusType.Active }));
    }, [tabKey]);

    useEffect(() => {
        const initialCheckedListUsers =
            conditions[index]?.recipientList
                ?.filter((item: any) => item?.recipientTypeId === 1)
                ?.map((item: any) => {
                    const user = usersList?.find(
                        (user: any) => user?.userId === item?.recipientId
                    );
                    return {
                        // Note: recipientTypeId : 1 defines we are selecting user from list
                        recipientTypeId: 1,
                        recipientId: item?.recipientId,
                        label: user
                            ? `${user?.firstName} ${user?.lastName}`
                            : t('alerts.loading'),
                    };
                }) ?? [];

        const initialCheckedListRoles =
            conditions[index]?.recipientList
                ?.filter((item: any) => item?.recipientTypeId === 2)
                ?.map((item: any) => {
                    const role = rolesList?.find(
                        (role: any) => role?.roleId === item?.recipientId
                    );
                    return {
                        // Note: recipientTypeId : 2 defines we are selecting role from list
                        recipientTypeId: 2,
                        recipientId: item?.recipientId,
                        label: role ? role?.roleName : t('alerts.loading'),
                    };
                }) ?? [];

        const initialSelectedItems = [
            ...initialCheckedListUsers,
            ...initialCheckedListRoles,
        ];

        setCheckedListUsers(initialCheckedListUsers);
        setCheckedListRoles(initialCheckedListRoles);
        setSelectedItems(initialSelectedItems);
    }, [usersList, rolesList, deleted]);

    useEffect(() => {
        if (alertState !== ALERTTYPE?.view) {
            setSelectedItems([...checkedListUsers, ...checkedListRoles]);
            if (!!checkedListRoles.length || !!checkedListUsers.length) {
                const conditionArray = [...conditions];
                conditionArray[index] = {
                    ...conditionArray[index],
                    recipientList: [
                        ...checkedListUsers,
                        ...checkedListRoles,
                    ].map((item: any) => {
                        const { label, ...prev } = item;
                        return prev;
                    }),
                };
                setConditions(conditionArray);
            }
        }
    }, [checkedListRoles, checkedListUsers]);

    const tableDataMapperUsers = (): [] => {
        const temp: any = [];
        usersList?.map((item: any, index: number) => {
            temp.push({
                value: item.userId,
                label: `${item.firstName} ${item.lastName}`,
            });
        });
        return temp;
    };

    const tableDataMapperRoles = (): [] => {
        const temp: any = [];
        rolesList?.map((item: any, index: number) => {
            temp.push({
                value: item.roleId,
                label: `${item.roleName}`,
            });
        });
        return temp;
    };

    const checkAllUsers =
        tableDataMapperUsers().length === checkedListUsers.length;
    const indeterminate =
        checkedListUsers.length > 0 &&
        checkedListUsers.length < tableDataMapperUsers().length;

    const onChangeUsers = (label: any, e: any): any => {
        const { value, checked } = e.target;

        if (checked) {
            setCheckedListUsers((prevItems: any) => [
                ...prevItems,
                { recipientTypeId: 1, recipientId: value, label: label },
            ]);
        } else {
            setCheckedListUsers((prevItems: any) =>
                prevItems.filter((item: any) => item.recipientId !== value)
            );
        }
    };

    const onCheckAllChangeUsers: any['onChange'] = (e: any): any => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setCheckedListUsers(
                tableDataMapperUsers().map((item: any) => ({
                    recipientTypeId: userSelectionId.Users,
                    recipientId: item.value,
                    label: item.label,
                }))
            );
        } else {
            setCheckedListUsers([]);
        }
    };

    const checkAllRoles =
        tableDataMapperRoles().length === checkedListRoles.length;
    const indeterminateRoles =
        checkedListRoles.length > 0 &&
        checkedListRoles.length < tableDataMapperRoles().length;

    const onChangeRoles = (label: any, e: any): any => {
        const { value, checked } = e.target;
        if (checked) {
            setCheckedListRoles((prevItems: any) => [
                ...prevItems,
                { recipientTypeId: 2, recipientId: value, label: label },
            ]);
        } else {
            setCheckedListRoles((prevItems: any) =>
                prevItems.filter((item: any) => item.recipientId !== value)
            );
        }
    };

    const onCheckAllChangeRoles: any['onChange'] = (e: any): any => {
        const isChecked = e.target.checked;
        if (isChecked) {
            setCheckedListRoles(
                tableDataMapperRoles().map((item: any) => ({
                    recipientTypeId: userSelectionId.Roles,
                    recipientId: item.value,
                    label: item.label,
                }))
            );
        } else {
            setCheckedListRoles([]);
        }
    };

    const handleSearchChange = (e: any): any => {
        setSearchState(e.target.value);
        if (searchTimeout) {
            clearTimeout(searchTimeout);
        }
        // Debouncing for search implemented
        setSearchTimeout(
            setTimeout(() => {
                if (tabKey === 'users') {
                    dispatch(
                        getAllUsers({
                            search: e.target.value,
                            status: StatusType.Active,
                        })
                    );
                } else {
                    dispatch(
                        getAllRoles({
                            search: e.target.value,
                            status: StatusType.Active,
                        })
                    );
                }
            }, 1000)
        );
    };

    const tabItems = [
        {
            id: userSelectionId.Users,
            key: 'users',
            label: t('alerts.users'),
            children: (
                <div className="customTabDropdownItemsWrapper">
                    {!!tableDataMapperUsers()?.length && (
                        <Checkbox
                            indeterminate={indeterminate}
                            onChange={onCheckAllChangeUsers}
                            checked={checkAllUsers}
                            className="customTabDropdownItems"
                        >
                            Select All
                        </Checkbox>
                    )}
                    <div>
                        {tableDataMapperUsers()?.map((item: any) => (
                            <div key={item?.value}>
                                <Checkbox
                                    value={item?.value}
                                    checked={checkedListUsers.some(
                                        (checkedItem: any) =>
                                            checkedItem.recipientId ===
                                            item?.value
                                    )}
                                    onChange={onChangeUsers.bind(
                                        this,
                                        item.label
                                    )}
                                >
                                    {item.label}
                                </Checkbox>
                            </div>
                        ))}
                    </div>
                </div>
            ),
        },
        {
            id: userSelectionId.Roles,
            key: 'roles',
            label: t('alerts.roles'),
            children: (
                <div className="customTabDropdownItemsWrapper">
                    {!!tableDataMapperRoles()?.length && (
                        <Checkbox
                            indeterminate={indeterminateRoles}
                            onChange={onCheckAllChangeRoles}
                            checked={checkAllRoles}
                            className="customTabDropdownItems"
                        >
                            Select All
                        </Checkbox>
                    )}
                    <div>
                        {tableDataMapperRoles()?.map((item: any) => (
                            <div key={item?.value}>
                                <Checkbox
                                    value={item?.value}
                                    checked={checkedListRoles.some(
                                        (checkedItem: any) =>
                                            checkedItem.recipientId ===
                                            item?.value
                                    )}
                                    onChange={onChangeRoles.bind(
                                        this,
                                        item.label
                                    )}
                                >
                                    {item.label}
                                </Checkbox>
                            </div>
                        ))}
                    </div>
                </div>
            ),
        },
    ];

    return (
        <div className="customDropdownWithTab">
            <Select
                placeholder={placeholder}
                value={
                    selectedItems?.map((item: any) => item.label).join(', ') ||
                    null
                }
                onChange={(value) => {
                    setSelectedItems(value);
                }}
                disabled={alertState === ALERTTYPE?.view}
                style={{ width: '100%' }}
                dropdownRender={(menu) => (
                    <div className="customDropdownWithTabWrapper">
                        <Input
                            allowClear
                            className="customDropdownWithTab__search"
                            placeholder="Search"
                            prefix={<SearchOutlined />}
                            value={searchState}
                            onChange={handleSearchChange}
                        />
                        <div className="customDropdownWithTab__tabsWrapper">
                            <TabsComponent
                                tabItem={tabItems}
                                setTabKey={tabKey}
                                setActiveTabKey={setTabKey}
                                activeTabKey={tabKey}
                            />
                        </div>
                    </div>
                )}
                getPopupContainer={(triggerNode) => triggerNode.parentElement}
            ></Select>
        </div>
    );
};

export default CustomSelectWithTab;
