import React, { useEffect, useRef, useState } from 'react';
import { Anchor, Button, Card, Col, Input, Radio, Row, Tag } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import './index.scss';
import { ReactComponent as BackIcon } from 'assets/icons/backIcon.svg';
import CustomButton from 'components/common/CustomButton';
import { ALERTTYPE, BUTTONTYPE, alertConsequences } from 'types/enums';
import { ReactComponent as ConfirmationIcon } from 'assets/icons/confirmationIcon.svg';
import { ReactComponent as CancelConfirmationIcon } from 'assets/icons/cancelConfirmIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
    getCalculationCycleList,
    getDurationList,
    getAlertOperators,
    getConsequenceList,
    setAlertState,
    getKpiListByAsset,
    createAlert,
    updateAlert,
} from 'redux/actions/AlertActions/alertActions';
import AlertStepTwo from './alertStepTwo';
import AlertStepThree from './alertStepThree';
import { parseJwt } from 'utils/jwtTokenFunction';
import ConfirmationModal from 'components/common/Modals/ConfirmationModal';
import { cancelHandle } from 'utils/modalFunction';
import SuccessfulModal from 'components/common/Modals/SuccessfulModal';
import { SET_CREATED_STATE } from 'redux/types/alertTypes';
import { useTranslation } from 'react-i18next';
import { getTagListData } from 'redux/actions/ImplementationActions/attributeActions';

const CreateAlert: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('translation');
    const details = parseJwt();
    const [alertConditions, setAlertConditions] = useState<any>([
        {
            valueList: [],
            isAbsolute: false,
        },
    ]);
    const [alertAction, setAlertAction] = useState<any>([
        { isEnableNotification: false },
    ]);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [submittable, setSubmittable] = useState([false]);
    const [submittableActions, setSubmittableActions] = useState([false]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [successModalOpen, setSuccessModalOpen] = useState(false);
    const [deletedCondition, setDeletedCondition] = useState<Boolean>(false);
    const [deletedAction, setDeletedAction] = useState<Boolean>(false);
    const selectedAsset = useSelector(
        (state: any) => state.implementation?.attribute?.selectedAsset
    );

    const { TextArea } = Input;
    const containerRef = useRef<any | null>(null);
    const [consequences, setConsequences] = useState([]);
    const consequenceList = useSelector(
        (state: any) => state?.alert?.consequenceList
    );
    const alertCreated = useSelector(
        (state: any) => state?.alert?.alertCreated
    );
    const alertUpdated = useSelector(
        (state: any) => state?.alert?.alertUpdated
    );
    const alertState = useSelector((state: any) => state?.alert?.alertState);
    const alertDetails = useSelector(
        (state: any) => state?.alert?.alertDetails
    );

    useEffect(() => {
        dispatch(getKpiListByAsset({ assetId: selectedAsset?.key }));
        dispatch(getTagListData({ id: selectedAsset?.key, page: 0, includeChildNode: false}));
        dispatch(getAlertOperators());
        dispatch(getConsequenceList());
        dispatch(getDurationList());
        dispatch(getCalculationCycleList());
    }, [selectedAsset]);

    useEffect(() => {
        (alertCreated || alertUpdated) && setSuccessModalOpen(true);
    }, [alertCreated, alertUpdated]);

    useEffect(() => {
        if (alertState === ALERTTYPE.view || alertState === ALERTTYPE.edit) {
            setName(alertDetails?.name);
            setDescription(alertDetails?.description);
            setAlertAction(alertDetails?.alertsAction);
            setAlertConditions(alertDetails?.alertConditionRequestList);
            setConsequences(alertDetails?.consequence);
        }
    }, [alertDetails]);

    const onOkHandler = (): any => {
        if (alertState === ALERTTYPE.create) {
            dispatch(
                createAlert({
                    name: name,
                    desc: description,
                    assetId: selectedAsset?.key,
                    alertCondition: alertConditions,
                    alertAction: alertAction,
                    consequences: consequences,
                    requestedBy: details.username,
                })
            );
        } else if (alertState === ALERTTYPE.edit) {
            dispatch(
                updateAlert({
                    alertId: alertDetails?.id,
                    details: {
                        name: name,
                        desc: description,
                        assetId: selectedAsset?.key,
                        alertCondition: alertConditions,
                        alertAction: alertAction,
                        consequences: consequences,
                        requestedBy: details.username,
                    },
                })
            );
        }
        setIsModalOpen(false);
    };

    const onRadioChange: any = (e: any, id: any) => {
        let consequenceArray: any = [...consequences];
        if (
            consequenceArray
                ?.map((item: any) => item?.consequenceId)
                .includes(id)
        ) {
            consequenceArray = consequenceArray?.map((item: any) => {
                if (item?.consequenceId === id) {
                    return {
                        consequenceId: id,
                        analyseId: e.target.value,
                    };
                }
                return item;
            });
        } else {
            consequenceArray.push({
                consequenceId: id,
                analyseId: e.target.value,
            });
        }
        setConsequences(consequenceArray);
    };

    const getDefaultValue = (consequenceId: any): any => {
        const consequence: any = (consequences as any[])?.find(
            (item: any) =>
                parseInt(item?.consequenceId) === parseInt(consequenceId)
        );
        return consequence ? consequence.analyseId : null;
    };

    return (
        <>
            <div className="createAlertWrapper">
                <Card
                    className="createAlertWrapper__cardWrapper"
                    bordered={false}
                    bodyStyle={{ padding: 0 }}
                >
                    <Row className="createAlertWrapper__headerWrapper">
                        <div className="createAlertWrapper__heading">
                            <div className="createAlertWrapper__backIcon">
                                <BackIcon
                                    onClick={() => {
                                        alertState === ALERTTYPE.view
                                            ? dispatch(
                                                  setAlertState(
                                                      ALERTTYPE?.display
                                                  )
                                              )
                                            : setCancelModal(true);
                                    }}
                                />
                            </div>
                            <div className="createAlertWrapper__headingLabel">
                                <div className="createAlertWrapper__headingName fw-500 fs-20">
                                    {alertState === ALERTTYPE?.view
                                        ? t('alerts.viewAlert')
                                        : alertState === ALERTTYPE?.edit
                                        ? t('alerts.editAlert')
                                        : t('alerts.createAlert')}
                                </div>
                                <div className="createAlertWrapper__alertName fw-500 fs-20">
                                    <Tag color="blue">
                                        {selectedAsset?.title}
                                    </Tag>
                                </div>
                            </div>
                            {alertState === ALERTTYPE?.view ? (
                                <div className="createAlertWrapper__editButton">
                                    <Button
                                        type="primary"
                                        ghost
                                        className="addNewButton"
                                        onClick={() => {
                                            dispatch(
                                                setAlertState(ALERTTYPE.edit)
                                            );
                                        }}
                                        disabled={!alertDetails?.isActive}
                                    >
                                        {t('alerts.edit')}
                                    </Button>
                                </div>
                            ) : (
                                <div>
                                    <Button
                                        type="primary"
                                        className="addNewButton"
                                        disabled={true}
                                    >
                                        {t('alerts.advOptions')}
                                    </Button>
                                </div>
                            )}
                        </div>
                    </Row>
                    <Anchor
                        className="createAlertWrapper__tabsWrapper"
                        direction="horizontal"
                        affix={false}
                        getContainer={() => containerRef.current}
                        targetOffset={10}
                        items={[
                            {
                                key: 'section1',
                                href: '#section1',
                                title: (
                                    <Button
                                        href="#section1"
                                        className="tabButton"
                                        type="primary"
                                        shape="round"
                                        ghost
                                    >
                                        {t('alerts.basicDetailsBtnText')}
                                    </Button>
                                ),
                            },
                            {
                                key: 'section2',
                                href: '#section2',
                                title: (
                                    <Button
                                        href="#section2"
                                        className="tabButton"
                                        type="primary"
                                        shape="round"
                                        ghost
                                    >
                                        {t('alerts.conditionsBtnText')}
                                    </Button>
                                ),
                            },
                            {
                                key: 'section3',
                                href: '#section3',
                                title: (
                                    <Button
                                        href="#section3"
                                        className="tabButton"
                                        type="primary"
                                        shape="round"
                                        ghost
                                    >
                                        {t('alerts.actionsBtnText')}
                                    </Button>
                                ),
                            },
                            {
                                key: 'section4',
                                href: '#section4',
                                title: (
                                    <Button
                                        href="#section4"
                                        className="tabButton"
                                        type="primary"
                                        shape="round"
                                        ghost
                                    >
                                        {t('alerts.consequencesBtnText')}
                                    </Button>
                                ),
                            },
                        ]}
                    />
                    <div
                        ref={containerRef}
                        className="createAlertWrapper__stepWrapper"
                    >
                        <Card
                            id="section1"
                            className="createAlertWrapper__outerCard"
                        >
                            <Row className="createAlertWrapper__stepTitle">
                                <div>
                                    <Button
                                        shape="circle"
                                        type="primary"
                                        className="stepperButton"
                                    >
                                        1
                                    </Button>
                                </div>
                                <div className="fw-500 fs-20">
                                    {t('alerts.headerStepOne')}
                                </div>
                            </Row>
                            <Row className="createAlertWrapper__stepOneInfo">
                                <Col span={10}>
                                    <div className="createAlertWrapper__label fw-400 fs-14">
                                        <span>*</span>
                                        {t('alerts.alertName')}
                                    </div>
                                    <Input
                                        className="createAlertWrapper__nameInput"
                                        placeholder={t('alerts.enterAlertName')}
                                        onChange={(e) => {
                                            setName(e.target.value);
                                        }}
                                        disabled={
                                            alertState === ALERTTYPE?.view
                                        }
                                        value={name}
                                        maxLength={100}
                                    ></Input>
                                </Col>

                                <Col span={14}>
                                    <div className="createAlertWrapper__label fw-400 fs-14">
                                        {t('alerts.description')}
                                    </div>
                                    <TextArea
                                        className="createAlertWrapper__descriptionInput"
                                        placeholder={t('alerts.enterDesc')}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                        }}
                                        disabled={
                                            alertState === ALERTTYPE?.view
                                        }
                                        value={description}
                                        maxLength={200}
                                    ></TextArea>
                                </Col>
                            </Row>
                        </Card>
                        {alertConditions?.length > 1 &&
                            alertState !== ALERTTYPE?.view && (
                                <div className="createAlertWrapper__clearTextDiv">
                                    <div
                                        className="clearText"
                                        onClick={() => {
                                            setAlertConditions([
                                                {
                                                    valueList: [],
                                                    isAbsolute: false,
                                                },
                                            ]);
                                            setDeletedCondition(
                                                !deletedCondition
                                            );
                                            setSubmittable([false]);
                                        }}
                                    >
                                        {t('alerts.clearAll')}
                                    </div>
                                </div>
                            )}
                        <Card
                            id="section2"
                            className="createAlertWrapper__outerCard"
                        >
                            <Row className="createAlertWrapper__stepTitle">
                                <div>
                                    <Button
                                        shape="circle"
                                        type="primary"
                                        className="stepperButton"
                                    >
                                        2
                                    </Button>
                                </div>
                                <div className="fw-500 fs-20">
                                    {t('alerts.headerStepTwo')}
                                </div>
                            </Row>

                            {alertConditions?.map(
                                (item: any, index: Number) => (
                                    <>
                                        <AlertStepTwo
                                            conditionList={
                                                alertDetails?.alertConditionRequestList
                                            }
                                            submittable={submittable}
                                            setSubmittable={setSubmittable}
                                            index={index}
                                            conditions={alertConditions}
                                            setConditions={setAlertConditions}
                                            deleted={deletedCondition}
                                            setDeleted={setDeletedCondition}
                                        ></AlertStepTwo>
                                    </>
                                )
                            )}

                            <div className="createAlertWrapper__verticalLineDiv">
                                <PlusCircleOutlined
                                    disabled={alertState === ALERTTYPE?.view}
                                    className="plusIconButton"
                                    onClick={() => {
                                        if (alertState !== ALERTTYPE.view) {
                                            const submittableArr = [
                                                ...submittable,
                                            ];
                                            submittableArr[
                                                alertConditions?.length - 1
                                            ] = false;
                                            !alertConditions[
                                                alertConditions?.length - 1
                                            ]?.operandId &&
                                                setSubmittable(submittableArr);
                                            const tempArray = [
                                                ...alertConditions,
                                            ];
                                            tempArray.push({
                                                valueList: [],
                                                isAbsolute: false,
                                            });
                                            setAlertConditions(tempArray);
                                        }
                                    }}
                                />
                            </div>
                        </Card>
                        <Card
                            id="section3"
                            className="createAlertWrapper__outerCard"
                        >
                            <Row className="createAlertWrapper__stepTitle">
                                <div>
                                    <Button
                                        shape="circle"
                                        type="primary"
                                        className="stepperButton"
                                    >
                                        3
                                    </Button>
                                </div>
                                <div className="fw-500 fs-20">
                                    {t('alerts.headerStepThree')}
                                </div>
                            </Row>

                            {alertAction?.map((item: any, index: Number) => (
                                <>
                                    <AlertStepThree
                                        submittable={submittableActions}
                                        setSubmittable={setSubmittableActions}
                                        index={index}
                                        conditions={alertAction}
                                        setConditions={setAlertAction}
                                        deleted={deletedAction}
                                        setDeleted={setDeletedAction}
                                    ></AlertStepThree>
                                </>
                            ))}
                            <div className="createAlertWrapper__verticalLineDiv">
                                <PlusCircleOutlined
                                    disabled={alertState === ALERTTYPE?.view}
                                    className="plusIconButton"
                                    onClick={() => {
                                        if (alertState !== ALERTTYPE.view) {
                                            const tempArray = [...alertAction];
                                            tempArray.push({
                                                isEnableNotification: false,
                                            });
                                            setAlertAction(tempArray);
                                        }
                                    }}
                                />
                            </div>
                        </Card>
                        <Card
                            id="section4"
                            className="createAlertWrapper__outerCard"
                        >
                            <Row className="createAlertWrapper__stepTitle">
                                <div>
                                    <Button
                                        shape="circle"
                                        type="primary"
                                        className="stepperButton"
                                    >
                                        4
                                    </Button>
                                </div>
                                <div className="fw-500 fs-20">
                                    {t('alerts.headerStepFour')}
                                </div>
                            </Row>
                            <Row>
                                <Col span={11}></Col>
                                <Col span={4} className="fs-16 fw-500">
                                    {t('alerts.yes')}
                                </Col>
                                <Col
                                    span={4}
                                    className="fs-16 fw-500 createAlertWrapper__alertHeading"
                                >
                                    {t('alerts.no')}
                                </Col>
                                <Col span={5} className="fs-16 fw-500">
                                    {t('alerts.notAnalyzed')}
                                </Col>
                            </Row>
                            {consequenceList?.map((item: any) => (
                                <Row
                                    key={item?.id}
                                    className="createAlertWrapper__stepConsequences"
                                >
                                    <Col
                                        className="fs-18 fw-400 createAlertWrapper__alertRadioLabel"
                                        span={7}
                                    >
                                        {item?.name}
                                    </Col>
                                    <Col span={17}>
                                        <Radio.Group
                                            disabled={
                                                alertState === ALERTTYPE?.view
                                            }
                                            className="createAlertWrapper__alertRadioGroup"
                                            name="radiogroup"
                                            onChange={(e) => {
                                                onRadioChange(e, item?.id);
                                            }}
                                            value={getDefaultValue(item?.id)}
                                        >
                                            <Radio
                                                value={alertConsequences.Yes}
                                            ></Radio>
                                            <Radio
                                                value={alertConsequences.No}
                                            ></Radio>
                                            <Radio
                                                value={
                                                    alertConsequences.NotAnalysed
                                                }
                                            ></Radio>
                                        </Radio.Group>
                                    </Col>
                                </Row>
                            ))}
                        </Card>
                    </div>

                    <div className="createAlertWrapper__createRoleFooter">
                        <div className="createAlertWrapper__footerButtonWrapper">
                            <CustomButton
                                type={BUTTONTYPE.cancel}
                                disabled={false}
                                handleClick={() => {
                                    alertState === ALERTTYPE.view
                                        ? dispatch(
                                              setAlertState(ALERTTYPE?.display)
                                          )
                                        : setCancelModal(true);
                                }}
                            />
                        </div>
                        <div className="createAlertWrapper__footerButtonWrapper">
                            {
                                <CustomButton
                                    type={BUTTONTYPE.save}
                                    typeOfButton={'submit'}
                                    disabled={
                                        submittable.includes(false) ||
                                        submittableActions.includes(false) ||
                                        !name.length ||
                                        alertState === ALERTTYPE.view
                                    }
                                    handleClick={() => {
                                        setIsModalOpen(true);
                                    }}
                                />
                            }
                        </div>
                    </div>
                </Card>
            </div>
            {isModalOpen && (
                <ConfirmationModal
                    open={isModalOpen}
                    icon={<ConfirmationIcon />}
                    onOk={() => onOkHandler()}
                    onCancel={() => cancelHandle(isModalOpen, setIsModalOpen)}
                    text={
                        alertState === ALERTTYPE.create
                            ? t('alerts.saveAlert')
                            : t('alerts.updateAlert')
                    }
                />
            )}
            {successModalOpen && (
                <SuccessfulModal
                    open={successModalOpen}
                    onCancel={() => {
                        dispatch(setAlertState(ALERTTYPE.display));
                        dispatch({ type: SET_CREATED_STATE });
                        cancelHandle(successModalOpen, setSuccessModalOpen);
                    }}
                    text={
                        alertState === ALERTTYPE.create
                            ? t('alerts.alertSaved')
                            : t('alerts.alertUpdated')
                    }
                />
            )}
            {cancelModal && (
                <ConfirmationModal
                    open={cancelModal}
                    icon={<CancelConfirmationIcon />}
                    onOk={() => {
                        dispatch(setAlertState(ALERTTYPE?.display));
                    }}
                    onCancel={() => cancelHandle(cancelModal, setCancelModal)}
                    text={t('alerts.changesLost')}
                    footer={[
                        <Button
                            key="back"
                            onClick={() =>
                                cancelHandle(cancelModal, setCancelModal)
                            }
                        >
                            Cancel
                        </Button>,
                        <Button
                            danger
                            key="submit"
                            onClick={() => {
                                dispatch(setAlertState(ALERTTYPE?.display));
                            }}
                        >
                            {t('alerts.yes')}
                        </Button>,
                    ]}
                />
            )}
        </>
    );
};

export default CreateAlert;
