import TabsComponent from 'components/common/TabsComponent';
import { useState, useEffect } from 'react';
import './index.scss';
import TagMappingSection from './TagMappingSection';
import { TagMappingSubTabs } from 'types/enums';
const TagMapping: React.FC<any> = ({ activeTabKey }) => {
    const [activeSubTabKey, setActiveSubTabKey] = useState('');

    useEffect(() => {
        setActiveSubTabKey(TagMappingSubTabs?.otTags);
    }, [activeTabKey]);
    const tabItems = [
        {
            key: TagMappingSubTabs?.otTags,
            label: `OT Tags`,
            children: <TagMappingSection activeSubTabKey={activeSubTabKey} />,
        },
        {
            key: TagMappingSubTabs?.itTags,
            label: `IT Tags`,
            children: <TagMappingSection activeSubTabKey={activeSubTabKey} />,
        },
        {
            key: TagMappingSubTabs?.forms,
            label: `Forms`,
            children: <TagMappingSection activeSubTabKey={activeSubTabKey} />,
        },
    ];
    return (
        <>
            <div className="tagMapping">
                <div className="tagMapping__tabswrapper fw-500 fs-16">
                    <TabsComponent
                        tabItem={tabItems}
                        setTabKey={activeSubTabKey}
                        setActiveTabKey={setActiveSubTabKey}
                        activeTabKey={activeSubTabKey}
                    />
                </div>
            </div>
        </>
    );
};
export default TagMapping;
