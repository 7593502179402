import { Col, Popover, Row, Spin, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { type ColumnsType } from 'antd/es/table';
import { attribute, EMPTY, sortOrder, timeCapsule } from 'types/enums';
import { DATE_FORMAT, PAGE } from 'utils/constants';
import { ReactComponent as UpArrow } from 'assets/icons/upArrowIcon.svg';
import { ReactComponent as DownArrow } from 'assets/icons/downArrowIcon.svg';
import { ReactComponent as ActiveDotIcon } from 'assets/icons/activeDot.svg';
import { ReactComponent as WarningDotIcon } from 'assets/icons/warningDot.svg';
import { ReactComponent as InActiveDotIcon } from 'assets/icons/connectionInActiveDot.svg';
import { ReactComponent as ErrorDotIcon } from 'assets/icons/inactiveDot.svg';
import CustomPagination from 'components/common/CustomPagination';
import EmptyDataComponent from 'components/common/EmptyDataComponent';
import {
    ConnectionsStatus,
    CreateConnectionScreen,
    MonitoringState,
    MonitoringTableColumnSort,
} from 'types/enums/businessIntegratorEnum';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getMonitoringById, setMonitoringState } from 'redux/actions/BusinessIntegratorActions/monitoringActions';

const MonitoringTable: React.FC<any> = ({
    setPage,
    page,
    setPageSize,
    pageSize,
    setSortOrder,
    setSortColumn,
}): any => {
    const { t } = useTranslation('translation');
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState<any>([]);
    const monitoringTableList = useSelector(
        (state: any) => state.businessIntegrator.monitoring.monitoringListData
    );
    const monitoringTableLoader = useSelector(
        (state: any) =>
            state.businessIntegrator.monitoring.monitoringTableLoader
    );
    const tableDataMapper = (): [] => {
        const temp: any = [];
        monitoringTableList?.paginatedResponse?.records?.map(
            (item: any, index: number) => {
                temp.push({
                    ...item,
                    key: item.id,
                });
            }
        );
        return temp;
    };
    useEffect(() => {
        setTableData(tableDataMapper());
    }, [monitoringTableList]);

    const SearchNoDataText = (
        <EmptyDataComponent
            textValue={t('BusinessIntegrator.mapping.searchNoDataMonitoring')}
            loading={monitoringTableLoader}
        />
    );

    const getConnectionStatus = (status: any): any => {
        switch (status) {
            case ConnectionsStatus.warning:
                return <WarningDotIcon />;
            case ConnectionsStatus.healthy:
                return <ActiveDotIcon />;
            case ConnectionsStatus.error:
                return <ErrorDotIcon />;
            default:
                return <InActiveDotIcon />;
        }
    };

    const onSorting = (columnName: any, sortOrder: any): any => {
        setPage(PAGE);
        setSortColumn(columnName);
        setSortOrder(sortOrder);
    };

    const columns: ColumnsType<any> = [
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {"Id"}
                    </div>
                </div>
            ),
            dataIndex: 'name',
            width: 180,
            key: 'name',
            className: 'column__name',
            render: (_: any, data: any) => {
                const nameString = data.monitoringType === "Message" ? data?.messageMonitoringName : data?.connectionResponse?.name
                const truncatedText =
                    nameString?.length >
                    attribute.attributeNameLength
                        ? nameString?.substring(
                              CreateConnectionScreen.adapterScreen,
                              attribute.attributeNameLength
                          ) + '...'
                        : nameString;
                return (
                    <>
                        <div
                            className="attributeTableWrapper__nameData"
                            onClick={() => {
                                dispatch(
                                    setMonitoringState(
                                        MonitoringState.viewMonitoring
                                    )
                                );
                                dispatch(
                                    getMonitoringById(data?.id)
                                );
                            }}
                        >
                            {nameString?.length >
                            attribute.attributeNameLength ? (
                                <div className="blaTableWrapper__status">
                                    {getConnectionStatus(
                                        data?.connectionStatus
                                    )}
                                    <Popover
                                        content={nameString}
                                        trigger="hover"
                                        placement="topLeft"
                                    >
                                        <span className="fs-14 fw-500 name">
                                            {truncatedText}
                                        </span>
                                    </Popover>
                                </div>
                            ) : (
                                <div className="blaTableWrapper__status">
                                    {getConnectionStatus(
                                        data?.connectionStatus
                                    )}
                                    <span className="fs-14 fw-500 name">
                                        {nameString}
                                    </span>
                                </div>
                            )}
                        </div>
                    </>
                );
            },
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {"Monitoring Type"}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.monitoringType,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.monitoringType,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            width: 180,
            dataIndex: 'monitoringType',
            key: 'monitoringType',
            className: 'column__properties',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {data?.monitoringType}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.adapterType'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.adapterType,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.adapterType,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            width: 180,
            dataIndex: 'adapterType',
            key: 'adapterType',
            className: 'column__properties',
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {data?.connectionResponse?.adapter?.name}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.direction'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.direction,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            cursor="pointer"
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.direction,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'direction',
            key: 'direction',
            className: 'column__properties',
            width: 160,
            render: (_: any, data: any) => (
                <>
                    <div className="attributeTableWrapper__status">
                        {data?.connectionResponse?.direction?.name}
                    </div>
                </>
            ),
        },
        {
            key: 'pollingTime',
            className: 'column__addedOn',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>{t(
                            'BusinessIntegrator.mapping.tableColumn.timestamp'
                        )}</div>
                    <div className="sortArrows">
                        <UpArrow
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.timestamp,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.timestamp,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            width: 220,
            dataIndex: 'pollingTime',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {moment(record?.pollingTime).format(DATE_FORMAT)}
                    </div>
                </>
            ),
        },
        {
            title: (
                <div className="attributeTableWrapper__columnTitle">
                    <div>
                        {t('BusinessIntegrator.connections.tableColumn.label')}
                    </div>
                </div>
            ),
            dataIndex: 'label',
            key: 'label',
            width: 160,
            className: 'column__valueType',
            render: (_: any, data: any) => {
                const array: string[] = [];
                data.monitoringType === "Message" ? data?.operationMappingResponse?.labels?.map((item: any) => {
                    array?.push(item);
                }) : data?.connectionResponse?.labelResponse?.map((item: any) => {
                    array?.push(item?.name);
                })
                const maxLength = 18;
                const dataLength = 200;
                const joinedArray = array?.join(', ');
                let truncatedData = joinedArray;
                let remainingData: string[] = [];
                if (joinedArray?.length > maxLength) {
                    const ellipsisLength = 3;
                    const remainingLength = maxLength - ellipsisLength;
                    truncatedData =
                        joinedArray.substring(0, remainingLength) + '...';
                    const lastCommaIndex = truncatedData?.lastIndexOf(',');
                    if (
                        lastCommaIndex !== -1 &&
                        lastCommaIndex < remainingLength
                    ) {
                        const remainingString = truncatedData?.substring(
                            lastCommaIndex + 1,
                            remainingLength
                        );
                        remainingData = [
                            remainingString +
                                joinedArray
                                    ?.substring(remainingLength)
                                    ?.split(',')[0],
                            ...joinedArray
                                ?.substring(remainingLength)
                                ?.split(',')
                                ?.slice(1),
                        ];
                    } else {
                        remainingData = joinedArray
                            ?.substring(remainingLength)
                            ?.split(',');
                    }
                }
                return (
                    <div className="timeCapsuleTableWrapper__subItems">
                        <span>{truncatedData}</span>
                        {remainingData?.length > 0 && (
                            <Tooltip
                                overlayClassName={
                                    remainingData?.join(', ')?.length >
                                    dataLength
                                        ? 'monitoringDivToolTip'
                                        : 'monitoringToolTip'
                                }
                                title={joinedArray}
                                color={'white'}
                            >
                                <span className="timeCapsuleTable__subItems">
                                    +{remainingData?.length}
                                </span>
                            </Tooltip>
                        )}
                    </div>
                );
            },
        },
        {
            key: 'operation',
            className: 'column__addedOn',
            width: 180,
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>{'Operation'}</div>
                    <div className="sortArrows">
                        <UpArrow
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.operationId,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.operationId,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'operation',
            render: (_: any, data: any) => {
                const truncatedText =
                    data?.operationMappingResponse?.operationName?.length > timeCapsule.timeCapsuleNameLength
                        ? data?.operationMappingResponse?.operationName?.substring(
                              CreateConnectionScreen.adapterScreen,
                              timeCapsule.timeCapsuleNameLength
                          ) + '...'
                        : data?.operationMappingResponse?.operationName;
                return (
                    <>
                        {
                            data?.operationMappingResponse?.operationName ? (
                                <div>
                                    {data?.operationMappingResponse?.operationName?.length >
                                    timeCapsule.timeCapsuleNameLength ? (
                                        <div>
                                            <Popover
                                                content={data?.operationMappingResponse?.operationName}
                                                trigger="hover"
                                                placement="topLeft"
                                            >
                                                <span className="fs-14 fw-500 name">
                                                    {truncatedText}
                                                </span>
                                            </Popover>
                                        </div>
                                    ) : (
                                        <div>
                                            <span className="fs-14 fw-500 name">
                                                {data?.operationMappingResponse?.operationName}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                "-"
                            )
                        }
                        
                    </>
                );
            },
        },
        {
            key: 'mapping',
            className: 'column__addedOn',
            width: 180,
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>{'Mapping'}</div>
                    <div className="sortArrows">
                        <UpArrow
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.mappingId,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.mappingId,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'mapping',
            render: (_: any, data: any) => {
                const truncatedText =
                    data?.operationMappingResponse?.mappingName?.length > timeCapsule.timeCapsuleNameLength
                        ? data?.operationMappingResponse?.mappingName?.substring(
                              CreateConnectionScreen.adapterScreen,
                              timeCapsule.timeCapsuleNameLength
                          ) + '...'
                        : data?.operationMappingResponse?.mappingName;
                return (
                    <>
                        {
                            data?.operationMappingResponse?.mappingName ? (
                                <div>
                                    {data?.operationMappingResponse?.mappingName?.length >
                                    timeCapsule.timeCapsuleNameLength ? (
                                        <div>
                                            <Popover
                                                content={data?.operationMappingResponse?.mappingName}
                                                trigger="hover"
                                                placement="topLeft"
                                            >
                                                <span className="fs-14 fw-500 name">
                                                    {truncatedText}
                                                </span>
                                            </Popover>
                                        </div>
                                    ) : (
                                        <div>
                                            <span className="fs-14 fw-500 name">
                                                {data?.operationMappingResponse?.mappingName}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                "-"
                            )
                        }
                        
                    </>
                );
            },
        },
        {
            key: 'createdBy',
            className: 'column__addedOn',
            width: 180,
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.createdBy'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.createdBy,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.createdBy,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'createdBy',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {record?.createdBy}
                    </div>
                </>
            ),
        },
        {
            key: 'createdOn',
            className: 'column__addedOn',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.createdOn'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.createdOn,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.createdOn,
                                    sortOrder.descending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'createdOn',
            width: '180px',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {moment(record.createdOn).format(DATE_FORMAT)}
                    </div>
                </>
            ),
        },
        {
            key: 'changedBy',
            width: '180px',
            className: 'column__addedOn',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.changedBy'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.updatedBy,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.updatedBy,
                                    sortOrder.ascending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'changedBy',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {record?.updatedBy}
                    </div>
                </>
            ),
        },
        {
            key: 'changedOn',
            width: '180px',
            className: 'column__addedOn',
            title: (
                <div className="userTableWrapper__columnTitle">
                    <div>
                        {t(
                            'BusinessIntegrator.connections.tableColumn.changedOn'
                        )}
                    </div>
                    <div className="sortArrows">
                        <UpArrow
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.updatedOn,
                                    sortOrder.ascending
                                );
                            }}
                        />
                        <DownArrow
                            fill="white"
                            onClick={() => {
                                onSorting(
                                    MonitoringTableColumnSort.updatedOn,
                                    sortOrder.ascending
                                );
                            }}
                        />
                    </div>
                </div>
            ),
            dataIndex: 'changedOn',
            render: (_: any, record: any) => (
                <>
                    <div className="userTableWrapper__addedOn">
                        {moment(record?.updatedOn).format(DATE_FORMAT)}
                    </div>
                </>
            ),
        },
    ];

    return (
        <>
            {monitoringTableLoader ? (
                <div className="view__loader">
                    <Spin />
                </div>
            ) : (
                <>
                    <Row
                        className={
                            monitoringTableList?.paginatedResponse
                                ?.totalRecords > 50
                                ? 'attributeWrapper__attributeListPagination'
                                : 'attributeWrapper__attributeList'
                        }
                    >
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={tableData}
                                pagination={false}
                                loading={monitoringTableLoader}
                                scroll={{
                                    y:
                                        monitoringTableList?.paginatedResponse
                                            ?.totalRecords > 50
                                            ? 'calc(100vh - 400px)'
                                            : 'calc(100vh - 370px)',
                                }}
                                locale={{ emptyText: SearchNoDataText }}
                            />
                        </Col>
                    </Row>
                    {monitoringTableList?.paginatedResponse?.totalRecords >
                    50 ? (
                        <CustomPagination
                            totalRecords={
                                monitoringTableList?.paginatedResponse
                                    ?.totalRecords
                            }
                            setPage={setPage}
                            page={page}
                            setPageSize={setPageSize}
                            pageSize={pageSize}
                        />
                    ) : (
                        EMPTY.string
                    )}
                </>
            )}
        </>
    );
};

export default MonitoringTable;
