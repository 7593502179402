import { useEffect } from 'react';
import { embedDashboard } from '@superset-ui/embedded-sdk';
import { useDispatch, useSelector } from 'react-redux';
import './index.scss';
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';
import { dashboardEmbeddedUuid } from 'redux/actions/UserManagementActions/usersAction';

const ReportingDashboard: React.FC<any> = () => {
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const { id } = useParams();

    const dashboardId = id;

    const loggedInUserDashboardEmbeddedUuid = useSelector(
        (state: any) => state.userManagement.users?.dashboardEmbeddedUuid
    );

    useEffect(() => {
        document.cookie = `loggedInUserDashboardEmbeddedUuid=${loggedInUserDashboardEmbeddedUuid}; path=/`;
    }, [cookies, loggedInUserDashboardEmbeddedUuid]);

    const commonRawDashboardUrl =
        'https://dashboard-builder.{environment}test.solulever.com';
    const applicationUrl = window.location.hostname;
    const generatedSupersetDomainUrl = (): any => {
        const environment = applicationUrl.split('.')[1];
        return commonRawDashboardUrl.replace('{environment}', environment);
    };

    useEffect(() => {
        const embed = async (payload?: any): Promise<any> => {
            embedDashboard({
                id: cookies.get('loggedInUserDashboardEmbeddedUuid'),
                supersetDomain: generatedSupersetDomainUrl(),
                mountPoint: document.getElementById('dashboard') as any,
                fetchGuestToken: (): any => cookies.get('session'),
                dashboardUiConfig: {
                    hideTitle: false,
                    hideChartControls: false,
                    hideTab: true,
                },
            });
        };
        if (document.getElementById('dashboard')) {
            embed();
        }
    }, [loggedInUserDashboardEmbeddedUuid]);

    useEffect(() => {
        dispatch(dashboardEmbeddedUuid(dashboardId));
    }, [dashboardId]);

    return (
        <>
            <div id="dashboard" />
        </>
    );
};

export default ReportingDashboard;
