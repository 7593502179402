import { all, call, put, takeLatest } from 'redux-saga/effects';
import * as types from '../../types/assetModellingTypes';
import {
    getNodeTypeDetailsService,
    setNodeDataService,
    getModelListService,
    addModelService,
    deleteModelService,
    editModelService,
    getMappedTagsListService,
    getModelDetailsByIdService,
    getTagListByDeviceService,
    mapTagNodesService,
    getDataTypeListService,
    getUnitsListService,
    updateTagFactorService,
    setEquipmentClassService,
    getEquipmentClassListService,
    getNodeTypeIdService,
    editEquipmentClassService,
    equipmentClassCloneService,
    nodeTypeCloneService,
    nodeTypeDeleteService,
    getEquipmentIdService,
    equipmentClassDeleteService,
    getTreeByModelIdService,
    getNodeDetailsByIdService,
    deleteNodeService,
    addNodeService,
    unMapTagNodesService,
    editNodeService,
    NodeTypeUpdateService,
} from 'redux/services/AssetModellingServices/assetModellingServices';
import { message } from 'antd';
import { PAGE, PAGE_SIZE } from 'utils/constants';

export function* setNodeDataSaga({
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    const params = {
        name: payload?.name,
        description: payload?.description,
        categories: payload?.categories,
    };
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const setNodeDataDetails = yield call(setNodeDataService, params);
        yield put({
            type: types.SET_NODE_DATA_SUCCESS,
            response: setNodeDataDetails,
        });
        message?.success('Node Type created successfully');
        if (setNodeDataDetails) {
            const getNodeTypeDetails = yield call(
                getNodeTypeDetailsService,{
                    payload,
                    page: PAGE,
                    pageSize: PAGE_SIZE,
            }
            );
            yield put({
                type: types.GET_NODE_TYPE_DETAILS_SUCCESS,
                response: getNodeTypeDetails,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({
            type: types.SET_NODE_DATA_FAILURE,
            response: error,
        });
    }
}

export function* getNodeTypeDetailsSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getNodeTypeDetails = yield call(
            getNodeTypeDetailsService,
            payload
        );
        yield put({
            type: types.GET_NODE_TYPE_DETAILS_SUCCESS,
            response: getNodeTypeDetails,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({
            type: types.GET_NODE_TYPE_DETAILS_FAILURE,
            response: error,
        });
    }
}
export function* setEquipmentClassSaga({
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    const params = {
        name: payload?.name,
        description: payload?.description,
        categories: payload?.categories,
    };
    
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const setEquipmentClass = yield call(setEquipmentClassService, params);
        yield put({
            type: types.SET_EQUIPMENT_DATA_SUCCESS,
            response: setEquipmentClass,
        });
        message?.success('Equipment class created successfully');
        if (setEquipmentClass) {
            const getEquipmentClasseDetails = yield call(
                getEquipmentClassListService,
                payload?.paginatedPayload
            );
            yield put({
                type: types.GET_EQUIPMENT_DETAILS_SUCCESS,
                response: getEquipmentClasseDetails,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({
            type: types.SET_EQUIPMENT_DATA_FAILURE,
            response: error,
        });
    }
}

export function* getEquipmentClassListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getEquipmentClasseDetails = yield call(
            getEquipmentClassListService,
            payload
        );
        yield put({
            type: types.GET_EQUIPMENT_DETAILS_SUCCESS,
            response: getEquipmentClasseDetails,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({
            type: types.GET_EQUIPMENT_DETAILS_FAILURE,
            response: error,
        });
    }
}

export function* getModelListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getModelListData = yield call(getModelListService, payload);
        yield put({
            type: types.GET_TOTAL_MODEL_LIST_SUCCESS,
            response: getModelListData,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_TOTAL_MODEL_LIST_FAILURE });
    }
}
export function* NodeTypeUpdateSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });

        const NodeTypeUpdateData = yield call(NodeTypeUpdateService, payload);
        yield put({
            type: types.PUT_NODE_TYPE_UPDATE_SUCCESS,
            response: NodeTypeUpdateData,
        });
        message?.success('Node Type saved successfully');
        if (NodeTypeUpdateData) {
            const getNodeTypeDetails = yield call(
                getNodeTypeDetailsService,{
                    payload,
                    page: PAGE,
                    pageSize: PAGE_SIZE,
            }
            );
            yield put({
                type: types.GET_NODE_TYPE_DETAILS_SUCCESS,
                response: getNodeTypeDetails,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {}
}

export function* addModelSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });

        const modelDetails = yield call(addModelService, payload);
        yield put({
            type: types.SET_MODEL_DETAILS_SUCCESS,
            response: modelDetails,
        });
        message?.success('Model created successfully');

        if (modelDetails) {
            const getModelListData = yield call(getModelListService, payload);
            yield put({
                type: types.GET_TOTAL_MODEL_LIST_SUCCESS,
                response: getModelListData,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {}
}

export function* getModelDetailsByIdSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        const getModelDetails = yield call(getModelDetailsByIdService, payload);
        yield put({
            type: types.GET_MODEL_DETAILS_BY_ID_SUCCESS,
            response: getModelDetails,
        });
    } catch (error: any) {}
}

export function* deleteModelSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });

        const deleteModel = yield call(deleteModelService, payload);
        yield put({
            type: types.DELETE_MODEL_SUCCESS,
            response: deleteModel,
        });
        message?.success('Model deleted successfully');
        if (deleteModel) {
            const getModelListData = yield call(getModelListService, payload);
            yield put({
                type: types.GET_TOTAL_MODEL_LIST_SUCCESS,
                response: getModelListData,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {}
}

export function* editModelSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });

        const editModelDetails = yield call(editModelService, payload);
        yield put({
            type: types.EDIT_MODEL_DETAILS_SUCCESS,
            response: editModelDetails,
        });
        message?.success('Model details saved successfully');
        if (editModelDetails) {
            const getModelListData = yield call(getModelListService, payload);
            yield put({
                type: types.GET_TOTAL_MODEL_LIST_SUCCESS,
                response: getModelListData,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {}
}
export function* getNodeTypeIdSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getNodeTypeId = yield call(getNodeTypeIdService, payload);
        yield put({
            type: types.GET_NODE_TYPE_BY_ID_SUCCESS,
            response: getNodeTypeId,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({
            type: types.GET_NODE_TYPE_BY_ID_FAILURE,
            response: error,
        });
    }
}

export function* getTagListByDeviceSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getTagListByDevice = yield call(
            getTagListByDeviceService,
            payload
        );
        yield put({
            type: types.GET_TAG_LIST_BY_DEVICE_SUCCESS,
            response: getTagListByDevice,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_TAG_LIST_BY_DEVICE_FAILURE });
    }
}

export function* putEquipmentClassUpdateSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const putEquipmentClassUpdate = yield call(
            editEquipmentClassService,
            payload
        );

        yield put({
            type: types.PUT_EQUIPMENT_CLASS_UPDATE_SUCCESS,
            response: putEquipmentClassUpdate,
        });
        message?.success('Equipment Class saved successfully');
        if (putEquipmentClassUpdate) {
            const getEquipmentClasseDetails = yield call(
                getEquipmentClassListService,{
                    payload,
                    page: PAGE,
                    pageSize: PAGE_SIZE,
            }
            );
            yield put({
                type: types.GET_EQUIPMENT_DETAILS_SUCCESS,
                response: getEquipmentClasseDetails,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.PUT_EQUIPMENT_CLASS_UPDATE_FAILURE });
    }
}
export function* equipmentClassCloneSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const equipmentClassClone = yield call(
            equipmentClassCloneService,
            payload
        );
        yield put({
            type: types.CLONE_EQUIPMENT_CLASS_SUCCESS,
            response: equipmentClassClone,
        });
        message?.success('Equipment class cloned successfully');
        if (equipmentClassClone) {
            const getEquipmentClasseDetails = yield call(
                getEquipmentClassListService,{
                payload,
                page: PAGE,
                pageSize: PAGE_SIZE,
        });
            yield put({
                type: types.GET_EQUIPMENT_DETAILS_SUCCESS,
                response: getEquipmentClasseDetails,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.CLONE_EQUIPMENT_CLASS_FAILURE });
    }
}
export function* nodeTypeCloneSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const nodeTypeClone = yield call(nodeTypeCloneService, payload);
        yield put({
            type: types.CLONE_NODE_TYPE_SUCCESS,
            response: nodeTypeClone,
        });
        message?.success('Node Type cloned successfully');
        if (nodeTypeClone) {
            const getNodeTypeDetails = yield call(
                getNodeTypeDetailsService,{
                payload,
                page: PAGE,
                pageSize: PAGE_SIZE,
        });
            yield put({
                type: types.GET_NODE_TYPE_DETAILS_SUCCESS,
                response: getNodeTypeDetails,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.CLONE_NODE_TYPE_FAILURE });
    }
}
export function* nodeTypeDeleteSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const nodeTypeDelete = yield call(nodeTypeDeleteService, payload);

        yield put({
            type: types.DELETE_NODE_TYPE_SUCCESS,
            response: nodeTypeDelete,
        });
        message?.success('Node type deleted successfully');
        if (nodeTypeDelete) {
            const getNodeTypeDetails = yield call(
                getNodeTypeDetailsService,{
                    payload,
                    page: PAGE,
                    pageSize: PAGE_SIZE,
            }
            );
            yield put({
                type: types.GET_NODE_TYPE_DETAILS_SUCCESS,
                response: getNodeTypeDetails,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.DELETE_NODE_TYPE_FAILURE });
    }
}
export function* getMappedTagsListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getMappedTagsList = yield call(getMappedTagsListService, payload);
        yield put({
            type: types.GET_MAPPED_TAGS_LIST_SUCCESS,
            response: getMappedTagsList,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_MAPPED_TAGS_LIST_FAILURE });
    }
}
export function* equipmentClassDeleteSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const equipmentClassDelete = yield call(
            equipmentClassDeleteService,
            payload
        );
        yield put({
            type: types.DELETE_EQUIPMENT_CLASS_SUCCESS,
            response: equipmentClassDelete,
        });
        message?.success('Equipment class deleted successfully');
        if (equipmentClassDelete) {
            const getEquipmentClasseDetails = yield call(
                getEquipmentClassListService,{
                payload,
                page: PAGE,
                pageSize: PAGE_SIZE,
        });
            yield put({
                type: types.GET_EQUIPMENT_DETAILS_SUCCESS,
                response: getEquipmentClasseDetails,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.DELETE_EQUIPMENT_CLASS_FAILURE });
    }
}

export function* getEquipmentClassIdSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getEquipmentClassId = yield call(getEquipmentIdService, payload);
        yield put({
            type: types.GET_EQUIPMENT_CLASS_BY_ID_SUCCESS,
            response: getEquipmentClassId,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({
            type: types.GET_EQUIPMENT_CLASS_BY_ID_FAILURE,
            response: error,
        });
    }
}

export function* mapTagNodesSaga({
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const mapTagNodesDetails = yield call(mapTagNodesService, payload);
        yield put({
            type: types.MAP_TAG_NODES_SUCCESS,
            response: mapTagNodesDetails,
        });
        if (mapTagNodesDetails) {
            const getMappedTagsList = yield call(
                getMappedTagsListService,
                payload
            );
            yield put({
                type: types.GET_MAPPED_TAGS_LIST_SUCCESS,
                response: getMappedTagsList,
            });
            const getTagListByDevice = yield call(
                getTagListByDeviceService,
                payload
            );
            yield put({
                type: types.GET_TAG_LIST_BY_DEVICE_SUCCESS,
                response: getTagListByDevice,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({
            type: types.MAP_TAG_NODES_FAILURE,
            response: error,
        });
    }
}

export function* getDataTypeListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getDataTypeList = yield call(getDataTypeListService, payload);
        yield put({
            type: types.GET_DATATYPE_LIST_SUCCESS,
            response: getDataTypeList,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_DATATYPE_LIST_FAILURE });
    }
}

export function* getUnitsListSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getUnitsList = yield call(getUnitsListService, payload);
        yield put({
            type: types.GET_UNITS_LIST_SUCCESS,
            response: getUnitsList,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_UNITS_LIST_FAILURE });
    }
}

export function* updateTagFactorSaga({
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const updateTagFactor = yield call(updateTagFactorService, payload);
        yield put({
            type: types.UPDATE_TAG_FACTOR_SUCCESS,
            response: updateTagFactor,
        });
        if (updateTagFactor) {
            const getMappedTagsList = yield call(
                getMappedTagsListService,
                payload
            );
            yield put({
                type: types.GET_MAPPED_TAGS_LIST_SUCCESS,
                response: getMappedTagsList,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({
            type: types.UPDATE_TAG_FACTOR_FAILURE,
            response: error,
        });
    }
}

export function* getTreeByModelIdSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getTreeByModelId = yield call(getTreeByModelIdService, payload);
        yield put({
            type: types.GET_TREE_BY_MODEL_ID_SUCCESS,
            response: getTreeByModelId,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_TREE_BY_MODEL_ID_FAILURE });
    }
}

export function* getNodeDetailsByIdSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const getNodeDetailsById = yield call(
            getNodeDetailsByIdService,
            payload
        );
        yield put({
            type: types.GET_NODE_DETAILS_BY_ID_SUCCESS,
            response: getNodeDetailsById,
        });
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.GET_NODE_DETAILS_BY_ID_FAILURE });
    }
}

export function* deleteNodeSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const deleteNodeDetails = yield call(deleteNodeService, payload);
        yield put({
            type: types.DELETE_NODE_SUCCESS,
            response: deleteNodeDetails,
        });
        message?.success('Node deleted successfully');
        if (deleteNodeDetails) {
            const getTreeByModelId = yield call(
                getTreeByModelIdService,
                payload?.modelId
            );
            yield put({
                type: types.GET_TREE_BY_MODEL_ID_SUCCESS,
                response: getTreeByModelId,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.DELETE_NODE_FAILURE });
    }
}

export function* addNodeSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const addNodeDetails = yield call(addNodeService, payload);
        yield put({
            type: types.ADD_NODE_SUCCESS,
            response: addNodeDetails,
        });
        if (addNodeDetails) {
            const getTreeByModelId = yield call(
                getTreeByModelIdService,
                payload?.modelId
            );
            yield put({
                type: types.GET_TREE_BY_MODEL_ID_SUCCESS,
                response: getTreeByModelId,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.ADD_NODE_FAILURE });
    }
}

export function* unMapTagNodesSaga({
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const unMapTagNodesDetails = yield call(unMapTagNodesService, payload);
        yield put({
            type: types.REMOVE_MAPPING_SUCCESS,
            response: unMapTagNodesDetails,
        });
        if (unMapTagNodesDetails) {
            const getMappedTagsList = yield call(
                getMappedTagsListService,
                payload
            );
            yield put({
                type: types.GET_MAPPED_TAGS_LIST_SUCCESS,
                response: getMappedTagsList,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({
            type: types.REMOVE_MAPPING_FAILURE,
            response: error,
        });
    }
}

export function* editNodeSaga({
    type,
    payload,
}: {
    type: string;
    payload: any;
}): Generator<any> {
    try {
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: true });
        const editNodeDetails = yield call(editNodeService, payload);
        yield put({
            type: types.EDIT_NODE_DETAILS_SUCCESS,
            response: editNodeDetails,
        });
        message?.success('Node updated successfully');
        if (editNodeDetails) {
            const getTreeByModelId = yield call(
                getTreeByModelIdService,
                payload?.modelId
            );
            yield put({
                type: types.GET_TREE_BY_MODEL_ID_SUCCESS,
                response: getTreeByModelId,
            });
        }
        yield put({ type: types.SHOW_LOADER_SUCCESS, showLoader: false });
    } catch (error: any) {
        yield put({ type: types.EDIT_NODE_DETAILS_FAILURE });
    }
}

export function* assetModellingSaga(): any {
    yield all([
        takeLatest(types.GET_NODE_TYPE_DETAILS, getNodeTypeDetailsSaga),
    ]);
    yield all([takeLatest(types.SET_NODE_DATA, setNodeDataSaga)]);
    yield all([takeLatest(types.GET_TOTAL_MODEL_LIST, getModelListSaga)]);
    yield all([takeLatest(types.SET_MODEL_DETAILS, addModelSaga)]);
    yield all([
        takeLatest(types.GET_MODEL_DETAILS_BY_ID, getModelDetailsByIdSaga),
    ]);
    yield all([takeLatest(types.DELETE_MODEL, deleteModelSaga)]);
    yield all([takeLatest(types.EDIT_MODEL_DETAILS, editModelSaga)]);
    yield all([takeLatest(types.PUT_NODE_TYPE_UPDATE, NodeTypeUpdateSaga)]);
    yield all([
        takeLatest(types.GET_TAG_LIST_BY_DEVICE, getTagListByDeviceSaga),
    ]);
    yield all([takeLatest(types.SET_EQUIPMENT_DATA, setEquipmentClassSaga)]);
    yield all([
        takeLatest(types.GET_EQUIPMENT_DETAILS, getEquipmentClassListSaga),
    ]);
    yield all([takeLatest(types.GET_NODE_TYPE_BY_ID, getNodeTypeIdSaga)]);
    yield all([
        takeLatest(
            types.PUT_EQUIPMENT_CLASS_UPDATE,
            putEquipmentClassUpdateSaga
        ),
    ]);
    yield all([
        takeLatest(
            types.CLONE_EQUIPMENT_CLASS_REQUEST,
            equipmentClassCloneSaga
        ),
    ]);
    yield all([takeLatest(types.CLONE_NODE_TYPE_REQUEST, nodeTypeCloneSaga)]);
    yield all([takeLatest(types.DELETE_NODE_TYPE, nodeTypeDeleteSaga)]);
    yield all([
        takeLatest(types.DELETE_EQUIPMENT_CLASS, equipmentClassDeleteSaga),
    ]);
    yield all([
        takeLatest(types.GET_EQUIPMENT_CLASS_BY_ID, getEquipmentClassIdSaga),
    ]);
    yield all([takeLatest(types.GET_MAPPED_TAGS_LIST, getMappedTagsListSaga)]);
    yield all([takeLatest(types.MAP_TAG_NODES, mapTagNodesSaga)]);
    yield all([takeLatest(types.GET_DATATYPE_LIST, getDataTypeListSaga)]);
    yield all([takeLatest(types.GET_UNITS_LIST, getUnitsListSaga)]);
    yield all([takeLatest(types.UPDATE_TAG_FACTOR, updateTagFactorSaga)]);
    yield all([takeLatest(types.GET_TREE_BY_MODEL_ID, getTreeByModelIdSaga)]);
    yield all([
        takeLatest(types.GET_NODE_DETAILS_BY_ID, getNodeDetailsByIdSaga),
    ]);
    yield all([takeLatest(types.DELETE_NODE, deleteNodeSaga)]);
    yield all([takeLatest(types.ADD_NODE, addNodeSaga)]);
    yield all([takeLatest(types.REMOVE_MAPPING, unMapTagNodesSaga)]);
    yield all([takeLatest(types.EDIT_NODE_DETAILS, editNodeSaga)]);
}
